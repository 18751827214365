import { Box, Grid, TextField } from '@mui/material';
import SubSubtitle from 'components/SubSubtitle';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';

function UppercasePolicyForm({ minUppercase, minUppercaseError, maxUppercase, maxUppercaseError, onChange }) {
  const { t } = useTranslation();

  return (
    <Box marginBottom="48px">
      <SubSubtitle>{t('organization.organization-settings.password-policy.subsubtitle-uppercase')}</SubSubtitle>

      <Grid container direction="row" rowSpacing="16px" columnSpacing={1} sx={{ marginTop: '4px' }}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="min-uppercase"
            value={minUppercase}
            onChange={(event) => onChange(filterNumbers(event.target.value), maxUppercase)}
            label={t('organization.organization-settings.password-policy.min-label')}
            variant="outlined"
            error={Boolean(minUppercaseError)}
            helperText={minUppercaseError}
            data-testid="min-uppercase-text-field"
            inputProps={{ 'data-testid': 'min-uppercase-input' }}
            FormHelperTextProps={{ 'data-testid': 'min-uppercase-error' }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="max-uppercase"
            value={maxUppercase}
            onChange={(event) => onChange(minUppercase, filterNumbers(event.target.value))}
            label={t('organization.organization-settings.password-policy.max-label')}
            variant="outlined"
            error={Boolean(maxUppercaseError)}
            helperText={maxUppercaseError}
            data-testid="max-uppercase-text-field"
            inputProps={{ 'data-testid': 'max-uppercase-input' }}
            FormHelperTextProps={{ 'data-testid': 'max-uppercase-error' }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UppercasePolicyForm;
