import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.css';

const Badge = ({ children, className, type = 'default', ...props }) => (
  <span className={classnames(styles.badge, styles[type], className)} {...props}>
    {children}
  </span>
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default Badge;
