import { Box, Grid, TextField } from '@mui/material';
import SubSubtitle from 'components/SubSubtitle';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';

function LengthPolicyForm({ minLength, maxLength, minLengthError, maxLengthError, onChange }) {
  const { t } = useTranslation();

  return (
    <Box marginBottom="48px">
      <SubSubtitle>{t('organization.organization-settings.password-policy.subsubtitle-length')}</SubSubtitle>
      <Grid container direction="row" rowSpacing="16px" columnSpacing={1} sx={{ marginTop: '4px' }}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="min-length"
            value={minLength}
            onChange={(event) => onChange(filterNumbers(event.target.value), maxLength)}
            label={t('organization.organization-settings.password-policy.min-label')}
            variant="outlined"
            error={Boolean(minLengthError)}
            helperText={minLengthError}
            data-testid="min-length-text-field"
            inputProps={{ 'data-testid': 'min-length-input' }}
            FormHelperTextProps={{ 'data-testid': 'min-length-error' }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="max-length"
            value={maxLength}
            onChange={(event) => onChange(minLength, filterNumbers(event.target.value))}
            label={t('organization.organization-settings.password-policy.max-label')}
            variant="outlined"
            error={Boolean(maxLengthError)}
            helperText={maxLengthError}
            data-testid="max-length-text-field"
            inputProps={{ 'data-testid': 'max-length-input' }}
            FormHelperTextProps={{ 'data-testid': 'max-length-error' }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default LengthPolicyForm;
