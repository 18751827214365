import { Box, Grid, TextField } from '@mui/material';
import SubSubtitle from 'components/SubSubtitle';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';

function LowercasePolicyForm({ minLowercase, minLowercaseError, maxLowercase, maxLowercaseError, onChange }) {
  const { t } = useTranslation();

  return (
    <Box marginBottom="48px">
      <SubSubtitle>{t('organization.organization-settings.password-policy.subsubtitle-lowercase')}</SubSubtitle>

      <Grid container direction="row" rowSpacing="16px" columnSpacing={1} sx={{ marginTop: '4px' }}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="min-lowercase"
            value={minLowercase}
            onChange={(event) => onChange(filterNumbers(event.target.value), maxLowercase)}
            label={t('organization.organization-settings.password-policy.min-label')}
            variant="outlined"
            error={Boolean(minLowercaseError)}
            helperText={minLowercaseError}
            data-testid="min-lowercase-text-field"
            inputProps={{ 'data-testid': 'min-lowercase-input' }}
            FormHelperTextProps={{ 'data-testid': 'min-lowercase-error' }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="max-lowercase"
            value={maxLowercase}
            onChange={(event) => onChange(minLowercase, filterNumbers(event.target.value))}
            label={t('organization.organization-settings.password-policy.max-label')}
            variant="outlined"
            error={Boolean(maxLowercaseError)}
            helperText={maxLowercaseError}
            data-testid="max-lowercase-text-field"
            inputProps={{ 'data-testid': 'max-lowercase-input' }}
            FormHelperTextProps={{ 'data-testid': 'max-lowercase-error' }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default LowercasePolicyForm;
