import { useEffect, useState } from 'react';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import Header from 'components/dialogs/DialogHeader';
import Button from 'components/Button';
import Loader from 'components/SectionLoader';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedUnit, setOrganizationSelectedUnit } from 'features/organizationSlice';
import { deleteUnit, fetchUnit, updateUnit } from 'api/portal';
import { showErrorToast } from 'features/toastSlice';
import { Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import Fade from 'components/Fade';
import App from '../template';

function EditUnit() {
  const unit = useSelector((state) => state.organization.selectedUnit);

  const [name, setName] = useState(unit.name || '');
  const [additionalInformation, setAdditionalInformation] = useState(unit.additional_information || '');
  const [id, setId] = useState(unit.id || -1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isToShowDeleteDialog, setIsToShowDeleteDialog] = useState(false);
  const [errors, setErrors] = useState(new Map());

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();

  const REQUIRED_FIELD = t('messages.fill-required');

  const { unitId } = params;

  const handleGetUnitRequest = (id) => {
    setIsLoading(true);

    fetchUnit(id)
      .then((response) => {
        const { data } = response;

        dispatch(setOrganizationSelectedUnit(data));
        setAdditionalInformation(data.additional_information);
        setId(data.id);
        setName(data.name);
        setIsLoading(false);
      })
      .catch(() => {
        navigate('/organization/units');
      });
  };

  useEffect(() => {
    if (unit.id !== Number(unitId)) {
      dispatch(clearOrganizationSelectedUnit());
      handleGetUnitRequest(unitId);
    }
  }, []);

  const handleOnSaveChanges = () => {
    const errors = new Map();

    if (!name) {
      errors.set('name', REQUIRED_FIELD);
    }

    if (!additionalInformation) {
      errors.set('additionalInformation', REQUIRED_FIELD);
    }

    if (errors.size > 0) {
      setErrors(errors);
      return;
    }

    setErrors(errors);
    setIsSaving(true);

    updateUnit(id, {
      name,
      additional_information: additionalInformation,
    })
      .then(() => {
        setIsSaving(false);
        navigate('/organization/units');
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(showErrorToast('messages.save-failure'));
      });
  };

  const handleDeleteUnit = () => {
    setIsDeleting(true);
    setIsToShowDeleteDialog(false);

    deleteUnit(id)
      .then(() => {
        setIsDeleting(false);
        navigate('/organization/units');
      })
      .catch(() => {
        setIsDeleting(false);
        dispatch(showErrorToast('messages.delete-failure'));
      });
  };

  const renderDeleteDialog = () => (
    <Dialog open={isToShowDeleteDialog} data-testid="delete-dialog">
      <Header>{t('organization.organization-units-edit-delete-dialog.title')}</Header>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-units-edit-delete-dialog.text')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={() => setIsToShowDeleteDialog(false)} data-testid="dialog-button-cancel">
          {t('organization.organization-units-edit-delete-dialog.button-cancel')}
        </Button>
        <Button fullWidth color="gradient" onClick={handleDeleteUnit} data-testid="dialog-button-delete">
          {t('organization.organization-units-edit-delete-dialog.button-delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <App>
      <Fade>
        <form onSubmit={(event) => event.preventDefault()}>
          <Section>
            <Subtitle>{t('organization.organization-units-edit.title-sub-unit-info')}</Subtitle>
            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('organization.organization-units-edit.text-unit-edit-info')}
            </Typography>

            <Loader loading={isLoading}>
              <TextField
                sx={{ margin: '16px 0 0 0' }}
                value={name}
                onChange={(event) => setName(event.target.value)}
                label={t('organization.organization-units-edit.input-unit-name')}
                variant="outlined"
                error={errors.has('name')}
                helperText={errors.get('name')}
                data-testid="text-field-unit-name"
                inputProps={{ 'data-testid': 'input-unit-name' }}
                FormHelperTextProps={{ 'data-testid': 'helper-text-unit-name' }}
                fullWidth
              />

              <TextField
                sx={{ margin: '32px 0 0 0' }}
                value={additionalInformation}
                onChange={(event) => setAdditionalInformation(event.target.value)}
                label={t('organization.organization-units-edit.input-unit-info')}
                variant="outlined"
                error={errors.has('additionalInformation')}
                helperText={errors.get('additionalInformation')}
                data-testid="text-field-unit-info"
                inputProps={{ 'data-testid': 'input-unit-info' }}
                FormHelperTextProps={{ 'data-testid': 'helper-text-unit-info' }}
                fullWidth
              />
            </Loader>
          </Section>
          <Section>
            <Subtitle>{t('organization.organization-units-edit.title-sub-unit-delete')}</Subtitle>
            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('organization.organization-units-edit.text-unit-edit-delete')}
            </Typography>
            <Button
              disabled={isLoading}
              loading={isDeleting}
              onClick={() => setIsToShowDeleteDialog(true)}
              variant="outlined"
              color="error"
              data-testid="button-delete-unit"
            >
              {t('organization.organization-units-edit.button-unit-delete')}
            </Button>
          </Section>

          <Button
            color="gradient"
            type="submit"
            disabled={isLoading}
            loading={isSaving}
            onClick={handleOnSaveChanges}
            data-testid="button-save"
          >
            {t('organization.organization-units-edit.button-save')}
          </Button>
          <Button
            sx={{ margin: '0 0 0 16px' }}
            variant="outlined"
            onClick={() => navigate('/organization/units')}
            data-testid="button-cancel"
          >
            {t('organization.organization-units-edit.button-cancel')}
          </Button>
        </form>
      </Fade>
      {renderDeleteDialog()}
    </App>
  );
}

export default EditUnit;
