import AppMenu from 'components/templates/app-menu';
import { useTranslation } from 'react-i18next';

function Organization({ children }) {
  const { pathname, search } = window.location;

  const { t } = useTranslation();

  const INFO_TEXT = t('organization.organization-settings.info.menu-item');
  const INFO_LINK = '/organization/info';
  const PASSWORD_POLICY_TEXT = t('organization.organization-settings.password-policy.menu-item');
  const PASSWORD_POLICY_LINK = '/organization/password-policy';
  const AUTO_LOGOUT_TEXT = t('organization.organization-settings.inactivity-policy.menu-item');
  const AUTO_LOGOUT_LINK = '/organization/inactivity-policy';
  const IP_BLOCKING_TEXT = t('organization.organization-settings.ip-blocking-policy.menu-item');
  const IP_BLOCKING_LINK = '/organization/ip-policy';
  const REPORTS_TEXT = t('organization.organization-settings.reports.menu-item');
  const REPORTS_LINK = '/organization/reports';

  const HOME = t('organization.breadcrumbs.home');
  const ORGANIZATION = t('organization.organization-settings.title-main-organization');

  const getBreadcrumb = (path) => {
    switch (path) {
      case INFO_LINK:
        return INFO_TEXT;
      case PASSWORD_POLICY_LINK:
        return PASSWORD_POLICY_TEXT;
      case AUTO_LOGOUT_LINK:
        return AUTO_LOGOUT_TEXT;
      case IP_BLOCKING_LINK:
        return IP_BLOCKING_TEXT;
      case REPORTS_LINK:
        return REPORTS_TEXT;
      default:
        return '';
    }
  };

  const BREADCRUMBS = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: INFO_LINK,
      title: ORGANIZATION,
    },
    {
      path: `${pathname}${search}`,
      title: getBreadcrumb(pathname),
      isActive: true,
    },
  ];

  const SIDE_MENU = [
    {
      href: INFO_LINK,
      text: INFO_TEXT,
      isActive: pathname === INFO_LINK,
    },
    {
      href: PASSWORD_POLICY_LINK,
      text: PASSWORD_POLICY_TEXT,
      isActive: pathname === PASSWORD_POLICY_LINK,
    },
    {
      href: AUTO_LOGOUT_LINK,
      text: AUTO_LOGOUT_TEXT,
      isActive: pathname === AUTO_LOGOUT_LINK,
    },
    {
      href: IP_BLOCKING_LINK,
      text: IP_BLOCKING_TEXT,
      isActive: pathname === IP_BLOCKING_LINK,
    },
    {
      href: REPORTS_LINK,
      text: REPORTS_TEXT,
      isActive: pathname === REPORTS_LINK,
    },
  ];

  return (
    <AppMenu
      breadcrumbs={BREADCRUMBS}
      title={t('organization.organization-settings.title-main-organization')}
      menuItems={SIDE_MENU}
    >
      {children}
    </AppMenu>
  );
}

export default Organization;
