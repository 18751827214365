import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import CardBadge from 'components/CardBadge';
import mobileDeviceIcon from 'assets/images/ic_mobile.svg';
import { isTrustedDevice } from 'utils/mobile-devices';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const STATUS_MAP_BADGE = {
  TRUSTED: 'success',
  NORMAL: 'secondary',
  BLOCKED: 'danger',
};

const STATUS_MAP_TEXT = {
  TRUSTED: 'success',
  NORMAL: 'secondary',
  BLOCKED: 'error',
};

const MobileDeviceCard = ({ device = {}, onClick, sx, ...props }) => {
  const lastAccessDate = device.last_access ? new Date(device.last_access).toLocaleString(i18next.language) : '-';
  const deviceStatus = device.status.toLowerCase();
  const syncedMembers = device.synced_members;

  const { t } = useTranslation();

  const TEXT_MODEL = t('organization.organization-mobile-devices-info.text-model');
  const TEXT_UID = t('organization.organization-mobile-devices-info.text-uid');
  const TEXT_APP_VERSION = t('organization.organization-mobile-devices-info.text-app-version');
  const TEXT_STATUS = t('organization.organization-mobile-devices-info.text-device-status');
  const TEXT_SYNCED_MEMBERS = t('organization.organization-mobile-devices-info.text-pending-syncs');
  const TEXT_OF = t('organization.organization-mobile-devices-info.text-of');

  return (
    <Card
      sx={{ display: 'flex', flexDirection: 'column', padding: 0, ...sx }}
      onClick={onClick}
      role="presentation"
      {...props}
    >
      <CardBadge variant={STATUS_MAP_BADGE[device.status]} lock={device.status === 'BLOCKED'} />
      <CardHeader
        avatar={
          <Box
            sx={{
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              marginRight: '16px',
              backgroundColor: 'primary.light',
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={mobileDeviceIcon} alt="Device icon" />
          </Box>
        }
        sx={{ alignItems: 'flex-start', flexGrow: 0, padding: '24px 24px 0 24px' }}
        title={
          <div>
            <Typography color="primary.light" variant="body1" marginBottom="4px">
              {device.name}
            </Typography>
            <Typography color="secondary" variant="body2" marginBottom="4px">
              {TEXT_MODEL}: {device.model}
            </Typography>
            <Typography color="secondary" variant="body2" marginBottom="4px">
              {TEXT_UID}: {device.org_device_id}
            </Typography>
            <Typography color="secondary" variant="body2" marginBottom="4px">
              {TEXT_APP_VERSION}: {device.app_version}
            </Typography>
            <Typography color="secondary" variant="body2" marginBottom="4px">
              {TEXT_STATUS}:{' '}
              <Typography color={STATUS_MAP_TEXT[device.status]} fontWeight="bold" variant="bold2">
                {t(`organization.organization-mobile-devices-info.${deviceStatus}`)}
              </Typography>
            </Typography>
            {isTrustedDevice(device.status) && syncedMembers ? (
              <Typography color="secondary" variant="body2">
                {TEXT_SYNCED_MEMBERS} : {syncedMembers.nb_synced_members} {TEXT_OF} {syncedMembers.nb_members}
              </Typography>
            ) : null}
          </div>
        }
      />
      <CardContent
        sx={{
          padding: '16px 0 0 0',
          display: 'flex',
          alignItems: 'flex-end',
          flexGrow: 1,
          '&:last-child': {
            padding: '16px 0 0 0',
          },
        }}
      >
        <Typography
          sx={{ backgroundColor: 'var(--color-grey-light)' }}
          color="secondary"
          variant="caption"
          width="100%"
          padding="8px 24px"
        >
          {t('organization.organization-mobile-devices-info.text-last-access', { last_access: lastAccessDate })}
        </Typography>
      </CardContent>
    </Card>
  );
};

MobileDeviceCard.propTypes = {
  onClick: PropTypes.func,
  device: PropTypes.object,
};

export default MobileDeviceCard;
