import { fetchMember } from 'api/portal';
import { useEffect } from 'react';

export function useFetchMember({ memberId, onSuccess = () => {}, onFailure = () => {} }) {
  useEffect(() => {
    fetchMember(memberId)
      .then((response) => {
        const { data } = response;
        onSuccess(data);
      })
      .catch(() => {
        onFailure();
      });
  }, []);
}
