import ActiveAccountItem from 'components/ActiveAccount';
import AccountItem from 'components/AccountItem';
import Apps from 'components/Apps';
import BCAvatar from 'assets/images/braincare-user/organization-avatar.png';
import BraincareLogo from 'assets/images/logo-braincare.png';
import Navbar from 'components/AppBar';
import NavbarProfile from 'components/NavbarProfile';
import Notifications from 'components/Notifications';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setApplications } from 'features/applicationsSlice';
import { clearNotifications } from 'features/notificationsSlice';
import {
  STATUS_FAILED,
  clearActiveOrganizationData,
  clearOrganizationProfileData,
  setCorporateProfileData,
  setIsBraincareUser,
  updateOrganizations,
  updateProfile,
} from 'features/userSlice';
import { braincareApps } from 'utils/braincare-admins';
import { directLogin, fetchApplications } from 'api/portal';
import { setToken } from 'utils/tokens';
import { showWarningToast } from 'features/toastSlice';
import Button from 'components/Button';
import { Box, Divider, Popover, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import MenuTranslation from 'components/MenuTranslator';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import UpdatePasswordDialog from '../dialogs/UpdatePasswordDialog';
import LoginDialog from '../dialogs/LoginDialog';

function Menu() {
  const [anchorEl, setAnchorEl] = useState(null);

  const [account, setAccount] = useState(undefined);

  const [isLoadingApplications, setIsLoadingApplication] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(new Map());

  const [credentials, setCredentials] = useState({});
  const [passwordUpdateType, setPasswordUpdateType] = useState(undefined);
  const [passwordPolicy, setPasswordPolicy] = useState({});
  const [skipSettings, setSkipSettings] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const applications = useSelector((state) => state.applications);
  const user = useSelector((state) => state.user);

  const { isBraincareUser, organizations, profile } = user;
  const organizationProfile = user.organization_profile;
  const activeOrganization = user.active_organization;
  const braincarePermissions = profile.braincare_permissions ? profile.braincare_permissions : [];

  const IP_BLOCKED = t('auth.login.ip-blocked');
  const CORPORATE_LOGIN_PASSWORD_ERROR = t('auth.accounts-corp-login.text-corp-login-pwd-error');

  const setBraincareApplications = () => {
    const permittedApps = braincareApps.filter((app) => braincarePermissions.indexOf(app.permission) !== -1);
    dispatch(setApplications({ data: permittedApps, isLoading: false }));
  };

  const fetchApplicationsHandler = () => {
    setIsLoadingApplication(true);
    dispatch(setApplications({ data: [], isLoading: true }));

    fetchApplications()
      .then((response) => {
        const { data } = response;
        dispatch(setApplications({ data, isLoading: false }));
        setIsLoadingApplication(false);
      })
      .catch(() => {
        setIsLoadingApplication(false);
        dispatch(setApplications({ data: [], isLoading: false }));
      });
  };

  useEffect(() => {
    if (isBraincareUser) {
      setBraincareApplications();
    } else {
      fetchApplicationsHandler();
    }

    dispatch(updateProfile());
    dispatch(updateOrganizations());
  }, []);

  useEffect(() => {
    if (isBraincareUser && braincarePermissions.length > 0) {
      setBraincareApplications();
    }
  }, [braincarePermissions.length]);

  useEffect(() => {
    if (user.status === STATUS_FAILED) {
      dispatch(showWarningToast('messages.error-update-data'));
    }
  }, [user.status]);

  const onClickBraincareUser = () => {
    dispatch(clearNotifications());
    dispatch(setIsBraincareUser(true));

    setBraincareApplications();

    dispatch(clearActiveOrganizationData());
    dispatch(clearOrganizationProfileData());

    navigate('/');
  };

  const getSelectedOrganization = () => {
    if (account) {
      if (account.physio_org_id) {
        return account;
      }
    }

    return undefined;
  };

  const handleCorporateLoginError = () => {
    setIsSubmitting(false);
    setErrors(new Map([['password', CORPORATE_LOGIN_PASSWORD_ERROR]]));
  };

  const onSuccessLogin = (data) => {
    const { roles } = data;

    setToken('access_token', data.access_token);
    setToken('refresh_token', data.refresh_token);

    dispatch(setCorporateProfileData(data));
    dispatch(clearNotifications());
    dispatch(setIsBraincareUser(false));
    fetchApplicationsHandler();
    setAccount(undefined);
    setIsSubmitting(false);

    if (roles.length === 1) {
      const role = roles[0];

      if (role.id === 1) {
        navigate('/organization/members');
        return;
      }

      if (role.id === 3) {
        navigate('/organization/physio');
        return;
      }
    }

    navigate('/');
  };

  const corporateLogin = (formData) => {
    directLogin({ document: profile.document, password: formData.password, account: account.id })
      .then((response) => {
        const { data } = response;
        onSuccessLogin(data);
      })
      .catch((error) => {
        const { response } = error;

        const { data } = response;

        setIsSubmitting(false);

        if (data.hasOwnProperty('update_password')) {
          setCredentials({ document: profile.document, orgId: account.id, password: formData.password });
          setPasswordUpdateType(data.update_password);
          setPasswordPolicy(data.password_policy);
          setSkipSettings(data.skip_settings);
          return;
        }

        if (data.hasOwnProperty('ip_blocked')) {
          setIsSubmitting(false);
          setErrors(new Map([['password', IP_BLOCKED]]));
          return;
        }

        handleCorporateLoginError();
      });
  };

  const onSuccessPasswordUpdate = (updatePasswordPromise) =>
    updatePasswordPromise.then((response) => {
      setPasswordUpdateType(undefined);
      const { data } = response;
      onSuccessLogin(data);
    });

  const renderActiveAccountAvatar = () => {
    if (isBraincareUser) {
      return (
        <ActiveAccountItem
          photo={BCAvatar}
          title={`${profile.first_name} ${profile.last_name}`}
          subtitle={t('auth.accounts-dropdown.label-sub-dropdown-braincare')}
          buttonText={t('auth.accounts-dropdown.button-account-config')}
          onClick={() => navigate('/account/member/edit/corporate-info')}
          data-testid="active-account-item"
        />
      );
    }

    return (
      <ActiveAccountItem
        photo={activeOrganization.photo}
        title={activeOrganization.name}
        subtitle={organizationProfile.email}
        buttonText={t('auth.accounts-dropdown.button-account-config')}
        onClick={() => navigate('/account/member/edit/corporate-info')}
        data-testid="active-account-item"
      />
    );
  };

  const renderAccountList = () => {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box width="408px" sx={{ padding: '24px' }}>
          {renderActiveAccountAvatar()}

          {!isBraincareUser && Boolean(braincarePermissions.length) && (
            <>
              <Typography color="primary.main" variant="body2" marginBottom="8px">
                {t('auth.accounts-dropdown.title-sub-dropdown-braincare')}
              </Typography>
              <AccountItem
                image={BCAvatar}
                title={`${profile.first_name} ${profile.last_name}`}
                subtitle={t('auth.accounts-dropdown.label-sub-dropdown-braincare')}
                onClick={() => onClickBraincareUser()}
                data-testid="braincare-account-item"
              />
            </>
          )}

          {((organizations.length > 1 && activeOrganization.id !== -1) ||
            (organizations.length > 0 && activeOrganization.id === -1)) && (
            // eslint-disable-next-line react/jsx-indent
            <Typography color="primary.main" variant="body2" marginBottom="8px">
              {t('auth.accounts-dropdown.title-sub-dropdown-accounts')}
            </Typography>
          )}

          {organizations
            .filter((item) => item.id !== activeOrganization.id)
            .map((item) => (
              <AccountItem
                key={item.id}
                image={item.photo}
                title={item.name}
                subtitle={item.address}
                onClick={() => setAccount(item)}
                data-testid={`organization-account-item-${item.id}`}
              />
            ))}

          {organizations.length > 0 && <Divider />}

          <Box sx={{ marginTop: '16px' }} display="flex" direction="row" justifyContent="center" alignItems="center">
            <Button
              variant="outlined"
              color="error"
              onClick={() => navigate('/auth/logout')}
              data-testid="logout-button"
            >
              {t('auth.accounts-dropdown.button-logout')}
            </Button>
          </Box>
        </Box>
      </Popover>
    );
  };

  return (
    <Navbar companyLogo={BraincareLogo} companyLogoText="Braincare logo" brandHref="/">
      <Notifications />
      <Apps applications={applications.data} isLoading={isLoadingApplications} />
      <div className={styles['help-icon-container']}>
        <HelpIcon
          sx={{ width: '28px', height: '28px' }}
          className={styles['help-icon']}
          onClick={() => {
            window.open(`https://${user.language === 'en-us' ? 'help' : 'suporte'}.brain4.care/`, '_blank');
          }}
        />
      </div>
      <MenuTranslation sx={{ margin: '0 16px 0 0' }} />
      <Box sx={{ cursor: 'pointer' }} onClick={(event) => setAnchorEl(event.currentTarget)} data-testid="menu-toggle">
        <NavbarProfile
          hasOrganization={activeOrganization.id !== -1 || isBraincareUser}
          profileImage={profile.photo}
          profileAlt="Member profile photo"
          userName={`${profile.first_name} ${profile.last_name}`}
          universityName={!isBraincareUser ? activeOrganization.name : t('auth.accounts-dropdown.navbar-org-braincare')}
          universityAlt="Organization image"
          universityFlag={!isBraincareUser ? activeOrganization.photo : BCAvatar}
          data-testid="authenticated-profile"
        />
      </Box>
      {renderAccountList()}
      <LoginDialog
        organization={getSelectedOrganization()}
        isOpen={account !== undefined}
        onClose={() => {
          setAccount(undefined);
          setErrors(new Map());
        }}
        isSubmitting={isSubmitting}
        onSubmit={(data) => {
          setIsSubmitting(true);
          corporateLogin(data);
        }}
        errors={errors}
      />
      <UpdatePasswordDialog
        credentials={credentials}
        isOpen={passwordUpdateType !== undefined}
        onClose={() => {
          setPasswordUpdateType(undefined);
          setSkipSettings(null);
          setAccount(undefined);
          setErrors(new Map());
        }}
        onSuccess={onSuccessPasswordUpdate}
        passwordPolicy={passwordPolicy}
        skipSettings={skipSettings}
        type={passwordUpdateType}
      />
    </Navbar>
  );
}

export default Menu;
