import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from 'components/Button';
import DialogHeader from 'components/dialogs/DialogHeader';
import { ANALYTICS_REPORTS_URL } from 'config/settings';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function AnalyticsDialog({ open, onClose, selectedAcquisition }) {
  const activeOrganization = useSelector((state) => state.user.active_organization);

  const protocolId = selectedAcquisition.acquisition_id;

  const protocolEncoded = btoa(protocolId);
  /* eslint-disable-next-line max-len */
  const analyticsURL = `${ANALYTICS_REPORTS_URL}/home#!/analysis-tool/return/Y2xvc2Vfd2luZG93/acquisitions/${protocolEncoded}`;
  const encodedAnalyticsURL = btoa(analyticsURL);
  const encodedLocation = btoa(window.location);
  const messageAnalytics = activeOrganization.country_new ? activeOrganization.country_new.message_analytics : '';
  /* eslint-disable-next-line max-len */
  const authAndAnalyticsRedirectURL = `${ANALYTICS_REPORTS_URL}/auth-gateway?goTo=${encodedAnalyticsURL}&ifFail=${encodedLocation}`;

  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader>{t('physio.physio-personal.popup-warning')}</DialogHeader>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="32px">
          {messageAnalytics}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button sx={{ margin: '0 0 0 16px' }} variant="outlined" onClick={onClose}>
          {t('physio.physio-personal.button-popup-cancel')}
        </Button>
        <form
          action={authAndAnalyticsRedirectURL}
          method="POST"
          target="_blank"
          onSubmit={() => {
            onClose();
          }}
        >
          <input type="hidden" value={Cookies.get('access_token')} name="token" />
          <Button color="gradient" type="submit">
            {t('physio.physio-personal.button-popup-proceed')}
          </Button>
        </form>
      </DialogActions>
    </Dialog>
  );
}

export default AnalyticsDialog;
