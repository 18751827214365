import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const DrawerItem = ({ isActive = false, href, children, ...props }) => (
  <Link className={classNames(styles.menuItemLink, { [styles.activeItem]: isActive })} to={href} {...props}>
    {children}
  </Link>
);

DrawerItem.propTypes = {
  isActive: PropTypes.bool,
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default DrawerItem;
