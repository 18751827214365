import i18next from 'i18next';
import PasswordValidator from './PasswordValidator';

export default class PersonalInformationValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { excludePersonalInformation } = passwordPolicy;

    let fields = '';

    if (excludePersonalInformation) {
      const fieldsTranslations = new Map([
        ['email', i18next.t('organization.password-rules.email-field')],
        ['phone_number', i18next.t('organization.password-rules.corporate-phone-number-field')],
        ['user__document', i18next.t('organization.password-rules.document-field')],
        ['user__first_name', i18next.t('organization.password-rules.first-name-field')],
        ['user__last_name', i18next.t('organization.password-rules.last-name-field')],
        ['organization__name', i18next.t('organization.password-rules.organization-name-field')],
      ]);

      if (excludePersonalInformation.fields) {
        excludePersonalInformation.fields.forEach((field) => {
          if (fieldsTranslations.has(field)) {
            if (fields.length !== 0) {
              fields += ', ';
            }

            fields += fieldsTranslations.get(field);
          }
        });
      }
    }

    let translation = null;

    if (fields !== '') {
      translation = i18next.t('organization.password-rules.exclude-personal-information', { fields });
    }

    super(excludePersonalInformation, 'exclude_personal_information', true, translation);
  }
}
