import App from 'components/templates/app';
import { useEffect, useState } from 'react';
import { showErrorToast } from 'features/toastSlice';
import DatePicker from 'components/DatePicker';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import { FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import Button from 'components/Button';
import Loader from 'components/SectionLoader';
import { useTranslation } from 'react-i18next';
import { formatToDate, formatToTime } from 'utils/format';
import { validateDate } from 'utils/validation';
import { useDispatch } from 'react-redux';
import { fetchBuLogs, fetchMemberHistory, fetchOrganizations } from 'api/portal';
import LogTable from 'components/tables/LogTable';
import LogDialog from 'components/dialogs/LogDialog';
import Autocomplete from 'components/Autocomplete';
import styles from './styles.module.css';

const LogsAdmin = () => {
  const { t } = useTranslation();

  const DEFAULT_OPERATION = {
    action: 'ALL',
    label: t('logs.activities-report.default-value-input-operation'),
  };

  const DEFAULT_MEMBER = {
    id: -1,
    label: t('logs.activities-report.default-value-input-user'),
  };

  const DEFAULT_ORGANIZATION = {
    id: -1,
    label: t('logs.activities-report.personal-logs-item'),
  };

  const [date, setDate] = useState(new Date());
  const [isValidDate, setIsValidDate] = useState(true);

  const [time, setTime] = useState(formatToTime(date));

  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(true);
  const [organizations, setOrganizations] = useState([DEFAULT_ORGANIZATION]);
  const [organization, setOrganization] = useState(DEFAULT_ORGANIZATION);

  const [isLoadingMembers, setIsLoadingMembers] = useState(true);
  const [members, setMembers] = useState([DEFAULT_MEMBER]);
  const [membersMap, setMembersMap] = useState(new Map([[DEFAULT_MEMBER.id, DEFAULT_MEMBER.label]]));
  const [member, setMember] = useState(DEFAULT_MEMBER);

  const [operationsMap, setOperationsMap] = useState(new Map([[DEFAULT_OPERATION.id, DEFAULT_OPERATION.label]]));
  const [operation, setOperation] = useState(DEFAULT_OPERATION);

  const [errors, setErrors] = useState(new Map());

  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [logObjects, setLogObjects] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);

  const [isLoadMoreButtonLoading, setIsLoadMoreButtonLoading] = useState(false);
  const [searchedData, setSearchedData] = useState(undefined);

  const [dialogLogObject, setDialogLogObject] = useState(null);

  const dispatch = useDispatch();

  const INVALID_DATE_ERROR = t('logs.activities-report.messages.invalid-date');
  const MAXIMUM_DATE_ERROR = t('logs.activities-report.messages.maximum-date');
  const FILL_REQUIRED = t('logs.activities-report.messages.fill-required');

  const HOME = t('logs.breadcrumbs.home');
  const TITLE = t('logs.breadcrumbs.activities-report');

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/logs',
      title: TITLE,
      isActive: true,
    },
  ];

  const getOperations = () => {
    const logOperations = [
      {
        action: 'update-password',
        label: t('logs.activities-report.default-operations.update-password'),
      },
      {
        action: 'view-doctor-list',
        label: t('logs.activities-report.default-operations.view-doctor-list'),
      },
      {
        action: 'view-notifications',
        label: t('logs.activities-report.default-operations.view-notifications'),
      },
      {
        action: 'view-invitations',
        label: t('logs.activities-report.default-operations.view-invitations'),
      },
      {
        action: 'update-invitation-status-accepted',
        label: t('logs.activities-report.default-operations.update-invitation-status-accepted'),
      },
      {
        action: 'update-invitation-status-declined',
        label: t('logs.activities-report.default-operations.update-invitation-status-declined'),
      },
      {
        action: 'view-user-personal-data',
        label: t('logs.activities-report.default-operations.view-user-personal-data'),
      },
      { action: 'add-member', label: t('logs.activities-report.default-operations.add-member') },
      {
        action: 'update-member-data',
        label: t('logs.activities-report.default-operations.update-member-data'),
      },
      {
        action: 'update-member-status',
        label: t('logs.activities-report.default-operations.update-member-status'),
      },
      {
        action: 'delete-member',
        label: t('logs.activities-report.default-operations.delete-member'),
      },
      {
        action: 'add-user-signup',
        label: t('logs.activities-report.default-operations.add-user-signup'),
      },
      {
        action: 'activate-account',
        label: t('logs.activities-report.default-operations.activate-account'),
      },
      {
        action: 'reset-password',
        label: t('logs.activities-report.default-operations.reset-password'),
      },
      {
        action: 'request-reset-password',
        label: t('logs.activities-report.default-operations.request-reset-password'),
      },
      {
        action: 'request-bulk-upload',
        label: t('logs.activities-report.default-operations.request-bulk-upload'),
      },
      {
        action: 'add-member-bulk',
        label: t('logs.activities-report.default-operations.add-member-bulk'),
      },
      {
        action: 'add-signup-user-bulk',
        label: t('logs.activities-report.default-operations.add-signup-user-bulk'),
      },
      {
        action: 'add-unit',
        label: t('logs.activities-report.default-operations.add-unit'),
      },
      {
        action: 'delete-unit',
        label: t('logs.activities-report.default-operations.delete-unit'),
      },
      {
        action: 'update-unit-members',
        label: t('logs.activities-report.default-operations.update-unit-members'),
      },
      {
        action: 'login',
        label: t('logs.activities-report.default-operations.login'),
      },
      {
        action: 'session-refresh',
        label: t('logs.activities-report.default-operations.session-refresh'),
      },
      {
        action: 'view-acquisitions-list',
        label: t('logs.activities-report.default-operations.view-acquisitions-list'),
      },
      {
        action: 'view-patient-data',
        label: t('logs.activities-report.default-operations.view-patient-data'),
      },
      {
        action: 'view-session-data',
        label: t('logs.activities-report.default-operations.view-session-data'),
      },
      {
        action: 'request-report-authorization',
        label: t('logs.activities-report.default-operations.request-report-authorization'),
      },
      {
        action: 'view-report',
        label: t('logs.activities-report.default-operations.view-report'),
      },
      {
        action: 'update-share-report',
        label: t('logs.activities-report.default-operations.update-share-report'),
      },
      {
        action: 'request-encrypted-patients-data',
        label: t('logs.activities-report.default-operations.request-encrypted-patients-data'),
      },
      {
        action: 'request-org-key',
        label: t('logs.activities-report.default-operations.request-org-key'),
      },
      {
        action: 'request-current-user',
        label: t('logs.activities-report.default-operations.request-current-user'),
      },
      {
        action: 'request-share-link',
        label: t('logs.activities-report.default-operations.request-share-link'),
      },
      {
        action: 'add-acquisition-patient-data',
        label: t('logs.activities-report.default-operations.add-acquisition-patient-data'),
      },
      {
        action: 'member-auto-suspension',
        label: t('logs.activities-report.default-operations.member-auto-suspension'),
      },
      {
        action: 'add-device',
        label: t('logs.activities-report.default-operations.add-device'),
      },
      {
        action: 'list-devices',
        label: t('logs.activities-report.default-operations.list-devices'),
      },
      {
        action: 'update-device',
        label: t('logs.activities-report.default-operations.update-device'),
      },
      {
        action: 'delete-device',
        label: t('logs.activities-report.default-operations.delete-device'),
      },
      {
        action: 'login-device',
        label: t('logs.activities-report.default-operations.login-device'),
      },
      {
        action: 'create-device-qr-code',
        label: t('logs.activities-report.default-operations.create-device-qr-code'),
      },
      {
        action: 'refresh-device-qr-code',
        label: t('logs.activities-report.default-operations.refresh-device-qr-code'),
      },
      {
        action: 'update-device-member-pin',
        label: t('logs.activities-report.default-operations.update-device-member-pin'),
      },
      {
        action: 'list-device-active-members',
        label: t('logs.activities-report.default-operations.list-device-active-members'),
      },
      {
        action: 'list-device-eligible-members',
        label: t('logs.activities-report.default-operations.list-device-eligible-members'),
      },
      {
        action: 'update-organization-settings',
        label: t('logs.activities-report.default-operations.update-organization-settings'),
      },
      {
        action: 'admin-update-organization-settings',
        label: t('logs.activities-report.default-operations.admin-update-organization-settings'),
      },
    ];

    let operations = logOperations.map((el, index) => ({
      ...el,
      id: String(index),
    }));
    operations = operations.sort((a, b) => (a.label > b.label ? 1 : -1));
    operations.unshift(DEFAULT_OPERATION);
    return operations;
  };

  const onFetchDataFailure = () => dispatch(showErrorToast('messages.fetch-data-failure'));

  const fetchOrganizationsHandler = () => {
    setIsLoadingOrganizations(true);

    const searchParams = new URLSearchParams();

    searchParams.set('page_size', 9999);

    fetchOrganizations(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoadingOrganizations(false);
        const organizations = data.results.map((obj) => ({
          id: obj.physio_org_id,
          name: `${obj.name} (${obj.physio_org_id})`,
        }));
        const sortedOrganizations = organizations.sort((a, b) => (a.name > b.name ? 1 : -1));
        setOrganizations([...sortedOrganizations]);
        setOrganization(DEFAULT_ORGANIZATION);
      })
      .catch(() => {
        setIsLoadingOrganizations(false);
        onFetchDataFailure();
      });
  };

  const defineAccountType = () => {
    fetchOrganizationsHandler();
  };

  const fetchLogsHandler = (searchParams) => {
    setIsLoadingLogs(true);
    setLogObjects([]);

    fetchBuLogs(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoadingLogs(false);

        if (data && !data.error) {
          setLogObjects(data.logs);
          setTotalLogs(data.recordsMatched);
          return;
        }

        onFetchDataFailure();
      })
      .catch(() => {
        setIsLoadingLogs(false);
        onFetchDataFailure();
      });
  };

  const getLogsFilters = (date, time, orgId, userId, action) => {
    const searchParams = new URLSearchParams();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    searchParams.set('date', formatToDate(date));
    searchParams.set('time', time);
    searchParams.set('timezone', timezone);

    if (orgId !== DEFAULT_ORGANIZATION.id) {
      searchParams.set('org_id', orgId);
    }

    if (userId !== DEFAULT_MEMBER.id) {
      searchParams.set('user_id', userId);
    }

    if (action !== DEFAULT_OPERATION.action) {
      searchParams.set('action', action);
    }

    return searchParams;
  };

  useEffect(() => {
    const operations = getOperations();

    setTime(formatToTime(date));
    setDate(date);
    setOperation(DEFAULT_OPERATION);
    setOperationsMap(new Map(operations.map((obj) => [obj.action, obj.description])));
    defineAccountType();

    const searchParams = getLogsFilters(
      date,
      time,
      DEFAULT_ORGANIZATION.id,
      DEFAULT_MEMBER.id,
      DEFAULT_OPERATION.action,
    );
    fetchLogsHandler(searchParams);
  }, []);

  const handleMembersFetched = (results) => {
    const members = results.map((obj) => ({ id: obj.id, name: `${obj.first_name} ${obj.last_name}` }));
    const sortedMembers = members.sort((a, b) => (a.name > b.name ? 1 : -1));
    setMembers(sortedMembers);
    setMember(DEFAULT_MEMBER);
    setMembersMap(new Map(sortedMembers.map((obj) => [obj.id, obj.name])));
  };

  const fetchMembersHandler = () => {
    const selectedOrganization = organizations.filter((obj) => obj.id === organization)[0];
    const orgId = selectedOrganization ? selectedOrganization.orgId : -1;
    setIsLoadingMembers(true);

    const searchParams = new URLSearchParams();

    if (orgId !== -1) {
      searchParams.set('org_id', orgId);
    }

    fetchMemberHistory(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoadingMembers(false);

        if (data && !data.error) {
          handleMembersFetched(data);
          return;
        }

        onFetchDataFailure();
      })
      .catch(() => {
        setIsLoadingMembers(false);
        onFetchDataFailure();
      });
  };

  useEffect(() => {
    setMembers([DEFAULT_MEMBER]);
    setMember(DEFAULT_MEMBER);
    setMembersMap(new Map([[DEFAULT_MEMBER.id, DEFAULT_MEMBER.label]]));
    fetchMembersHandler();
  }, [organization]);

  useEffect(() => {
    errors.delete('member');
    setErrors(errors);
  }, [member]);

  const handleSearchDate = (date) => {
    setIsValidDate(date === null || validateDate(date));
    setDate(date);
  };

  const onClearClick = () => {
    const date = new Date();
    setDate(date);
    setTime(formatToTime(date));
    setMember(DEFAULT_MEMBER);
    setOperation(DEFAULT_OPERATION);
  };

  const updateLogs = (logs) => {
    const { searchDate, time } = searchedData;

    const prevLogs = logObjects;
    const lastLog = prevLogs[prevLogs.length - 1];
    // looking for the last log already in the table in the logs returned;
    // it must be removed from logs when it's founded
    while (logs.length > 0 && lastLog['@ptr'] !== logs['@ptr']) logs.shift();
    logs.shift();

    const limitDate = new Date(`${searchDate} ${time}`);
    limitDate.setDate(limitDate.getDate() - 1);

    const newLogs = logs.filter((el) => new Date(el.timestamp) >= limitDate);
    setLogObjects([...prevLogs, ...newLogs]);
  };

  const loadMoreLogsHandle = () => {
    const { orgId, userId, action } = searchedData;

    const date = new Date(logObjects[logObjects.length - 1].timestamp);
    date.setSeconds(date.getSeconds() + 1);
    const time = formatToTime(date, true);

    setIsLoadMoreButtonLoading(true);

    const searchParams = getLogsFilters(date, time, orgId, userId, action);

    fetchBuLogs(searchParams)
      .then((response) => {
        const { data } = response;

        if (data && !data.error) {
          updateLogs(data.logs);
          return;
        }

        onFetchDataFailure();
      })
      .catch(() => {
        setIsLoadingLogs(false);
        onFetchDataFailure();
      })
      .finally(() => {
        setIsLoadMoreButtonLoading(false);
      });
  };

  const renderLogTable = () => {
    if (isLoadingLogs) {
      return <Loader />;
    }

    if (logObjects.length > 0) {
      return (
        <>
          <LogTable
            logObjs={logObjects}
            membersMap={membersMap}
            operationsMap={operationsMap}
            onClick={(logObject) => {
              setDialogLogObject(logObject);
            }}
          />
          <div className={styles.tableFooter}>
            <div className={styles.tableFooterLeft}>
              <p className={styles.nLoad}>
                {`${t('logs.activities-report.n-logs-loaded-label')}: ${logObjects.length}/${totalLogs}`}
              </p>
            </div>
            {logObjects && logObjects.length < totalLogs && (
              <Button variant="outlined" onClick={loadMoreLogsHandle} loading={isLoadMoreButtonLoading}>
                {t('logs.activities-report.load-more-button-label')}
              </Button>
            )}
            <div className={styles.tableFooterRight} />
          </div>
        </>
      );
    }

    if (logObjects.length === 0) {
      return (
        <p className={styles.noLogsFound} data-testid="no-logs-message">
          {t('logs.activities-report.not-found-logs-message')}
        </p>
      );
    }

    return null;
  };

  const onFilterClick = () => {
    const errors = new Map();

    if (date === null || !isValidDate) {
      errors.set('date', FILL_REQUIRED);
    } else {
      let currentDate = new Date();
      currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

      const onlyDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      if (currentDate - onlyDate < 0) {
        errors.set('date', MAXIMUM_DATE_ERROR);
      }
    }

    if (time === '') {
      errors.set('time', FILL_REQUIRED);
    }

    setErrors(errors);

    if (errors.size === 0) {
      const orgId = organization ? organization.id : DEFAULT_ORGANIZATION.id;
      const userId = member ? member.id : DEFAULT_MEMBER.id;
      const action = operation ? operation.action : DEFAULT_OPERATION.action;

      setSearchedData({ date: new Date(date), time, orgId, userId, action });
      const searchParams = getLogsFilters(date, time, orgId, userId, action);
      fetchLogsHandler(searchParams);
    }
  };

  const operations = getOperations();

  const organizationOptions = [
    DEFAULT_ORGANIZATION,
    ...organizations.map((item) => ({
      label: item.name,
      id: item.id,
    })),
  ];

  const memberOptions = [DEFAULT_MEMBER, ...members.map((item) => ({ label: item.name, id: item.id }))];

  return (
    <App title={TITLE} breadcrumbs={breadcrumbs}>
      <Section className={styles.sectionFilters}>
        <Subtitle>{t('physio.physio-personal.title-filter-physio')}</Subtitle>
        <Typography variant="body2" color="secondary">
          {t('logs.activities-report.filter-text')}
        </Typography>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <Grid marginTop="16px" marginBottom="8px" container spacing={1}>
            <Grid item xs={12} sm={2.4}>
              <DatePicker
                value={date}
                onChange={handleSearchDate}
                label={t('logs.activities-report.label-input-date')}
                format="yyyy-MM-dd"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !isValidDate || Boolean(errors && errors.get('date')),
                    helperText:
                      (!isValidDate && INVALID_DATE_ERROR) || (errors && errors.get('date') ? errors.get('date') : ''),
                    'data-testid': 'date-text-field',
                    InputProps: {
                      'data-testid': 'date-input',
                    },
                    FormHelperTextProps: {
                      'data-testid': 'date-error',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2.4}>
              <TextField
                id="time"
                value={time}
                label={t('logs.activities-report.label-input-time')}
                onChange={(event) => setTime(event.target.value)}
                variant="outlined"
                data-testid="time-text-field"
                inputProps={{ 'data-testid': 'time-input' }}
                error={errors.has('time')}
                helperText={errors.get('time')}
                FormHelperTextProps={{ 'data-testid': 'time-error' }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2.4}>
              <FormControl disabled={isLoadingOrganizations} sx={{ marginBottom: 0 }} fullWidth>
                <Autocomplete
                  disablePortal
                  value={organization}
                  onChange={(event, newValue) => setOrganization(newValue)}
                  options={organizationOptions}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <Typography
                      {...props}
                      variant="body2"
                      color="secondary"
                      key={option.id}
                      data-testid={`organization-option-${option.id}`}
                    >
                      {option.label}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t('logs.activities-report.label-input-organization')} />
                  )}
                  data-testid="organization-select"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2.4}>
              <FormControl disabled={isLoadingMembers} error={errors.has('member')} sx={{ marginBottom: 0 }} fullWidth>
                <Autocomplete
                  disablePortal
                  value={member}
                  onChange={(event, newValue) => setMember(newValue)}
                  options={memberOptions}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <Typography
                      {...props}
                      variant="body2"
                      color="secondary"
                      key={option.id}
                      data-testid={`member-option-${option.id}`}
                    >
                      {option.label}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t('logs.activities-report.label-input-user')} />
                  )}
                  data-testid="member-select"
                />
                <FormHelperText>{errors.has('member') ? FILL_REQUIRED : ''}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2.4}>
              <FormControl fullWidth sx={{ marginBottom: 0 }}>
                <Autocomplete
                  disablePortal
                  value={operation}
                  onChange={(event, newValue) => setOperation(newValue)}
                  options={operations}
                  isOptionEqualToValue={(option, value) => option.action === value.action}
                  renderOption={(props, option) => (
                    <Typography
                      {...props}
                      variant="body2"
                      color="secondary"
                      key={option.action}
                      data-testid={`operation-option-${option.action}`}
                    >
                      {option.label}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t('logs.activities-report.label-input-operation')} />
                  )}
                  data-testid="operation-select"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2.4}>
              <Button variant="outlined" onClick={onClearClick} fullWidth data-testid="clear-filters-button">
                {t('logs.activities-report.label-button-clear-filter')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={2.4}>
              <Button
                color="gradient"
                onClick={onFilterClick}
                disabled={isLoadingOrganizations || isLoadingMembers || isLoadingLogs}
                fullWidth
                data-testid="apply-filters-button"
              >
                {t('logs.activities-report.label-button-apply-filter')}
              </Button>
            </Grid>
          </Grid>
        </form>
        {renderLogTable()}
        <LogDialog isOpen={dialogLogObject !== null} onClose={() => setDialogLogObject(null)} log={dialogLogObject} />
      </Section>
    </App>
  );
};

export default LogsAdmin;
