import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MemberRow from '../MemberRow';

function MemberTable({ members, onClickButton, onClick }) {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('organization.organization-members.full-name-column')}</TableCell>
            <TableCell align="left">{t('organization.organization-members.username-column')}</TableCell>
            <TableCell align="left">{t('organization.organization-members.roles-column')}</TableCell>
            <TableCell align="left">{t('organization.organization-members.status-column')}</TableCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member) => (
            <MemberRow
              key={member.id}
              member={member}
              onClick={() => onClick(member)}
              onClickButton={() => onClickButton(member)}
              data-testid={`member-row-${member.id}`}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MemberTable;
