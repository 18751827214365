import Button from 'components/Button';
import Subtitle from 'components/Subtitle';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import App from 'pages/organization/settings/App';
import VisuallyHiddenInput from 'components/VisuallyHiddenInput';
import { useState } from 'react';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { updateMemberOrganization } from 'api/portal';
import { setActiveOrganizationData, setOrganizations } from 'features/userSlice';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Avatar, Box, Grid, TextField } from '@mui/material';
import Fade from 'components/Fade';
import styles from './styles.module.css';

function OrganizationInfo() {
  const organization = useSelector((state) => state.user.active_organization);
  const organizations = useSelector((state) => state.user.organizations);

  const [address, setAddress] = useState(organization.address);
  const [photo, setPhoto] = useState(organization.photo);
  const [photoFile, setPhotoFile] = useState(undefined);
  const [errors, setErrors] = useState(new Map());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const FILL_REQUIRED = t('messages.fill-required');

  const showImagePreview = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => callback(reader.result);
  };

  const handleOnImageChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    showImagePreview(file, (previewUrl) => {
      setPhoto(previewUrl);
      setPhotoFile(file);
    });
  };

  const onSubmit = () => {
    const errors = new Map();

    if (!address) {
      errors.set('address', FILL_REQUIRED);
    }

    setErrors(errors);

    if (errors.size > 0) {
      dispatch(showErrorToast('messages.fix-fields'));
      return;
    }

    const formData = new FormData();

    formData.append('address', address);

    if (photoFile !== undefined) {
      formData.append('photo', photoFile);
    }

    setIsSubmitting(true);

    updateMemberOrganization(formData)
      .then((response) => {
        setIsSubmitting(false);

        const { data } = response;

        dispatch(showSuccessToast('organization.organization-settings.save-success-message'));
        dispatch(setActiveOrganizationData(data));
        dispatch(
          setOrganizations(
            organizations.map((item) => {
              if (item.id === data.id) {
                return data;
              }

              return item;
            }),
          ),
        );
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(showErrorToast('organization.organization-settings.save-failure-message'));
      });
  };

  return (
    <App>
      <Fade>
        <Box maxWidth="428px" marginBottom="48px">
          <Subtitle className={styles.subtitle}>{t('braincare.organizations-edit.step-basic-info.title')}</Subtitle>

          <Grid container alignItems="center" spacing="16px">
            <Grid container justifyContent="center" item xs={12} md={4}>
              <Avatar sx={{ width: 120, height: 120 }} src={photo} alt="organization image" />
            </Grid>

            <Grid container justifyContent="center" item xs={6} md={4}>
              <Button
                component="label"
                variant="outlined"
                data-testid="upload-photo-button"
                startIcon={<CameraAltIcon />}
              >
                {t('braincare.organizations-edit.step-basic-info.button-add-photo')}
                <VisuallyHiddenInput onChange={handleOnImageChange} type="file" />
              </Button>
            </Grid>

            <Grid container justifyContent="center" item xs={6} md={4}>
              <Button
                color="error"
                variant="outlined"
                data-testid="remove-photo-button"
                onClick={() => {
                  setPhoto(null);
                  setPhotoFile('');
                }}
              >
                {t('braincare.organizations-edit.step-basic-info.button-remove-photo')}
              </Button>
            </Grid>
          </Grid>

          <TextField
            id="name"
            sx={{ margin: '40px 0 0 0' }}
            value={organization.name || ''}
            disabled
            label={t('braincare.organizations-edit.step-basic-info.input-label-name')}
            variant="outlined"
            data-testid="name-text-field"
            inputProps={{ 'data-testid': 'name-input' }}
            fullWidth
          />

          <TextField
            id="address"
            sx={{ marginTop: 4 }}
            value={address || ''}
            onChange={(event) => setAddress(event.target.value)}
            label={t('braincare.organizations-edit.step-basic-info.input-label-address')}
            variant="outlined"
            error={errors.has('address')}
            helperText={errors.get('address')}
            data-testid="address-text-field"
            inputProps={{ 'data-testid': 'address-input' }}
            FormHelperTextProps={{ 'data-testid': 'address-error' }}
            fullWidth
          />
        </Box>
      </Fade>

      <Button loading={isSubmitting} variant="outlined" onClick={onSubmit} data-testid="save-button">
        {t('braincare.organizations-edit.buttons.button-save')}
      </Button>
      <Button sx={{ marginLeft: 2 }} variant="outlined" onClick={() => navigate('/')} data-testid="cancel-button">
        {t('braincare.organizations-edit.buttons.button-cancel')}
      </Button>
    </App>
  );
}

export default OrganizationInfo;
