import i18next from 'i18next';
import PasswordValidator from './PasswordValidator';

export default class LowercaseValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { lowercaseRange } = passwordPolicy;

    const { min, max } = lowercaseRange;

    let translation = null;

    if (min !== undefined && max !== undefined) {
      translation = i18next.t('organization.password-rules.lowercase-constraint-1', { min, max });
    } else if (min !== undefined) {
      translation = i18next.t('organization.password-rules.lowercase-constraint-2', { min });
    } else if (max !== undefined) {
      translation = i18next.t('organization.password-rules.lowercase-constraint-3', { max });
    }

    super(lowercaseRange, 'lowercase_range', false, translation);
  }

  validate(password) {
    if (this.policy) {
      const lowercaseChars = password.match(/[a-z]/g);

      const min = this.policy.min ? this.policy.min : 0;
      const max = this.policy.max ? this.policy.max : 128;

      const numberLowercase = lowercaseChars ? lowercaseChars.length : 0;

      if (min > numberLowercase || max < numberLowercase) {
        return false;
      }
    }

    return true;
  }
}
