import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showErrorToast, showSuccessToast, showWarningToast } from 'features/toastSlice';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LinkItem from 'components/LinkItem';
import AuthTitle from 'components/AuthTitle';
import Translator from 'components/Translator';
import { acceptEduTerms, eduLogin, resetEduPassword } from 'api/portal';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { clearAllTokens, setToken } from 'utils/tokens';
import { formatToCpfUsername, unformatCpf } from 'utils/format';
import { isCpf } from 'utils/validation';
import BraincareLogo from 'assets/images/logo-braincare.png';
import { useDispatch } from 'react-redux';
import Brand from 'components/Brand';
import Button from 'components/Button';
import styles from './styles.module.css';

function EduLogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formattedUsername, setFormattedUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetUsername, setResetUsername] = useState('');
  const [usernameResetError, setUsernameResetError] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [expiration, setExpiration] = useState(0);
  const [loginToken, setLoginToken] = useState('');
  const [accounts, setAccounts] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectAccount, setSelectAccount] = useState(false);
  const [inputAccountPassword, setInputAccountPassword] = useState(false);

  const { t } = useTranslation();

  const USERNAME_EMPTY_ERROR = t('auth.login.doc-empty-error');
  const PASSWORD_EMPTY_ERROR = t('auth.login.pwd-empty-error');

  const dispatch = useDispatch();

  const resetState = () => {
    setPassword('');
    setFormattedUsername('');
    setUsernameError('');
    setPasswordError('');
    setResetSuccess(false);
    setResetUsername('');
    setUsernameResetError('');
    setIsPasswordVisible(false);
    setIsLoading(false);
    setIsLogin(true);
    setHasAcceptedTerms(false);
    setAccessToken('');
    setRefreshToken('');
    setExpiration(0);
    setLoginToken('');
    setAccounts('');
    setSelectedAccount(null);
    setSelectAccount(false);
    setInputAccountPassword(false);
  };

  const formatUsername = (event) => {
    const { value } = event.target;

    const maxLength = isCpf(formattedUsername) ? 14 : 64;

    if (value.length > maxLength) {
      return;
    }

    if (isCpf(value)) {
      setUsername(unformatCpf(formatToCpfUsername(value)));
      setFormattedUsername(formatToCpfUsername(value));
      return;
    }

    setUsername(value);
    setFormattedUsername(value);
  };

  const handleLoginError = (error) => {
    const { response } = error;
    const { data, status } = response;

    setIsLoading(false);

    if (response && status === 401) {
      if (data.hasOwnProperty('blocked')) {
        dispatch(showErrorToast('auth.login.user-blocked'));
      } else {
        dispatch(showErrorToast('auth.login.auth-error'));
      }

      setPassword('');
      return;
    }

    if (data) {
      setPassword('');
      dispatch(showErrorToast('messages.generic-error'));
    }
  };

  const handleLoginResponse = (response) => {
    const { data } = response;

    setIsLoading(false);

    if (data.hasOwnProperty('accounts')) {
      setLoginToken(data.token);
      setAccounts(data.accounts);
      setSelectAccount(true);
      setPassword('');
      return;
    }

    setToken('access_token', data.access_token);
    setToken('refresh_token', data.refresh_token);

    setAccessToken(data.access_token);
    setRefreshToken(data.refresh_token);
    setExpiration(data.expiration);
    setSelectAccount(false);
    setInputAccountPassword(false);

    if (data.hasOwnProperty('edu_terms') && data.edu_terms === false) {
      setPassword('');
      setFormattedUsername('');
      setHasAcceptedTerms(true);
      return;
    }

    window.location.assign(
      `muvecrio://?token=${data.access_token}&refresh_token=${data.refresh_token}&expire_date*=${data.expiration}`,
    );

    dispatch(showSuccessToast('auth.login.integration-auth-success'));
    setPassword('');
    setFormattedUsername('');
    clearAllTokens();
  };

  // Normal Login
  const handleLoginSubmit = () => {
    if (!username) {
      setUsernameError(USERNAME_EMPTY_ERROR);
      return;
    }

    if (!password) {
      setPasswordError(PASSWORD_EMPTY_ERROR);
      return;
    }

    setUsernameError('');
    setPasswordError('');
    setIsLoading(true);

    eduLogin({ document: username, password }).then(handleLoginResponse).catch(handleLoginError);
  };

  const handleAccountLoginError = (error) => {
    const { response } = error;
    const { data, status } = response;

    setIsLoading(false);

    if (status === 401) {
      if (data.hasOwnProperty('blocked')) {
        dispatch(showErrorToast('auth.login.user-blocked'));
        setPassword('');
        setAccounts(null);
        setSelectedAccount(null);
        setFormattedUsername('');
        setSelectAccount(false);
        setInputAccountPassword(false);
      } else {
        dispatch(showErrorToast('auth.login.auth-error'));
        setPassword('');
      }
      return;
    }

    if (data) {
      dispatch(showErrorToast('messages.generic-error'));
      resetState();
    }
  };

  const handleAccountLoginResponse = (response) => {
    const { data } = response;

    setIsLoading(false);

    setToken('access_token', data.access_token);
    setToken('refresh_token', data.refresh_token);

    setAccessToken(data.access_token);
    setRefreshToken(data.refresh_token);
    setExpiration(data.expiration);
    setSelectAccount(false);
    setInputAccountPassword(false);

    if (data.hasOwnProperty('edu_terms') && data.edu_terms === false) {
      setPassword('');
      setFormattedUsername('');
      setHasAcceptedTerms(true);
      return;
    }

    window.location.assign(
      `muvecrio://?token=${data.access_token}&refresh_token=${data.refresh_token}&expire_date*=${data.expiration}`,
    );

    dispatch(showSuccessToast('auth.login.integration-auth-success'));
    setPassword('');
    setFormattedUsername('');
    setSelectAccount(false);
    setInputAccountPassword(false);
    clearAllTokens();
  };

  // Account Login
  const handleAccountLoginSubmit = () => {
    if (!password) {
      setPasswordError(PASSWORD_EMPTY_ERROR);
      return;
    }

    setPasswordError('');
    setIsLoading(true);

    eduLogin({
      account: selectedAccount.id,
      document: username,
      password,
    })
      .then(handleAccountLoginResponse)
      .catch(handleAccountLoginError);
  };

  // Login with token
  const handleAccountListLogin = (account) => {
    if (account.login_status) {
      setIsLoading(true);

      eduLogin({
        account: account.id,
        token: loginToken,
        document: username,
      })
        .then(handleLoginResponse)
        .catch(handleLoginError);
      return;
    }

    setSelectedAccount(account);
    setInputAccountPassword(true);
  };

  // Terms
  const handleDialogConfirmation = () => {
    setIsLoading(true);

    // eslint-disable-next-line no-unused-vars
    acceptEduTerms()
      .then(() => {
        setIsLoading(false);

        window.location.assign(
          `muvecrio://?token=${accessToken}&refresh_token=${refreshToken}&expire_date*=${expiration}`,
        );

        dispatch(showSuccessToast('auth.login.integration-auth-success'));
        setHasAcceptedTerms(false);

        clearAllTokens();
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(showErrorToast('auth.b4c-edu.update-error'));
        setHasAcceptedTerms(false);
      });
  };

  // Password reset functions
  const handleReset = () => {
    setIsLogin(!isLogin);
    setUsernameResetError('');
    setResetUsername('');
    setResetSuccess(false);
  };

  const handleResetSubmit = () => {
    if (!resetUsername) {
      setUsernameResetError(USERNAME_EMPTY_ERROR);
      return;
    }

    const payload = {
      document: resetUsername,
    };

    if (selectedAccount && selectedAccount.id !== 0) {
      payload.token = loginToken;
      payload.organization = selectAccount.id;
    }

    resetEduPassword(payload)
      .then(() => {
        resetState();
        dispatch(showWarningToast('auth.forgot-password.check-email'));
      })
      .catch(() => {
        resetState();
        dispatch(showErrorToast('auth.forgot-password.redefine-failure'));
      });
  };

  // Render login functions
  const renderLoginForm = () => (
    <div className={styles.webview_container_form}>
      <Brand className={styles.brand} image={BraincareLogo} title="Braincare logo" />

      <div className={styles.login_form}>
        <FormControl error={Boolean(usernameError)}>
          <FormLabel>{t('auth.login.link-input-document')}</FormLabel>
          <OutlinedInput
            value={formattedUsername}
            onChange={(event) => formatUsername(event)}
            inputProps={{ 'data-testid': 'document-input' }}
          />
          <FormHelperText data-testid="document-helper-text">{usernameError && USERNAME_EMPTY_ERROR}</FormHelperText>
        </FormControl>

        <FormControl error={Boolean(passwordError)}>
          <FormLabel>{t('auth.login.input-pwd')}</FormLabel>
          <OutlinedInput
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            type={isPasswordVisible ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  edge="end"
                >
                  {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{ 'data-testid': 'password-input' }}
          />
          <FormHelperText data-testid="password-error">{passwordError && PASSWORD_EMPTY_ERROR}</FormHelperText>
        </FormControl>

        <LinkItem href="#" onClick={handleReset} data-testid="link-reset-password">
          {t('auth.login.link-password-reset')}
        </LinkItem>

        <Button
          color="gradient"
          size="large"
          fullWidth
          sx={{ margin: '32px 0 16px 0' }}
          onClick={handleLoginSubmit}
          loading={isLoading}
          data-testid="submit-button"
        >
          {t('auth.login.button-login')}
        </Button>

        <Typography variant="body2" color="secondary" className={styles.signUp}>
          {t('auth.login.text-signup')}
          &nbsp;
          <LinkItem href="/auth/edu/signup" data-testid="signup-link">
            {t('auth.login.link-signup')}
          </LinkItem>
        </Typography>
      </div>
      <Translator />
    </div>
  );

  const renderAccountsList = () => (
    <div className={styles.webview_container_form}>
      <AuthTitle className={styles.eduChooseAccountTitle}>
        {t('auth.accounts-start.title-sub-account-center')}
      </AuthTitle>

      <div className={styles.eduAccountsContainer}>
        <Card
          key={accounts[0].id}
          className={styles.eduAccountsCard}
          sx={{ padding: '16px' }}
          onClick={() => handleAccountListLogin(accounts[0])}
          data-testid="personal-account-card"
        >
          <CardHeader
            avatar={<Avatar src={accounts[0].picture} />}
            title={<h3 className={styles.cardTitle}>{t('auth.accounts-start.title-card-personal')}</h3>}
          />
        </Card>
        {accounts.slice(1, accounts.length).map((account) => (
          <Card
            key={account.id}
            className={styles.eduAccountsCard}
            sx={{ padding: '16px' }}
            onClick={() => handleAccountListLogin(account)}
            data-testid={`corporate-account-${account.id}-card`}
          >
            <CardHeader
              avatar={<Avatar sx={{ width: 48, height: 48, margin: 0 }} src={account.name} aria-label={account.name} />}
              title={<h3 className={styles.cardTitle}>{account.name}</h3>}
            />
            <Divider />
            <CardContent>
              <Typography variant="body2" color="secondary">
                {account.address}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>

      <Button
        variant="outlined"
        fullWidth
        sx={{ margin: '32px 0 16px 0' }}
        onClick={() => {
          setPassword('');
          setAccounts(null);
          setSelectedAccount(null);
          setFormattedUsername('');
          setSelectAccount(false);
          setInputAccountPassword(false);
        }}
      >
        {t('auth.forgot-password.button-cancel')}
      </Button>
    </div>
  );

  const renderAccountDialog = () => (
    <div className={styles.webview_container_form} data-testid="account-dialog">
      <div className={styles.eduAccountDialogContainer}>
        <AuthTitle className={styles.eduChooseAccountTitle}>
          {t('auth.accounts-corp-login.title-box-corp-login', { organization: selectedAccount.name })}
        </AuthTitle>
        <Avatar sx={{ width: 238, height: 238 }} src={selectedAccount.photo} />
      </div>

      <FormControl fullWidth error={Boolean(passwordError)}>
        <FormLabel>{t('auth.login.input-pwd')}</FormLabel>
        <OutlinedInput
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          type={isPasswordVisible ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                edge="end"
              >
                {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{ 'data-testid': 'password-input' }}
        />
        <FormHelperText data-testid="password-error">{passwordError && PASSWORD_EMPTY_ERROR}</FormHelperText>
      </FormControl>

      <LinkItem href="#" onClick={handleReset} data-testid="link-reset-password">
        {t('auth.login.link-password-reset')}
      </LinkItem>

      <Button
        color="gradient"
        fullWidth
        sx={{ margin: '32px 0 16px 0' }}
        onClick={handleAccountLoginSubmit}
        loading={isLoading}
        data-testid="submit-button"
      >
        {t('auth.forgot-password.button-reset-password')}
      </Button>

      <Button
        variant="outlined"
        fullWidth
        onClick={() => {
          setPassword('');
          setPasswordError(false);
          setInputAccountPassword(false);
        }}
      >
        {t('auth.forgot-password.button-cancel')}
      </Button>
    </div>
  );

  // Reset render functions
  const renderResetForm = () => (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <Box>
        <AuthTitle>{t('auth.forgot-password.title-main-password')}</AuthTitle>

        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('auth.forgot-password.text-password-forgot')}
        </Typography>

        <WarningAmberIcon sx={{ marginRight: '8px' }} fontSize="12px" color="error" />
        <Typography marginRight="8px" display="inline" fontWeight={600} color="error" variant="body2">
          {t('auth.forgot-password.warning-brand')}
        </Typography>
        <Typography display="inline" color="secondary" variant="body2">
          {t('auth.forgot-password.warning-password-forgot')}
        </Typography>

        <FormControl sx={{ marginTop: '32px' }} fullWidth error={!!usernameResetError}>
          <FormLabel>{t('auth.login.link-input-document')}</FormLabel>
          <OutlinedInput
            value={resetUsername}
            onChange={(event) => setResetUsername(event.target.value)}
            inputProps={{ 'data-testid': 'reset-document-input' }}
          />
          <FormHelperText data-testid="reset-document-helper-text">{usernameResetError}</FormHelperText>
        </FormControl>

        <Button
          color="gradient"
          fullWidth
          sx={{ margin: '24px 0 16px 0' }}
          onClick={handleResetSubmit}
          loading={isLoading}
          data-testid="reset-password-button"
        >
          {t('auth.forgot-password.button-reset-password')}
        </Button>

        <Button variant="outlined" fullWidth onClick={handleReset}>
          {t('auth.forgot-password.button-cancel')}
        </Button>
      </Box>
    </Box>
  );

  const renderResetSuccess = () => (
    <div className={styles.reset_form}>
      <AuthTitle>{t('auth.forgot-password-success.title')}</AuthTitle>
      <Typography variant="body2" color="secondary">
        {t('auth.forgot-password-success.text')}
      </Typography>
      <Button color="gradient" fullWidth onClick={handleReset}>
        {t('auth.forgot-password-success.button-close')}
      </Button>
    </div>
  );

  const renderReset = () => (
    <div className={styles.webview_container_form}>{resetSuccess ? renderResetSuccess() : renderResetForm()}</div>
  );

  const renderAccounts = () => (
    <div className={styles.webview_container_form}>
      {inputAccountPassword ? renderAccountDialog() : renderAccountsList()}
    </div>
  );

  // Terms render
  const renderDialog = () => (
    <div className={styles.reset_form} data-testid="terms-dialog">
      <AuthTitle>{t('auth.b4c-edu.title')}</AuthTitle>

      <Typography variant="body2" color="secondary">
        {t('auth.b4c-edu.terms')}
      </Typography>

      <Button
        color="gradient"
        fullWidth
        sx={{ margin: '24px 0 16px 0' }}
        onClick={handleDialogConfirmation}
        loading={isLoading}
        data-testid="accept-terms-button"
      >
        {t('auth.b4c-edu.confirm')}
      </Button>

      <Button variant="outlined" fullWidth onClick={resetState}>
        {t('auth.b4c-edu.cancel')}
      </Button>
    </div>
  );

  const renderLogin = () => (
    <div className={styles.webview_container_form}>{hasAcceptedTerms ? renderDialog() : renderLoginForm()}</div>
  );

  const renderAuthentication = () => (
    <div className={styles.webview_container_form}>{selectAccount ? renderAccounts() : renderLogin()}</div>
  );

  return <div className={styles.webview_container}>{isLogin ? renderAuthentication() : renderReset()}</div>;
}

export default EduLogin;
