import PropTypes from 'prop-types';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import Fade from 'components/Fade';
import styles from './styles.module.css';

const AccountCard = ({
  onClick,
  picture = '',
  pictureDescription,
  title,
  subtitle = '',
  delay = 0,
  icon = undefined,
  ...props
}) => (
  <Fade delay={delay}>
    <Card sx={{ margin: '20px 20px 16px 20px', width: '230px', height: '240px' }} onClick={onClick} {...props}>
      <Box display="flex" justifyContent="center">
        <Avatar sx={{ width: 80, height: 80, marginRight: 0 }} src={picture} alt={pictureDescription} />
      </Box>
      {icon ? <img src={icon} className={styles.blockedIcon} alt="Device icon" /> : null}

      <CardContent>
        <Typography color="primary.main" variant="body1" data-testid="profile-card-title">
          {title}
        </Typography>
        <Typography color="secondary" variant="body2" data-testid="profile-card-subtitle">
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  </Fade>
);

AccountCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  picture: PropTypes.string,
  pictureDescription: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  subtitle: PropTypes.string,
  delay: PropTypes.number,
  icon: PropTypes.string,
};

export default AccountCard;
