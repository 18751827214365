import { useState } from 'react';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import VisuallyHiddenInput from 'components/VisuallyHiddenInput';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Badge from 'components/Badge';
import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import Loader from 'components/SectionLoader';
import { formatToCpfUsername, unformatCpf } from 'utils/format';
import { useTranslation } from 'react-i18next';
import App from 'pages/organization/members/template';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updatePersonalInfoMember } from 'api/portal';
import { isCpf, validateCpf, validateEmail } from 'utils/validation';
import { Avatar, Box, Grid, TextField, Typography } from '@mui/material';
import { useFetchMember } from 'hooks/useFetchMember';
import Fade from 'components/Fade';
import styles from './styles.module.css';

const BADGE_STATUS = {
  ACTIVE: 'success',
  PENDING: 'pending',
  SUSPENDED: 'danger',
  DECLINED: 'alert',
  BLOCKED: 'blocked',
};

function MemberPersonalInfo() {
  const [member, setMember] = useState({});

  const [photo, setPhoto] = useState('');
  const [photoFile, setPhotoFile] = useState(undefined);
  const status = member.status || 'ACTIVE';
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthYear, setBirthYear] = useState('');

  const [errors, setErrors] = useState(new Map());

  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { memberId } = params;

  const { t } = useTranslation();

  const INVALID_USERNAME_ERROR = t('messages.invalid-document');
  const EXISTING_USERNAME_ERROR = t('messages.existing-document');
  const FILL_REQUIRED = t('messages.fill-required');

  useFetchMember({
    memberId,
    onSuccess: (data) => {
      const { user } = data;

      const document = user.document || '';

      setMember(data);

      setPhoto(user.photo || '');
      setUsername(isCpf(document) ? formatToCpfUsername(document) : document);
      setFirstName(user.first_name || '');
      setLastName(user.last_name || '');
      setBirthYear(user.birth_date ? user.birth_date.split('-')[0] : '');
    },
    onFailure: () => {
      dispatch(showErrorToast('messages.load-failure'));
      navigate('/organization/members');
    },
  });

  const validateUsername = (username) => {
    const isValidUsername = validateCpf(username) || validateEmail(username);

    const updatedErrors = new Map([...errors]);

    if (!isValidUsername) {
      updatedErrors.set('username', INVALID_USERNAME_ERROR);
    } else {
      updatedErrors.delete('username');
    }

    setErrors(updatedErrors);
  };

  const showImagePreview = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => callback(reader.result);
  };

  const handleOnImageChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    showImagePreview(file, (previewUrl) => {
      setPhoto(previewUrl);
      setPhotoFile(file);
    });
  };

  const onSave = () => {
    const errors = new Map();

    const isValidUsername = validateCpf(username) || validateEmail(username);

    if (!isValidUsername) {
      errors.set('username', INVALID_USERNAME_ERROR);
    }

    if (!firstName) {
      errors.set('firstName', FILL_REQUIRED);
    }

    if (!lastName) {
      errors.set('lastName', FILL_REQUIRED);
    }

    if (!birthYear) {
      errors.set('birthYear', FILL_REQUIRED);
    }

    setErrors(errors);

    if (errors.size) {
      dispatch(showErrorToast('messages.fix-fields'));
      return;
    }

    const formData = new FormData();

    formData.append('username', isCpf(username) ? unformatCpf(username) : username);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);

    if (photoFile !== undefined) {
      formData.append('photo', photoFile);
    }

    formData.append('birth_date', `${birthYear}-01-01`);

    setIsSaving(true);

    updatePersonalInfoMember(memberId, formData)
      .then(() => {
        setIsSaving(false);
        dispatch(showSuccessToast('account.member-edit-info.save-success-message'));
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        setIsSaving(false);

        if (data.hasOwnProperty('document')) {
          const updatedErrors = new Map([...errors]);
          updatedErrors.set('username', EXISTING_USERNAME_ERROR);
          setErrors(updatedErrors);
        }

        dispatch(showErrorToast('account.member-edit-info.save-failure-message'));
      });
  };

  return (
    <App>
      <Fade>
        <Box maxWidth="428px">
          <Subtitle>{t('organization.organization-members-edit.title-sub-member-edit')}</Subtitle>
          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-members-edit.text-organization-members-main')}
          </Typography>

          <Loader loading={!member.id}>
            <Fade>
              <Grid sx={{ margin: '0 0 40px 0' }} spacing="16px" container justifyContent="center" alignItems="center">
                <Grid container justifyContent="center" item xs={12} md={4}>
                  <Avatar sx={{ width: 120, height: 120 }} src={photo} alt="profile image" />
                </Grid>

                <Grid container justifyContent="center" item xs={6} md={4}>
                  <Button component="label" variant="outlined" startIcon={<CameraAltIcon />}>
                    {t('braincare.organizations-edit.step-basic-info.button-add-photo')}
                    <VisuallyHiddenInput onChange={handleOnImageChange} type="file" />
                  </Button>
                </Grid>
                <Grid container justifyContent="center" item xs={6} md={4}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setPhoto(null);
                      setPhotoFile('');
                    }}
                  >
                    {t('braincare.organizations-edit.step-basic-info.button-remove-photo')}
                  </Button>
                </Grid>
              </Grid>

              <div className={styles.statusContainer}>
                <div>
                  <b>{t('organization.organization-members-edit.label-status')}</b>
                  <Badge className={styles.statusBadge} type={BADGE_STATUS[status]} data-testid="status-badge">
                    {t(`utils.status.${status.toLowerCase()}`)}
                  </Badge>
                </div>
              </div>

              <TextField
                sx={{ margin: '16px 0 0 0' }}
                id="document"
                value={username}
                onChange={(event) => {
                  const { value } = event.target;
                  const formattedUsername = isCpf(value) ? formatToCpfUsername(value) : value;
                  setUsername(formattedUsername);
                }}
                onBlur={(event) => {
                  const { value } = event.target;
                  validateUsername(value);
                }}
                label={t('account.member-edit-personal-info.input-document')}
                variant="outlined"
                data-testid="document-text-field"
                error={errors.has('username')}
                helperText={errors.get('username')}
                FormHelperTextProps={{ 'data-testid': 'document-helper-text' }}
                inputProps={{ 'data-testid': 'document-input', maxLength: isCpf(username) ? '14' : '254' }}
                fullWidth
              />
              <TextField
                id="firstName"
                sx={{ margin: '32px 0 0 0' }}
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                label={t('account.member-edit-personal-info.input-first-name')}
                variant="outlined"
                data-testid="first-name-text-field"
                error={errors.has('firstName')}
                helperText={errors.get('firstName')}
                FormHelperTextProps={{ 'data-testid': 'first-name-helper-text' }}
                inputProps={{ 'data-testid': 'first-name-input' }}
                fullWidth
              />
              <TextField
                id="lastName"
                sx={{ margin: '32px 0 0 0' }}
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                label={t('account.member-edit-personal-info.input-last-name')}
                variant="outlined"
                data-testid="last-name-text-field"
                error={errors.has('lastName')}
                helperText={errors.get('lastName')}
                FormHelperTextProps={{ 'data-testid': 'last-name-helper-text' }}
                inputProps={{ 'data-testid': 'last-name-input' }}
                fullWidth
              />
              <TextField
                id="birthYear"
                sx={{ margin: '32px 0 32px 0' }}
                value={birthYear}
                onChange={(event) => setBirthYear(event.target.value)}
                label={t('account.member-edit-personal-info.input-birth-year')}
                variant="outlined"
                data-testid="birth-year-text-field"
                error={errors.has('birthYear')}
                helperText={errors.get('birthYear')}
                inputProps={{ 'data-testid': 'birth-year-input' }}
                fullWidth
              />

              <Button variant="outlined" loading={isSaving} onClick={onSave} type="submit" data-testid="save-button">
                {t('account.member-edit-personal-info.button-save')}
              </Button>

              <Button
                sx={{ margin: '0 0 0 16px' }}
                variant="outlined"
                onClick={() => {
                  navigate('/organization/members');
                }}
                data-testid="cancel-button"
              >
                {t('organization.organization-members-edit.button-cancel')}
              </Button>
            </Fade>
          </Loader>
        </Box>
      </Fade>
    </App>
  );
}

export default MemberPersonalInfo;
