import PropTypes from 'prop-types';
import { Avatar, Box, Typography } from '@mui/material';

const DropdownAccount = ({ image, title, subtitle = '', onClick, ...props }) => (
  <Box
    sx={{ cursor: 'pointer' }}
    display="flex"
    alignItems="center"
    marginBottom="24px"
    onClick={onClick}
    role="presentation"
    {...props}
  >
    <Avatar alt="Account image" src={image} />
    <Box marginLeft="16px">
      <Typography variant="body2" color="primary.light" data-testid="account-title">
        {title}
      </Typography>
      <Typography variant="caption" color="secondary" data-testid="account-subtitle">
        {subtitle}
      </Typography>
    </Box>
  </Box>
);

DropdownAccount.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  onClick: PropTypes.func,
};

export default DropdownAccount;
