import Header from 'components/dialogs/DialogHeader';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

function DeleteMemberDialog({ open, onClose, onDelete, ...props }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} {...props}>
      <Header data-testid="delete-dialog-title">
        {t('organization.organization-members-edit-suspend-exclude.title-box-member-exclude')}
      </Header>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-members-edit-suspend-exclude.text-box-organization-members-delete')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} data-testid="cancel-button">
          {t('organization.organization-members-edit-suspend-exclude.box-button-cancel')}
        </Button>
        <Button fullWidth color="gradient" onClick={onDelete} data-testid="confirm-button">
          {t('organization.organization-members-edit-suspend-exclude.box-button-suspend')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteMemberDialog;
