import PropTypes from 'prop-types';
import Brand from 'components/Brand';
import { AppBar as MuiAppBar, Toolbar, Box } from '@mui/material';

const AppBar = ({ companyLogo, companyLogoText, children, brandHref }) => (
  <MuiAppBar
    sx={{
      borderRadius: '0px',
      height: '88px',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: 'solid 1px var(--color-grey-light)',
      backgroundColor: 'white',
    }}
    component="nav"
  >
    <Toolbar sx={{ width: '100%', maxWidth: '1500px', marginRight: 'auto', marginLeft: 'auto', padding: '0 16px' }}>
      <Brand
        image={companyLogo}
        title={companyLogoText}
        href={brandHref}
        sx={{ display: { xs: 'none', md: 'flex' } }}
      />

      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>{children}</Box>
    </Toolbar>
  </MuiAppBar>
);

AppBar.propTypes = {
  companyLogo: PropTypes.string.isRequired,
  companyLogoText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  brandHref: PropTypes.string.isRequired,
};

export default AppBar;
