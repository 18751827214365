import PropTypes from 'prop-types';
import App from 'components/templates/app';
import { Grid, Stepper, Step, StepLabel, Box } from '@mui/material';

const AppWizard = ({ className, steps, currentStep, breadcrumbs, title, children }) => (
  <App className={className} breadcrumbs={breadcrumbs} title={title}>
    <Grid display="flex" container>
      <Grid sx={{ padding: '32px 0 0 0', borderRight: 'solid 1px var(--color-grey-light)' }} item xs={2}>
        <Box display="flex" justifyContent="center">
          <Stepper activeStep={currentStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step
                key={`step-${index + 1}`}
                sx={{
                  '& .Mui-active': {
                    color: 'var(--color-primary-light)',
                  },
                  '& .Mui-completed': {
                    color: 'var(--color-grey-dark)',
                  },
                }}
                index={index}
              >
                <StepLabel
                  sx={{
                    '& .MuiStepLabel-label': {
                      display: {
                        xs: 'none',
                        sm: 'block',
                      },
                    },
                  }}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>
      <Grid sx={{ padding: '40px' }} item xs={10}>
        {children}
      </Grid>
    </Grid>
  </App>
);

AppWizard.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.instanceOf(Array).isRequired,
  breadcrumbs: PropTypes.instanceOf(Array),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default AppWizard;
