import { combineReducers } from '@reduxjs/toolkit';
import applicationsSlice from 'features/applicationsSlice';
import notificationsSlice from 'features/notificationsSlice';
import organizationSlice from 'features/organizationSlice';
import userSlice from 'features/userSlice';
import toastSlice from 'features/toastSlice';

export default combineReducers({
  applications: applicationsSlice,
  notifications: notificationsSlice,
  organization: organizationSlice,
  user: userSlice,
  toast: toastSlice,
});
