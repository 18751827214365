import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AccountItem from 'components/AccountItem';
import { clearOrganizationSelectedMobileDevice, setOrganizationSelectedMobileDevice } from 'features/organizationSlice';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import { fetchMobileDevices, fetchMembersEligibleToPinPolicy, updateMobileDevice } from 'api/portal';
import Loader from 'components/SectionLoader';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import Fade from 'components/Fade';
import { isTrustedDevice } from 'utils/mobile-devices';
import App from '../template';

function MobileDevicePinPolicy() {
  const { t } = useTranslation();

  const NO_PIN = t('organization.organization-mobile-devices-create.pin-policy-options.no-pin');
  const YEAR_BIRTH_DATE = t('organization.organization-mobile-devices-create.pin-policy-options.year-birth-date');
  const PHONE_LAST_4_DIGITS = t(
    'organization.organization-mobile-devices-create.pin-policy-options.phone-last-4-digits',
  );

  const PIN_POLICY_CHOICES = [
    {
      value: 'NO_PIN',
      description: NO_PIN,
    },
    {
      value: 'YEAR_BIRTH_DATE',
      description: YEAR_BIRTH_DATE,
    },
    {
      value: 'PHONE_LAST_4_DIGITS',
      description: PHONE_LAST_4_DIGITS,
    },
  ];

  const NON_ELIGIBLE_MEMBER_SEARCH = t('organization.organization-mobile-devices-create.loading-non-eligible-members');
  const ERROR_FETCH_NON_ELIGIBLE_MEMBERS = t(
    'organization.organization-mobile-devices-create.load-non-eligible-members-failure',
  );
  const REQUIRE_PIN_CHANGE_CHECKBOX = t(
    'organization.organization-mobile-devices-create.input-require-pin-change-check-box',
  );
  const SUBTITLE_NON_ELIGIBLE_MEMBERS = t('organization.organization-mobile-devices-create.link-non-eligible-members');

  const mobileDevice = useSelector((state) => state.organization.selectedMobileDevice);

  const [isLoading, setIsLoading] = useState(true);
  const [pinPolicy, setPinPolicy] = useState(PIN_POLICY_CHOICES[0].value);
  const [requirePinChange, setRequirePinChange] = useState(false);
  const [nonEligibleMembers, setNonEligibleMembers] = useState([]);
  const [isFetchingMembers, setIsFetchingMembers] = useState(false);
  const [errorFetchingMembers, setErrorFetchingMembers] = useState(false);
  const [errorEligibleMembers, setErrorEligibleMembers] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useParams();

  const { deviceId } = params;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onErrorFetchingDevice = () => {
    setIsLoading(false);
    navigate('/org-mobile-devices/devices');
    dispatch(showErrorToast('organization.organization-mobile-devices-edit.error-fetch-device'));
  };

  const onSelectChange = (pinPolicy) => {
    setPinPolicy(pinPolicy);
    setIsFetchingMembers(true);
    setErrorFetchingMembers(false);
    setErrorEligibleMembers(false);

    fetchMembersEligibleToPinPolicy(pinPolicy, deviceId)
      .then((response) => {
        const { data } = response;
        setNonEligibleMembers(data.non_eligible);
        setErrorFetchingMembers(false);
        setIsFetchingMembers(false);
        setErrorEligibleMembers(Boolean(data.non_eligible.length));
      })
      .catch(() => {
        dispatch(showErrorToast('organization.organization-mobile-devices-create.load-non-eligible-members-failure'));
        setErrorFetchingMembers(true);
        setIsFetchingMembers(false);
        setErrorEligibleMembers(false);
      });
  };

  useEffect(() => {
    if (mobileDevice.org_device_id !== deviceId) {
      dispatch(clearOrganizationSelectedMobileDevice());
    }

    setIsLoading(true);

    fetchMobileDevices(new URLSearchParams(), { org_device_id: deviceId })
      .then((response) => {
        const { data } = response;

        if (!data.results) {
          onErrorFetchingDevice();
        } else if (data.results.length === 1) {
          const device = data.results[0];

          if (isTrustedDevice(device.status)) {
            dispatch(clearOrganizationSelectedMobileDevice());
            dispatch(setOrganizationSelectedMobileDevice(device));
            setPinPolicy(device.pin_policy);
            setRequirePinChange(device.change_pin_first_access);
            setIsLoading(false);

            onSelectChange(device.pin_policy);
          } else {
            onErrorFetchingDevice();
          }
        } else {
          onErrorFetchingDevice();
        }
      })
      .catch(() => onErrorFetchingDevice());
  }, []);

  const onSubmit = () => {
    setIsSubmitting(true);

    updateMobileDevice({
      org_device_id: deviceId,
      pin_policy: pinPolicy,
      change_pin_first_access: requirePinChange,
    })
      .then(() => {
        setIsSubmitting(false);
        dispatch(showSuccessToast('organization.organization-mobile-devices-edit.success-device-pin-policy-update'));
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(showErrorToast('organization.organization-mobile-devices-edit.error-device-pin-policy-update'));
      });
  };

  const renderHint = () => {
    const numberNonEligibleMembers = nonEligibleMembers.length;

    if (errorFetchingMembers) {
      return (
        <Typography variant="caption" data-testid="hint-error">
          {ERROR_FETCH_NON_ELIGIBLE_MEMBERS}
        </Typography>
      );
    }

    if (isFetchingMembers) {
      return (
        <>
          <CircularProgress size="16px" />
          <Typography sx={{ marginLeft: '8px' }} variant="caption">
            {NON_ELIGIBLE_MEMBER_SEARCH}
          </Typography>
        </>
      );
    }

    return numberNonEligibleMembers !== 0 ? (
      <Typography variant="caption">
        {t('organization.organization-mobile-devices-create.error-message-non-eligible-members', {
          number_members: numberNonEligibleMembers,
        })}
      </Typography>
    ) : (
      ''
    );
  };

  const numberNonEligibleMembers = nonEligibleMembers.length;

  return (
    <App device={mobileDevice}>
      <Subtitle>{t('organization.organization-mobile-devices-create.title-sub-pin-policy-setup')}</Subtitle>
      <Loader loading={isLoading}>
        <Fade>
          <Box maxWidth="428px">
            <Box maxWidth="428px" marginBottom="48px">
              <Typography variant="body2" color="secondary" marginBottom="24px">
                {t('organization.organization-mobile-devices-create.text-pin-policy-setup')}
              </Typography>
              <FormControl fullWidth error={errorEligibleMembers}>
                <InputLabel id="pin-policy-select-label">
                  {t('organization.organization-mobile-devices-create.input-pin-policy-choice')}
                </InputLabel>
                <Select
                  id="pin_policy"
                  onChange={(event) => onSelectChange(event.target.value)}
                  value={pinPolicy}
                  data-testid="pin-policy-select"
                  inputProps={{ 'data-testid': 'pin-policy-select-input' }}
                >
                  {PIN_POLICY_CHOICES.map((item) => (
                    <MenuItem key={item.value} value={item.value} data-testid={`${item.value}-option`}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText data-testid="pin-policy-select-error">{renderHint()}</FormHelperText>
              </FormControl>
              <FormControlLabel
                label={REQUIRE_PIN_CHANGE_CHECKBOX}
                control={
                  <Checkbox
                    checked={requirePinChange}
                    onClick={() => setRequirePinChange(!requirePinChange)}
                    inputProps={{ 'data-testid': 'require-pin-change-checkbox' }}
                  />
                }
              />
            </Box>

            {numberNonEligibleMembers !== 0 ? (
              <Section maxWidth="428px">
                <Subtitle isError>
                  {t('organization.organization-mobile-devices-create.title-sub-non-eligible-members')}
                </Subtitle>
                <Typography variant="body2" color="secondary" marginBottom="24px">
                  {t('organization.organization-mobile-devices-create.text-non-eligible-members')}
                </Typography>
                {nonEligibleMembers.map((member) => (
                  <AccountItem
                    key={`member-${member.id}`}
                    image={member.user ? member.user.photo : null}
                    title={`${member.user.first_name} ${member.user.last_name}`}
                    subtitle={SUBTITLE_NON_ELIGIBLE_MEMBERS}
                    onClick={() => navigate(`/organization/members/${member.id}/edit/info`)}
                    data-testid={`account-item-${member.id}`}
                  />
                ))}
              </Section>
            ) : null}

            <Button color="gradient" loading={isSubmitting} onClick={onSubmit} data-testid="save-button">
              {t('organization.organization-mobile-devices-create.button-confirm-pin-policy-setup')}
            </Button>
            <Button
              sx={{ margin: '0 0 0 16px' }}
              variant="outlined"
              onClick={() => navigate('/org-mobile-devices/devices')}
              data-testid="back-button"
            >
              {t('organization.organization-mobile-devices-create.button-back')}
            </Button>
          </Box>
        </Fade>
      </Loader>
    </App>
  );
}

export default MobileDevicePinPolicy;
