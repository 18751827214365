import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'features/userSlice';
import TermsAndServicesPortuguese from './pt';
import TermsAndServicesEnglish from './en';

function TermsAndServices() {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const defaultLanguage = searchParams.get('language');

  useEffect(() => {
    if (defaultLanguage) {
      if (defaultLanguage === 'pt-br' || defaultLanguage === 'en-us') {
        dispatch(setLanguage(defaultLanguage));
        navigate('/public/terms-and-service');
      }
    }
  }, []);

  if (user.language === 'pt-br') {
    return <TermsAndServicesPortuguese />;
  }

  return <TermsAndServicesEnglish />;
}

export default TermsAndServices;
