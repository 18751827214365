import { useState } from 'react';
import Subtitle from 'components/Subtitle';
import Header from 'components/dialogs/DialogHeader';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { redefineMemberPin } from 'api/portal';
import styles from './styles.module.css';

function DialogMemberPinUpdate({ deviceId, member, setSelectedMember }) {
  const [pin, setPin] = useState('');
  const [isPinVisible, setIsPinVisible] = useState(false);
  const [pinConfirmation, setPinConfirmation] = useState('');
  const [isPinConfirmationVisible, setIsPinConfirmationVisible] = useState(false);
  const [requirePinChange, setRequirePinChange] = useState(false);
  const [noPinRequired, setNoPinRequired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(new Map());

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const REQUIRE_PIN_CHANGE_CHECKBOX = t(
    'organization.organization-mobile-devices-edit.input-require-pin-change-check-box',
  );
  const NO_PIN_REQUIRED_CHECKBOX = t('organization.organization-mobile-devices-edit.input-no-pin-required-check-box');
  const ERROR_TOO_SHORT_PIN = t('organization.organization-mobile-devices-edit.error-too-short-pin');
  const ERROR_PIN_NOT_CONFIRMATED = t('organization.organization-mobile-devices-edit.error-pin-not-confirmed');

  const validatePin = (pin) => {
    const cleanedPin = pin.replace(/\D/g, '');
    setPin(cleanedPin);

    if (cleanedPin.length < 4) {
      errors.set('pin', ERROR_TOO_SHORT_PIN);
    } else {
      errors.delete('pin');
    }

    if (cleanedPin !== pinConfirmation) {
      errors.set('pinConfirmation', ERROR_PIN_NOT_CONFIRMATED);
    } else {
      errors.delete('pinConfirmation');
    }

    setErrors(errors);
    return errors;
  };

  const validatePinConfirmation = (pinConfirmation) => {
    const cleanedPinConfirmation = pinConfirmation.replace(/\D/g, '');
    setPinConfirmation(cleanedPinConfirmation);

    if (pin !== cleanedPinConfirmation) {
      errors.set('pinConfirmation', ERROR_PIN_NOT_CONFIRMATED);
    } else {
      errors.delete('pinConfirmation');
    }

    setErrors(errors);
    return errors;
  };

  const onClickNoPinRequiredCheckbox = () => {
    setErrors(new Map());
    setNoPinRequired(!noPinRequired);
    setPin('');
    setPinConfirmation('');
  };

  const handleClose = () => {
    setPin('');
    setIsPinVisible(false);
    setPinConfirmation('');
    setIsPinConfirmationVisible(false);
    setRequirePinChange(false);
    setNoPinRequired(false);
    setErrors(new Map());
    setSelectedMember(null);
  };

  const handleSubmit = async () => {
    if (!noPinRequired) {
      validatePin(pin);
      validatePinConfirmation(pinConfirmation);

      if (errors.size !== 0) {
        // Force component interface by creating a new map instance (shallow copy of the current one)
        setErrors(new Map(errors));
        return;
      }
    }

    setIsSubmitting(true);

    redefineMemberPin({
      org_device_id: deviceId,
      member_id: member.id,
      new_pin: pin,
      local_reset_required: requirePinChange,
    })
      .then(() => {
        setIsSubmitting(false);
        dispatch(showSuccessToast('organization.organization-mobile-devices-edit.success-member-pin-edit'));
        handleClose();
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(showErrorToast('organization.organization-mobile-devices-edit.error-member-pin-edit'));
      });
  };

  return (
    <Dialog open={member !== null} data-testid="pin-redefinition-dialog">
      <Header>{t('organization.organization-mobile-devices-edit.title-sub-member-pin-edit')}</Header>
      <DialogContent>
        <Subtitle className={styles['text-center']}>{member !== null ? member.name : ''}</Subtitle>

        <FormControl disabled={noPinRequired} error={errors.has('pin')} fullWidth>
          <InputLabel data-testid="pin-label">
            {t('organization.organization-mobile-devices-edit.pin-label-input-member-pin-edit')}
          </InputLabel>
          <OutlinedInput
            name="pin"
            value={pin}
            onBlur={(event) => validatePin(event.target.value)}
            onChange={(event) => validatePin(event.target.value)}
            type={isPinVisible ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsPinVisible(!isPinVisible)}
                  edge="end"
                >
                  {isPinVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{ 'data-testid': 'pin-input' }}
          />
          <FormHelperText data-testid="pin-error">{errors.get('pin')}</FormHelperText>
        </FormControl>

        <FormControl disabled={noPinRequired} error={errors.has('pinConfirmation')} fullWidth>
          <InputLabel data-testid="pin-confirmation-label">
            {t('organization.organization-mobile-devices-edit.pin-label-input-member-pin-confirmation-edit')}
          </InputLabel>
          <OutlinedInput
            name="pin_confirmation"
            value={pinConfirmation}
            onBlur={(event) => validatePinConfirmation(event.target.value)}
            onChange={(event) => validatePinConfirmation(event.target.value)}
            type={isPinConfirmationVisible ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsPinConfirmationVisible(!isPinConfirmationVisible)}
                  edge="end"
                >
                  {isPinConfirmationVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{ 'data-testid': 'pin-confirmation-input' }}
          />
          <FormHelperText data-testid="pin-confirmation-error">{errors.get('pinConfirmation')}</FormHelperText>
        </FormControl>

        <FormGroup>
          <FormControlLabel
            label={REQUIRE_PIN_CHANGE_CHECKBOX}
            control={
              <Checkbox
                checked={requirePinChange}
                onClick={() => setRequirePinChange(!requirePinChange)}
                inputProps={{ 'data-testid': 'require-pin-change-checkbox' }}
              />
            }
          />
          <FormControlLabel
            label={NO_PIN_REQUIRED_CHECKBOX}
            control={
              <Checkbox
                checked={noPinRequired}
                onClick={() => onClickNoPinRequiredCheckbox()}
                inputProps={{ 'data-testid': 'no-pin-required-checkbox' }}
              />
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button color="gradient" loading={isSubmitting} onClick={handleSubmit} data-testid="submit-button">
          {t('organization.organization-mobile-devices-edit.button-confirm-dialog-member-pin-edit')}
        </Button>
        <Button sx={{ margin: '0 0 0 16px' }} variant="outlined" onClick={handleClose} data-testid="cancel-button">
          {t('organization.organization-mobile-devices-edit.button-cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogMemberPinUpdate;
