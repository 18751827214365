import { useEffect, useState } from 'react';
import Loader from 'components/SectionLoader';
import { fetchRoles } from 'api/portal';
import { Card, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function MemberPermissions({ onToggle, selected = [], ...props }) {
  const [allPermissions, setAllPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (allPermissions.length === 0) {
      setIsLoading(true);

      fetchRoles()
        .then((response) => {
          const { data } = response;
          setIsLoading(false);
          // Remove the roles: "Gestor de Dispositivos" and "Gestor de Contrato"
          setAllPermissions(data.filter((el) => el.id === 1 || el.id === 3));
          setError(false);
        })
        .catch(() => {
          setIsLoading(false);
          setError(true);
        });
    }
  }, []);

  const handleInputToggle = (value) => {
    let permissions = [];

    if (selected.includes(value)) {
      permissions = selected.filter((item) => item !== value);
    } else {
      permissions = [...selected, value];
    }

    onToggle(permissions);
  };

  const renderResults = () => {
    if (error) {
      return (
        <Typography variant="body2" color="error">
          {t('messages.error-roles')}
        </Typography>
      );
    }

    return (
      <Grid container spacing="32px">
        {allPermissions.map((permission) => (
          <Grid key={permission.id.toString()} item xs={12} md={4} lg={3}>
            <Card sx={{ padding: '16px' }}>
              <FormControlLabel
                label={
                  <Typography variant="body2" color="primary.light" fontWeight="bold">
                    {t(`name-role-${permission.id}`)}
                  </Typography>
                }
                control={
                  <Checkbox
                    checked={selected.includes(permission.id)}
                    onClick={() => handleInputToggle(permission.id)}
                    inputProps={{ 'data-testid': `permission-${permission.id}-checkbox` }}
                  />
                }
                data-testid={`permission-${permission.id}`}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <div {...props}>
      <Loader loading={isLoading}>{renderResults()}</Loader>
    </div>
  );
}

export default MemberPermissions;
