import { useState } from 'react';
import braincare from 'components/routes/braincare';
import Subtitle from 'components/Subtitle';
import Loader from 'components/SectionLoader';
import { formatToCpfUsername, unformatCpf } from 'utils/format';
import { isCpf, validateCpf, validateEmail } from 'utils/validation';
import App from 'pages/braincare/users/template';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBuUser } from 'api/portal';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { filterPhoneChars } from 'utils/inputs';
import { useFetchUserAdmin } from 'hooks/useFetchUserAdmin';
import Fade from 'components/Fade';

function AdminUserInfo() {
  const [user, setUser] = useState(undefined);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [username, setUsername] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [errors, setErrors] = useState(new Map());

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { userId } = params;

  const { t } = useTranslation();

  const FILL_REQUIRED = t('messages.fill-required');
  const INVALID_EMAIL_ERROR = t('messages.invalid-email');
  const INVALID_USERNAME_ERROR = t('messages.invalid-document');

  useFetchUserAdmin({
    userId,
    onSuccess: (data) => {
      const { document } = data;

      setUser(data);

      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
      setPhoneNumber(data.phone_number || '');
      setUsername(isCpf(document) ? formatToCpfUsername(document) : document);
    },
    onFailure: () => {
      dispatch(showErrorToast('messages.load-failure'));
      navigate('/braincare/users');
    },
  });

  const handleValidateEmail = (value) => {
    setIsValidEmail(value === '' || validateEmail(value));
  };

  const handleValidateUsername = (event) => {
    const { value } = event.target;
    setIsValidUsername(value === '' || validateCpf(value) || validateEmail(value));
  };

  const handleUpdateUser = () => {
    setIsSaving(true);

    updateBuUser(userId, {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
      document: isCpf(username) ? unformatCpf(username) : username,
    })
      .then(() => {
        setIsSaving(false);
        dispatch(showSuccessToast('messages.save-success'));
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(showErrorToast('messages.save-failure'));
      });
  };

  const validateRequiredFields = () => {
    const requiredFields = {
      firstName,
      lastName,
      username,
    };

    const errors = new Map();

    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    if (!errors.size && isValidEmail && isValidUsername) {
      handleUpdateUser();
    } else {
      setErrors(errors);
      dispatch(showErrorToast('messages.fix-fields'));
    }
  };

  const setFormatUsername = (value) => {
    setUsername(isCpf(value) ? formatToCpfUsername(value) : value);
  };

  return (
    <App title={user ? `${firstName} ${lastName}` : undefined}>
      <Loader loading={!user}>
        <Fade>
          <Box maxWidth="428px" marginBottom="48px">
            <Subtitle>{t('braincare.users-edit.title-user-info')}</Subtitle>

            <TextField
              id="name"
              sx={{ margin: '16px 0 16px 0' }}
              value={firstName}
              label={t('braincare.users-edit.input-name')}
              variant="outlined"
              onChange={(event) => setFirstName(event.target.value)}
              data-testid="first-name-text-field"
              inputProps={{ 'data-testid': 'first-name-input' }}
              FormHelperTextProps={{ 'data-testid': 'first-name-error' }}
              error={errors.has('firstName')}
              helperText={errors.get('firstName')}
              fullWidth
            />

            <TextField
              id="last_name"
              sx={{ margin: '16px 0 16px 0' }}
              value={lastName}
              label={t('braincare.users-edit.input-surname')}
              variant="outlined"
              onChange={(event) => setLastName(event.target.value)}
              data-testid="last-name-text-field"
              inputProps={{ 'data-testid': 'last-name-input' }}
              FormHelperTextProps={{ 'data-testid': 'last-name-error' }}
              error={errors.has('lastName')}
              helperText={errors.get('lastName')}
              fullWidth
            />
            <br />
            <br />
            <Subtitle>{t('braincare.users-edit.title-user-identification')}</Subtitle>
            <TextField
              id="username"
              sx={{ margin: '16px 0 16px 0' }}
              value={username}
              label={t('braincare.users-edit.input-document')}
              variant="outlined"
              onChange={(event) => setFormatUsername(event.target.value)}
              onBlur={handleValidateUsername}
              data-testid="document-text-field"
              inputProps={{ 'data-testid': 'document-input' }}
              FormHelperTextProps={{ 'data-testid': 'document-error' }}
              error={!isValidUsername || errors.has('username')}
              helperText={
                (!isValidUsername && INVALID_USERNAME_ERROR) || (errors.has('username') ? errors.get('username') : '')
              }
              fullWidth
            />
            <br />
            <br />
            <Subtitle>{t('braincare.users-edit.title-user-contact-info')}</Subtitle>
            <TextField
              id="email"
              sx={{ margin: '16px 0 16px 0' }}
              value={email}
              label={t('braincare.users-edit.input-email')}
              variant="outlined"
              onChange={(event) => setEmail(event.target.value)}
              onBlur={() => handleValidateEmail(email)}
              data-testid="email-text-field"
              inputProps={{ 'data-testid': 'email-input' }}
              FormHelperTextProps={{ 'data-testid': 'email-error' }}
              error={errors.has('email') || !isValidEmail}
              helperText={(!isValidEmail && INVALID_EMAIL_ERROR) || (errors.has('email') ? errors.get('email') : '')}
              fullWidth
            />
            <TextField
              id="phone"
              sx={{ margin: '16px 0 16px 0' }}
              value={phoneNumber}
              label={t('braincare.users-edit.input-phone')}
              variant="outlined"
              onChange={(event) => setPhoneNumber(filterPhoneChars(event.target.value))}
              data-testid="phone-text-field"
              inputProps={{ 'data-testid': 'phone-input' }}
              FormHelperTextProps={{ 'data-testid': 'phone-error' }}
              error={errors.has('phoneNumber')}
              helperText={errors.get('phoneNumber')}
              fullWidth
            />
          </Box>
          <Button color="gradient" loading={isSaving} onClick={validateRequiredFields} data-testid="save-button">
            {t('braincare.users-edit.button-save')}
          </Button>
          <Button
            sx={{ margin: '0 0 0 16px' }}
            variant="outlined"
            onClick={() => navigate('/braincare/users')}
            data-testid="cancel-button"
          >
            {t('braincare.users-edit.button-cancel')}
          </Button>
        </Fade>
      </Loader>
    </App>
  );
}

export default braincare(AdminUserInfo);
