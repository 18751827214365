import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import SubSubtitle from 'components/SubSubtitle';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';

function PasswordExpirationForm({
  enable,
  expirationPeriod,
  expirationPeriodError,
  numberSkips,
  skippingPeriod,
  onChange,
}) {
  const { t } = useTranslation();

  return (
    <Box>
      <SubSubtitle>{t('organization.organization-settings.password-policy.password-expiration.title')}</SubSubtitle>

      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-settings.password-policy.password-expiration.description')}
      </Typography>

      <FormControlLabel
        sx={{ mb: '8px' }}
        label={t('organization.organization-settings.password-policy.password-expiration.enable-checkbox')}
        control={
          <Checkbox
            checked={enable}
            onClick={() => onChange(!enable, expirationPeriod, numberSkips, skippingPeriod)}
            inputProps={{ 'data-testid': 'enable-password-expiration-checkbox' }}
          />
        }
      />

      <TextField
        id="expiration-period"
        sx={{ my: '16px' }}
        value={expirationPeriod}
        onChange={(event) => onChange(enable, filterNumbers(event.target.value), numberSkips, skippingPeriod)}
        label={t('organization.organization-settings.password-policy.password-expiration.expiration-period')}
        variant="outlined"
        error={Boolean(expirationPeriodError)}
        helperText={expirationPeriodError}
        data-testid="expiration-period-text-field"
        inputProps={{ 'data-testid': 'expiration-period-input' }}
        FormHelperTextProps={{ 'data-testid': 'expiration-period-error' }}
        fullWidth
      />

      <TextField
        id="number-skips"
        sx={{ my: '16px' }}
        value={numberSkips}
        onChange={(event) => onChange(enable, expirationPeriod, filterNumbers(event.target.value), skippingPeriod)}
        label={t('organization.organization-settings.password-policy.password-expiration.number-of-skips')}
        variant="outlined"
        data-testid="number-skips-text-field"
        inputProps={{ 'data-testid': 'number-skips-input' }}
        FormHelperTextProps={{ 'data-testid': 'number-skips-error' }}
        fullWidth
      />

      <TextField
        id="skipping-period"
        sx={{ my: '16px' }}
        value={skippingPeriod}
        onChange={(event) => onChange(enable, expirationPeriod, numberSkips, filterNumbers(event.target.value))}
        label={t('organization.organization-settings.password-policy.password-expiration.skipping-deadline')}
        variant="outlined"
        data-testid="skipping-period-text-field"
        inputProps={{ 'data-testid': 'skipping-period-input' }}
        FormHelperTextProps={{ 'data-testid': 'skipping-period-error' }}
        fullWidth
      />
    </Box>
  );
}

export default PasswordExpirationForm;
