import PropTypes from 'prop-types';
import { Avatar, Box, Typography } from '@mui/material';

const NavbarProfile = ({
  profileImage,
  profileAlt,
  universityFlag,
  universityAlt,
  userName,
  universityName,
  hasOrganization = false,
  ...props
}) => (
  <Box display="flex" alignItems="center" {...props}>
    <Box display="flex" alignItems="center">
      <Avatar
        sx={{ width: 40, height: 40, zIndex: 2 }}
        src={profileImage}
        alt={profileAlt}
        data-testid="profile-image"
      />
      {hasOrganization && (
        <Avatar
          sx={{
            width: 32,
            height: 32,
            marginLeft: '-10px',
            borderRadius: '50%',
            boxShadow: '0 0 4px 0 rgba(155, 155, 155, 0.2)',
          }}
          src={universityFlag}
          alt={universityAlt}
          data-testid="organization-image"
        />
      )}
    </Box>
    <Box
      sx={{
        display: {
          xs: 'none',
          sm: 'block',
        },
      }}
      paddingLeft="8px"
    >
      <Typography color="primary.main" data-testid="profile-username">
        {userName}
      </Typography>
      {universityName && (
        <Typography color="secondary" variant="caption" data-testid="profile-organization">
          {universityName}
        </Typography>
      )}
    </Box>
  </Box>
);

NavbarProfile.propTypes = {
  profileImage: PropTypes.string,
  profileAlt: PropTypes.string,
  universityFlag: PropTypes.string,
  universityAlt: PropTypes.string,
  userName: PropTypes.string.isRequired,
  universityName: PropTypes.string,
  hasOrganization: PropTypes.bool,
};

export default NavbarProfile;
