import { Box } from '@mui/material';

function NotificationHeader({ children, header, ...props }) {
  return (
    <Box display="flex" flexDirection="column" width="408px" {...props}>
      <Box
        sx={{
          padding: '16px',
          fontSize: '16px',
          fontWeight: '300',
          lineHeight: '1.19',
          color: 'var(--color-primary)',
          borderBottom: 'solid 2px var(--color-primary-light)',
        }}
      >
        {header}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}

export default NotificationHeader;
