import PropTypes from 'prop-types';
import AppMenu from 'components/templates/app-menu';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const App = ({ title, children }) => {
  const params = useParams();

  const { t } = useTranslation();

  const { orgId } = params;

  const { pathname, search } = window.location;

  const HOME = t('braincare.breadcrumbs.home');
  const ORGANIZATIONS = t('braincare.breadcrumbs.organizations');
  const ORGANIZATIONS_EDIT = t('braincare.breadcrumbs.organizations-edit');

  const INFO = t('braincare.organizations-edit.sidebar.step-basic-info');
  const MEMBERS = t('braincare.organizations-edit.sidebar.step-members');
  const INACTIVITY_POLICY = t('braincare.organizations-edit.sidebar.step-inactivity-policy');
  const IP_BLOCKING = t('braincare.organizations-edit.sidebar.step-ip-blocking-policy');
  const SHARE_AND_PURGE_POLICY = t('braincare.organizations-edit.sidebar.step-reports-policy');
  const PASSWORD_POLICY = t('braincare.organizations-edit.sidebar.step-password-policy');

  const INFO_LINK = `/braincare/organizations/${orgId}/edit/info`;
  const MEMBERS_LINK = `/braincare/organizations/${orgId}/members`;
  const PASSWORD_POLICY_LINK = `/braincare/organizations/${orgId}/edit/password-policy`;
  const AUTO_LOGOUT_LINK = `/braincare/organizations/${orgId}/edit/inactivity-policy`;
  const IP_BLOCKING_LINK = `/braincare/organizations/${orgId}/edit/ip-policy`;
  const REPORTS_LINK = `/braincare/organizations/${orgId}/edit/reports`;

  const SIDE_MENU = [
    {
      href: INFO_LINK,
      text: INFO,
      isActive: pathname === INFO_LINK,
    },
    {
      href: PASSWORD_POLICY_LINK,
      text: PASSWORD_POLICY,
      isActive: pathname === PASSWORD_POLICY_LINK,
    },
    {
      href: AUTO_LOGOUT_LINK,
      text: INACTIVITY_POLICY,
      isActive: pathname === AUTO_LOGOUT_LINK,
    },
    {
      href: IP_BLOCKING_LINK,
      text: IP_BLOCKING,
      isActive: pathname === IP_BLOCKING_LINK,
    },
    {
      href: REPORTS_LINK,
      text: SHARE_AND_PURGE_POLICY,
      isActive: pathname === REPORTS_LINK,
    },
    {
      href: MEMBERS_LINK,
      text: MEMBERS,
      isActive: pathname === MEMBERS_LINK,
    },
  ];
  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/organizations',
      title: ORGANIZATIONS,
    },
    {
      path: `${pathname}${search}`,
      title: ORGANIZATIONS_EDIT,
      isActive: true,
    },
  ];
  const TITLE = title || ORGANIZATIONS_EDIT;

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={TITLE} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default App;
