import AppWizard from 'components/templates/AppWizard';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { showErrorToast } from 'features/toastSlice';
import DriveFolderUploadSharpIcon from '@mui/icons-material/DriveFolderUploadSharp';
import VisuallyHiddenInput from 'components/VisuallyHiddenInput';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import { bulkUpload } from 'api/portal';
import BulkUploadModelXlsx from 'assets/files/bulk_upload_model.xlsx';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import BulkUploadInstructionsTable from 'components/tables/BulkUploadInstructionsTable';
import BulkUploadResultsDialog from 'components/dialogs/BulkUploadResultsDialog';
import { hasBulkUploadFileExtension } from 'utils/validation';
import Fade from 'components/Fade';

function BulkUpload() {
  const [selectedFile, setselectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dataResponse, setDataResponse] = useState(null);
  const [dataError, setDataError] = useState(null);
  const [forcePasswordChange, setForcePasswordChange] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const STEPS = [t('organization.organization-members-create.wizard-import-csv-bulk-upload')];

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_ORGANIZATION = t('organization.breadcrumbs.manage-organization');
  const BULK_UPLOAD = t('organization.breadcrumbs.bulk-upload');

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/organization/members',
      title: MANAGE_ORGANIZATION,
    },
    {
      path: '/organization/members/bulk-upload-instructions',
      title: BULK_UPLOAD,
      isActive: true,
    },
  ];

  const handleSelectFile = (event) => {
    const file = event.target.files[0];

    setselectedFile({
      file,
      name: file.name,
    });
  };

  const getErrorMessage = (error) => {
    const { status, data } = error.response;

    if (status === 400) {
      if (data.message === 'Incorrect CSV headers') {
        return t('organization.organization-members-bulk-upload.responses.error.error-400-incorrect-headers');
      }

      return t('organization.organization-members-bulk-upload.responses.error.error-400-no-data');
    }

    if (status === 415) {
      return t('organization.organization-members-bulk-upload.responses.error.error-415');
    }

    if (status === 422) {
      return t('organization.organization-members-bulk-upload.responses.error.error-422');
    }

    return t('organization.organization-members-bulk-upload.responses.error.error-500');
  };

  const submitFile = () => {
    if (selectedFile === null) {
      dispatch(showErrorToast('messages.file-not-selected'));
      return;
    }

    if (selectedFile.file.size === 0) {
      dispatch(showErrorToast('messages.file-is-empty'));
      return;
    }

    if (selectedFile.file.size >= 1048576) {
      dispatch(showErrorToast('messages.file-too-large'));
      return;
    }

    if (!hasBulkUploadFileExtension(selectedFile)) {
      dispatch(showErrorToast('messages.file-not-csv'));
      return;
    }

    setIsLoading(true);
    setShowDialog(true);

    const formData = new FormData();
    formData.append('file', selectedFile.file);

    if (forcePasswordChange) {
      formData.append('force_password_change', 'True');
    }

    bulkUpload(formData)
      .then((response) => {
        const importedRows = response.data.data.summary.rows_imported.rows.toString();
        const skippedRows = response.data.data.summary.rows_skipped.rows.toString();
        const errorsRows = response.data.data.summary.rows_not_imported.rows;

        setDataResponse({
          errorsList: response.data.data.errors_list,
          importedList: response.data.data.imported_list,
          skippedList: response.data.data.skipped_list,
          totalSentRows: response.data.data.summary.csv_rows,
          totalImportedRows: response.data.data.summary.rows_imported.count,
          importedRows,
          totalNotImportedRows: response.data.data.summary.rows_not_imported.count,
          errorsRows,
          totalSkippedRows: response.data.data.summary.rows_skipped.count,
          skippedRows,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setDataError({
          errorMessage: getErrorMessage(error),
        });
        setIsLoading(false);
      });
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setselectedFile(null);
    setDataResponse(null);
    document.location.reload();
  };

  return (
    <AppWizard title={BULK_UPLOAD} breadcrumbs={breadcrumbs} steps={STEPS} currentStep={0}>
      <Fade>
        <Section maxWidth="100%">
          <Subtitle>{t('organization.organization-members-bulk-upload.title-bulk-upload')}</Subtitle>

          <Grid marginBottom="8px" container>
            <Grid item xs={12}>
              <Typography variant="body2" color="secondary" marginBottom="24px">
                {t('organization.organization-members-bulk-upload.text-bulk-upload')}
              </Typography>
            </Grid>

            <Grid container item xs={12}>
              <Grid paddingRight="12px" item xs={12} md={10}>
                <Typography variant="body2" color="error" marginBottom="24px">
                  {t('organization.organization-members-bulk-upload.warning.text-bulk')}
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" item xs={12} md={2}>
                <a href={BulkUploadModelXlsx} download aria-label="download-model-csv">
                  <Button variant="outlined" data-testid="download-button">
                    {t('organization.organization-members-bulk-upload.button-download-model-csv')}
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>

          <Section>
            <BulkUploadInstructionsTable />
          </Section>

          <FormControlLabel
            label={t('organization.organization-members-bulk-upload.force-password-change-label')}
            control={
              <Checkbox
                checked={forcePasswordChange}
                onClick={() => setForcePasswordChange(!forcePasswordChange)}
                inputProps={{ 'data-testid': 'force-password-change-checkbox' }}
              />
            }
          />
          <Typography variant="body2" color="error" marginBottom="8px">
            {t('organization.organization-members-bulk-upload.force-password-change-text')}
          </Typography>

          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-members-bulk-upload.text-chose-file-csv-to-send')}
          </Typography>

          <Grid container spacing="8px">
            <Grid item flexGrow={1}>
              <Grid container alignItems="center">
                <Grid item>
                  <Button component="label" variant="outlined" data-testid="choose-csv-button">
                    {t('organization.organization-members-bulk-upload.button-chose-csv')}
                    <VisuallyHiddenInput onChange={handleSelectFile} type="file" data-testid="input-file" />
                  </Button>
                </Grid>
                <Grid item>
                  <Typography display="inline" variant="body2" color="secondary" marginLeft="8px">
                    {selectedFile
                      ? selectedFile.name
                      : t('organization.organization-members-bulk-upload.text-no-file-selected')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item flexGrow={0}>
              <Button
                variant="outlined"
                color="success"
                onClick={submitFile}
                startIcon={<DriveFolderUploadSharpIcon />}
                data-testid="upload-button"
              >
                {t('organization.organization-members-bulk-upload.button-bulk-upload')}
              </Button>
            </Grid>
          </Grid>
        </Section>

        <BulkUploadResultsDialog
          dataResponse={dataResponse}
          dataError={dataError}
          loading={isLoading}
          onClose={handleCloseDialog}
          open={showDialog}
        />
      </Fade>
    </AppWizard>
  );
}

export default BulkUpload;
