import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setLanguage } from 'features/userSlice';
import PrivacyPolicyPortuguese from './pt';
import PrivacyPolicyEnglish from './en';

function PrivacyPolicy() {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const defaultLanguage = searchParams.get('language');

  useEffect(() => {
    if (defaultLanguage) {
      if (defaultLanguage === 'pt-br' || defaultLanguage === 'en-us') {
        dispatch(setLanguage(defaultLanguage));
        navigate('/public/privacy-policy');
      }
    }
  }, []);

  if (user.language === 'pt-br') {
    return <PrivacyPolicyPortuguese />;
  }

  return <PrivacyPolicyEnglish />;
}

export default PrivacyPolicy;
