import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import BreadcrumbItem from 'components/BreadcrumbItem';
import LinkItem from 'components/LinkItem';
import Button from 'components/Button';
import Menu from 'components/Menu';
import { Box, Grid } from '@mui/material';
import styles from './styles.module.css';

function App({ actionButton, breadcrumbs, children, className, linkItems, secondActionButton, title }) {
  const navigate = useNavigate();

  return (
    <div className={classNames(className, styles.container)}>
      <Menu />

      <Box
        sx={{
          padding: '88px 40px 0',
          width: '100%',
          height: '100%',
          flexGrow: 1,
          maxWidth: '1500px',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        {breadcrumbs && breadcrumbs.length > 0 && (
          <Breadcrumbs>
            {breadcrumbs.map((item, index) => (
              <BreadcrumbItem
                key={index.toString()}
                path={item.path}
                isActive={item.isActive}
                data-testid={`page-tab-${index}`}
              >
                {item.title}
              </BreadcrumbItem>
            ))}
          </Breadcrumbs>
        )}

        {title && (
          <Box
            sx={{
              borderBottom: 'solid 1px var(--color-grey-light)',
              fontWeight: 200,
              fontSize: '32px',
              lineHeight: 1,
              color: 'transparent',
              background: 'var(--color-primary-gradient)',
              backgroundClip: 'text',
              padding: '24px 0 16px 0',
              margin: 0,
              textTransform: 'lowercase',
            }}
            data-testid="page-title"
          >
            {title}
          </Box>
        )}
        {((linkItems && linkItems.length) || actionButton || secondActionButton) && (
          <Grid
            sx={{ margin: '8px 0 24px 0' }}
            container
            alignItems="center"
            justifyContent="space-between"
            spacing="16px"
          >
            <Grid item xs={12} sm={4}>
              {linkItems &&
                linkItems.length > 0 &&
                linkItems.map((item, index) => (
                  <LinkItem
                    key={index.toString()}
                    style={{ marginRight: '16px' }}
                    href={item.path}
                    isActive={window.location.pathname === item.path}
                    data-testid={`app-link-item-${index}`}
                  >
                    {item.title}
                  </LinkItem>
                ))}
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container spacing="16px" justifyContent="flex-end">
                {secondActionButton && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(secondActionButton.href)}
                      data-testid="second-action-button"
                    >
                      {secondActionButton.text}
                    </Button>
                  </Grid>
                )}
                {actionButton && (
                  <Grid item>
                    <Button variant="outlined" onClick={() => navigate(actionButton.href)} data-testid="action-button">
                      {actionButton.text}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}

        <Box>{children}</Box>
      </Box>
    </div>
  );
}

export default App;
