import { useEffect, useRef, useState } from 'react';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchMobileDevices, issueDeviceQrCode, refreshDeviceQrCode } from 'api/portal';
import { showErrorToast } from 'features/toastSlice';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

function DeviceQRCode({ device, onQRCodeRead, pinPolicy, requirePinChange, status }) {
  const [qrCode, setQRCode] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [devicePool, setDevicePool] = useState(null);
  const savedCountdownCallback = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const checkIfDeviceWasRegistered = () => {
    fetchMobileDevices(new URLSearchParams(), { qr_code: qrCode.qr_code, status }).then((response) => {
      const { data } = response;
      const { results } = data;

      if (results.length > 0) {
        const device = results[0];

        if (device.pin_policy === pinPolicy) {
          clearInterval(devicePool);
          onQRCodeRead(device);
        }
      }
    });
  };

  const countdownCallback = () => {
    if (countdown > 0) {
      if (countdown % 10 === 0) {
        checkIfDeviceWasRegistered();
      }

      setCountdown(countdown - 1);
    }
  };

  useEffect(() => {
    savedCountdownCallback.current = countdownCallback;
  });

  function tick() {
    savedCountdownCallback.current();
  }

  useEffect(() => {
    const interval = setInterval(tick, 1000);
    setDevicePool(interval);

    return () => clearInterval(devicePool);
  }, []);

  const startCountdown = (response) => {
    const { data } = response;
    const initialCountdown = Math.ceil((new Date(data.expiration).getTime() - Date.now()) / 1000);
    setQRCode(data);
    setCountdown(initialCountdown);
  };

  const generateQRCode = () => {
    setIsLoading(true);

    const payload = {
      pin_policy: pinPolicy,
      change_pin_first_access: requirePinChange,
      status,
    };

    if (device) {
      payload.org_device_id = device.org_device_id;
    }

    issueDeviceQrCode(payload)
      .then((response) => {
        setIsLoading(false);
        startCountdown(response);
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(showErrorToast('organization.organization-mobile-devices-create.error-generate-qr-code'));
      });
  };

  const refreshQRCode = () => {
    setIsLoading(true);

    if (countdown <= 0) {
      fetchMobileDevices(new URLSearchParams(), { qr_code: qrCode.qr_code })
        .then((response) => {
          const { data } = response;
          const { results } = data;

          if (results.length > 0) {
            const device = results[0];
            if (device.status === status && device.pin_policy === pinPolicy) {
              onQRCodeRead(device);
              return;
            }
          }

          refreshDeviceQrCode(qrCode.qr_code)
            .then((response) => {
              setIsLoading(false);
              startCountdown(response);
            })
            .catch(() => {
              setIsLoading(false);
              dispatch(showErrorToast('organization.organization-mobile-devices-create.error-generate-qr-code'));
            });
        })
        .catch(() =>
          dispatch(showErrorToast('organization.organization-mobile-devices-create.error-generate-qr-code')),
        );
    }
  };

  const navigateToDeviceList = () => {
    clearInterval(devicePool);
    navigate('/org-mobile-devices/devices');
  };

  const renderGenerateQRCodeLayout = () => (
    <div className={styles.buttonsContainer}>
      <Button color="gradient" loading={isLoading} onClick={generateQRCode} data-testid="generate-qrcode-button">
        {t('organization.organization-mobile-devices-create.button-generate-qr-code')}
      </Button>
      <Button
        sx={{ margin: '0 0 0 16px' }}
        variant="outlined"
        onClick={navigateToDeviceList}
        data-testid="button-cancel"
      >
        {t('organization.organization-members-create.button-cancel')}
      </Button>
    </div>
  );

  const renderRefreshQRCodeLayout = () => (
    <>
      <Section maxWidth="428px" className={styles['qr-code']}>
        {countdown > 0 ? (
          <>
            <img src={`data:image/jpeg;base64,${qrCode.qr_code_image}`} alt="QR code" data-testid="qrcode-image" />
            <h5 data-testid="qrcode">{qrCode.qr_code}</h5>
          </>
        ) : (
          <h5>{t('organization.organization-mobile-devices-create.warning-qr-code-expired')}</h5>
        )}
        <h5>{t('organization.organization-mobile-devices-create.text-countdown-seconds', { count: countdown })}</h5>
      </Section>
      <div className={styles.buttonsContainer}>
        <Button
          color="gradient"
          loading={isLoading}
          disabled={countdown > 0}
          onClick={() => refreshQRCode()}
          data-testid="refresh-qrcode-button"
        >
          {t('organization.organization-mobile-devices-create.button-generate-new-qr-code')}
        </Button>
        <Button
          sx={{ margin: '0 0 0 16px' }}
          variant="outlined"
          onClick={navigateToDeviceList}
          data-testid="back-button"
        >
          {t('organization.organization-members-create.button-cancel')}
        </Button>
      </div>
    </>
  );

  return (
    <>
      <Section maxWidth="428px">
        <Subtitle>{t('organization.organization-mobile-devices-create.title-sub-qr-code')}</Subtitle>
        <Typography variant="body2" color="secondary">
          {t('organization.organization-mobile-devices-create.text-qr-code-1')}
          <br />
          <br />
          {t('organization.organization-mobile-devices-create.text-qr-code-2')}
        </Typography>
      </Section>
      <div data-testid="container">{qrCode ? renderRefreshQRCodeLayout() : renderGenerateQRCodeLayout()}</div>
    </>
  );
}

export default DeviceQRCode;
