import React from 'react';
import { useDispatch } from 'react-redux';
import Section from 'components/Section';
import AuthTitle from 'components/AuthTitle';
import Subtitle from 'components/Subtitle';
import { Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setLanguage } from 'features/userSlice';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

export default function TermsAndServices() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const defaultLanguage = searchParams.get('language');

  if (defaultLanguage) {
    if (defaultLanguage === 'pt-br' || defaultLanguage === 'en-us') {
      dispatch(setLanguage(defaultLanguage));
      navigate('/public/security');
    }
  }

  return (
    <Section className={styles.section}>
      <AuthTitle>{t('auth.security.title00')}</AuthTitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text00')}
      </Typography>
      <Subtitle>{t('auth.security.title01')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text01')}
        <ul>
          <li>{t('auth.security.text02')}</li>
          <li>{t('auth.security.text03')}</li>
          <li>{t('auth.security.text04')}</li>
          <li>{t('auth.security.text05')}</li>
        </ul>
      </Typography>
      <Subtitle>{t('auth.security.title02')}</Subtitle>
      <br />
      <Subtitle>{t('auth.security.title03')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text06')}
        <br />
        <br />
        {t('auth.security.text07')}
        <br />
        <br />
        {t('auth.security.text08')}
      </Typography>
      <Subtitle>{t('auth.security.title04')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text09')}
      </Typography>
      <Subtitle>{t('auth.security.title05')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text10')}
        <br />
        <br />
        {t('auth.security.text11')}
      </Typography>
      <Subtitle>{t('auth.security.title06')}</Subtitle>
      <br />
      <Subtitle>{t('auth.security.title07')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text12')}
        <br />
        <br />
        {t('auth.security.text13')}
      </Typography>
      <Subtitle>{t('auth.security.title08')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text14')}
        <ul>
          <li>{t('auth.security.text15')}</li>
          <li>{t('auth.security.text16')}</li>
          <li>{t('auth.security.text17')}</li>
          <li>{t('auth.security.text18')}</li>
          <li>{t('auth.security.text19')}</li>
        </ul>
      </Typography>
      <Subtitle>{t('auth.security.title09')}</Subtitle>
      <br />
      <Subtitle>{t('auth.security.title10')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text20')}
      </Typography>
      <Subtitle>{t('auth.security.title11')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text21')}
      </Typography>
      <Subtitle>{t('auth.security.title12')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text22')}
      </Typography>
      <Subtitle>{t('auth.security.title13')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text23')}
        <br />
        <br />
        {t('auth.security.text24')}
      </Typography>
      <Subtitle>{t('auth.security.title14')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.security.text25')}
        <ul>
          <li>{t('auth.security.text26')}</li>
          <li>{t('auth.security.text27')}</li>
          <li>{t('auth.security.text28')}</li>
        </ul>
        {t('auth.security.text29')}
      </Typography>
    </Section>
  );
}
