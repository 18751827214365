import { useState } from 'react';
import AppWizard from 'components/templates/AppWizard';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import Fade from 'components/Fade';
import ConfirmationStatusUpdate from 'components/MobileDeviceStatusConfirmation';
import OperationConcluded from 'components/MobileDeviceSummary';

export default function MobileDeviceStatusUpdate({ device }) {
  const { pathname, search } = window.location;

  const [step, setStep] = useState(0);
  const [updatedDevice, setUpdatedDevice] = useState({});

  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  const { t } = useTranslation();

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_DEVICES = t('organization.breadcrumbs.manage-devices');
  const CURRENT_NAV = t('organization.breadcrumbs.edit-organization-mobile-device');

  const SUBTITLE_STATUS_UPDATED = t('organization.organization-mobile-devices-edit.title-sub-device-updated');
  const TEXT_STATUS_UPDATED = t('organization.organization-mobile-devices-edit.text-device-updated');

  const STEP_1 = t('organization.organization-mobile-devices-create.wizard-confirmation');
  const STEP_2 = t('organization.organization-mobile-devices-create.wizard-end');

  const STEPS = [STEP_1, STEP_2];

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <ConfirmationStatusUpdate
            device={device}
            status={status}
            onClickNextStep={(device) => {
              setStep(1);
              setUpdatedDevice(device);
            }}
          />
        );
      case 1:
        return (
          <OperationConcluded subtitle={SUBTITLE_STATUS_UPDATED} text={TEXT_STATUS_UPDATED} device={updatedDevice} />
        );
      default:
        return null;
    }
  };

  const TITLE = t('organization.organization-mobile-devices-edit.title-edit-device', { name: device.name });

  const BREADCRUMBS = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/org-mobile-devices/devices',
      title: MANAGE_DEVICES,
    },
    {
      path: `${pathname}${search}`,
      title: CURRENT_NAV,
      isActive: true,
    },
  ];

  return (
    <AppWizard title={TITLE} breadcrumbs={BREADCRUMBS} steps={STEPS} currentStep={step}>
      <Fade>
        <Box marginBottom="48px">{renderStep()}</Box>
      </Fade>
    </AppWizard>
  );
}
