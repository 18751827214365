import { useState } from 'react';
import AppWizard from 'components/templates/AppWizard';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createMember, signupMember } from 'api/portal';
import { isCpf, validateCpf, validateEmail } from 'utils/validation';
import { unformatCpf } from 'utils/format';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';
import PersonalInfo from './steps/PersonalInfo';
import CorporateInfo from './steps/CorporateInfo';
import DefinePassword from './steps/DefinePassword';

const MAP_FIELDS = {
  document: 'document',
  first_name: 'firstName',
  last_name: 'lastName',
  birth_date: 'birthYear',
  email: 'email',
  phone_number: 'phoneNumber',
  roles: 'roles',
  units: 'units',
  notification_language: 'notificationLanguage',
};

// TODO - treat errors better
function MemberCreation() {
  const [personalData, setPersonalData] = useState({
    document: '',
    firstName: '',
    lastName: '',
    email: '',
    birthYear: '',
    notificationLanguage: 'pt-br',
  });
  const [corporateData, setCorporateData] = useState({
    email: '',
    phoneNumber: '',
    roles: [],
    units: [],
  });
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState(new Map());
  const [errorsMember, setErrorsMember] = useState(new Map());
  const [step, setStep] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const STEPS = [
    t('organization.organization-members-create.wizard-personal-info'),
    t('organization.organization-members-create.wizard-organization-info'),
    t('organization.organization-members-create.wizard-define-password'),
  ];

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_ORGANIZATION = t('organization.breadcrumbs.manage-organization');
  const ADD_MEMBER = t('organization.breadcrumbs.add-member');
  const INVALID_USERNAME_ERROR = t('messages.invalid-document');
  const FILL_REQUIRED = t('messages.fill-required');
  const SELECT_AT_LEAST_ONE = t('messages.select-at-least-one');

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/organization/members',
      title: MANAGE_ORGANIZATION,
    },
    {
      path: '/organization/members/create',
      title: ADD_MEMBER,
      isActive: true,
    },
  ];

  const onFillPersonalInfo = (data) => {
    const { userId, document, firstName, lastName, birthYear, notificationLanguage } = data;

    const requiredFields = {
      firstName,
      lastName,
      document,
      birthYear,
      notificationLanguage,
    };

    const errors = new Map();

    if (!userId) {
      Object.entries(requiredFields).forEach(([key, value]) => {
        if (!value) {
          errors.set(key, FILL_REQUIRED);
        }
      });
    }

    if (document !== '' && (isCpf(document) ? !validateCpf(document) : !validateEmail(document))) {
      errors.set('document', INVALID_USERNAME_ERROR);
    }

    setErrors(errors);

    if (errors.size) {
      dispatch(showErrorToast('messages.fix-fields'));
    } else {
      setPersonalData(data);
      setStep(step + 1);
    }
  };

  const requestCreateMember = (userId, passwordData) => {
    const { email, phoneNumber, roles, units } = corporateData;
    const { password, emailNotification, forcePasswordChange } = passwordData;

    createMember({
      user: userId,
      email,
      phone_number: phoneNumber,
      roles,
      units,
      password,
      mail_again: emailNotification,
      force_password_change: forcePasswordChange,
    })
      .then(() => {
        setIsSaving(false);
        dispatch(showSuccessToast('organization.organization-members-create.save-member-success'));
        navigate('/organization/members');
      })
      .catch((error) => {
        setIsSaving(false);

        const { response } = error;
        const { data } = response;

        const errors = new Map();

        Object.entries(data).forEach(([key, value]) => {
          errors.set(MAP_FIELDS[key], value[0]);
        });

        setErrorsMember(errors);

        if (data.non_field_errors) {
          dispatch(showErrorToast('organization.organization-members-create.message-member-exists'));
          return;
        }

        dispatch(showErrorToast('organization.organization-members-create.save-member-failure'));
      });
  };

  const requestCreateUser = (personalData, passwordData) => {
    const { document, firstName, lastName, birthYear, notificationLanguage } = personalData;

    signupMember({
      document: isCpf(document) ? unformatCpf(document) : document,
      first_name: firstName,
      last_name: lastName,
      birth_date: `${birthYear}-01-01`,
      has_read_privacy_policy: false,
      has_read_terms_and_conditions: false,
      accept_terms: false,
      notification_language: notificationLanguage,
    })
      .then((response) => {
        const { data } = response;

        requestCreateMember(data.id, passwordData);
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        const errors = new Map();

        Object.entries(data).forEach(([key, value]) => {
          errors.set(MAP_FIELDS[key], value[0]);
        });

        setIsSaving(false);
        setErrors(errors);
        dispatch(showErrorToast('organization.organization-members-create.save-info-failure'));
      });
  };

  const onFillCorporateInfo = (data) => {
    const { email, roles } = data;

    const requiredFields = {
      email,
      roles,
    };

    const errors = new Map();

    Object.entries(requiredFields).forEach(([key, value]) => {
      if (key === 'roles' && value.length <= 0) {
        errors.set(key, SELECT_AT_LEAST_ONE);
      } else if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    setErrorsMember(errors);

    if (errors.size) {
      dispatch(showErrorToast('messages.fix-fields'));
      return;
    }

    setCorporateData(data);
    setStep(step + 1);
  };

  const onFillPassword = (data) => {
    const { userId } = personalData;

    setIsSaving(true);

    if (userId !== null) {
      requestCreateMember(userId, data);
    } else {
      requestCreateUser(personalData, data);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <PersonalInfo errors={errors} initialData={personalData} onSave={onFillPersonalInfo} />;
      case 1:
        return (
          <CorporateInfo
            errors={errorsMember}
            initialData={corporateData}
            onPreviousStep={() => setStep(step - 1)}
            onNextStep={onFillCorporateInfo}
          />
        );
      case 2:
        return (
          <DefinePassword
            isSaving={isSaving}
            memberEmail={corporateData.email}
            onPreviousStep={() => setStep(step - 1)}
            onSave={onFillPassword}
          />
        );
      default:
        return null;
    }
  };

  return (
    <AppWizard title={ADD_MEMBER} breadcrumbs={breadcrumbs} steps={STEPS} currentStep={step}>
      <Fade>{renderStep()}</Fade>
    </AppWizard>
  );
}

export default MemberCreation;
