import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'features/userSlice';
import i18next from 'i18next';
import styles from './styles.module.css';
import Button from '../Button';

const Translator = () => {
  const language = useSelector((state) => state.user.language);
  const dispatch = useDispatch();

  const changeLanguage = (lang) => {
    dispatch(setLanguage(lang));
    i18next.changeLanguage(lang);
  };

  return (
    <div className={styles.buttons}>
      <Button
        sx={{
          color: language === 'en-us' ? 'var(--color-primary)' : 'var(--color-grey)',
        }}
        onClick={() => changeLanguage('en-us')}
      >
        English (US)
      </Button>
      <Button
        sx={{
          color: language === 'pt-br' ? 'var(--color-primary)' : 'var(--color-grey)',
          margin: '0 8px',
        }}
        onClick={() => changeLanguage('pt-br')}
      >
        Português (BR)
      </Button>
    </div>
  );
};

export default Translator;
