import HumanizeDuration from 'humanize-duration';
import i18next from 'i18next';

const months = [
  'months.january',
  'months.february',
  'months.march',
  'months.april',
  'months.may',
  'months.june',
  'months.july',
  'months.august',
  'months.september',
  'months.october',
  'months.november',
  'months.december',
];

const pad = (value, max = 2) => {
  const str = value.toString();
  return str.length < max ? pad(`0${str}`, max) : str;
};

export default pad;

export const getMonthName = (index) => i18next.t(months[index]);

export const formatToDate = (value) => {
  const year = value.getFullYear();
  const month = value.getMonth() + 1;
  const day = value.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const formatToFullDate = (timestamp) => {
  const dateParsed = new Date(timestamp);
  return `${pad(dateParsed.getDate())} ${getMonthName(dateParsed.getMonth())} ${dateParsed.getFullYear()}`;
};

export const formatToTime = (date, secondsPrecision = false) => {
  const dateParsed = new Date(date);

  if (secondsPrecision) {
    return `${pad(dateParsed.getHours())}:${pad(dateParsed.getMinutes())}:${pad(dateParsed.getSeconds())}`;
  }

  return `${pad(dateParsed.getHours())}:${pad(dateParsed.getMinutes())}`;
};

export const formatToDatetime = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return date.toLocaleString('pt-BR', options);
};

export const formatToHumanizedDate = (date) => {
  const parseDate = Date.parse(date);
  const currentDate = Date.now();
  const createdDate = currentDate - parseDate;
  const { language } = i18next;

  const humanized = HumanizeDuration(createdDate, {
    language: language.split('-')[0],
    largest: 2,
    units: ['mo', 'w', 'd', 'h', 'm'],
    delimiter: i18next.t('utils.delimiter'),
    round: true,
  });

  return humanized;
};

export const formatDuration = (date) => {
  const duration = date * 1000;
  const { language } = i18next;

  return HumanizeDuration(duration, {
    language: language.split('-')[0],
    units: ['h', 'm', 's'],
    round: true,
  });
};

export const formatToCpfUsername = (username) => {
  let val = username.replace(/^(\d{3})(\d)/, '$1.$2');
  val = val.replace(/^(\d{3}.\d{3})(\d)/, '$1.$2');
  val = val.replace(/^(\d{3}.\d{3}.\d{3})(\d{1,2})$/, '$1-$2');
  return val;
};

export const unformatCpf = (cpf) => cpf.replace(/\D/g, '');

export const getNameInitials = (name) => {
  const nameArr = name.split(' ');

  const getFirstChar = (string) => string.split('')[0];

  const firstName = nameArr[0];

  if (nameArr.length === 1) {
    return `${getFirstChar(firstName)}`;
  }

  const lastName = nameArr[nameArr.length - 1];

  return `${getFirstChar(firstName)}${getFirstChar(lastName)}`;
};

export const formatToPrettyDate = (dateFormat) => {
  const map = new Map([
    ['%d', '20'],
    ['%m', '03'],
    ['%b', 'Mar.'],
    ['%B', 'March'],
    ['%y', '99'],
    ['%Y', '1999'],
  ]);

  const format1 = dateFormat.slice(0, 2);
  const prettyFormat1 = map.get(format1);

  const separatorsAndFormat2 = dateFormat.slice(2, -2);

  const match = /%[A-Za-z]/.exec(separatorsAndFormat2);
  let prettyFormat2 = '';

  if (match !== null) {
    const format2 = match[0];
    prettyFormat2 = separatorsAndFormat2.replace(format2, map.get(format2));
  }

  const format3 = dateFormat.slice(-2);
  const prettyFormat3 = map.get(format3);

  return `${prettyFormat1}${prettyFormat2}${prettyFormat3}`;
};
