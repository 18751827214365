import { formatToCpfUsername } from 'utils/format';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isCpf } from 'utils/validation';
import RowBadge from 'components/RowBadge';
import Button from 'components/Button';

const VARIANT_MAP = {
  ACTIVE: 'success',
  BLOCKED: 'dark',
  SUSPENDED: 'danger',
};

function MemberRow({ member, onClick, onClickButton, ...props }) {
  const { document } = member.user;
  const variant = VARIANT_MAP[member.status];

  const { t } = useTranslation();

  return (
    <TableRow
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'var(--color-white-secondary)',
        },
      }}
      onClick={onClick}
      {...props}
    >
      <TableCell>{`${member.user.first_name} ${member.user.last_name}`}</TableCell>
      <TableCell>{isCpf(document) ? formatToCpfUsername(document) : document}</TableCell>
      <TableCell>
        {member.roles.map((role) => t(`organization.organization-members.card-member-role-${role.id}`)).join(', ')}
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <RowBadge variant={variant} lock={variant !== 'success'} />{' '}
          <Typography variant="body2" sx={{ ml: '16px' }}>
            {t(`utils.status.${member.status.toLowerCase()}`)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          onClick={(event) => {
            event.stopPropagation();
            onClickButton(event);
          }}
        >
          {t('organization.organization-members.button-reset-password')}
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default MemberRow;
