import { Box, TextField, Typography } from '@mui/material';
import SubSubtitle from 'components/SubSubtitle';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';

function PasswordReusePolicyForm({ maxRepetitions, maxRepetitionError, onChange }) {
  const { t } = useTranslation();

  const MAX_REPETITIONS_LABEL = t(
    'organization.organization-settings.password-policy.password-reuse.max-repetitions-label',
  );

  return (
    <Box marginBottom="48px">
      <SubSubtitle>{t('organization.organization-settings.password-policy.password-reuse.subsubtitle')}</SubSubtitle>

      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-settings.password-policy.password-reuse.text')}
      </Typography>

      <TextField
        id="max-repetitions"
        value={maxRepetitions}
        onChange={(event) => onChange(filterNumbers(event.target.value))}
        label={MAX_REPETITIONS_LABEL}
        variant="outlined"
        error={Boolean(maxRepetitionError)}
        helperText={maxRepetitionError}
        data-testid="max-repetitions-text-field"
        inputProps={{ 'data-testid': 'max-repetitions-input' }}
        FormHelperTextProps={{ 'data-testid': 'max-repetitions-error' }}
        fullWidth
      />
    </Box>
  );
}

export default PasswordReusePolicyForm;
