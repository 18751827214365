import { styled } from '@mui/material/styles';
import LoginImage from 'assets/images/login-background.png';
import Section from 'components/Section';

export const Logo = styled(Section)(() => ({
  backgroundImage: `url(${LoginImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom left',
  backgroundSize: 'contain',
  backgroundColor: '#F1F4F8',
  height: '100%',
  marginBottom: '0',
  padding: '60px',
  color: 'var(--color-primary)',
  fontSize: '32px',
  fontWeight: '300',
}));
