import { useEffect, useState } from 'react';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import Subtitle from 'components/Subtitle';
import Loader from 'components/SectionLoader';
import Button from 'components/Button';
import MemberUnits from 'components/MemberUnits';
import { Box, TextField, Typography } from '@mui/material';
import App from 'pages/braincare/members/template';
import { useNavigate, useParams } from 'react-router-dom';
import braincare from 'components/routes/braincare';
import { useDispatch } from 'react-redux';
import { fetchUnitsByOrganization, updateMemberByOrganization } from 'api/portal';
import Permissions from 'components/Permissions';
import { validateEmail } from 'utils/validation';
import { useTranslation } from 'react-i18next';
import { useFetchMemberForAdmin } from 'hooks/useFetchMemberAdmin';
import Fade from 'components/Fade';

function PermissionsAdmin() {
  const [member, setMember] = useState({});

  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [units, setUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState((member.units || []).map((unit) => unit.id));
  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const [errorUnits, setErrorUnits] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const [errors, setErrors] = useState(new Map());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { orgId, memberId } = params;

  const { t } = useTranslation();

  const INVALID_EMAIL_ERROR = t('messages.invalid-email');
  const FILL_REQUIRED = t('logs.activities-report.messages.fill-required');

  useFetchMemberForAdmin({
    orgId,
    memberId,
    onSuccess: (data) => {
      setMember(data);
      setEmail(data.email);
      setPhoneNumber(data.phone_number);
      setSelectedPermissions(data.roles.map((role) => role.id));
      setSelectedUnits(data.units.map((unit) => unit.id));
    },
    onFailure: () => {
      dispatch(showErrorToast('messages.load-failure'));
      navigate(`/braincare/organizations/${orgId}/members`);
    },
  });

  useEffect(() => {
    fetchUnitsByOrganization(orgId)
      .then((response) => {
        setIsLoadingUnits(false);

        const { data } = response;

        setUnits(data.results);
        setErrorUnits(false);
      })
      .catch(() => {
        setIsLoadingUnits(false);
        setErrorUnits(true);
      });
  }, []);

  const handleMemberSubmit = () => {
    const errors = new Map();

    if (email === '') {
      errors.set('email', FILL_REQUIRED);
    } else if (!validateEmail(email)) {
      errors.set('email', INVALID_EMAIL_ERROR);
    }

    if (selectedPermissions.length === 0) {
      errors.set('permissions', FILL_REQUIRED);
    }

    setErrors(errors);

    if (errors.size !== 0) {
      dispatch(showErrorToast('messages.fix-fields'));
      return;
    }

    setIsSaving(true);

    updateMemberByOrganization(orgId, memberId, {
      email,
      phone_number: phoneNumber,
      roles: selectedPermissions,
      units: selectedUnits,
    })
      .then(() => {
        setIsSaving(false);
        dispatch(showSuccessToast('messages.save-success'));
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(showErrorToast('messages.save-failure'));
      });
  };

  return (
    <App>
      <Fade>
        <Box marginBottom="48px">
          <Subtitle>{t('organization.organization-members-edit-permissions.title-sub-member-contact')}</Subtitle>
          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-members-edit-permissions.text-organization-members-contact')}
          </Typography>

          <Loader loading={!member.id}>
            <Fade>
              <Box maxWidth="428px">
                <TextField
                  id="email"
                  sx={{ margin: '0 0 16px 0' }}
                  value={email}
                  placeholder="logan.chandler@organization.med"
                  label={t('organization.organization-members-edit-permissions.input-email')}
                  variant="outlined"
                  onChange={(event) => setEmail(event.target.value)}
                  data-testid="email-text-field"
                  inputProps={{ 'data-testid': 'email-input' }}
                  FormHelperTextProps={{ 'data-testid': 'email-error' }}
                  error={errors.has('email')}
                  helperText={errors.has('email') ? errors.get('email') : ''}
                  fullWidth
                />
                <TextField
                  id="phone"
                  sx={{ margin: '16px 0 16px 0' }}
                  value={phoneNumber}
                  placeholder="+1 512-312-344"
                  label={t('organization.organization-members-edit-permissions.input-phone')}
                  variant="outlined"
                  onChange={(event) => setPhoneNumber(event.target.value)}
                  data-testid="phone-text-field"
                  inputProps={{ 'data-testid': 'phone-input' }}
                  FormHelperTextProps={{ 'data-testid': 'phone-error' }}
                  error={errors.has('phoneNumber')}
                  helperText={errors.has('phoneNumber') ? errors.get('phoneNumber') : ''}
                  fullWidth
                />
              </Box>
            </Fade>
          </Loader>
          <Box marginBottom="48px">
            <Subtitle>{t('organization.organization-members-edit-permissions.title-sub-member-roles')}</Subtitle>
            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('organization.organization-members-edit-permissions.text-organization-members-role')}
            </Typography>
            <Loader loading={!member.id}>
              <Box marginBottom="48px">
                {errors.has('permissions') ? (
                  <Typography variant="body2" color="error" marginBottom="24px" data-testid="permission-error">
                    {errors.get('permissions')}
                  </Typography>
                ) : (
                  <Permissions
                    selected={selectedPermissions}
                    onToggle={(permissions) => setSelectedPermissions(permissions)}
                    data-testid="permissions"
                  />
                )}
              </Box>
            </Loader>
          </Box>
          <Box marginBottom="48px">
            <Subtitle>{t('organization.organization-members-edit-permissions.title-sub-member-units')}</Subtitle>
            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('organization.organization-members-edit-permissions.text-organization-members-units')}
            </Typography>
            <Loader loading={!member.id}>
              <Box>
                <MemberUnits
                  error={errorUnits}
                  isLoading={isLoadingUnits}
                  onToggle={(units) => setSelectedUnits(units)}
                  selectedUnits={selectedUnits}
                  units={units}
                  data-testid="units"
                />
              </Box>
            </Loader>
          </Box>
          <Button
            variant="outlined"
            onClick={handleMemberSubmit}
            loading={isSaving}
            disabled={!member.id}
            data-testid="save-button"
          >
            {t('organization.organization-members-edit-permissions.button-save')}
          </Button>
          <Button
            sx={{ margin: '0 0 0 16px' }}
            variant="outlined"
            onClick={() => navigate(`/braincare/organizations/${orgId}/members`)}
            data-testid="cancel-button"
          >
            {t('organization.organization-members-edit-permissions.button-cancel')}
          </Button>
        </Box>
      </Fade>
    </App>
  );
}

export default braincare(PermissionsAdmin);
