import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import App from 'pages/organization/members/template';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import Loader from 'components/SectionLoader';
import Subtitle from 'components/Subtitle';
import { useDispatch } from 'react-redux';
import RuledPasswordInput from 'components/RuledPasswordInput';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from 'components/Button';
import { validatePassword as applyPolicyValidation } from 'utils/password-validators';
import { updateMemberPassword } from 'api/portal';
import {
  Box,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFetchMember } from 'hooks/useFetchMember';

function MemberPassword() {
  const [member, setMember] = useState({});

  const { organization } = member;
  const passwordPolicy = organization ? organization.password_policy : {};

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [emailNotification, setEmailNotification] = useState(false);
  const [forcePasswordChange, setForcePasswordChange] = useState(false);

  const [errors, setErrors] = useState(new Map());
  const [passwordPolicyErrors, setPasswordPolicyErrors] = useState(undefined);

  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { memberId } = params;

  const { t } = useTranslation();

  const REQUIRED_FIELD_ERROR = t('account.edit-password.fill-required');
  const MISMATCH_PASSWORD_ERROR = t('messages.mismatch-error');

  useFetchMember({
    memberId,
    onSuccess: (data) => {
      setMember(data);
    },
    onFailure: () => {
      dispatch(showErrorToast('messages.load-failure'));
      navigate('/organization/members');
    },
  });

  const validatePassword = (password) => {
    const policyErrors = applyPolicyValidation(passwordPolicy, password);
    setPasswordPolicyErrors(policyErrors);

    errors.delete('password');
    errors.delete('passwordConfirmation');

    if (policyErrors.length !== 0) {
      errors.set('password', policyErrors);
    }

    if (!password) {
      errors.set('password', REQUIRED_FIELD_ERROR);
    }

    if (password !== passwordConfirmation) {
      errors.set('passwordConfirmation', MISMATCH_PASSWORD_ERROR);
    }

    setPassword(password);
    setPasswordConfirmation(passwordConfirmation);
    setErrors(errors);
  };

  const validatePasswordConfirmation = (passwordConfirmation) => {
    errors.delete('passwordConfirmation');

    if (password !== passwordConfirmation) {
      errors.set('passwordConfirmation', MISMATCH_PASSWORD_ERROR);
    }

    setPasswordConfirmation(passwordConfirmation);
    setErrors(errors);
  };

  const handleSubmit = () => {
    validatePassword(password);
    validatePasswordConfirmation(passwordConfirmation);

    if (errors.size !== 0) {
      dispatch(showErrorToast('messages.fix-fields'));
      return;
    }

    setIsSaving(true);

    updateMemberPassword(memberId, {
      password,
      force_password_change: forcePasswordChange,
      mail_again: emailNotification,
    })
      .then(() => {
        setIsSaving(false);
        dispatch(showSuccessToast('messages.save-success'));
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        setIsSaving(false);

        const passwordErrors = data.password;

        if (Array.isArray(passwordErrors)) {
          const policyErrors = passwordErrors.map((passwordError) => passwordError.split(']')[0].split('[')[1]);
          setPasswordPolicyErrors(policyErrors);
        }

        dispatch(showErrorToast('messages.save-failure'));
      });
  };

  return (
    <App>
      <Fade in timeout={500}>
        <Box maxWidth="428px" data-testid="change-member-password-form">
          <Subtitle>
            {t('organization.organization-members-edit-change-password.title-sub-member-change-password')}
          </Subtitle>
          <Loader loading={!member.id}>
            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('organization.organization-members-edit-change-password.text-member-change-password')}
            </Typography>
            <RuledPasswordInput
              errors={passwordPolicyErrors}
              hasError={errors.has('password')}
              passwordPolicy={passwordPolicy}
              onChange={(event) => validatePassword(event.target.value)}
              value={password}
            />
            <FormControl error={errors.has('passwordConfirmation')} fullWidth>
              <InputLabel data-testid="password-confirmation-label">
                {t('organization.organization-members-edit-change-password.input-password-confirmation')}
              </InputLabel>
              <OutlinedInput
                name="password_confirmation"
                value={passwordConfirmation}
                onChange={(event) => validatePasswordConfirmation(event.target.value)}
                type={isPasswordConfirmationVisible ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)}
                      edge="end"
                    >
                      {isPasswordConfirmationVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ 'data-testid': 'password-confirmation-input' }}
              />
              <FormHelperText data-testid="password-confirmation-error">
                {errors.get('passwordConfirmation')}
              </FormHelperText>
            </FormControl>
            <FormGroup sx={{ margin: '0 0 48px 0' }}>
              <FormControlLabel
                label={t('organization.organization-members-edit-change-password.input-mail-notification-check-box', {
                  email: member.email,
                })}
                control={
                  <Checkbox
                    checked={emailNotification}
                    onClick={() => setEmailNotification(!emailNotification)}
                    inputProps={{ 'data-testid': 'email-notification-checkbox' }}
                  />
                }
                data-testid="email-notification"
              />
              <FormControlLabel
                label={t('organization.organization-members-edit-change-password.input-force-password-change-checkbox')}
                control={
                  <Checkbox
                    checked={forcePasswordChange}
                    onClick={() => setForcePasswordChange(!forcePasswordChange)}
                    inputProps={{ 'data-testid': 'force-password-change-checkbox' }}
                  />
                }
              />
            </FormGroup>
            <Button variant="outlined" loading={isSaving} onClick={handleSubmit} data-testid="submit-button">
              {t('organization.organization-members-edit-permissions.button-save')}
            </Button>
            <Button
              sx={{ margin: '0 0 0 16px' }}
              variant="outlined"
              onClick={() => navigate('/organization/members')}
              data-testid="cancel-button"
            >
              {t('organization.organization-members-edit-permissions.button-cancel')}
            </Button>
          </Loader>
        </Box>
      </Fade>
    </App>
  );
}

export default MemberPassword;
