import PropTypes from 'prop-types';
import Button from 'components/Button';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';

const ActiveAccount = ({ photo, title, subtitle, buttonText, onClick, ...props }) => (
  <Box {...props}>
    <Box display="flex">
      <Avatar src={photo} alt="Active account profile image" sx={{ width: 80, height: 80, margin: '0 16px 0 0' }} />
      <Box>
        <Typography variant="h6" marginBottom="8px" color="primary" data-testid="active-account-title">
          {title}
        </Typography>
        <Typography variant="body2" color="secondary" data-testid="active-account-subtitle">
          {subtitle}
        </Typography>
      </Box>
    </Box>
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Button sx={{ margin: '16px 0' }} color="gradient" onClick={onClick} data-testid="account-settings-button">
        {buttonText}
      </Button>
    </Grid>
    <Divider sx={{ marginBottom: '16px' }} />
  </Box>
);

ActiveAccount.propTypes = {
  photo: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ActiveAccount;
