import { useEffect, useState } from 'react';
import braincare from 'components/routes/braincare';
import { useDispatch } from 'react-redux';
import { showErrorToast } from 'features/toastSlice';
import App from 'components/templates/app';
import Title from 'components/AuthTitle';
import Section from 'components/Section';
import UserCard from 'components/cards/UserCard';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Loader from 'components/SectionLoader';
import { useNavigate } from 'react-router-dom';
import { fetchAllOrganizations, fetchBuUsers } from 'api/portal';
import Button from 'components/Button';
import Autocomplete from 'components/Autocomplete';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';

const PAGE_SIZE = 8;

function AdminUserList() {
  const { t } = useTranslation();

  const defaultOrganization = {
    label: t('braincare.users.dropdown-filter-organization'),
    id: 0,
    'data-testid': 'organization-option-0',
  };

  const [nameFilter, setNameFilter] = useState('');
  const [organizationFilter, setOrganizationFilter] = useState(defaultOrganization);
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [users, setUsers] = useState([]);
  const [errorUsers, setErrorUsers] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [errorOrganizations, setErrorOrganizations] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const HOME = t('braincare.breadcrumbs.home');
  const USERS = t('braincare.breadcrumbs.users');
  const TITLE = t('braincare.users.title');

  const LOAD_USERS_FAILURE = t('braincare.users.load-users-failure');
  const LOAD_ORGANIZATIONS_FAILURE = t('braincare.users.load-organizations-failure');

  const BUTTON_CREATE_USER = t('braincare.users.button-create-user');

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/organizations',
      title: USERS,
      isActive: true,
    },
  ];

  const USER_STATUS = [
    { id: 'ACTIVE', text: t('utils.status.active') },
    { id: 'SUSPENDED', text: t('utils.status.suspended') },
  ];

  const fetchOrganizations = () => {
    setErrorOrganizations(false);

    const searchParams = new URLSearchParams();
    searchParams.set('page_size', 9999);

    fetchAllOrganizations(searchParams)
      .then((response) => {
        const { data } = response;
        setErrorOrganizations(false);
        setOrganizations(data.results);
      })
      .catch(() => {
        setErrorOrganizations(true);
        dispatch(showErrorToast('braincare.users.load-organizations-failure'));
      });
  };

  const getSearchParams = (page, name = '', organization = undefined, status = 'ALL') => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', PAGE_SIZE);
    searchParams.set('search_name', name);

    if (organization && organization.id !== defaultOrganization.id) {
      searchParams.set('organization', organization.id);
    }

    if (status !== 'ALL') {
      searchParams.set('is_active', status === 'ACTIVE');
    }

    return searchParams;
  };

  const fetchUsersHandler = (searchParams) => {
    setIsLoading(true);
    setErrorUsers(false);

    fetchBuUsers(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setErrorUsers(false);
        setUsers(data.results);
        setCount(Math.ceil(data.count / PAGE_SIZE));
        setCurrentPage(Number(searchParams.get('page')));
      })
      .catch(() => {
        setIsLoading(false);
        setErrorUsers(true);
        dispatch(showErrorToast('braincare.users.load-users-failure'));
      });
  };

  useEffect(() => {
    const searchParams = getSearchParams(1);
    fetchUsersHandler(searchParams);
    fetchOrganizations();
  }, []);

  const handleClearFilterClick = () => {
    setNameFilter('');
    setOrganizationFilter(defaultOrganization);
    setStatusFilter('ALL');
    const searchParams = getSearchParams(1);
    fetchUsersHandler(searchParams);
  };

  const openEditUserPage = (id) => navigate(`/braincare/users/${id}/info`);

  const renderUsers = () => {
    if (errorUsers) {
      return (
        <Typography variant="body2" color="error" data-testid="error-load-users">
          {LOAD_USERS_FAILURE}
        </Typography>
      );
    }

    if (users.length === 0) {
      return (
        <Typography display="flex" justifyContent="center" variant="body2" color="secondary" data-testid="no-users">
          {t('braincare.users.no-users-found')}
        </Typography>
      );
    }

    return (
      <Grid container spacing="16px">
        {users.map((user) => (
          <Grid key={`user-${user.id}`} item xs={12} sm={6} md={3}>
            <UserCard
              sx={{ height: '100%' }}
              onClick={() => openEditUserPage(user.id)}
              user={user}
              data-testid={`user-card-${user.id}`}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const actionButton = {
    text: BUTTON_CREATE_USER,
    href: '/braincare/users/create',
  };

  const organizationOptions = [
    defaultOrganization,
    ...organizations.map((organization) => ({
      label: organization.name,
      id: organization.id,
    })),
  ];

  return (
    <App breadcrumbs={breadcrumbs} actionButton={actionButton}>
      <Section>
        <Title>{TITLE}</Title>
      </Section>

      <Section>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <Grid marginBottom="16px" container spacing="12px">
            <Grid item xs={12} sm={4} md={2.5}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel htmlFor="filter">{t('braincare.users.input-filter-name')}</InputLabel>
                <OutlinedInput
                  name="search"
                  value={nameFilter}
                  onChange={(event) => setNameFilter(event.target.value)}
                  inputProps={{ 'data-testid': 'search-input' }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2.5}>
              <FormControl sx={{ margin: 0 }} error={errorOrganizations} fullWidth>
                <Autocomplete
                  disablePortal
                  value={organizationFilter}
                  onChange={(event, newValue) => setOrganizationFilter(newValue)}
                  options={organizationOptions}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <Typography
                      {...props}
                      variant="body2"
                      color="secondary"
                      key={option.id}
                      data-testid={`organization-option-${option.id}`}
                    >
                      {option.label}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t('braincare.users.input-filter-organization')} />
                  )}
                  data-testid="organization-select"
                />
                <FormHelperText data-testid="organization-select-error">
                  {errorOrganizations ? LOAD_ORGANIZATIONS_FAILURE : ''}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2.5}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel htmlFor="status">{t('braincare.users.input-filter-status')}</InputLabel>
                <Select
                  id="status"
                  onChange={(event) => setStatusFilter(event.target.value)}
                  value={statusFilter}
                  data-testid="status-select"
                >
                  <MenuItem value="ALL">{t('braincare.users.dropdown-filter-status')}</MenuItem>
                  {USER_STATUS.map((status) => (
                    <MenuItem
                      key={`filter-status-${status.id}`}
                      value={status.id}
                      data-testid={`status-option-${status.id}`}
                    >
                      {status.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <Button variant="outlined" onClick={handleClearFilterClick} data-testid="clear-filters-button" fullWidth>
                {t('braincare.users.button-clear-filters')}
              </Button>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <Button
                onClick={() => {
                  const searchParams = getSearchParams(1, nameFilter, organizationFilter, statusFilter);
                  fetchUsersHandler(searchParams);
                }}
                color="gradient"
                type="submit"
                data-testid="apply-filters-button"
                fullWidth
              >
                {t('braincare.users.button-apply-filters')}
              </Button>
            </Grid>
          </Grid>
        </form>

        <Loader loading={isLoading}>
          <Fade>{renderUsers()}</Fade>
          <Pagination
            count={count}
            page={currentPage}
            onChange={(event, value) => {
              const searchParams = getSearchParams(value, nameFilter, organizationFilter, statusFilter);
              fetchUsersHandler(searchParams);
            }}
          />
        </Loader>
      </Section>
    </App>
  );
}

export default braincare(AdminUserList);
