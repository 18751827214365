import { useEffect, useState } from 'react';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import AppMenu from 'components/templates/app-menu';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearOrganizationSelectedUnit, setOrganizationSelectedUnit } from 'features/organizationSlice';
import { createUnit } from 'api/portal';
import Button from 'components/Button';
import { showErrorToast } from 'features/toastSlice';
import { TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function CreateUnit() {
  const [unitName, setUnitName] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [errors, setErrors] = useState(new Map());
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_ORGANIZATION = t('organization.breadcrumbs.manage-organization');
  const ADD_UNIT = t('organization.breadcrumbs.add-unit');

  const UNITS_URL = '/organization/units';
  const CREATE_UNIT_URL = 'organization/units/create';

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: UNITS_URL,
      title: MANAGE_ORGANIZATION,
    },
    {
      path: CREATE_UNIT_URL,
      title: ADD_UNIT,
      isActive: true,
    },
  ];

  const SIDE_MENU = [
    {
      href: CREATE_UNIT_URL,
      text: t('organization.organization-units-edit.menu-unit-info'),
      isActive: true,
    },
  ];

  const REQUIRED_FIELD = t('messages.fill-required');

  useEffect(() => {
    dispatch(clearOrganizationSelectedUnit());
  }, []);

  const handleOnSaveChanges = () => {
    const formErrors = new Map();

    if (!unitName) {
      formErrors.set('name', REQUIRED_FIELD);
    }

    if (!additionalInformation) {
      formErrors.set('additionalInformation', REQUIRED_FIELD);
    }

    if (formErrors.size > 0) {
      setErrors(formErrors);
      return;
    }

    setIsSaving(true);
    setErrors(formErrors);

    createUnit({
      name: unitName,
      additional_information: additionalInformation,
    })
      .then((response) => {
        const { data } = response;

        setIsSaving(false);
        dispatch(setOrganizationSelectedUnit(data));
        navigate(`/organization/units/${data.id}/members`);
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(showErrorToast('messages.save-failure'));
      });
  };

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={ADD_UNIT} menuItems={SIDE_MENU}>
      <form onSubmit={(event) => event.preventDefault()}>
        <Section>
          <Subtitle>{t('organization.organization-units-create.title-sub-unit-create')}</Subtitle>
          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-units-create.text-unit-create-page')}
          </Typography>

          <TextField
            sx={{ margin: '16px 0 0 0' }}
            value={unitName}
            onChange={(event) => setUnitName(event.target.value)}
            label={t('organization.organization-units-create.input-unit-name')}
            variant="outlined"
            error={errors.has('name')}
            helperText={errors.get('name')}
            data-testid="text-field-unit-name"
            inputProps={{ 'data-testid': 'input-unit-name' }}
            FormHelperTextProps={{ 'data-testid': 'helper-text-unit-name' }}
            fullWidth
          />

          <TextField
            sx={{ margin: '32px 0 0 0' }}
            value={additionalInformation}
            onChange={(event) => setAdditionalInformation(event.target.value)}
            label={t('organization.organization-units-create.input-unit-info')}
            variant="outlined"
            error={errors.has('additionalInformation')}
            helperText={errors.get('additionalInformation')}
            data-testid="text-field-unit-info"
            inputProps={{ 'data-testid': 'input-unit-info' }}
            FormHelperTextProps={{ 'data-testid': 'helper-text-unit-info' }}
            fullWidth
          />
        </Section>
        <Button
          type="submit"
          color="gradient"
          loading={isSaving}
          onClick={handleOnSaveChanges}
          data-testid="button-save"
        >
          {t('organization.organization-units-create.button-save')}
        </Button>
        <Button
          sx={{ margin: '0 0 0 16px' }}
          variant="outlined"
          onClick={() => navigate('/organization/units')}
          data-testid="button-cancel"
        >
          {t('organization.organization-units-create.button-cancel')}
        </Button>
      </form>
    </AppMenu>
  );
}

export default CreateUnit;
