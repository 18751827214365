import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import ChipInput from 'components/ChipInput';
import App from 'pages/braincare/organizations/template';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import SectionLoader from 'components/SectionLoader';
import { updateOrganization } from 'api/portal';
import braincare from 'components/routes/braincare';
import { isValidIpAddress } from 'utils/network';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFetchOrganizationAdmin } from 'hooks/useFetchOrganizationAdmin';
import Fade from 'components/Fade';

function AdminOrganizationIpBlocking() {
  const [organization, setOrganization] = useState();

  const [name, setName] = useState('');

  const [enableIpBlocking, setEnableIpBlocking] = useState(false);
  const [ipAllowed, setIpAllowed] = useState('');
  const [listIpAllowed, setListIpAllowed] = useState([]);

  const [errors, setErrors] = useState(new Map());

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { orgId } = params;

  const { t } = useTranslation();

  useFetchOrganizationAdmin({
    orgId,
    onSuccess: (data) => {
      setOrganization(data);
      setName(data.name || '');
      setEnableIpBlocking(data.enable_ip_blocking || false);
      setListIpAllowed(data.ips_allowed || []);
    },
    onFailure: () => {
      navigate('/braincare/organizations');
      dispatch(showErrorToast('messages.load-failure'));
    },
  });

  const removeIpAllowed = (ip) => {
    setListIpAllowed(listIpAllowed.filter((value) => value !== ip));
  };

  const addIpAllowed = (event) => {
    if (event.key === 'Enter') {
      const ip = event.target.value.trim();

      if (!isValidIpAddress(ip)) {
        dispatch(showErrorToast('organization.organization-settings.ip-blocking-policy.error-invalid-ip'));
        return;
      }

      if (!listIpAllowed.includes(ip)) {
        setListIpAllowed([...listIpAllowed, ip]);
        setIpAllowed('');
      } else {
        dispatch(showErrorToast('organization.organization-settings.ip-blocking-policy.error-ip-exist'));
      }
    }
  };

  const validateForm = () => {
    const errors = new Map();

    if (enableIpBlocking && listIpAllowed.length === 0) {
      errors.set('ipAllowed', t('organization.organization-settings.ip-blocking-policy.ip-blocking-empty'));
    }

    return errors;
  };

  const onSubmit = () => {
    const errors = validateForm();

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      updateOrganization(orgId, {
        enable_ip_blocking: enableIpBlocking,
        ips_allowed: listIpAllowed.length > 0 ? listIpAllowed : null,
      })
        .then(() => {
          setIsSubmitting(false);
          dispatch(showSuccessToast('organization.organization-settings.save-success-message'));
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(showErrorToast('organization.organization-settings.save-failure-message'));
        });
    } else {
      dispatch(showErrorToast('messages.fix-fields'));
    }
  };

  return (
    <App title={name}>
      <Fade>
        <Box maxWidth="428px">
          <Subtitle>{t('organization.organization-settings.ip-blocking-policy.ip-blocking-subtitle')}</Subtitle>

          <Typography variant="body2" color="secondary">
            {t('organization.organization-settings.ip-blocking-policy.ip-blocking-text')}
          </Typography>

          <SectionLoader loading={!organization}>
            <Fade>
              <FormControlLabel
                sx={{ marginBottom: '16px' }}
                label={t('organization.organization-settings.ip-blocking-policy.ip-blocking-enable-checkbox')}
                control={
                  <Checkbox
                    checked={enableIpBlocking}
                    onClick={() => setEnableIpBlocking(!enableIpBlocking)}
                    inputProps={{ 'data-testid': 'ip-blocking-checkbox' }}
                  />
                }
              />

              <ChipInput
                items={listIpAllowed}
                label={t('organization.organization-settings.ip-blocking-policy.ip-blocking-label')}
                onCloseItem={(item) => removeIpAllowed(item)}
                onChange={(event) => setIpAllowed(event.target.value)}
                errorMessage={errors.has('ipAllowed') ? errors.get('ipAllowed') : ''}
                hasError={errors.has('ipAllowed')}
                onKeyPress={addIpAllowed}
                value={ipAllowed}
                data-testid="ip-allowed-input"
              />

              <Box marginTop="32px">
                <Button variant="outlined" loading={isSubmitting} onClick={onSubmit} data-testid="save-button">
                  {t('organization.organization-settings.ip-blocking-policy.submit-button-text')}
                </Button>
                <Button
                  sx={{ marginLeft: 2 }}
                  variant="outlined"
                  onClick={() => navigate('/braincare/organizations')}
                  data-testid="cancel-button"
                >
                  {t('organization.organization-settings.ip-blocking-policy.cancel-button-text')}
                </Button>
              </Box>
            </Fade>
          </SectionLoader>
        </Box>
      </Fade>
    </App>
  );
}

export default braincare(AdminOrganizationIpBlocking);
