import PropTypes from 'prop-types';
import App from 'components/templates/app';
import { Box, Divider, Drawer, IconButton, Toolbar } from '@mui/material';
import DrawerItem from 'components/DrawerItem';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import Brand from 'components/Brand';
import LogoBraincare from 'assets/images/logo-braincare.png';

const drawerWidth = '272px';

function AppMenu({ className, menuItems, breadcrumbs, title, children }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = menuItems.map((item, index) => (
    <DrawerItem key={index.toString()} isActive={item.isActive} href={item.href} data-testid={`drawer-item-${index}`}>
      {item.text}
    </DrawerItem>
  ));

  return (
    <App
      className={className}
      breadcrumbs={breadcrumbs}
      title={
        <>
          <Toolbar sx={{ display: { xs: 'inline', sm: 'none' } }}>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
              <MenuIcon color="primary" />
            </IconButton>
          </Toolbar>
          {title}
        </>
      }
    >
      <Box display="flex">
        {menuItems && menuItems.length > 0 && (
          <>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { borderRadius: 0, width: drawerWidth },
              }}
            >
              <Box sx={{ padding: '16px 0' }} display="flex" justifyContent="center">
                <Brand image={LogoBraincare} title="logo braincare" />
              </Box>
              {drawer}
            </Drawer>
            <Drawer
              sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'column',
                padding: '48px 30px 0 0',
                width: drawerWidth,
                marginTop: 0,
                zIndex: 1,
                '& .MuiDrawer-paper': {
                  position: 'relative',
                  borderRadius: '0px',
                  border: 'none',
                },
              }}
              variant="permanent"
              anchor="left"
            >
              {drawer}
            </Drawer>
          </>
        )}
        <Divider
          sx={{ display: { xs: 'none', sm: 'flex' }, margin: 0 }}
          variant="middle"
          flexItem
          orientation="vertical"
        />
        <Box sx={{ padding: '40px', width: '100%' }}>{children}</Box>
      </Box>
    </App>
  );
}

AppMenu.propTypes = {
  menuItems: PropTypes.instanceOf(Array),
  breadcrumbs: PropTypes.instanceOf(Array),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default AppMenu;
