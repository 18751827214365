import { useState } from 'react';
import { showErrorToast, showWarningToast } from 'features/toastSlice';
import AuthTitle from 'components/AuthTitle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Button from 'components/Button';
import { Box, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetCorporatePassword, resetAllPasswords } from 'api/portal';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';

function ForgotPassword() {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const [email, setEmail] = useState('');
  const [accountManagers, setAccountManagers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { t } = useTranslation();

  const { orgId } = params;

  const REDEFINE_FAILURE = t('auth.forgot-password.redefine-failure');

  const handleRequestWithAccount = () => {
    resetCorporatePassword({
      document: username,
      organization: orgId,
    })
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setIsSuccess(true);
        setUsernameError('');
        setEmail(data.email);
        setAccountManagers(data.account_managers);
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        if (!data.detail) {
          setIsLoading(false);
          setUsernameError(REDEFINE_FAILURE);
          dispatch(showErrorToast('auth.forgot-password.redefine-failure'));
        }
      });
  };

  const handleRequestWithoutAccount = () => {
    resetAllPasswords({ document: username })
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setIsSuccess(true);
        setUsernameError('');
        setEmail(data.email);
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        if (!data.detail) {
          setIsLoading(false);
          setUsernameError(REDEFINE_FAILURE);
          dispatch(showErrorToast('auth.forgot-password.redefine-failure'));
        }
      });
  };

  const handleOnNextClick = () => {
    if (username === '') {
      setUsernameError(t('messages.fill-required'));
      return;
    }

    setUsernameError('');
    setIsLoading(true);

    if (orgId) {
      handleRequestWithAccount();
    } else {
      handleRequestWithoutAccount();
    }
  };

  const handleClickSuccess = () => {
    dispatch(showWarningToast('auth.forgot-password.check-email'));
    navigate('/auth/direct-login');
  };

  const renderSuccessMessage = () => (
    <Fade>
      <Box sx={{ marginBottom: '48px' }}>
        <AuthTitle data-testid="success-message-title">{t('auth.forgot-password-success.title')}</AuthTitle>
        <Typography variant="body2" color="secondary" marginBottom="16px" data-testid="success-message-text">
          {t('auth.forgot-password-success.text', { email })}
        </Typography>
        {accountManagers.length === 0 ? null : (
          <Typography
            variant="body2"
            color="secondary"
            marginBottom="16px"
            data-testid="success-message-account-managers"
          >
            {t('auth.forgot-password-success.text-account-managers-list')}
            <ul>
              {accountManagers.map((accountManager, index) => (
                <li key={index} data-testid={`account-manager-${index}`}>
                  {accountManager}
                </li>
              ))}
            </ul>
          </Typography>
        )}
        <Button fullWidth color="gradient" onClick={handleClickSuccess} data-testid="success-message-close-button">
          {t('auth.forgot-password-success.button-close')}
        </Button>
      </Box>
    </Fade>
  );

  const renderForm = () => (
    <Fade>
      <Box sx={{ marginBottom: '48px' }}>
        <AuthTitle>{t('auth.forgot-password.title-main-password')}</AuthTitle>
        <Typography variant="body2" color="secondary" marginBottom="16px">
          {t('auth.forgot-password.text-password-forgot')}
        </Typography>

        <WarningAmberIcon sx={{ marginRight: '4px' }} color="error" fontSize="12px" />
        <Typography display="inline" variant="body2" color="error" fontWeight={600} marginRight="8px">
          {t('auth.forgot-password.warning-brand')}
        </Typography>
        <Typography display="inline" variant="body2" color="secondary" marginBottom="16px">
          {t('auth.forgot-password.warning-password-forgot')}
        </Typography>

        <TextField
          id="document"
          sx={{ margin: '16px 0 16px 0' }}
          value={username}
          label={t('auth.forgot-password.input-password-forgot')}
          onChange={(event) => setUsername(event.target.value)}
          variant="outlined"
          data-testid="document-text-field"
          inputProps={{ 'data-testid': 'document-input' }}
          error={!!usernameError}
          helperText={usernameError}
          FormHelperTextProps={{ 'data-testid': 'document-error' }}
          fullWidth
        />

        <Button
          sx={{ margin: '24px 0 16px 0' }}
          fullWidth
          color="gradient"
          onClick={handleOnNextClick}
          loading={isLoading}
          data-testid="submit-button"
        >
          {t('auth.forgot-password.button-reset-password')}
        </Button>

        <Button variant="outlined" fullWidth onClick={() => navigate('/auth/direct-login')} data-testid="cancel-button">
          {t('auth.forgot-password.button-cancel')}
        </Button>
      </Box>
    </Fade>
  );

  return <Box sx={{ padding: '0 16px' }}>{isSuccess ? renderSuccessMessage() : renderForm()}</Box>;
}

export default ForgotPassword;
