import Translator from 'components/Translator';
import Footer from 'components/Footer';
import Auth from 'components/templates/auth';
import { Outlet } from 'react-router-dom';

function PublicPage() {
  return (
    <>
      <Auth>
        <Outlet />
      </Auth>
      <Footer />
      <Translator />
    </>
  );
}

export default PublicPage;
