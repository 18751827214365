import { Box, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import SubSubtitle from 'components/SubSubtitle';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';

function SequentialTextPolicyForm({
  maxLength,
  maxLengthError,
  caseSensitiveSeqStrings,
  reverseOrderSeqStrings,
  repeatedLettersSeqStrings,
  loopingSeqStrings,
  onChange,
}) {
  const { t } = useTranslation();

  const MAX_LENGTH_SEQUENTIAL_STR_LABEL = t(
    'organization.organization-settings.password-policy.sequential-strings.max-length-label',
  );
  const SEQUENTIAL_STR_LOOPING_LABEL = t(
    'organization.organization-settings.password-policy.sequential-strings.looping-label',
  );
  const SEQUENTIAL_STR_CASE_SENSITIVE_LABEL = t(
    'organization.organization-settings.password-policy.sequential-strings.case-sensitive-label',
  );
  const SEQUENTIAL_STR_REVERSE_ORDER_LABEL = t(
    'organization.organization-settings.password-policy.sequential-strings.reverse-order-label',
  );
  const REPEATED_LETTERS_LABEL = t(
    'organization.organization-settings.password-policy.sequential-strings.repeated-letters-label',
  );

  return (
    <Box marginBottom="48px">
      <SubSubtitle>
        {t('organization.organization-settings.password-policy.sequential-strings.subsubtitle')}
      </SubSubtitle>

      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-settings.password-policy.sequential-strings.text')}
      </Typography>

      <TextField
        id="max-length-seq-strings"
        value={maxLength}
        onChange={(event) =>
          onChange(
            filterNumbers(event.target.value),
            caseSensitiveSeqStrings,
            reverseOrderSeqStrings,
            repeatedLettersSeqStrings,
            loopingSeqStrings,
          )
        }
        label={MAX_LENGTH_SEQUENTIAL_STR_LABEL}
        variant="outlined"
        error={Boolean(maxLengthError)}
        helperText={maxLengthError}
        data-testid="max-length-seq-strings-text-field"
        inputProps={{ 'data-testid': 'max-length-seq-strings-input' }}
        FormHelperTextProps={{ 'data-testid': 'max-length-seq-strings-error' }}
        fullWidth
      />

      <FormGroup>
        <FormControlLabel
          label={SEQUENTIAL_STR_CASE_SENSITIVE_LABEL}
          control={
            <Checkbox
              checked={caseSensitiveSeqStrings}
              onChange={() =>
                onChange(
                  maxLength,
                  !caseSensitiveSeqStrings,
                  reverseOrderSeqStrings,
                  repeatedLettersSeqStrings,
                  loopingSeqStrings,
                )
              }
              inputProps={{ 'data-testid': 'case-sensitive-seq-strings-checkbox' }}
            />
          }
          sx={{ marginTop: 1 }}
        />

        <FormControlLabel
          label={SEQUENTIAL_STR_REVERSE_ORDER_LABEL}
          control={
            <Checkbox
              checked={reverseOrderSeqStrings}
              onChange={() =>
                onChange(
                  maxLength,
                  caseSensitiveSeqStrings,
                  !reverseOrderSeqStrings,
                  repeatedLettersSeqStrings,
                  loopingSeqStrings,
                )
              }
              inputProps={{ 'data-testid': 'reverse-order-seq-strings-checkbox' }}
            />
          }
          sx={{ marginTop: 1 }}
        />

        <FormControlLabel
          label={REPEATED_LETTERS_LABEL}
          control={
            <Checkbox
              checked={repeatedLettersSeqStrings}
              onChange={() =>
                onChange(
                  maxLength,
                  caseSensitiveSeqStrings,
                  reverseOrderSeqStrings,
                  !repeatedLettersSeqStrings,
                  loopingSeqStrings,
                )
              }
              inputProps={{ 'data-testid': 'repeated-letters-seq-strings-checkbox' }}
            />
          }
          sx={{ marginTop: 1 }}
        />

        <FormControlLabel
          label={SEQUENTIAL_STR_LOOPING_LABEL}
          control={
            <Checkbox
              checked={loopingSeqStrings}
              onChange={() =>
                onChange(
                  maxLength,
                  caseSensitiveSeqStrings,
                  reverseOrderSeqStrings,
                  repeatedLettersSeqStrings,
                  !loopingSeqStrings,
                )
              }
              inputProps={{ 'data-testid': 'looping-seq-strings-checkbox' }}
            />
          }
          sx={{ marginTop: 1 }}
        />
      </FormGroup>
    </Box>
  );
}

export default SequentialTextPolicyForm;
