import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import moment from 'moment';
import Header from 'components/dialogs/DialogHeader';
import Button from 'components/Button';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from 'components/Autocomplete';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import { formatToDate } from 'utils/format';
import i18next from 'i18next';
import { searchUser } from 'api/portal';
import { Trans, useTranslation } from 'react-i18next';
import { getShareLink, share } from 'api/analytics';
import { getReport } from 'api/client-phi';
import { openUrl } from 'api/general';
import styles from './styles.module.css';
import ProfileTag from '../../ProfileChip';
import DropdownAccount from '../../AccountItem';

function ShareAcquisitionDialog({ open, protocolToShare, orgToShare, onClose }) {
  const [acceptSharingTerms, setAcceptSharingTerms] = useState(false);
  const [membersToShare, setMembersToShare] = useState([]);
  const [members, setMembers] = useState([]);
  const [isSharing, setIsSharing] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [generatingLink, setGeneratingLink] = useState(false);
  const [clipboardLink, setClipboardLink] = useState('');
  const [dataVisibility, setDataVisibility] = useState('');

  const { t } = useTranslation();

  const SHARE_LINK_NOK = t('physio.physio-personal-share-box.input-box-share-link-copy-nok');
  const SHARE_LINK_OK = t('physio.physio-personal-share-box.input-box-share-link-copy-ok');

  const SHARE_TYPES = [
    {
      label: t('physio.physio-personal-share-box.option-share-full'),
      value: 'FULL',
    },
    {
      label: t('physio.physio-personal-share-box.option-share-hide'),
      value: 'HIDE',
    },
    {
      label: t('physio.physio-personal-share-box.option-share-link'),
      value: 'LINK',
    },
  ];

  const dispatch = useDispatch();

  const linkRef = React.createRef();

  const onGenerateLinkClick = () => {
    getShareLink({
      // 1. request para o physio API - get fileToken, confirmar qual eh a rota com lucas
      acquisition_id: protocolToShare,
      report_language: i18next.language,
      report_format: 'PDF',
    })
      .then((response) => {
        const { data, error } = response.data;

        if (!error) {
          setGeneratingLink(true);
          // 2. apos receber o tokenFile request report file using received token
          // 3. chamar lambda hospital - passar tokenFile. confirmar com lucas estrutura do json data
          getReport(orgToShare, data).then((response) => {
            // 4. hospital devolve o link do pdf no S3
            // open pre signed url
            const { data, error } = response.data;
            let timeOutCount = 0;

            const interval = setInterval(() => {
              openUrl(data)
                .then((res) => {
                  timeOutCount += 1; // cada tentativa equivale a 5s
                  if (res.status === 200) {
                    setGeneratingLink(false);

                    if (!error) {
                      setShareLink(data); // 5. abre o link do pdf em uma nova aba
                    }

                    clearInterval(interval);
                  }
                  if (timeOutCount >= 180) {
                    // atingindo um total de 15min (180 tentativas), da o timeOut
                    // eslint-disable-next-line no-alert
                    alert('Report error, please try again');
                    setGeneratingLink(false);
                    clearInterval(interval);
                  }
                })
                .catch(() => {});
            }, 2000);
          });
        }
      })
      .catch(() => {
        setGeneratingLink(false);
      });
  };

  const searchMembers = (shareType) => {
    const searchParams = new URLSearchParams();

    searchParams.set('share_type', shareType);

    searchUser(searchParams).then((response) => {
      const { data } = response;
      setMembers([
        ...data.map((item) => ({
          id: item.user_id,
          label: item.full_name,
          image: item.photo,
          organizationName: item.organization_name,
          orgId: item.organization_physio_id,
        })),
      ]);
    });
  };

  const closeDialogHandler = (shared) => {
    setMembersToShare([]);
    setShareLink('');
    setClipboardLink('');
    setDataVisibility('');
    onClose(shared);
  };

  const handleShareMembers = () => {
    setIsSharing(true);

    share({
      acquisition_id: protocolToShare,
      org_id: orgToShare,
      shared: membersToShare.map((member) => ({
        toUser: member.id.toString(),
        toOrganization: member.orgId ? member.orgId : 'null',
        shareType: dataVisibility,
        expirationDate: formatToDate(moment().add(6, 'months').toDate()),
      })),
    })
      .then((response) => {
        const { error } = response.data;

        if (!error) {
          setIsSharing(false);
          dispatch(showSuccessToast('physio.physio-personal-share-box.file-share-success'));
          closeDialogHandler(true);
          return;
        }

        dispatch(showErrorToast('physio.physio-personal-share-box.file-share-failure'));
      })
      .catch(() => {
        setIsSharing(false);
        dispatch(showErrorToast('physio.physio-personal-share-box.file-share-failure'));
      });
  };

  const onChangeShareType = (event) => {
    if (dataVisibility === 'HIDE') {
      setMembersToShare([]);
    }

    const { value } = event.target;

    setDataVisibility(value);

    if (value === 'HIDE' || value === 'FULL') {
      searchMembers(value);
    }
  };

  const copyTextToClipboard = () => {
    if (shareLink === '') {
      setClipboardLink(SHARE_LINK_NOK);
      return;
    }
    linkRef.current.select();
    document.execCommand('copy');

    setClipboardLink(SHARE_LINK_OK);
  };

  const renderSharingField = () => (
    <Autocomplete
      multiple
      onChange={(event, newValue) => setMembersToShare([...newValue])}
      options={members}
      isOptionEqualToValue={(option, value) => option.id === value.id && option.orgId === value.orgId}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <ProfileTag key={index} name={option.label} {...getTagProps({ index })} />)
      }
      renderOption={(props, option) => (
        <DropdownAccount
          {...props}
          key={`member-${option.id}${option.orgId}`}
          sx={{ marginBottom: '0px', cursor: 'pointer' }}
          image={option.photo}
          title={option.label}
          subtitle={option.organizationName || ''}
          data-testid={`member-${option.id}${option.orgId}`}
        />
      )}
      renderInput={(params) => (
        <TextField {...params} label={t('physio.physio-personal-share-box.input-box-search-user')} />
      )}
      data-testid="sharing-member-select"
    />
  );

  const renderFullShare = () => (
    <>
      <Typography variant="body2" color="error">
        <Trans i18nKey="physio.physio-personal-share-box.warning-share-full" />
      </Typography>

      <Typography variant="body2" color="secondary" margin="32px 0">
        {t('physio.physio-personal-share-box.text-share-full')}
      </Typography>
      {renderSharingField()}
    </>
  );

  const renderHideShare = () => (
    <>
      <Typography variant="body2" color="secondary" margin="32px 0">
        {t('physio.physio-personal-share-box.text-share-hide')}
      </Typography>
      {renderSharingField()}
    </>
  );

  const renderLinkShare = () => (
    <>
      <Typography variant="body2" color="error">
        <Trans i18nKey="physio.physio-personal-share-box.warning-share-link" />
      </Typography>

      <div className={styles.contentWrapper}>
        <FormControlLabel
          label={t('physio.physio-personal-share-box.input-box-share-link-terms')}
          control={
            <Checkbox
              checked={acceptSharingTerms}
              onClick={() => setAcceptSharingTerms(!acceptSharingTerms)}
              data-testid="accept-sharing-terms-checkbox"
              inputProps={{
                'data-testid': 'accept-sharing-terms-checkbox-input',
              }}
            />
          }
        />
        <Button
          variant="outlined"
          onClick={onGenerateLinkClick}
          disabled={!acceptSharingTerms}
          loading={generatingLink}
          data-testid="generate-link-button"
        >
          {t('physio.physio-personal-share-box.button-box-generate-link')}
        </Button>
      </div>

      <OutlinedInput
        name="url"
        inputRef={linkRef}
        value={shareLink}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={copyTextToClipboard} disabled={!acceptSharingTerms} aria-label="copy icon" edge="end">
              <FileCopySharpIcon />
            </IconButton>
          </InputAdornment>
        }
        disabled={!acceptSharingTerms}
        fullWidth
        inputProps={{ 'data-testid': 'url-input' }}
      />
      <Typography variant="body2" color="secondary">
        {clipboardLink}
      </Typography>
      <Typography variant="body2" color="primary.main" marginBottom="24px">
        {t('physio.physio-personal-share-box.text-box-share-link-pdf')}
      </Typography>
    </>
  );

  const renderShareForm = () => {
    switch (dataVisibility) {
      case 'FULL':
        return renderFullShare();
      case 'HIDE':
        return renderHideShare();
      case 'LINK':
        return renderLinkShare();
      default:
        return null;
    }
  };

  const renderShareButton = () => {
    switch (dataVisibility) {
      case 'FULL':
      case 'HIDE':
        return (
          <Button
            color="gradient"
            className={styles.confirmShareButton}
            onClick={handleShareMembers}
            loading={isSharing}
            disabled={membersToShare.length <= 0}
            data-testid="share-button"
          >
            {t('physio.physio-personal-share-box.box-button-confirm-share')}
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open}>
      <Header>{t('physio.physio-personal-share-box.title-box-share-report')}</Header>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('physio.physio-personal-share-box.text-share-select')}
        </Typography>
        <FormControl fullWidth>
          <InputLabel htmlFor="dataVisibility">{t('physio.physio-personal-share-box.label-share-select')}</InputLabel>
          <Select
            id="dataVisibility"
            onChange={onChangeShareType}
            value={dataVisibility}
            data-testid="data-visibility-select"
          >
            {SHARE_TYPES.map((type) => (
              <MenuItem key={type.value} value={type.value} data-testid={`data-visibility-item-${type.value}`}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {renderShareForm()}
        <br />
        <br />
      </DialogContent>
      <DialogActions>
        {renderShareButton()}
        <Button variant="outlined" onClick={() => closeDialogHandler(false)}>
          {t('physio.physio-personal-share-box.button-cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShareAcquisitionDialog;
