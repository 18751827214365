import PropTypes from 'prop-types';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

const MemberItem = ({ image, primaryText, secondaryText, onClick, disabled = false, hasIcon = false, ...props }) => (
  <Grid
    sx={{
      cursor: 'pointer',
    }}
    container
    display="flex"
    alignItems="center"
    marginBottom="24px"
    onClick={onClick}
    role="presentation"
    {...props}
  >
    <Grid item xs={6}>
      <Box display="flex" alignItems="center">
        <Avatar sx={{ width: 40, height: 40 }} src={image} alt="Profile account image" />
        <Typography color="primary.light" marginLeft="16px" variant="body2">
          {primaryText}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={6}>
      <Box display="flex" alignItems="center">
        <Typography
          fontWeight={disabled ? 'normal' : '600'}
          color={disabled ? 'secondary' : 'primary'}
          marginRight="16px"
          paddingLeft="16px"
          variant="body2"
        >
          {secondaryText}
        </Typography>
        {hasIcon ? <HelpIcon sx={{ width: '20px', height: '20px' }} /> : null}
      </Box>
    </Grid>
  </Grid>
);

MemberItem.propTypes = {
  image: PropTypes.string,
  primaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hasIcon: PropTypes.bool,
};

export default MemberItem;
