import BrFlag from 'assets/images/br-flag.svg';
import UsFlag from 'assets/images/us-flag.svg';
import { Box, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setLanguage } from 'features/userSlice';
import i18next from 'i18next';
import styles from './styles.module.css';

function MenuTranslation(props) {
  const dispatch = useDispatch();

  const changeLanguage = (lang) => {
    dispatch(setLanguage(lang));
    i18next.changeLanguage(lang);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" {...props}>
      <img
        className={styles.languageIcon}
        src={BrFlag}
        onClick={() => changeLanguage('pt-br')}
        onKeyDown={() => changeLanguage('pt-br')}
        alt="Help icon"
      />
      <Divider sx={{ margin: '0 8px 0 8px' }} orientation="vertical" variant="middle" flexItem />
      <img
        className={styles.languageIcon}
        src={UsFlag}
        onClick={() => changeLanguage('en-us')}
        onKeyDown={() => changeLanguage('en-us')}
        alt="Help icon"
      />
    </Box>
  );
}

export default MenuTranslation;
