import App from 'components/templates/app';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  List,
  OutlinedInput,
  Pagination,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Loader from 'components/SectionLoader';
import Button from 'components/Button';
import { showErrorToast } from 'features/toastSlice';
import TagsSelectDialog from 'components/dialogs/TagFilterDialog';
import Autocomplete from 'components/Autocomplete';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { fetchAllOrganizations, getDoctorNames } from 'api/portal';
import { getAcquisitionsByOrg, getDoctorsByOrg, getTagsByOrg, reportAuthorizationByOrg } from 'api/analytics';
import { getReport } from 'api/client-phi';
import Fade from 'components/Fade';
import AnonymizedAcquisitionRow from 'components/tables/AnonymizedAcquisitionRow';
import { deserializeTabularData } from 'utils/serialization';
import AcquisitionMenuItem from 'components/AcquisitionMenuItem';
import styles from './styles.module.css';

const LIMIT_PER_PAGE = 10;

function AdminOrganizationReports() {
  const { t } = useTranslation();

  const ALL_DOCTORS = {
    id: -1,
    label: t('physio.physio-personal.dropdown-filter-doctor-all'),
  };

  const LOADING_DOCTOR = {
    id: -1,
    label: t('physio.physio-personal.select-loading'),
  };

  const LOADING_ORGANIZATION = {
    id: -1,
    physio_org_id: -1,
    label: t('physio.physio-personal.select-loading'),
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const [organizations, setOrganizations] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [tags, setTags] = useState([]);

  const [protocol, setProtocol] = useState('');
  const [organization, setOrganization] = useState(LOADING_ORGANIZATION);
  const [doctor, setDoctor] = useState(LOADING_DOCTOR);
  const [selectedTags, setSelectedTags] = useState([]);

  const [acquisitions, setAcquisitions] = useState([]);

  const [selectedAcquisition, setSelectedAcquisition] = useState({});
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showFilterByTags, setShowFilterByTags] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingDoctors, setLoadingDoctors] = useState(false);

  const dispatch = useDispatch();

  const HOME = t('physio.breadcrumbs.home');
  const TITLE = t('physio.breadcrumbs.admin-title-organization', { organization: organization.name });

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/organization/physio',
      title: TITLE,
      isActive: true,
    },
  ];

  const onFetchDataFailure = () => dispatch(showErrorToast('messages.fetch-data-failure'));

  const fetchDoctorNames = (values) => {
    getDoctorNames(values)
      .then((response) => {
        const { data } = response;
        setLoadingDoctors(false);

        setDoctor(ALL_DOCTORS);
        setDoctors([ALL_DOCTORS, ...data.map((doctor) => ({ ...doctor, label: doctor.full_name }))]);
      })
      .catch(() => {
        setLoadingDoctors(false);
        onFetchDataFailure();
      });
  };

  const fetchDoctorByOrg = (orgId) => {
    setLoadingDoctors(true);
    setDoctor(LOADING_DOCTOR);

    getDoctorsByOrg(orgId)
      .then((response) => {
        const { data, error } = response.data;

        if (!error && data && !data.error) {
          fetchDoctorNames(data.values);
          return;
        }

        setLoadingDoctors(false);
        onFetchDataFailure();
      })
      .catch(() => {
        setLoadingDoctors(false);
        onFetchDataFailure();
      });
  };

  const fetchAcquisitionsByOrg = ({ physioOrgId, selectedTags, protocol, doctor, currentPage }) => {
    const doctorId = doctor && doctor.id !== -1 ? doctor.id : '';

    setLoading(true);
    setCurrentPage(currentPage);

    getAcquisitionsByOrg(
      {
        filters: {
          tags: selectedTags,
          acquisition_id: [protocol],
          doctor_id: String(doctorId),
        },
        paging: {
          page: currentPage,
          limit: LIMIT_PER_PAGE,
        },
      },
      physioOrgId,
    )
      .then((response) => {
        const { data, error } = response.data;

        setLoading(false);

        if (!error) {
          const acquisitionKeys = data.acquisitions.keys;

          setAcquisitions(
            data.acquisitions.values.map((acquisition) => deserializeTabularData(acquisitionKeys, acquisition)),
          );
          setCount(Math.ceil(data.acquisitions.paging.count / LIMIT_PER_PAGE));

          return;
        }

        onFetchDataFailure();
      })
      .catch(() => {
        setLoading(false);
        onFetchDataFailure();
      });
  };

  const fetchTagsByOrg = (orgId) => {
    getTagsByOrg(orgId)
      .then((response) => {
        const { data, error } = response.data;

        if (error) {
          onFetchDataFailure();
          return;
        }

        setTags(data.values);
      })
      .catch(() => {
        onFetchDataFailure();
      });
  };

  useEffect(() => {
    if (organization.physio_org_id !== -1) {
      fetchTagsByOrg(organization.physio_org_id);
      fetchDoctorByOrg(organization.physio_org_id);
      fetchAcquisitionsByOrg({
        physioOrgId: organization.physio_org_id,
        selectedTags: [],
        protocol: '',
        doctor: ALL_DOCTORS,
        currentPage: 1,
      });
    }
  }, [organization.physio_org_id]);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('page_size', 9999);

    fetchAllOrganizations(searchParams)
      .then((response) => {
        const { results } = response.data;

        if (results.length > 0) {
          setOrganization({ ...results[0], label: results[0].name });
        }

        setOrganizations(results.map((organization) => ({ ...organization, label: organization.name })));
      })
      .catch(() => onFetchDataFailure());
  }, []);

  const filterAcquisitions = () => {
    fetchAcquisitionsByOrg({
      physioOrgId: organization.physio_org_id,
      selectedTags,
      protocol,
      doctor,
      currentPage: 1,
    });
  };

  const clearFilters = () => {
    setProtocol('');
    setDoctor(ALL_DOCTORS);
    setSelectedTags([]);

    fetchAcquisitionsByOrg({
      physioOrgId: organization.physio_org_id,
      selectedTags: [],
      protocol: '',
      doctor: ALL_DOCTORS,
      currentPage: 1,
    });
  };

  const onPaginationChange = (event, value) => {
    fetchAcquisitionsByOrg({
      physioOrgId: organization.physio_org_id,
      selectedTags,
      protocol,
      doctor,
      currentPage: value,
    });
  };

  const setAcquisitionAsDownloading = (targetProtocolId, isDownloading) => {
    setAcquisitions(
      acquisitions.map((acquisition) => {
        const protocolId = acquisition.acquisition_id;
        return {
          ...acquisition,
          isDownloading: protocolId === targetProtocolId ? isDownloading : acquisition.isDownloading,
        };
      }),
    );
  };

  const openReportWhenAuthorized = (protocolId, url) => {
    let timeOutCount = 0;

    const interval = setInterval(() => {
      fetch(url).then((res) => {
        timeOutCount += 1;
        // open the report in a new tab
        if (res.status === 200) {
          setAcquisitionAsDownloading(protocolId, false);
          window.open(url, '_blank');
          clearInterval(interval);
        }
        // timeout after 3 minutes (180 attempts)
        if (timeOutCount >= 180) {
          // eslint-disable-next-line no-alert
          alert('Report error, please try again');
          setAcquisitionAsDownloading(protocolId, false);
          clearInterval(interval);
        }
      });
    }, 1000);
  };

  const downloadReport = (acquisition) => {
    const protocolId = acquisition.acquisition_id;
    const orgId = organization.physio_org_id;

    setAcquisitionAsDownloading(protocolId, true);

    reportAuthorizationByOrg(
      {
        acquisition_id: protocolId,
        report_language: i18next.language,
        report_format: 'PDF',
      },
      orgId,
    )
      .then((response) => {
        const { data, error } = response.data;

        if (error) {
          setAcquisitionAsDownloading(protocolId, false);
          onFetchDataFailure();
          return;
        }

        getReport(orgId, data)
          .then((response) => {
            const { data } = response;
            openReportWhenAuthorized(protocolId, data.data);
          })
          .catch(() => {
            setAcquisitionAsDownloading(protocolId, false);
            onFetchDataFailure();
          });
      })
      .catch(() => {
        setAcquisitionAsDownloading(protocolId, false);
        onFetchDataFailure();
      });
  };

  const renderActionPopover = () => {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          <AcquisitionMenuItem
            icon={<VisibilitySharpIcon />}
            onClick={() => {
              downloadReport(selectedAcquisition);
              setAnchorEl(null);
            }}
            data-testid="view-report-item"
            text={t('physio.physio-personal.button-report-view')}
          />
        </List>
      </Popover>
    );
  };

  const renderMonitorizationTable = () =>
    acquisitions && acquisitions.length > 0 ? (
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('physio.physio-personal.table-physio-protocol')}</TableCell>
                <TableCell align="left">{t('physio.physio-personal.table-physio-date')}</TableCell>
                <TableCell align="left">{t('physio.physio-personal.table-physio-duration')}</TableCell>
                <TableCell align="left">{t('physio.physio-personal.table-physio-doctor')}</TableCell>
                <TableCell align="left">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acquisitions.map((acquisition) => (
                <AnonymizedAcquisitionRow
                  key={acquisition.acquisition_id}
                  loadingDoctor={loadingDoctors}
                  acquisition={acquisition}
                  doctors={doctors}
                  onClickMenu={(event, acquisition) => {
                    setSelectedAcquisition(acquisition);
                    setAnchorEl(event.currentTarget);
                  }}
                  onClickProtocol={(acquisition) => downloadReport(acquisition)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {renderActionPopover()}
      </Box>
    ) : (
      <Typography variant="body2" color="secondary" data-testid="no-monitorization-text">
        {t('physio.physio-personal.no-monitorizations-found')}
      </Typography>
    );

  return (
    <App title={TITLE} breadcrumbs={breadcrumbs}>
      <Section className={styles.sectionFilters}>
        <Subtitle>{t('physio.physio-personal.title-filter-physio')}</Subtitle>
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <Grid marginTop="8px" container spacing="12px">
            <Grid item xs={12} sm={4} md={2}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel>{t('physio.physio-personal.input-filter-protocol')}</InputLabel>
                <OutlinedInput
                  name="protocol"
                  value={protocol}
                  onChange={(event) => setProtocol(event.target.value)}
                  inputProps={{ 'data-testid': 'protocol-input' }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <Autocomplete
                  disablePortal
                  value={organization}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setOrganization(newValue);
                    }
                  }}
                  options={organizations}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <Typography
                      {...props}
                      variant="body2"
                      color="secondary"
                      key={option.id}
                      data-testid={`organization-option-${option.id}`}
                    >
                      {option.label}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t('physio.physio-personal.input-filter-organization')} />
                  )}
                  data-testid="organization-select"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <Autocomplete
                  disablePortal
                  value={doctor}
                  onChange={(event, newValue) => setDoctor(newValue)}
                  options={doctors}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <Typography
                      {...props}
                      variant="body2"
                      color="secondary"
                      key={option.id}
                      data-testid={`doctor-option-${option.id}`}
                    >
                      {option.label}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t('physio.physio-personal.input-filter-doctor')} />
                  )}
                  data-testid="doctor-select"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button variant="outlined" onClick={() => setShowFilterByTags(true)} fullWidth data-testid="tag-button">
                {selectedTags.length === 0
                  ? t('physio.physio-personal.button-filter-by-tag')
                  : t('physio.physio-personal.button-filter-by-tag-selected', { number_tags: selectedTags.length })}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button variant="outlined" onClick={clearFilters} fullWidth data-testid="reset-button">
                {t('physio.physio-personal.button-filter-reset')}
              </Button>{' '}
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button type="submit" color="gradient" onClick={filterAcquisitions} fullWidth data-testid="apply-button">
                {t('physio.physio-personal.button-filter-apply')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Section>

      <Section>
        <Loader loading={loading}>
          <Fade>{renderMonitorizationTable()}</Fade>
        </Loader>
      </Section>

      <TagsSelectDialog
        tags={tags}
        selectedTags={selectedTags}
        show={showFilterByTags}
        onCheckboxClick={(tag) => {
          if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
            return;
          }

          setSelectedTags([...selectedTags, tag]);
        }}
        onDialogToggle={() => setShowFilterByTags(false)}
      />

      <Pagination count={count} onChange={onPaginationChange} page={currentPage} />
    </App>
  );
}

export default AdminOrganizationReports;
