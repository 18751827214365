import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import MobileDeviceDetails from '../MobileDeviceDetails';

function OperationConcluded({ device, subtitle, text }) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <>
      <Section maxWidth="428px">
        <Subtitle>{subtitle}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {text}
        </Typography>
        <MobileDeviceDetails device={device} />
      </Section>
      <div className={styles.buttonsContainer}>
        <Button color="gradient" onClick={() => navigate('/org-mobile-devices/devices')} data-testid="back-button">
          {t('organization.organization-mobile-devices-create.button-back')}
        </Button>
      </div>
    </>
  );
}

export default OperationConcluded;
