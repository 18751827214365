import Tag from 'components/TagButton';
import { Grid, Typography } from '@mui/material';
import Loader from 'components/SectionLoader';
import { useTranslation } from 'react-i18next';

function MemberUnits({ error, isLoading, onToggle, selectedUnits, units, ...props }) {
  const { t } = useTranslation();

  const handleUnitsInputToggle = (value) => {
    if (selectedUnits.includes(value)) {
      onToggle(selectedUnits.filter((item) => item !== value));
    } else {
      onToggle([...selectedUnits, value]);
    }
  };

  return (
    <Loader loading={isLoading}>
      <Grid container spacing="8px" {...props}>
        {units.map((unit) => (
          <Grid key={unit.id} item>
            <Tag
              isSelected={selectedUnits.includes(unit.id)}
              onToggle={() => handleUnitsInputToggle(unit.id)}
              data-testid={`unit-tag-${unit.id}`}
            >
              {unit.name}
            </Tag>
          </Grid>
        ))}
        {error && (
          <Typography variant="body2" color="error">
            {t('organization.organization-units.error-units')}
          </Typography>
        )}
      </Grid>
    </Loader>
  );
}

export default MemberUnits;
