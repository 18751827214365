import Snackbar from 'components/Snackbar';
import { closeToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SnackbarContainer = () => {
  const toast = useSelector((state) => state.toast);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { message, open, type } = toast;

  return (
    <Snackbar open={open} type={type} onClose={() => dispatch(closeToast())} data-testid="toast">
      {t(message)}
    </Snackbar>
  );
};

export default SnackbarContainer;
