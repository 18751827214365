import { useState } from 'react';
import { useDispatch } from 'react-redux';
import braincare from 'components/routes/braincare';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Badge from 'components/Badge';
import Subtitle from 'components/Subtitle';
import VisuallyHiddenInput from 'components/VisuallyHiddenInput';
import Loader from 'components/SectionLoader';
import { Avatar, Box, Grid, TextField } from '@mui/material';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import App from 'pages/braincare/organizations/template';
import { useNavigate, useParams } from 'react-router-dom';
import { updateOrganization } from 'api/portal';
import { useFetchOrganizationAdmin } from 'hooks/useFetchOrganizationAdmin';
import Fade from 'components/Fade';
import styles from './styles.module.css';

function AdminOrganizationInfo() {
  const [organization, setOrganization] = useState({});

  const [photo, setPhoto] = useState('');
  const [photoFile, setPhotoFile] = useState(undefined);
  const [name, setName] = useState('');
  const [physioOrgId, setPhysioOrgId] = useState('');
  const [address, setAddress] = useState('');
  const [errors, setErrors] = useState(new Map());
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { orgId } = params;

  const { t } = useTranslation();

  const FILL_REQUIRED = t('messages.fill-required');

  const ORGANIZATION_STATUS = [
    { id: 'ACTIVE', text: t('utils.status.active') },
    { id: 'INACTIVE', text: t('utils.status.inactive') },
  ];

  useFetchOrganizationAdmin({
    orgId,
    onSuccess: (data) => {
      setOrganization(data);
      setPhoto(data.photo || '');
      setName(data.name || '');
      setPhysioOrgId(data.physio_org_id || '');
      setAddress(data.address || '');
    },
    onFailure: () => {
      navigate('/braincare/organizations');
      dispatch(showErrorToast('messages.load-failure'));
    },
  });

  const handleOnSaveChanges = () => {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('address', address);

    if (photoFile) {
      formData.append('photo', photoFile);
    }

    setIsSaving(true);

    updateOrganization(orgId, formData)
      .then(() => {
        setIsSaving(false);
        dispatch(showSuccessToast('messages.save-success'));
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(showErrorToast('messages.save-failure'));
      });
  };

  const showImagePreview = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => callback(reader.result);
  };

  const handleOnImageChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    showImagePreview(file, (previewUrl) => {
      setPhoto(previewUrl);
      setPhotoFile(file);
    });
  };

  const validateRequiredFields = () => {
    const requiredFields = {
      name,
      physioOrgId,
      address,
    };

    const errors = new Map();
    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    if (!errors.size) {
      handleOnSaveChanges();
    } else {
      setErrors(errors);
      dispatch(showErrorToast('messages.fix-fields'));
    }
  };

  const status = organization.is_active ? 'ACTIVE' : 'INACTIVE';

  return (
    <App title={name}>
      <Box maxWidth="428px">
        <Subtitle>{t('braincare.organizations-edit.step-basic-info.title')}</Subtitle>

        <Loader loading={!organization.id}>
          <Fade>
            <Grid sx={{ margin: '0 0 24px 0' }} container justifyContent="center" alignItems="center" spacing="16px">
              <Grid container justifyContent="center" item xs={12} md={4}>
                <Avatar sx={{ width: 120, height: 120 }} src={photo} alt="organization image" />
              </Grid>

              <Grid container justifyContent="center" item xs={6} md={4}>
                <Button component="label" variant="outlined" startIcon={<CameraAltIcon />}>
                  {t('braincare.organizations-edit.step-basic-info.button-add-photo')}
                  <VisuallyHiddenInput onChange={handleOnImageChange} type="file" />
                </Button>
              </Grid>

              <Grid container justifyContent="center" item xs={6} md={4}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setPhoto(null);
                    setPhotoFile('');
                  }}
                >
                  {t('braincare.organizations-edit.step-basic-info.button-remove-photo')}
                </Button>
              </Grid>
            </Grid>

            <div className={styles.statusContainer}>
              <div>
                <p>{t('organization.organization-members-edit.label-status')}</p>
                <Badge className={styles.statusBadge} type={organization.is_active ? 'success' : 'danger'}>
                  {ORGANIZATION_STATUS.find((item) => item.id === status).text}
                </Badge>
              </div>
            </div>

            <TextField
              id="name"
              sx={{ margin: '16px 0 16px 0' }}
              value={name}
              label={t('braincare.organizations-edit.step-basic-info.input-label-name')}
              onChange={(event) => setName(event.target.value)}
              variant="outlined"
              data-testid="name-text-field"
              inputProps={{ 'data-testid': 'name-input' }}
              error={errors.has('name')}
              helperText={errors.has('name') ? errors.get('name') : ''}
              FormHelperTextProps={{ 'data-testid': 'name-error' }}
              fullWidth
            />

            <TextField
              id="physio_org_id"
              sx={{ margin: '16px 0 16px 0' }}
              value={physioOrgId}
              label={t('braincare.organizations-edit.step-basic-info.input-label-physio-org-id')}
              onChange={(event) => setPhysioOrgId(event.target.value)}
              variant="outlined"
              data-testid="physio-org-id-text-field"
              inputProps={{ 'data-testid': 'physio-org-id-input' }}
              error={errors.has('setPhysioOrgId')}
              helperText={errors.has('setPhysioOrgId') ? errors.get('setPhysioOrgId') : ''}
              FormHelperTextProps={{ 'data-testid': 'physio-org-id-error' }}
              disabled
              fullWidth
            />

            <TextField
              id="address"
              sx={{ margin: '16px 0 32px 0' }}
              value={address}
              label={t('braincare.organizations-edit.step-basic-info.input-label-address')}
              onChange={(event) => setAddress(event.target.value)}
              variant="outlined"
              data-testid="address-text-field"
              inputProps={{ 'data-testid': 'address-input' }}
              error={errors.has('address')}
              helperText={errors.has('address') ? errors.get('address') : ''}
              FormHelperTextProps={{ 'data-testid': 'address-error' }}
              fullWidth
            />
          </Fade>
        </Loader>

        <Button
          color="gradient"
          disabled={!organization.id}
          loading={isSaving}
          onClick={validateRequiredFields}
          data-testid="save-button"
        >
          {t('braincare.organizations-edit.buttons.button-save')}
        </Button>
        <Button
          sx={{ margin: '0 0 0 16px' }}
          variant="outlined"
          disabled={!organization.id}
          onClick={() => navigate('/braincare/organizations')}
          data-testid="cancel-button"
        >
          {t('braincare.organizations-edit.buttons.button-cancel')}
        </Button>
      </Box>
    </App>
  );
}

export default braincare(AdminOrganizationInfo);
