import { Avatar, Box, Card, CardContent, CardHeader, Divider } from '@mui/material';
import CardBadge from 'components/CardBadge';
import OrganizationAggregate from 'components/OrganizationAggregate';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import SensorsIcon from '@mui/icons-material/Sensors';
import { useTranslation } from 'react-i18next';
import { round } from 'utils/math';
import styles from './styles.module.css';

function OrganizationCard({ organization, onClick, ...props }) {
  const { t } = useTranslation();

  const items = {
    members: organization.members_count || 0,
    units: organization.units_count || 0,
    devices: organization.devices_count || 0,
  };

  const variant = organization.is_active ? 'active' : 'danger';

  const CARD_MEMBERS = t('braincare.organizations.card-members');
  const CARD_UNITS = t('braincare.organizations.card-units');
  const CARD_DEVICES = t('braincare.organizations.card-devices');

  const ITEMS = {
    members: { text: CARD_MEMBERS, icon: <GroupIcon /> },
    units: { text: CARD_UNITS, icon: <HomeIcon /> },
    devices: { text: CARD_DEVICES, icon: <SensorsIcon /> },
  };

  return (
    <Card sx={{ padding: '16px 16px 0' }} onClick={onClick} {...props}>
      <CardBadge variant={variant} lock={!organization.is_active} />
      <CardHeader
        avatar={
          <Avatar sx={{ width: 48, height: 48, margin: 0 }} src={organization.photo} aria-label={organization.name} />
        }
        title={<h3 className={styles.cardTitle}>{organization.name}</h3>}
      />
      <Divider />
      <CardContent
        sx={{
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Box display="flex" flexDirection="column">
          {Object.keys(items).map((key) => (
            <OrganizationAggregate
              key={`item-${key}`}
              icon={ITEMS[key].icon}
              value={typeof items[key] === 'number' ? round(items[key]) : items[key]}
              label={ITEMS[key].text}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

export default OrganizationCard;
