import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import RuledPasswordInput from 'components/RuledPasswordInput';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { validatePassword as applyPolicyValidation } from 'utils/password-validators';
import { showErrorToast } from 'features/toastSlice';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SectionLoader from 'components/SectionLoader';
import { useTranslation } from 'react-i18next';
import { useFetchOrganizationAdmin } from 'hooks/useFetchOrganizationAdmin';
import Fade from 'components/Fade';

function DefinePassword({ isSaving, memberEmail, onPreviousStep, onSave }) {
  const [organization, setOrganization] = useState(undefined);

  const [password, setPassword] = useState('');
  const [passwordPolicyErrors, setPasswordPolicyErrors] = useState(undefined);
  const [passwordPolicy, setPasswordPolicy] = useState({});

  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState('');

  const [emailNotification, setEmailNotification] = useState(false);
  const [forcePasswordChange, setForcePasswordChange] = useState(false);

  const [errors, setErrors] = useState(new Map());

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { orgId } = params;

  const { t } = useTranslation();

  const REQUIRED_FIELD_ERROR = t('account.edit-password.fill-required');
  const MISMATCH_PASSWORD_ERROR = t('messages.mismatch-error');
  const PASSWORD_POLICY_ERROR = t('messages.password-policy-error');

  useFetchOrganizationAdmin({
    orgId,
    onSuccess: (data) => {
      setOrganization(data);
      setPasswordPolicy(data.password_policy);
    },
    onFailure: () => setErrors(['passwordPolicy', PASSWORD_POLICY_ERROR]),
  });

  const validatePassword = (password) => {
    const policyErrors = applyPolicyValidation(passwordPolicy, password);
    setPasswordPolicyErrors(policyErrors);

    errors.delete('password');
    errors.delete('passwordConfirmation');

    if (policyErrors.length !== 0) {
      errors.set('password', policyErrors);
    }

    if (!password) {
      errors.set('password', REQUIRED_FIELD_ERROR);
    }

    if (password !== passwordConfirmation) {
      errors.set('passwordConfirmation', MISMATCH_PASSWORD_ERROR);
    }

    setPassword(password);
    setPasswordConfirmation(passwordConfirmation);
    setErrors(errors);
  };

  const validatePasswordConfirmation = (passwordConfirmation) => {
    errors.delete('passwordConfirmation');

    if (password !== passwordConfirmation) {
      errors.set('passwordConfirmation', MISMATCH_PASSWORD_ERROR);
    }

    setPasswordConfirmation(passwordConfirmation);
    setErrors(errors);
  };

  return (
    <Fade>
      <Box maxWidth="428px" data-testid="define-member-password-form">
        <Subtitle>{t('organization.organization-members-create.title-sub-define-password')}</Subtitle>
        <SectionLoader loading={!organization}>
          <Fade>
            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('organization.organization-members-create.text-sub-define-password')}
            </Typography>
            {errors.has('password_policy') ? (
              errors.get('password_policy')
            ) : (
              <>
                <RuledPasswordInput
                  errors={passwordPolicyErrors}
                  hasError={errors.has('password')}
                  passwordPolicy={passwordPolicy}
                  onChange={(event) => validatePassword(event.target.value)}
                  value={password}
                />
                <FormControl error={errors.has('passwordConfirmation')} fullWidth>
                  <InputLabel data-testid="password-confirmation-label">
                    {t('organization.organization-members-edit-change-password.input-password-confirmation')}
                  </InputLabel>
                  <OutlinedInput
                    name="password_confirmation"
                    value={passwordConfirmation}
                    onChange={(event) => validatePasswordConfirmation(event.target.value)}
                    onBlur={(event) => validatePasswordConfirmation(event.target.value)}
                    type={isPasswordConfirmationVisible ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)}
                          edge="end"
                        >
                          {isPasswordConfirmationVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{ 'data-testid': 'password-confirmation-input' }}
                  />
                  <FormHelperText data-testid="password-confirmation-error">
                    {errors.get('passwordConfirmation')}
                  </FormHelperText>
                </FormControl>
                <FormGroup sx={{ margin: '0 0 48px 0' }}>
                  <FormControlLabel
                    label={t(
                      'organization.organization-members-edit-change-password.input-mail-notification-check-box',
                      { email: memberEmail },
                    )}
                    control={
                      <Checkbox
                        checked={emailNotification}
                        onClick={() => setEmailNotification(!emailNotification)}
                        inputProps={{ 'data-testid': 'email-notification-checkbox' }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t(
                      'organization.organization-members-edit-change-password.input-force-password-change-checkbox',
                    )}
                    control={
                      <Checkbox
                        checked={forcePasswordChange}
                        onClick={() => setForcePasswordChange(!forcePasswordChange)}
                        inputProps={{ 'data-testid': 'force-password-change-checkbox' }}
                      />
                    }
                  />
                </FormGroup>
                <Grid container spacing="16px">
                  <Grid item>
                    <Button variant="outlined" onClick={onPreviousStep} data-testid="previous-step-button">
                      {t('organization.organization-members-create.button-previous')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="gradient"
                      loading={isSaving}
                      onClick={() => {
                        validatePassword(password);
                        validatePasswordConfirmation(passwordConfirmation);

                        if (errors.size !== 0) {
                          dispatch(showErrorToast('messages.fix-fields'));
                          return;
                        }

                        onSave({ password, emailNotification, forcePasswordChange });
                      }}
                      data-testid="save-button"
                    >
                      {t('organization.organization-members-create.button-save')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => navigate('/organization/members')}
                      data-testid="cancel-button"
                    >
                      {t('organization.organization-members-edit-permissions.button-cancel')}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Fade>
        </SectionLoader>
      </Box>
    </Fade>
  );
}

export default DefinePassword;
