import { useEffect, useRef, useState } from 'react';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import App from 'pages/account/template';
import Loader from 'components/SectionLoader';
import { useNavigate } from 'react-router-dom';
import RuledPasswordInput from 'components/RuledPasswordInput';
import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { validatePassword } from 'utils/password-validators';
import { changePassword } from 'api/portal';
import { STATUS_LOADING } from 'features/userSlice';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';

function ChangePassword() {
  const user = useSelector((state) => state.user);
  const ref = useRef();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [isSaving, setIsSaving] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState(undefined);
  const [passwordPolicyErrors, setPasswordPolicyErrors] = useState(undefined);

  const [errors, setErrors] = useState(new Map());

  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const REQUIRED_FIELD_ERROR = t('account.edit-password.fill-required');
  const PASSWORD_MISMATCH_ERROR = t('account.edit-password.mismatch-error');

  useEffect(() => {
    if (passwordPolicy === undefined && ref.current === STATUS_LOADING && user.status !== STATUS_LOADING) {
      const passwordPolicy = user.active_organization.password_policy;

      setPasswordPolicy(passwordPolicy);
    }

    ref.current = user.status;
  }, [user.status]);

  const validateOldPassword = (oldPassword) => {
    setOldPassword(oldPassword);

    errors.delete('oldPassword');

    if (!oldPassword) {
      errors.set('oldPassword', REQUIRED_FIELD_ERROR);
    }

    setErrors(errors);
  };

  const validateNewPassword = (newPassword) => {
    const policyErrors = validatePassword(passwordPolicy, newPassword);

    setNewPassword(newPassword);
    setPasswordPolicyErrors(policyErrors);

    errors.delete('newPassword');
    errors.delete('passwordConfirmation');

    if (policyErrors.length !== 0) {
      errors.set('newPassword', policyErrors);
    }

    if (!newPassword) {
      errors.set('newPassword', REQUIRED_FIELD_ERROR);
    }

    if (newPassword !== passwordConfirmation) {
      errors.set('passwordConfirmation', PASSWORD_MISMATCH_ERROR);
    }

    setErrors(errors);
  };

  const validateConfirmPassword = (confirmPassword) => {
    setPasswordConfirmation(confirmPassword);

    errors.delete('passwordConfirmation');

    if (newPassword !== confirmPassword) {
      errors.set('passwordConfirmation', PASSWORD_MISMATCH_ERROR);
    }

    setErrors(errors);
  };

  const handleSubmit = () => {
    validateOldPassword(oldPassword);
    validateNewPassword(newPassword);
    validateConfirmPassword(passwordConfirmation);

    if (errors.size !== 0) {
      dispatch(showErrorToast('messages.fix-fields'));
      return;
    }

    setIsSaving(true);

    changePassword({
      old_password: oldPassword,
      password: newPassword,
    })
      .then(() => {
        setIsSaving(false);
        dispatch(showSuccessToast('messages.save-success'));
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        setIsSaving(false);

        if (data.old_password) {
          dispatch(showErrorToast('account.edit-password.old-password-error'));
          return;
        }

        const passwordErrors = data.password;

        if (Array.isArray(passwordErrors)) {
          const policyErrors = passwordErrors.map((passwordError) => passwordError.split(']')[0].split('[')[1]);
          setPasswordPolicyErrors(policyErrors);
        }

        dispatch(showErrorToast('messages.save-failure'));
      });
  };

  return (
    <App>
      <Fade>
        <Box maxWidth="428px" data-testid="change-password-form">
          <Subtitle>{t('account.edit-password.menu-personal-password')}</Subtitle>

          <Loader loading={passwordPolicy === undefined}>
            <Fade>
              <Typography variant="body2" color="secondary" marginBottom="32px">
                {t('account.edit-password.text-account-me-main')}
              </Typography>
              <FormControl error={errors.has('oldPassword')} fullWidth>
                <InputLabel data-testid="old-password-label">
                  {t('account.edit-password.input-password-old')}
                </InputLabel>
                <OutlinedInput
                  name="password_confirmation"
                  value={oldPassword}
                  onChange={(event) => validateOldPassword(event.target.value)}
                  onBlur={(event) => validateOldPassword(event.target.value)}
                  type={isOldPasswordVisible ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsOldPasswordVisible(!isOldPasswordVisible)}
                        edge="end"
                      >
                        {isOldPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{ 'data-testid': 'old-password-input' }}
                />
                <FormHelperText data-testid="old-password-error">{errors.get('oldPassword')}</FormHelperText>
              </FormControl>
              <RuledPasswordInput
                errors={passwordPolicyErrors}
                hasError={errors.has('newPassword')}
                passwordPolicy={passwordPolicy}
                onChange={(event) => validateNewPassword(event.target.value)}
                value={newPassword}
              />
              <FormControl sx={{ marginBottom: '32px' }} error={errors.has('passwordConfirmation')} fullWidth>
                <InputLabel data-testid="password-confirmation-label">
                  {t('account.edit-password.input-password-new-2')}
                </InputLabel>
                <OutlinedInput
                  name="password_confirmation"
                  value={passwordConfirmation}
                  onChange={(event) => validateConfirmPassword(event.target.value)}
                  onBlur={(event) => validateConfirmPassword(event.target.value)}
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                        edge="end"
                      >
                        {isConfirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{ 'data-testid': 'password-confirmation-input' }}
                />
                <FormHelperText data-testid="password-confirmation-error">
                  {errors.get('passwordConfirmation')}
                </FormHelperText>
              </FormControl>
              <Button color="gradient" onClick={handleSubmit} loading={isSaving} data-testid="save-button">
                {t('account.edit-password.button-save')}
              </Button>
              <Button
                sx={{ margin: '0 0 0 16px' }}
                variant="outlined"
                onClick={() => navigate('/')}
                data-testid="cancel-button"
              >
                {t('account.edit-password.button-cancel')}
              </Button>
            </Fade>
          </Loader>
        </Box>
      </Fade>
    </App>
  );
}

export default ChangePassword;
