import { useEffect, useState } from 'react';
import Subtitle from 'components/Subtitle';
import Loader from 'components/SectionLoader';
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Header from 'components/dialogs/DialogHeader';
import Button from 'components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMobileDevice, setOrganizationSelectedMobileDevice } from 'features/organizationSlice';
import { deleteMobileDevice, fetchMobileDevices } from 'api/portal';
import { showErrorToast } from 'features/toastSlice';
import MobileDeviceDetails from 'components/MobileDeviceDetails';
import StatusSetupDialog from 'components/dialogs/StatusSetupDialog';
import Fade from 'components/Fade';
import App from '../template';

function OrganizationMobileDeviceInfo() {
  const mobileDevice = useSelector((state) => state.organization.selectedMobileDevice);

  const [isLoadingDevice, setIsLoadingDevice] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [isToShowDeleteDialog, setIsToShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const { t } = useTranslation();

  const { deviceId } = params;

  const onErrorFetchingDevice = () => {
    dispatch(showErrorToast('organization.organization-mobile-devices-edit.error-fetch-device'));
    navigate('/org-mobile-devices/devices');
  };

  useEffect(() => {
    if (mobileDevice.org_device_id !== deviceId) {
      dispatch(clearOrganizationSelectedMobileDevice());
    }

    fetchMobileDevices(new URLSearchParams(), {
      org_device_id: deviceId,
    })
      .then((response) => {
        const { data } = response;

        if (!data.results) {
          onErrorFetchingDevice();
        } else if (data.results.length === 1) {
          const device = data.results[0];

          dispatch(clearOrganizationSelectedMobileDevice());
          dispatch(setOrganizationSelectedMobileDevice(device));
          setIsLoadingDevice(false);
        } else {
          onErrorFetchingDevice();
        }
      })
      .catch(() => {
        onErrorFetchingDevice();
      });
  }, []);

  const deleteDevice = () => {
    setIsDeleting(true);
    setIsToShowDeleteDialog(false);

    deleteMobileDevice(deviceId)
      .then(() => navigate('/org-mobile-devices/devices'))
      .catch(() => {
        setIsDeleting(false);
        dispatch(showErrorToast('organization.organization-mobile-devices-edit.error-delete-device'));
      });
  };

  const renderDeleteDialog = () => (
    <Dialog open={isToShowDeleteDialog} data-testid="confirm-deletion-dialog">
      <Header>{t('organization.organization-mobile-devices-info.dialog-remove-device.title-remove-device')}</Header>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-mobile-devices-info.dialog-remove-device.text-remove-device')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={() => setIsToShowDeleteDialog(false)} data-testid="dismiss-button">
          {t('organization.organization-mobile-devices-info.dialog-remove-device.cancel')}
        </Button>
        <Button fullWidth color="gradient" onClick={deleteDevice} data-testid="confirm-button">
          {t('organization.organization-mobile-devices-info.dialog-remove-device.confirm-removal')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <App device={mobileDevice}>
      <Subtitle>{t('organization.organization-mobile-devices-info.title-info-device')}</Subtitle>
      <Loader loading={isLoadingDevice}>
        <Fade>
          <Box maxWidth="428px" marginBottom="48px">
            <MobileDeviceDetails device={mobileDevice} />
            <Button color="gradient" onClick={() => setShowDialog(true)} data-testid="change-status-button">
              {t('organization.organization-mobile-devices-info.logdialog.change-trust-status-button')}
            </Button>
          </Box>
        </Fade>
      </Loader>
      {renderDeleteDialog()}
      <Button
        variant="outlined"
        color="error"
        loading={isDeleting}
        onClick={() => setIsToShowDeleteDialog(true)}
        data-testid="delete-button"
      >
        {t('organization.organization-mobile-devices-info.remove-device')}
      </Button>
      <Button
        sx={{ margin: '0 0 0 16px' }}
        variant="outlined"
        onClick={() => {
          navigate('/org-mobile-devices/devices');
        }}
        data-testid="cancel-button"
      >
        {t('organization.organization-members-edit.button-cancel')}
      </Button>
      <StatusSetupDialog
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        excludeStatus={mobileDevice.status}
        onConfirm={(status) => {
          navigate(`/org-mobile-devices/devices/${mobileDevice.org_device_id}/edit-status?status=${status}`);
        }}
        data-testid="choose-status-dialog"
      />
    </App>
  );
}

export default OrganizationMobileDeviceInfo;
