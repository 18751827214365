import { Avatar, Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import CardBadge from 'components/CardBadge';
import TagButton from 'components/TagButton';
import { useTranslation } from 'react-i18next';

function UserCard({ onClick, user, ...props }) {
  const { t } = useTranslation();

  const variant = user.is_active ? 'active' : 'danger';

  return (
    <Card onClick={onClick} {...props}>
      <CardBadge variant={variant} lock={!user.is_active} />
      <CardHeader
        avatar={<Avatar src={user.photo} aria-label={`${user.first_name} ${user.last_name}`} />}
        title={
          <Typography
            variant="body2"
            color="primary.light"
            fontWeight="bold"
          >{`${user.first_name} ${user.last_name}`}</Typography>
        }
        subheader={
          <Typography variant="caption" color="secondary" fontWeight="bold">
            {user.email}
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        {user.organizations ? (
          <Box>
            <Typography color="primary.main" variant="body2" fontWeight="600">
              {t('braincare.users.card-organizations')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', margin: '0' }}>
              {user.organizations.map((organization) => (
                <TagButton key={`tag-${user.id}-${organization.name}`} isSelected>
                  {organization.name}
                </TagButton>
              ))}
              {user.organizations.length > 4 && <TagButton isSelected>+ {user.organizations.length - 4}</TagButton>}
            </Box>
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
}

export default UserCard;
