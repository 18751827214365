import { useEffect, useState } from 'react';
import App from 'components/templates/app';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import Loader from 'components/SectionLoader';
import { MEMBER_STATUS } from 'utils/members';
import { useNavigate, useParams } from 'react-router-dom';
import braincare from 'components/routes/braincare';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationData, setOrganizationRoles, setOrganizationSelectedMember } from 'features/organizationSlice';
import { fetchMembersByOrganization, fetchOrganization, fetchRoles, fetchUnitsByOrganization } from 'api/portal';
import { showErrorToast } from 'features/toastSlice';
import MemberCard from 'components/cards/MemberCard';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import Autocomplete from 'components/Autocomplete';
import { useTranslation } from 'react-i18next';
import MemberTable from 'components/tables/MemberTable';
import Fade from 'components/Fade';

const PAGE_SIZE = 8;

function AdminMembers() {
  const { t } = useTranslation();

  const defaultUnit = {
    label: t('organization.organization-members.dropdown-filter-unit'),
    id: 0,
    'data-testid': 'unit-option-0',
  };

  const [members, setMembers] = useState([]);
  const [units, setUnits] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [organization, setOrganization] = useState(undefined);
  const [selectedUnit, setSelectedUnit] = useState(defaultUnit);
  const [selectedStatus, setSelectedStatus] = useState('ALL');
  const [selectedRole, setSelectedRole] = useState('ALL');
  const [filterValue, setFilterValue] = useState('');

  const [view, setView] = useState('cards');

  const [isLoadingMembers, setIsLoadingMembers] = useState(true);

  const roles = useSelector((state) => state.organization.roles);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const HOME = t('braincare.breadcrumbs.home');
  const ORGANIZATIONS = t('braincare.breadcrumbs.organizations');
  const ORGANIZATION_MEMBERS = t('braincare.breadcrumbs.organization-members');

  const { orgId } = params;

  const searchMembers = (page, unit = defaultUnit, status = 'ALL', role = 'ALL', name = '') => {
    setIsLoadingMembers(true);

    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', PAGE_SIZE);

    if (unit && unit.id !== defaultUnit.id) {
      searchParams.set('unit', unit.id);
    }

    if (status !== 'ALL') {
      searchParams.set('status', status);
    }

    if (role !== 'ALL') {
      searchParams.set('role', role);
    }

    searchParams.set('search_name', name);

    fetchMembersByOrganization(orgId, searchParams)
      .then((response) => {
        const { data } = response;

        if (data) {
          setIsLoadingMembers(false);

          setMembers(data.results);
          setCount(Math.ceil(data.count / PAGE_SIZE));
          setCurrentPage(page);
        }
      })
      .catch(() => {
        setIsLoadingMembers(false);
        dispatch(showErrorToast('messages.load-failure'));
      });
  };

  const onClearFilterClick = () => {
    setSelectedUnit(defaultUnit);
    setSelectedStatus('ALL');
    setSelectedRole('ALL');
    setFilterValue('');
    searchMembers(1);
  };

  useEffect(() => {
    dispatch(clearOrganizationData());

    fetchOrganization(orgId)
      .then((response) => {
        const { data } = response;
        setOrganization(data);
      })
      .catch(() => {
        navigate('/braincare/organizations');
      });

    searchMembers(1);

    fetchUnitsByOrganization(orgId)
      .then((response) => {
        const { data } = response;
        setUnits(data.results);
      })
      .catch(() => {
        dispatch(showErrorToast('messages.error-units'));
      });

    fetchRoles()
      .then((response) => {
        const { data } = response;
        dispatch(setOrganizationRoles(data));
      })
      .catch(() => {
        dispatch(showErrorToast('messages.error-roles'));
      });
  }, []);

  const openEditMemberPage = (id) => {
    const member = members.find((index) => index.id === id);
    dispatch(setOrganizationSelectedMember(member));
    navigate(`/braincare/organizations/${orgId}/members/${id}/edit/info`);
  };

  const renderPagination = () => (
    <Pagination
      count={count}
      page={currentPage}
      onChange={(event, value) => searchMembers(value, selectedUnit, selectedStatus, selectedRole, filterValue)}
    />
  );

  const renderResults = () => {
    if (view === 'cards') {
      return (
        <>
          <Fade>
            {members.length === 0 ? (
              <Typography display="flex" justifyContent="center" variant="body2" color="error">
                {t('braincare.organization-members.no-results-found')}
              </Typography>
            ) : (
              <Grid container spacing="16px">
                {members.map((member) => (
                  <Grid key={`member-card-${member.id}`} item xs={12} sm={6} md={3}>
                    <MemberCard
                      member={member}
                      sx={{ height: '100%' }}
                      onClick={() => openEditMemberPage(member.id)}
                      onClickButton={() =>
                        navigate(`/braincare/organizations/${orgId}/members/${member.id}/edit/password`)
                      }
                      data-testid={`member-card-${member.id}`}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Fade>
          {renderPagination()}
        </>
      );
    }

    return (
      <>
        <Fade>
          <MemberTable
            members={members}
            onClick={(member) => openEditMemberPage(member.id)}
            onClickButton={(member) => navigate(`/braincare/organizations/${orgId}/members/${member.id}/edit/password`)}
          />
        </Fade>
        {renderPagination()}
      </>
    );
  };

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/organizations',
      title: ORGANIZATIONS,
    },
    {
      path: `/braincare/organizations/${orgId}/members`,
      title: ORGANIZATION_MEMBERS,
      isActive: true,
    },
  ];

  const actionButton = {
    text: t('braincare.member-create.buttons.button-add-member'),
    href: `/braincare/organizations/${orgId}/members/create`,
  };

  const secondActionButton = {
    text: t('organization.organization-members.button-bulk-upload-member'),
    href: `/braincare/organizations/${orgId}/members/bulk-upload-instructions`,
  };

  const title = organization
    ? t('braincare.organization-members.main-title', { organization: organization.name })
    : t('braincare.organization-members.default-main-title');

  const unitOptions = [defaultUnit, ...units.map((unit) => ({ label: unit.name, id: unit.id }))];

  return (
    <App title={title} breadcrumbs={breadcrumbs} actionButton={actionButton} secondActionButton={secondActionButton}>
      <br />
      <Section>
        <Subtitle>{t('braincare.organization-members.title-filter-members')}</Subtitle>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <Grid container marginTop="8px" spacing="12px">
            <Grid item xs={12} sm={3} md={2}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel htmlFor="filter" data-testid="name-filter-label">
                  {t('braincare.organization-members.input-filter-name')}
                </InputLabel>
                <OutlinedInput
                  onChange={(event) => setFilterValue(event.target.value)}
                  value={filterValue}
                  placeholder={t('braincare.organization-members.dropdown-filter-name')}
                  inputProps={{ 'data-testid': 'name-filter-input' }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel htmlFor="role" data-testid="role-filter-label">
                  {t('braincare.organization-members.input-filter-role')}
                </InputLabel>
                <Select
                  id="role"
                  onChange={(event) => setSelectedRole(event.target.value)}
                  value={selectedRole}
                  data-testid="select-role"
                >
                  <MenuItem value="ALL">{t('braincare.organization-members.dropdown-filter-doctor')}</MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={`role-${role.id}`} value={role.id.toString()} data-testid={`role-option-${role.id}`}>
                      {t(`organization.organization-members.card-member-role-${role.id}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel htmlFor="status" data-testid="status-filter-label">
                  {t('braincare.organization-members.input-filter-status')}
                </InputLabel>
                <Select
                  id="status"
                  onChange={(event) => setSelectedStatus(event.target.value)}
                  value={selectedStatus}
                  data-testid="select-status"
                >
                  <MenuItem value="ALL">{t('braincare.organization-members.dropdown-filter-protocol')}</MenuItem>
                  {MEMBER_STATUS.map((status) => (
                    <MenuItem key={`status-${status}`} value={status} data-testid={`status-option-${status}`}>
                      {t(`utils.status.${status.toLowerCase()}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <Autocomplete
                  disablePortal
                  value={selectedUnit}
                  onChange={(event, newValue) => setSelectedUnit(newValue)}
                  options={unitOptions}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <Typography
                      {...props}
                      variant="body2"
                      color="secondary"
                      key={option.id}
                      data-testid={`unit-option-${option.id}`}
                    >
                      {option.label}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t('organization.organization-members.input-filter-unit')} />
                  )}
                  data-testid="select-unit"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <Button variant="outlined" onClick={onClearFilterClick} fullWidth data-testid="reset-filters-button">
                {t('braincare.organization-members.button-filter-reset')}
              </Button>
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <Button
                color="gradient"
                onClick={() => searchMembers(1, selectedUnit, selectedStatus, selectedRole, filterValue)}
                type="submit"
                fullWidth
                data-testid="apply-filters-button"
              >
                {t('braincare.organization-members.button-filter-apply')}
              </Button>
            </Grid>
          </Grid>
        </form>

        <ToggleButtonGroup
          sx={{ margin: '16px 0' }}
          value={view}
          exclusive
          onChange={(event, nextView) => {
            if (nextView) {
              setView(nextView);
            }
          }}
        >
          <ToggleButton value="cards" aria-label="cards">
            <ViewModuleIcon />
          </ToggleButton>
          <ToggleButton value="list" aria-label="list">
            <ViewListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <Loader loading={isLoadingMembers}>{renderResults()}</Loader>
      </Section>
    </App>
  );
}

export default braincare(AdminMembers);
