/* eslint-disable no-restricted-syntax */
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from 'components/Button';
import DialogHeader from 'components/dialogs/DialogHeader';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

function BulkUploadResultsDialog({ dataError, dataResponse, loading, open, onClose }) {
  const { t } = useTranslation();

  const RowsWithErrors = ({ list }) =>
    list.map((item) => {
      if (Object.keys(item).length <= 0) {
        return '';
      }

      const key = Object.keys(item)[0];
      const idKey = key + Math.random();

      return (
        <Typography key={idKey} ml="80px" display="inline" variant="body2" color="secondary">
          {`Linha ${key}: ${item[key].map((message) => t(message))}`}
        </Typography>
      );
    });

  const convertArrToCSV = (arr) => {
    const csvRows = [];

    if (arr && arr.length) {
      const headers = Object.keys(arr[0]);

      for (const row of arr) {
        const values = headers.map((header) => row[header]);
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    }

    return csvRows;
  };

  const handlerDownloadCSVList = (arr, nameFile) => {
    const CSVdata = convertArrToCSV(arr);
    const blob = new Blob([CSVdata], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', nameFile);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Dialog size="large" open={open} data-testid="dialog-header">
      <DialogHeader>
        {loading
          ? t('organization.organization-members-bulk-upload.text-processing-file')
          : t('organization.organization-members-bulk-upload.text-process-result')}
      </DialogHeader>

      <DialogContent>
        {dataError ? (
          <div className={styles.modalBody}>
            {dataError.errorMessage && (
              <Typography
                variant="body2"
                fontWeight="bold"
                color="error"
                marginBottom="24px"
                data-testid="error-message-text"
              >
                {dataError.errorMessage}
              </Typography>
            )}
          </div>
        ) : (
          ''
        )}

        {dataResponse ? (
          <div className={styles.modalBody}>
            {dataResponse.totalSentRows && (
              <div className={styles.summary} data-testid="total-rows">
                <Typography variant="body2" fontWeight="bold" color="primary.main" marginBottom="24px">
                  {t('organization.organization-members-bulk-upload.dialog.total-rows')}
                </Typography>
                <Typography variant="body2" color="secondary" marginBottom="24px">
                  {dataResponse.totalSentRows}
                </Typography>
              </div>
            )}

            {dataResponse.importedList && (
              <>
                <div className={styles.totalImportedLines} data-testid="total-imported-lines">
                  <Typography variant="body2" fontWeight="bold" color="primary.main">
                    {t('organization.organization-members-bulk-upload.dialog.total-imported')}
                  </Typography>
                  <Typography variant="body2" color="secondary">
                    {dataResponse.totalImportedRows}
                  </Typography>
                </div>

                <div className={styles.resultLists} data-testid="imported-rows">
                  <Typography variant="body2" color="primary.main">
                    {t('organization.organization-members-bulk-upload.dialog.imported-rows')}
                  </Typography>
                  <Typography variant="body2" color="secondary">
                    {dataResponse.importedRows}
                  </Typography>
                </div>

                <Button
                  className={styles.resultListsButton}
                  onClick={() => handlerDownloadCSVList(dataResponse.importedList, 'resultList.csv')}
                  data-testid="imported-list-button"
                >
                  [{t('organization.organization-members-bulk-upload.responses.click')}]
                </Button>
              </>
            )}

            {dataResponse.errorsList && (
              <>
                <div className={styles.totalImportedLines} data-testid="total-not-imported-lines">
                  <Typography variant="body2" fontWeight="bold" color="primary.main">
                    {t('organization.organization-members-bulk-upload.dialog.total-not-imported')}
                  </Typography>
                  <Typography variant="body2" color="secondary">
                    {dataResponse.totalNotImportedRows}
                  </Typography>
                </div>

                <div className={styles.resultLists} data-testid="errors-rows">
                  <Typography variant="body2" color="primary.main">
                    {t('organization.organization-members-bulk-upload.dialog.errors-rows')}
                  </Typography>
                </div>
                <div>
                  <RowsWithErrors list={dataResponse.errorsRows} />
                </div>

                <Button
                  className={styles.resultListsButton}
                  onClick={() => handlerDownloadCSVList(dataResponse.errorsList, 'errorList.csv')}
                  data-testid="errors-list-button"
                >
                  [{t('organization.organization-members-bulk-upload.responses.click')}]
                </Button>
              </>
            )}

            {dataResponse.skippedList && (
              <>
                <div className={styles.totalImportedLines} data-testid="total-skipped-lines">
                  <Typography variant="body2" fontWeight="bold" color="primary.main">
                    {t('organization.organization-members-bulk-upload.dialog.total-skipped')}
                  </Typography>
                  <Typography variant="body2" color="secondary">
                    {dataResponse.totalSkippedRows}
                  </Typography>
                  <Typography variant="body2" color="secondary">
                    {t('organization.organization-members-bulk-upload.dialog.skipped-warning')}
                  </Typography>
                </div>

                <div className={styles.resultLists} data-testid="skipped-rows">
                  <Typography variant="body2" color="primary.main">
                    {t('organization.organization-members-bulk-upload.dialog.skipped-rows')}
                  </Typography>
                  <Typography variant="body2" color="secondary">
                    {dataResponse.skippedRows}
                  </Typography>
                </div>

                <Button
                  className={styles.resultListsButton}
                  onClick={() => handlerDownloadCSVList(dataResponse.skippedList, 'skippedList.csv')}
                  data-testid="skipped-list-button"
                >
                  [{t('organization.organization-members-bulk-upload.responses.click')}]
                </Button>
              </>
            )}
          </div>
        ) : (
          ''
        )}
      </DialogContent>

      <DialogActions>
        <Button color="gradient" onClick={onClose} data-testid="exit-dialog-button">
          {loading ? <Loader /> : t('organization.organization-members-bulk-upload.exit-dialog-button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BulkUploadResultsDialog;
