import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Header from 'components/dialogs/DialogHeader';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { validateDeviceStatus } from '../../../utils/mobile-devices';

function StatusSetupDialog({ isOpen, onConfirm, onClose, excludeStatus = null, ...props }) {
  const [status, setStatus] = useState('NO_SELECTION');
  const [statusError, setStatusError] = useState('');

  const { t } = useTranslation();

  const SELECT_LABEL = t('organization.organization-mobile-devices-list.logdialog-register.select-label');
  const STATUS_EMPTY_ERROR = t(
    'organization.organization-mobile-devices-list.logdialog-register.error-status-required',
  );

  const onClickConfirmButton = () => {
    if (status === 'NO_SELECTION') {
      setStatusError(STATUS_EMPTY_ERROR);
      return;
    }

    if (validateDeviceStatus(status)) {
      onConfirm(status);
    }

    setStatusError('');
  };

  const renderOptions = () => {
    const options = [
      <MenuItem key="NO_SELECTION" value="NO_SELECTION">
        {t('organization.organization-mobile-devices-list.logdialog-register.choose-the-status')}
      </MenuItem>,
    ];

    if (excludeStatus !== 'TRUSTED') {
      options.push(
        <MenuItem key="TRUSTED" value="TRUSTED" data-testid="trusted-status-option">
          {t('organization.organization-mobile-devices-info.trusted')}
        </MenuItem>,
      );
    }

    if (excludeStatus !== 'NORMAL') {
      options.push(
        <MenuItem key="NORMAL" value="NORMAL" data-testid="normal-status-option">
          {t('organization.organization-mobile-devices-info.normal')}
        </MenuItem>,
      );
    }

    if (excludeStatus !== 'BLOCKED') {
      options.push(
        <MenuItem key="BLOCKED" value="BLOCKED" data-testid="blocked-status-option">
          {t('organization.organization-mobile-devices-info.blocked')}
        </MenuItem>,
      );
    }

    return options;
  };

  return (
    <Dialog open={isOpen} {...props}>
      <Header>{t('organization.organization-mobile-devices-list.logdialog-register.title-choose-the-status')}</Header>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-mobile-devices-list.logdialog-register.text-choose-the-status')}
        </Typography>
        <FormControl fullWidth error={Boolean(statusError)}>
          <InputLabel id="similarity-ratio-label">{SELECT_LABEL}</InputLabel>
          <Select
            id="trusted_level"
            onChange={(event) => setStatus(event.target.value)}
            value={status}
            data-testid="status-select"
            inputProps={{ 'data-testid': 'status-select-input' }}
          >
            {renderOptions()}
          </Select>
          <FormHelperText data-testid="status-select-error">{statusError}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="gradient" onClick={onClickConfirmButton} data-testid="confirm-button">
          {t('organization.organization-mobile-devices-list.logdialog-register.button-apply')}
        </Button>
        <Button sx={{ margin: '0 0 0 16px' }} variant="outlined" onClick={onClose} data-testid="dismiss-button">
          {t('organization.organization-mobile-devices-list.logdialog-register.button-cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StatusSetupDialog;
