import { Avatar, Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import CardBadge from 'components/CardBadge';
import Button from 'components/Button';
import { formatToCpfUsername } from 'utils/format';
import { isCpf } from 'utils/validation';
import { useTranslation } from 'react-i18next';

const VARIANT_MAP = {
  ACTIVE: 'success',
  BLOCKED: 'dark',
  SUSPENDED: 'danger',
};

function MemberCard({ member, onClick, onClickButton, sx, ...props }) {
  const { t } = useTranslation();

  const variant = VARIANT_MAP[member.status];

  const { document } = member.user;

  return (
    <Card onClick={onClick} sx={{ display: 'flex', flexDirection: 'column', padding: '24px', ...sx }} {...props}>
      <CardBadge variant={variant} lock={variant !== 'success'} />
      <CardHeader
        avatar={<Avatar src={member.user.photo} aria-label={`${member.user.first_name} ${member.user.last_name}`} />}
        title={
          <Typography
            color="primary.light"
            variant="body2"
            fontWeight="bold"
          >{`${member.user.first_name} ${member.user.last_name}`}</Typography>
        }
      />
      <Divider />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          '&:last-child': {
            padding: '16px 0 0 0',
          },
        }}
      >
        <Typography color="primary.main" variant="body2" fontWeight="600">
          {t('organization.organization-members.card-username')}
        </Typography>
        <Typography color="secondary" variant="body2" marginBottom="8px">
          {isCpf(document) ? formatToCpfUsername(document) : document}
        </Typography>
        <Typography color="primary.main" variant="body2" fontWeight="600">
          {t('organization.organization-members.card-member-roles')}
        </Typography>
        <Typography color="secondary" variant="body2" marginBottom="8px">
          {member.roles.map((role) => t(`organization.organization-members.card-member-role-${role.id}`)).join(', ')}
        </Typography>
        <Box sx={{ zIndex: 10, alignItems: 'flex-end', flexGrow: 1 }} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={(event) => {
              event.stopPropagation();
              onClickButton(event);
            }}
          >
            {t('organization.organization-members.button-reset-password')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default MemberCard;
