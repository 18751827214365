import CommonPasswordValidator from './CommonPasswordValidator';
import DigitsValidator from './DigitsValidator';
import ForbiddenWordsValidator from './ForbiddenWordsValidator';
import LengthValidator from './LengthValidator';
import LowercaseValidator from './LowercaseValidator';
import NonAlphanumericValidator from './NonAlphanumericValidator';
import PasswordPolicy from './PasswordPolicy';
import PasswordReuseValidator from './PasswordReuseValidator';
import PersonalInformationValidator from './PersonalInformationValidator';
import RepeatedCharSequencesValidator from './RepeatedCharSequenceValidator';
import SequentialStringsValidator from './SequentialStringsValidator';
import UppercaseValidator from './UppercaseValidator';

export const B4C_POLICY = {
  size_range: {
    max: 128,
    min: 8,
  },
  non_alphanumeric_range: {
    max: 128,
    min: 1,
  },
  digits_range: {
    max: 128,
    min: 1,
  },
  lowercase_range: {
    max: 128,
    min: 1,
  },
  uppercase_range: {
    max: 128,
    min: 1,
  },
};

export const INTERMEDIATE_POLICY = {
  size_range: {
    max: 128,
    min: 12,
  },
  non_alphanumeric_range: {
    max: 128,
    min: 1,
  },
  digits_range: {
    max: 128,
    min: 1,
  },
  lowercase_range: {
    max: 128,
    min: 1,
  },
  uppercase_range: {
    max: 128,
    min: 1,
  },
  exclude_common_passwords: true,
};

export const RIGOROUS_POLICY = {
  size_range: {
    max: 128,
    min: 16,
  },
  non_alphanumeric_range: {
    max: 128,
    min: 1,
  },
  digits_range: {
    max: 128,
    min: 1,
  },
  lowercase_range: {
    max: 128,
    min: 1,
  },
  uppercase_range: {
    max: 128,
    min: 1,
  },
  exclude_common_passwords: true,
  exclude_personal_information: {
    fields: ['email', 'phone_number', 'user__document', 'user__first_name', 'user__last_name', 'organization__name'],
    similarity_ratio: 0.7,
  },
};

export function getPasswordValidators(passwordPolicy) {
  const passwordPolicyObj = new PasswordPolicy(passwordPolicy);

  return [
    new LengthValidator(passwordPolicyObj),
    new NonAlphanumericValidator(passwordPolicyObj),
    new DigitsValidator(passwordPolicyObj),
    new LowercaseValidator(passwordPolicyObj),
    new UppercaseValidator(passwordPolicyObj),
    new SequentialStringsValidator(passwordPolicyObj),
    new ForbiddenWordsValidator(passwordPolicyObj),
    new CommonPasswordValidator(passwordPolicyObj),
    new PersonalInformationValidator(passwordPolicyObj),
    new RepeatedCharSequencesValidator(passwordPolicyObj),
    new PasswordReuseValidator(passwordPolicyObj),
  ];
}

export function validatePassword(passwordPolicy, password) {
  const validators = getPasswordValidators(passwordPolicy);

  const nonFulfilledRules = [];

  validators.forEach((validator) => {
    if (!validator.validate(password)) {
      nonFulfilledRules.push(validator.policyCode);
    }
  });

  return nonFulfilledRules;
}
