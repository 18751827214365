import classNames from 'classnames';
import LockIcon from '@mui/icons-material/Lock';
import styles from './styles.module.css';

const VARIANTS = ['danger', 'dark', 'secondary', 'success'];

function CardBadge({ variant, lock }) {
  return (
    VARIANTS.includes(variant) && (
      <>
        <div className={classNames(styles.statusFlag, styles[variant])} />
        {lock && (
          <LockIcon
            sx={{
              position: 'absolute',
              color: 'white',
              top: '6px',
              left: '4px',
            }}
            fontSize="14px"
          />
        )}
      </>
    )
  );
}

export default CardBadge;
