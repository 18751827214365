import LinkItem from 'components/LinkItem';
import Brand from 'components/Brand';
import LogoBraincare from 'assets/images/logo-braincare.png';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Footer() {
  const language = useSelector((state) => state.user.language);

  const { t } = useTranslation();

  const helpLink = language === 'pt-br' ? 'https://suporte.brain4.care' : 'https://help.brain4.care';

  return (
    <Box sx={{ margin: '0 16px' }} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography sx={{ marginBottom: '32px' }} variant="body2">
        <LinkItem href="/public/terms-and-service" target="_blank">
          {t('auth.footer.terms-and-services')}
        </LinkItem>
        {' | '}
        <LinkItem href="/public/privacy-policy" target="_blank">
          {t('auth.footer.privacy-policy')}
        </LinkItem>
        {' | '}
        <LinkItem href="/public/security" target="_blank">
          {t('auth.footer.security')}
        </LinkItem>
        {' | '}
        <LinkItem href="https://status.brain4.care" target="_blank">
          {t('auth.footer.system-status')}
        </LinkItem>
        {' | '}
        <LinkItem href={helpLink} target="_blank">
          {t('auth.footer.help')}
        </LinkItem>
      </Typography>
      <Brand image={LogoBraincare} title="logo braincare" />
    </Box>
  );
}

export default Footer;
