import PropTypes from 'prop-types';
import SubSubtitle from 'components/SubSubtitle';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import DialogHeader from 'components/dialogs/DialogHeader';

function LogDialog({ isOpen, onClose, log }) {
  const { t } = useTranslation();

  return (
    <Dialog size="large" open={isOpen} data-testid="log-dialog">
      <DialogHeader>{t('logs.activities-report.dialog.title')}</DialogHeader>
      <DialogContent>
        <SubSubtitle>{t('logs.activities-report.dialog.title-id')}</SubSubtitle>
        {log && log['@ptr'] ? (
          <Typography sx={{ overflowWrap: 'break-word' }} variant="body2" color="secondary" marginBottom="24px">
            {log['@ptr']}
          </Typography>
        ) : (
          ''
        )}
        <SubSubtitle>{t('logs.activities-report.dialog.title-user-agent')}</SubSubtitle>
        {log && log.user_agent ? (
          <Typography variant="body2" color="secondary" marginBottom="24px">
            {log.user_agent}
          </Typography>
        ) : (
          ''
        )}
        <SubSubtitle>{t('logs.activities-report.dialog.title-data')}</SubSubtitle>
        {log && log.additional_data ? (
          <Typography variant="body2" color="secondary" marginBottom="24px">
            {log.additional_data}
          </Typography>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('logs.activities-report.dialog.close-button-text')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LogDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  log: PropTypes.object,
};

export default LogDialog;
