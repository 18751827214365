import { useState } from 'react';
import Brand from 'components/Brand';
import LinkItem from 'components/LinkItem';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Title from 'components/AuthTitle';
import Button from 'components/Button';
import BraincareLogo from 'assets/images/logo-braincare.png';
import { Logo } from 'components/Logo';
import Translator from 'components/Translator';
import { useTranslation } from 'react-i18next';
import { isCpf } from 'utils/validation';
import { formatToCpfUsername, unformatCpf } from 'utils/format';
import Footer from 'components/Footer';
import Fade from 'components/Fade';

function LoginForm({ isSubmitting, onSubmit }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(new Map());
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { t } = useTranslation();

  const USERNAME_EMPTY_ERROR = t('auth.login.doc-empty-error');
  const PASSWORD_EMPTY_ERROR = t('auth.login.pwd-empty-error');

  const handleSubmit = (data) => {
    const errors = new Map();

    if (!username) {
      errors.set('username', USERNAME_EMPTY_ERROR);
    }

    if (!password) {
      errors.set('password', PASSWORD_EMPTY_ERROR);
    }

    if (errors.size > 0) {
      setErrors(errors);
      return;
    }

    setErrors(new Map());

    onSubmit(data);
  };

  return (
    <>
      <Grid container sx={{ width: '100vw', height: '100vh' }} data-testid="login-form">
        <Grid item display={{ xs: 'none', md: 'block' }} md={7}>
          <Logo>
            <Brand image={BraincareLogo} title="Braincare logo" />
          </Logo>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              background: 'var(--color-white-secondary)',
              borderLeft: '2px solid var(--color-grey-light)',
              height: '100vh',
              padding: '40px',
            }}
            display="flex"
            alignItems="center"
          >
            <Fade>
              <Box sx={{ margin: '0 0 64px 0' }}>
                <Title data-testid="form-title">{t('auth.login.title-main-login')}</Title>
              </Box>

              <form onSubmit={(event) => event.preventDefault()}>
                <FormControl error={errors.has('username')} fullWidth>
                  <FormLabel data-testid="document-label">{t('auth.login.link-input-document')}</FormLabel>
                  <OutlinedInput
                    name="username"
                    value={isCpf(username) ? formatToCpfUsername(username) : username}
                    onChange={(event) => setUsername(event.target.value)}
                    inputProps={{ 'data-testid': 'document-input' }}
                  />
                  <FormHelperText>{errors.get('username')}</FormHelperText>
                </FormControl>

                <FormControl error={errors.has('password')} fullWidth>
                  <FormLabel data-testid="password-label">{t('auth.login.input-pwd')}</FormLabel>
                  <OutlinedInput
                    name="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    type={isPasswordVisible ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                          edge="end"
                        >
                          {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{ 'data-testid': 'password-input' }}
                  />
                  <FormHelperText>{errors.get('password')}</FormHelperText>
                </FormControl>

                <LinkItem href="/auth/forgot-password" data-testid="forgot-password-link">
                  {t('auth.login.link-password-reset')}
                </LinkItem>

                <Button
                  sx={{ margin: '32px 0 32px 0' }}
                  type="submit"
                  color="gradient"
                  size="large"
                  fullWidth
                  onClick={() =>
                    handleSubmit({ document: isCpf(username) ? unformatCpf(username) : username, password })
                  }
                  loading={isSubmitting}
                  data-testid="submit-button"
                >
                  {t('auth.login.button-login')}
                </Button>
              </form>
              <Footer />
            </Fade>
          </Box>
        </Grid>
      </Grid>
      <Translator />
    </>
  );
}

export default LoginForm;
