import AppItem from 'components/AppItem';
import { Box, CircularProgress, Grid, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import MenuItem from 'components/MenuItem';
import { useTranslation } from 'react-i18next';

function Apps({ applications, isLoading }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();

  const renderAppItems = () => {
    if (applications.length > 0) {
      return applications.map((app) => (
        <Grid key={app.id} item xs={6} sm={4}>
          <AppItem text={t(app.name)} image={app.image} href={app.href} data-testid={`app-item-${app.id}`} />
        </Grid>
      ));
    }

    return (
      <Typography color="secondary" variant="body2" data-testid="applications-empty-text">
        {t('auth.account-personal.applications-empty')}
      </Typography>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <MenuItem onClick={(event) => setAnchorEl(event.currentTarget)} data-testid="apps-item">
        {t('auth.account-personal.text-account-application')}
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid sx={{ width: '408px', padding: { xs: '8px', sm: '16px' } }} container>
          {isLoading ? (
            <Box display="flex">
              <CircularProgress size="24px" data-testid="spinner" />
            </Box>
          ) : (
            renderAppItems()
          )}
        </Grid>
      </Popover>
    </>
  );
}

export default Apps;
