import { useEffect, useState } from 'react';
import { validateEmail } from 'utils/validation';
import { Box, Grid, TextField, Typography } from '@mui/material';
import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import MemberUnits from 'components/MemberUnits';
import { useNavigate } from 'react-router-dom';
import { fetchUnits } from 'api/portal';
import Permissions from 'components/Permissions';
import { filterPhoneChars } from 'utils/inputs';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';

function CorporateInfo({ errors, initialData, onPreviousStep, onNextStep }) {
  const [email, setEmail] = useState(initialData.email);
  const [isValidMemberEmail, setIsValidMemberEmail] = useState(true);

  const [phoneNumber, setPhoneNumber] = useState(initialData.phoneNumber);

  const [roles, setRoles] = useState(initialData.roles);

  const [selectedUnits, setSelectedUnits] = useState(initialData.units);
  const [units, setUnits] = useState([]);

  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const [errorUnits, setErrorUnits] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const INVALID_EMAIL = t('messages.invalid-email');

  useEffect(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('page_size', 9999);

    fetchUnits(searchParams)
      .then((response) => {
        setIsLoadingUnits(false);

        const { data } = response;

        setUnits(data.results);
        setErrorUnits(false);
      })
      .catch(() => {
        setIsLoadingUnits(false);
        setErrorUnits(true);
      });
  }, []);

  return (
    <Fade>
      <Box maxWidth="428px" marginBottom="48px">
        <Subtitle>{t('organization.organization-members-create.title-sub-organization-info')}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-members-create.text-sub-organization-info')}
        </Typography>

        <TextField
          id="email"
          sx={{ margin: '0 0 16px 0' }}
          value={email}
          placeholder="eg.: johndoe@yourorganization.com"
          label={t('organization.organization-members-create.input-email')}
          onChange={(event) => setEmail(event.target.value)}
          onBlur={() => setIsValidMemberEmail(email === '' || validateEmail(email))}
          variant="outlined"
          data-testid="email-text-field"
          inputProps={{ 'data-testid': 'email-input' }}
          error={!isValidMemberEmail || errors.has('email')}
          helperText={(!isValidMemberEmail && INVALID_EMAIL) || (errors.has('email') ? errors.get('email') : '')}
          FormHelperTextProps={{ 'data-testid': 'email-error' }}
          fullWidth
        />

        <TextField
          id="phone"
          sx={{ margin: '16px 0 16px 0' }}
          value={phoneNumber}
          placeholder="+1 123-456-7890"
          label={t('organization.organization-members-create.input-phone')}
          onChange={(event) => setPhoneNumber(filterPhoneChars(event.target.value))}
          variant="outlined"
          data-testid="phone-number-text-field"
          inputProps={{ 'data-testid': 'phone-number-input' }}
          error={errors.has('phoneNumber')}
          helperText={errors.has('phoneNumber') ? errors.get('phoneNumber') : ''}
          FormHelperTextProps={{ 'data-testid': 'phone-number-error' }}
          fullWidth
        />
      </Box>

      <Box marginBottom="48px" data-testid="roles-section">
        <Box>
          <Box>
            <Subtitle>{t('organization.organization-members-create.title-sub-member-roles')}</Subtitle>
            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('organization.organization-members-create.text-member-create-role')}
            </Typography>
          </Box>
        </Box>

        <Permissions
          selected={roles}
          onToggle={(selectedRoles) => setRoles(selectedRoles)}
          data-testid="roles-selection"
        />
        {errors.has('roles') && (
          <Typography variant="body2" color="error" marginBottom="24px" data-testid="roles-selection-error">
            {errors.get('roles')}
          </Typography>
        )}
      </Box>

      <Box marginBottom="48px">
        <Subtitle>{t('organization.organization-members-create.title-sub-member-units')}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-members-create.text-member-create-units')}
        </Typography>

        <MemberUnits
          error={errorUnits}
          isLoading={isLoadingUnits}
          onToggle={(selectedUnits) => setSelectedUnits(selectedUnits)}
          selectedUnits={selectedUnits}
          units={units}
          data-testid="units-selection"
        />
        {errors.has('units') && (
          <Typography variant="body2" color="error">
            {errors.get('units')}
          </Typography>
        )}
      </Box>

      <Grid container spacing="16px">
        <Grid item>
          <Button variant="outlined" onClick={onPreviousStep} data-testid="previous-step-button">
            {t('organization.organization-members-create.button-previous')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="gradient"
            onClick={() => {
              if (isValidMemberEmail) {
                onNextStep({
                  email,
                  phoneNumber,
                  roles,
                  units: selectedUnits,
                });
              }
            }}
            data-testid="next-step-button"
          >
            {t('organization.organization-members-create.button-continue')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => navigate('/organization/members')} data-testid="cancel-button">
            {t('organization.organization-members-create.button-cancel')}
          </Button>
        </Grid>
      </Grid>
    </Fade>
  );
}

export default CorporateInfo;
