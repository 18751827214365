import PropTypes from 'prop-types';
import { Avatar, Box } from '@mui/material';
import IconMore from 'components/IconMore';

const Hydra = ({ avatarList = [], count }) => {
  const numberItems = 4;
  const avatarSubList = avatarList.slice(0, numberItems);
  const listRest = avatarList.length - numberItems;

  const renderCount = () => {
    // This count property is specific for pagination components
    if (count && count > numberItems) {
      return <IconMore number={count - numberItems} />;
    }

    return listRest > 0 && <IconMore number={listRest} />;
  };

  return (
    <Box display="flex" padding="16px 0">
      {avatarSubList.map((avatarURL, index) => (
        <Avatar
          sx={{ width: 40, height: 40, margin: '0 0 0 -12px', '&:first-of-type': { margin: '0 0 0 0' } }}
          key={index.toString()}
          src={avatarURL}
        />
      ))}
      {renderCount()}
    </Box>
  );
};

Hydra.propTypes = {
  avatarList: PropTypes.instanceOf(Array),
  count: PropTypes.number,
};

export default Hydra;
