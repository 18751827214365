import { useState } from 'react';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import PasswordRequirements from '../PasswordRequirements';

function RuledPasswordInput({ hasError, errors, passwordPolicy, onChange, value }) {
  const [isVisible, setIsVisible] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <FormControl error={hasError} fullWidth>
        <InputLabel data-testid="password-label">
          {t('organization.organization-members-edit-change-password.input-password-new')}
        </InputLabel>
        <OutlinedInput
          name="password"
          value={value}
          onChange={(event) => onChange(event)}
          onBlur={(event) => onChange(event)}
          type={isVisible ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={() => setIsVisible(!isVisible)} edge="end">
                {isVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{ 'data-testid': 'password-input' }}
        />
      </FormControl>
      <PasswordRequirements passwordPolicy={passwordPolicy} nonFulfilledRules={errors} />
    </>
  );
}

export default RuledPasswordInput;
