import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import ChipInput from 'components/ChipInput';
import SubSubtitle from 'components/SubSubtitle';
import { showErrorToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

function ForbiddenWordsPolicyForm({ forbiddenWord, forbiddenWords, caseSensitiveForbiddenWords, onChange }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const LIST_FORBIDDEN_WORDS_LABEL = t(
    'organization.organization-settings.password-policy.forbidden-words.list-forbidden-words-label',
  );
  const FORBIDDEN_WORDS_CASE_SENSITIVE_LABEL = t(
    'organization.organization-settings.password-policy.forbidden-words.case-sensitive-label',
  );

  const addForbiddenWord = (event) => {
    if (event.code === 'Enter') {
      const word = event.target.value;

      const hasWord = forbiddenWords.some((value) => value === word);

      if (hasWord) {
        dispatch(showErrorToast('organization.organization-settings.password-policy.errors.existing-forbidden-words'));
        return;
      }

      onChange('', [...forbiddenWords, forbiddenWord], caseSensitiveForbiddenWords);
    }
  };

  const removeForbiddenWord = (word) => {
    onChange(
      forbiddenWord,
      forbiddenWords.filter((value) => value !== word),
      caseSensitiveForbiddenWords,
    );
  };

  return (
    <Box marginBottom="48px">
      <SubSubtitle>{t('organization.organization-settings.password-policy.forbidden-words.subsubtitle')}</SubSubtitle>

      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-settings.password-policy.forbidden-words.text')}
      </Typography>

      <ChipInput
        items={forbiddenWords}
        label={LIST_FORBIDDEN_WORDS_LABEL}
        onCloseItem={(item) => removeForbiddenWord(item)}
        onChange={(event) => onChange(event.target.value, forbiddenWords, caseSensitiveForbiddenWords)}
        onKeyPress={addForbiddenWord}
        value={forbiddenWord}
        data-testid="forbidden-words-input"
      />

      <FormControlLabel
        label={FORBIDDEN_WORDS_CASE_SENSITIVE_LABEL}
        control={
          <Checkbox
            checked={caseSensitiveForbiddenWords}
            onClick={() => onChange(forbiddenWord, forbiddenWords, !caseSensitiveForbiddenWords)}
            inputProps={{ 'data-testid': 'case-sensitive-forbidden-words-checkbox' }}
          />
        }
      />
    </Box>
  );
}

export default ForbiddenWordsPolicyForm;
