import { useEffect, useState } from 'react';
import App from 'components/templates/app';
import Section from 'components/Section';
import MemberCard from 'components/cards/MemberCard';
import Subtitle from 'components/Subtitle';
import Loader from 'components/SectionLoader';
import { MEMBER_STATUS } from 'utils/members';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationData, setOrganizationRoles, setOrganizationSelectedMember } from 'features/organizationSlice';
import { fetchMembers, fetchRoles, fetchUnits } from 'api/portal';
import { showErrorToast } from 'features/toastSlice';
import Button from 'components/Button';
import Autocomplete from 'components/Autocomplete';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { useTranslation } from 'react-i18next';
import MemberTable from 'components/tables/MemberTable';
import Fade from 'components/Fade';

const PAGE_SIZE = 8;

function Members() {
  const { t } = useTranslation();

  const defaultUnit = {
    label: t('organization.organization-members.dropdown-filter-unit'),
    id: 0,
    'data-testid': 'unit-option-0',
  };

  const [members, setMembers] = useState([]);
  const [units, setUnits] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedUnit, setSelectedUnit] = useState(defaultUnit);
  const [selectedStatus, setSelectedStatus] = useState('ALL');
  const [selectedRole, setSelectedRole] = useState('ALL');
  const [filterValue, setFilterValue] = useState('');
  const [view, setView] = useState('cards');

  const [isLoadingMembers, setIsLoadingMembers] = useState(true);
  const [errorMembers, setErrorMembers] = useState(false);

  const roles = useSelector((state) => state.organization.roles);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_ORGANIZATION = t('organization.breadcrumbs.manage-organization');

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/organization/members',
      title: MANAGE_ORGANIZATION,
      isActive: true,
    },
  ];

  const linkItems = [
    {
      path: '/organization/members',
      title: t('organization.organization-members.title-sub-member'),
    },
    {
      path: '/organization/units',
      title: t('organization.organization-units.title-sub-unit-edit'),
    },
  ];

  const actionButton = {
    text: t('organization.organization-members.button-add-member'),
    href: '/organization/members/create',
  };

  const secondActionButton = {
    text: t('organization.organization-members.button-bulk-upload-member'),
    href: '/organization/members/bulk-upload-instructions',
  };

  const searchMembers = (searchParams) => {
    setIsLoadingMembers(true);
    setErrorMembers(false);

    fetchMembers(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoadingMembers(false);
        setErrorMembers(false);
        setMembers(data.results);
        setCount(Math.ceil(data.count / PAGE_SIZE));
        setCurrentPage(Number(searchParams.get('page')));
      })
      .catch(() => {
        setIsLoadingMembers(false);
        setErrorMembers(true);
      });
  };

  const fetchMembersPage = (page) => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', PAGE_SIZE);

    if (selectedUnit && selectedUnit.id !== defaultUnit.id) {
      searchParams.set('unit', selectedUnit.id);
    }

    if (selectedStatus !== 'ALL') {
      searchParams.set('status', selectedStatus);
    }

    if (selectedRole !== 'ALL') {
      searchParams.set('role', selectedRole);
    }

    searchParams.set('search_name', filterValue);

    searchMembers(searchParams);
  };

  const onClearFilterClick = () => {
    setSelectedUnit(defaultUnit);
    setSelectedStatus('ALL');
    setSelectedRole('ALL');
    setFilterValue('');

    const searchParams = new URLSearchParams();

    searchParams.set('page', 1);
    searchParams.set('page_size', 8);

    searchMembers(searchParams);
  };

  useEffect(() => {
    dispatch(clearOrganizationData());
    fetchMembersPage(1);

    const searchParams = new URLSearchParams();

    searchParams.set('page_size', 9999);

    fetchUnits(searchParams)
      .then((response) => {
        const { data } = response;
        setUnits(data.results);
      })
      .catch(() => {
        dispatch(showErrorToast('messages.error-units'));
      });

    fetchRoles()
      .then((response) => {
        const { data } = response;
        dispatch(setOrganizationRoles(data));
      })
      .catch(() => {
        dispatch(showErrorToast('messages.error-roles'));
      });
  }, []);

  const openEditMemberPage = (id) => {
    const member = members.find((index) => index.id === id);
    dispatch(setOrganizationSelectedMember(member));
    navigate(`/organization/members/${id}/edit/info`);
  };

  const renderPagination = () => (
    <Pagination count={count} page={currentPage} onChange={(event, value) => fetchMembersPage(value)} />
  );

  const renderResults = () => {
    if (errorMembers) {
      return (
        <Typography variant="body2" color="error" textAlign="center" data-testid="error-members">
          {t('messages.error-members')}
        </Typography>
      );
    }

    if (view === 'cards') {
      return (
        <>
          <Fade>
            {members.length === 0 ? (
              <Typography display="flex" justifyContent="center" variant="body2" color="secondary">
                {t('organization.organization-members.no-results-found')}
              </Typography>
            ) : (
              <Grid container spacing="24px">
                {members.map((member) => (
                  <Grid key={`member-${member.id}`} item xs={12} sm={6} md={3}>
                    <MemberCard
                      sx={{ height: '100%' }}
                      member={member}
                      onClick={() => openEditMemberPage(member.id)}
                      onClickButton={() => navigate(`/organization/members/${member.id}/edit/password`)}
                      data-testid={`member-card-${member.id}`}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Fade>
          {renderPagination()}
        </>
      );
    }

    return (
      <>
        <Fade>
          <MemberTable
            members={members}
            onClickButton={(member) => navigate(`/organization/members/${member.id}/edit/password`)}
            onClick={(member) => openEditMemberPage(member.id)}
          />
        </Fade>
        {renderPagination()}
      </>
    );
  };

  const unitOptions = [defaultUnit, ...units.map((unit) => ({ label: unit.name, id: unit.id }))];

  return (
    <App
      title={t('organization.organization-members.title-main-member')}
      breadcrumbs={breadcrumbs}
      linkItems={linkItems}
      actionButton={actionButton}
      secondActionButton={secondActionButton}
    >
      <Section>
        <Subtitle>{t('organization.organization-members.title-filter-members')}</Subtitle>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <Grid container marginTop="8px" spacing={1}>
            <Grid
              item
              sx={{
                marginBottom: {
                  xs: '8px',
                  sm: '0',
                },
              }}
              xs={12}
              sm={3}
              md={2}
            >
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel>{t('organization.organization-members.input-filter-name')}</InputLabel>
                <OutlinedInput
                  value={filterValue}
                  onChange={(event) => setFilterValue(event.target.value)}
                  placeholder={t('organization.organization-members.dropdown-filter-name')}
                  inputProps={{ 'data-testid': 'name-filter-input' }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              sx={{
                marginBottom: {
                  xs: '8px',
                  sm: '0',
                },
              }}
              xs={12}
              sm={3}
              md={2}
            >
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel htmlFor="role">{t('organization.organization-members.input-filter-doctor')}</InputLabel>
                <Select
                  id="role"
                  onChange={(event) => setSelectedRole(event.target.value)}
                  value={selectedRole}
                  data-testid="role-select"
                >
                  <MenuItem value="ALL">{t('organization.organization-members.dropdown-filter-doctor')}</MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={`role-${role.id}`} value={role.id.toString()} data-testid={`role-option-${role.id}`}>
                      {t(`organization.organization-members.card-member-role-${role.id}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              sx={{
                marginBottom: {
                  xs: '8px',
                  sm: '0',
                },
              }}
              xs={12}
              sm={3}
              md={2}
            >
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel htmlFor="status">{t('organization.organization-members.input-filter-protocol')}</InputLabel>
                <Select
                  id="status"
                  onChange={(event) => setSelectedStatus(event.target.value)}
                  value={selectedStatus}
                  data-testid="status-select"
                >
                  <MenuItem value="ALL">{t('organization.organization-members.dropdown-filter-protocol')}</MenuItem>
                  {MEMBER_STATUS.map((status) => (
                    <MenuItem key={`status-${status}`} value={status} data-testid={`status-option-${status}`}>
                      {t(`utils.status.${status.toLowerCase()}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              sx={{
                marginBottom: {
                  xs: '8px',
                  sm: '0',
                },
              }}
              xs={12}
              sm={3}
              md={2}
            >
              <FormControl sx={{ margin: 0 }} fullWidth>
                <Autocomplete
                  disablePortal
                  value={selectedUnit}
                  onChange={(event, newValue) => setSelectedUnit(newValue)}
                  options={unitOptions}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <Typography
                      {...props}
                      variant="body2"
                      color="secondary"
                      key={option.id}
                      data-testid={`unit-option-${option.id}`}
                    >
                      {option.label}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t('organization.organization-members.input-filter-unit')} />
                  )}
                  data-testid="unit-select"
                />
              </FormControl>
            </Grid>

            <Grid
              item
              sx={{
                marginBottom: {
                  xs: '8px',
                  sm: '0',
                },
              }}
              xs={12}
              sm={3}
              md={2}
            >
              <Button variant="outlined" onClick={onClearFilterClick} fullWidth data-testid="reset-button">
                {t('organization.organization-members.button-filter-reset')}
              </Button>
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <Button
                color="gradient"
                onClick={() => fetchMembersPage(1)}
                type="submit"
                fullWidth
                data-testid="apply-button"
              >
                {t('organization.organization-members.button-filter-apply')}
              </Button>
            </Grid>
          </Grid>
        </form>
        <ToggleButtonGroup
          sx={{ margin: '16px 0' }}
          value={view}
          exclusive
          onChange={(event, nextView) => {
            if (nextView) {
              setView(nextView);
            }
          }}
        >
          <ToggleButton value="cards" aria-label="cards" data-testid="cards-visualization-button">
            <ViewModuleIcon />
          </ToggleButton>
          <ToggleButton value="table" aria-label="table" data-testid="table-visualization-button">
            <ViewListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <Loader loading={isLoadingMembers}>{renderResults()}</Loader>
      </Section>
    </App>
  );
}

export default Members;
