import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import SubSubtitle from 'components/SubSubtitle';
import { useTranslation } from 'react-i18next';

const corporateEmailAttr = 'email';
const corporatePhoneAttr = 'phone_number';
const documentAttr = 'user__document';
const firstNameAttr = 'user__first_name';
const lastNameAttr = 'user__last_name';
const organizationNameAttr = 'organization__name';

function PersonalDataPolicyForm({ personalData, similarityRatio, onChange }) {
  const { t } = useTranslation();

  const FIRST_NAME_LABEL = t('organization.organization-settings.password-policy.personal-data.first-name-label');
  const LAST_NAME_LABEL = t('organization.organization-settings.password-policy.personal-data.last-name-label');
  const DOCUMENT_LABEL = t('organization.organization-settings.password-policy.personal-data.document-label');
  const CORPORATE_EMAIL_LABEL = t(
    'organization.organization-settings.password-policy.personal-data.corporate-email-label',
  );
  const CORPORATE_PHONE_LABEL = t(
    'organization.organization-settings.password-policy.personal-data.corporate-phone-label',
  );

  const SIMILARITY_RATIO_LABEL = t(
    'organization.organization-settings.password-policy.personal-data.similarity-ratio-label',
  );
  const ORGANIZATION_NAME_LABEL = t(
    'organization.organization-settings.password-policy.personal-data.organization-name-label',
  );

  const SIMILARITY_RATIO_TEXT = t(
    'organization.organization-settings.password-policy.personal-data.similarity-ratio-text',
  );
  const SIMILARITY_RATIO_EQUAL_LABEL = t(
    'organization.organization-settings.password-policy.personal-data.similarity-ratio-equal',
  );
  const SIMILARITY_RATIO_LOW_LABEL = t(
    'organization.organization-settings.password-policy.personal-data.similarity-ratio-low',
  );
  const SIMILARITY_RATIO_MEDIUM_LABEL = t(
    'organization.organization-settings.password-policy.personal-data.similarity-ratio-medium',
  );
  const SIMILARITY_RATIO_HIGH_LABEL = t(
    'organization.organization-settings.password-policy.personal-data.similarity-ratio-high',
  );

  const hasAttr = (attr) => personalData.some((value) => value === attr);

  const addPersonalAttribute = (attr) => {
    if (hasAttr(attr)) {
      onChange(
        personalData.filter((value) => value !== attr),
        similarityRatio,
      );
    } else {
      onChange([...personalData, attr], similarityRatio);
    }
  };

  return (
    <Box marginBottom="48px">
      <SubSubtitle>{t('organization.organization-settings.password-policy.personal-data.subsubtitle')}</SubSubtitle>

      <Box marginBottom="48px">
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-settings.password-policy.personal-data.text')}
        </Typography>

        <FormGroup>
          <FormControlLabel
            label={CORPORATE_EMAIL_LABEL}
            control={
              <Checkbox
                checked={hasAttr(corporateEmailAttr)}
                onClick={() => addPersonalAttribute(corporateEmailAttr)}
                inputProps={{ 'data-testid': 'corporate-email-checkbox' }}
              />
            }
          />

          <FormControlLabel
            label={CORPORATE_PHONE_LABEL}
            control={
              <Checkbox
                checked={hasAttr(corporatePhoneAttr)}
                onClick={() => addPersonalAttribute(corporatePhoneAttr)}
                inputProps={{ 'data-testid': 'corporate-phone-checkbox' }}
              />
            }
          />

          <FormControlLabel
            label={DOCUMENT_LABEL}
            control={
              <Checkbox
                checked={hasAttr(documentAttr)}
                onClick={() => addPersonalAttribute(documentAttr)}
                inputProps={{ 'data-testid': 'document-checkbox' }}
              />
            }
          />

          <FormControlLabel
            label={FIRST_NAME_LABEL}
            control={
              <Checkbox
                checked={hasAttr(firstNameAttr)}
                onClick={() => addPersonalAttribute(firstNameAttr)}
                inputProps={{ 'data-testid': 'first-name-checkbox' }}
              />
            }
          />

          <FormControlLabel
            label={LAST_NAME_LABEL}
            control={
              <Checkbox
                checked={hasAttr(lastNameAttr)}
                onClick={() => addPersonalAttribute(lastNameAttr)}
                inputProps={{ 'data-testid': 'last-name-checkbox' }}
              />
            }
          />

          <FormControlLabel
            label={ORGANIZATION_NAME_LABEL}
            control={
              <Checkbox
                checked={hasAttr(organizationNameAttr)}
                onClick={() => addPersonalAttribute(organizationNameAttr)}
                inputProps={{ 'data-testid': 'organization-name-checkbox' }}
              />
            }
          />
        </FormGroup>
      </Box>

      <Box marginBottom="48px">
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {SIMILARITY_RATIO_TEXT}
        </Typography>

        <FormControl fullWidth>
          <InputLabel id="similarity-ratio-label">{SIMILARITY_RATIO_LABEL}</InputLabel>
          <Select
            labelId="similarity-ratio-label"
            id="similarity-ratio"
            value={similarityRatio}
            onChange={(event) => onChange(personalData, event.target.value)}
            data-testid="similarity-ratio-select"
            inputProps={{ 'data-testid': 'similarity-ratio-input' }}
          >
            <MenuItem value="1" data-testid="identical-option">
              {SIMILARITY_RATIO_EQUAL_LABEL}
            </MenuItem>
            <MenuItem value="0.9" data-testid="high-option">
              {SIMILARITY_RATIO_HIGH_LABEL}
            </MenuItem>
            <MenuItem value="0.7" data-testid="medium-option">
              {SIMILARITY_RATIO_MEDIUM_LABEL}
            </MenuItem>
            <MenuItem value="0.3" data-testid="low-option">
              {SIMILARITY_RATIO_LOW_LABEL}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

export default PersonalDataPolicyForm;
