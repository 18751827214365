import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const BreadcrumbItem = ({ isActive = false, path, children, ...props }) => (
  <Link className={classNames(styles.breadcrumb, { [styles.active]: isActive })} to={path} {...props}>
    {children}
  </Link>
);

BreadcrumbItem.propTypes = {
  isActive: PropTypes.bool,
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BreadcrumbItem;
