import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import Section from 'components/Section';
import SubSubtitle from 'components/SubSubtitle';
import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import App from 'pages/braincare/organizations/template';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import SectionLoader from 'components/SectionLoader';
import { updateOrganization } from 'api/portal';
import { useDispatch } from 'react-redux';
import braincare from 'components/routes/braincare';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';
import { useFetchOrganizationAdmin } from 'hooks/useFetchOrganizationAdmin';
import Fade from 'components/Fade';

function AdminOrganizationInactivityPolicy() {
  const [organization, setOrganization] = useState(undefined);

  const [name, setName] = useState('');

  const [enableAutoLogoutPortal, setEnableAutoLogoutPortal] = useState(false);
  const [inactivityTimePortal, setInactivityTimePortal] = useState('');

  const [enableAutoLogoutApp, setEnableAutoLogoutApp] = useState(false);
  const [inactivityTimeApp, setInactivityTimeApp] = useState('');

  const [autoSuspensionMembers, setAutoSuspensionMembers] = useState(false);
  const [maxInactivityPeriod, setMaxInactivityPeriod] = useState('');

  const [errors, setErrors] = useState(new Map());

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { orgId } = params;

  const { t } = useTranslation();

  const INACTIVITY_TIME_EMPTY = t('organization.organization-settings.inactivity-policy.inactivity-time-empty');
  const INACTIVITY_TIME_LOW = t('organization.organization-settings.inactivity-policy.inactivity-time-low');
  const MAX_INACTIVITY_PERIOD_EMPTY = t(
    'organization.organization-settings.inactivity-policy.max-inactivity-period-empty',
  );

  useFetchOrganizationAdmin({
    orgId,
    onSuccess: (data) => {
      setOrganization(data);
      setName(data.name || '');
      setEnableAutoLogoutApp(data.auto_logout_app || false);
      setInactivityTimeApp(data.auto_logout_time_app || '');
      setEnableAutoLogoutPortal(data.auto_logout_portal || false);
      setInactivityTimePortal(data.auto_logout_time_portal || '');
      setAutoSuspensionMembers(data.suspend_inactive_members || false);
      setMaxInactivityPeriod(data.maximum_inactive_period || '');
    },
    onFailure: () => {
      navigate('/braincare/organizations');
      dispatch(showErrorToast('messages.load-failure'));
    },
  });

  const validateForm = () => {
    const errors = new Map();

    if (enableAutoLogoutPortal) {
      if (!inactivityTimePortal) {
        errors.set('inactivityTimePortal', INACTIVITY_TIME_EMPTY);
      }
    }

    if (inactivityTimePortal !== '' && inactivityTimePortal !== null) {
      if (inactivityTimePortal < 1) {
        errors.set('inactivityTimePortal', INACTIVITY_TIME_LOW);
      }
    }

    if (enableAutoLogoutApp) {
      if (!inactivityTimeApp) {
        errors.set('inactivityTimeApp', INACTIVITY_TIME_EMPTY);
      }
    }

    if (inactivityTimeApp !== '' && inactivityTimeApp !== null) {
      if (inactivityTimeApp < 1) {
        errors.set('inactivityTimeApp', INACTIVITY_TIME_LOW);
      }
    }

    if (autoSuspensionMembers) {
      if (!maxInactivityPeriod) {
        errors.set('maxInactivityPeriod', MAX_INACTIVITY_PERIOD_EMPTY);
      }
    }

    return errors;
  };

  const onSubmit = () => {
    const errors = validateForm();

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      updateOrganization(orgId, {
        auto_logout_portal: enableAutoLogoutPortal,
        auto_logout_time_portal: inactivityTimePortal === '' ? null : inactivityTimePortal,
        auto_logout_app: enableAutoLogoutApp,
        auto_logout_time_app: inactivityTimeApp === '' ? null : inactivityTimeApp,
        suspend_inactive_members: autoSuspensionMembers,
        maximum_inactive_period: maxInactivityPeriod === '' ? null : maxInactivityPeriod,
      })
        .then(() => {
          setIsSubmitting(false);
          dispatch(showSuccessToast('organization.organization-settings.save-success-message'));
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(showErrorToast('organization.organization-settings.save-failure-message'));
        });
    } else {
      dispatch(showErrorToast('messages.fix-fields'));
    }
  };

  return (
    <App title={name}>
      <Fade>
        <Box maxWidth="428px">
          <Subtitle>{t('organization.organization-settings.inactivity-policy.logout-policy-subtitle')}</Subtitle>

          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-settings.inactivity-policy.logout-policy-text')}
          </Typography>

          <SectionLoader loading={!organization}>
            <Fade>
              <Box marginBottom="48px">
                <SubSubtitle>
                  {t('organization.organization-settings.inactivity-policy.portal-logout-subsubtitle')}
                </SubSubtitle>

                <FormControlLabel
                  label={t('organization.organization-settings.inactivity-policy.portal-logout-enable-checkbox')}
                  control={
                    <Checkbox
                      checked={enableAutoLogoutPortal}
                      onClick={() => setEnableAutoLogoutPortal(!enableAutoLogoutPortal)}
                      inputProps={{ 'data-testid': 'auto-logout-portal-checkbox' }}
                    />
                  }
                />

                <TextField
                  id="inactivity_time_portal"
                  sx={{ margin: '16px 0 32px 0' }}
                  value={inactivityTimePortal}
                  onChange={(event) => setInactivityTimePortal(filterNumbers(event.target.value))}
                  label={t('organization.organization-settings.inactivity-policy.portal-logout-time-input')}
                  variant="outlined"
                  error={errors.has('inactivityTimePortal')}
                  helperText={errors.get('inactivityTimePortal')}
                  data-testid="auto-logout-portal-text-field"
                  inputProps={{ 'data-testid': 'auto-logout-portal-input' }}
                  FormHelperTextProps={{ 'data-testid': 'auto-logout-portal-error' }}
                  fullWidth
                />

                <SubSubtitle>
                  {t('organization.organization-settings.inactivity-policy.app-logout-subsubtitle')}
                </SubSubtitle>

                <FormControlLabel
                  label={t('organization.organization-settings.inactivity-policy.app-logout-enable-checkbox')}
                  control={
                    <Checkbox
                      checked={enableAutoLogoutApp}
                      onClick={() => setEnableAutoLogoutApp(!enableAutoLogoutApp)}
                      inputProps={{ 'data-testid': 'auto-logout-app-checkbox' }}
                    />
                  }
                />

                <TextField
                  id="inactivity_time_portal"
                  sx={{ marginTop: 2 }}
                  value={inactivityTimeApp}
                  onChange={(event) => setInactivityTimeApp(filterNumbers(event.target.value))}
                  label={t('organization.organization-settings.inactivity-policy.app-logout-time-input')}
                  variant="outlined"
                  error={errors.has('inactivityTimeApp')}
                  helperText={errors.get('inactivityTimeApp')}
                  data-testid="auto-logout-app-text-field"
                  inputProps={{ 'data-testid': 'auto-logout-app-input' }}
                  FormHelperTextProps={{ 'data-testid': 'auto-logout-app-error' }}
                  fullWidth
                />
              </Box>

              <Box marginBottom="48px">
                <Subtitle>
                  {t('organization.organization-settings.inactivity-policy.auto-suspension-subtitle')}
                </Subtitle>

                <Section>
                  <Typography variant="body2" color="secondary" marginBottom="24px">
                    {t('organization.organization-settings.inactivity-policy.auto-suspension-text')}
                  </Typography>

                  <FormControlLabel
                    label={t('organization.organization-settings.inactivity-policy.auto-suspension-checkbox')}
                    control={
                      <Checkbox
                        checked={autoSuspensionMembers}
                        onClick={() => setAutoSuspensionMembers(!autoSuspensionMembers)}
                        inputProps={{ 'data-testid': 'auto-suspension-members-checkbox' }}
                      />
                    }
                  />

                  <TextField
                    id="maxInactivityPeriod"
                    sx={{ marginTop: 2 }}
                    value={maxInactivityPeriod}
                    onChange={(event) => setMaxInactivityPeriod(filterNumbers(event.target.value))}
                    label={t('organization.organization-settings.inactivity-policy.auto-suspension-input')}
                    variant="outlined"
                    error={errors.has('maxInactivityPeriod')}
                    helperText={errors.get('maxInactivityPeriod')}
                    data-testid="max-inactivity-period-text-field"
                    inputProps={{ 'data-testid': 'max-inactivity-period-input' }}
                    FormHelperTextProps={{ 'data-testid': 'max-inactivity-period-error' }}
                    fullWidth
                  />
                </Section>
              </Box>

              <Button variant="outlined" loading={isSubmitting} onClick={onSubmit} data-testid="save-button">
                {t('organization.organization-settings.inactivity-policy.submit-button-text')}
              </Button>
              <Button
                sx={{ marginLeft: 2 }}
                variant="outlined"
                onClick={() => navigate('/braincare/organizations')}
                data-testid="cancel-button"
              >
                {t('organization.organization-settings.inactivity-policy.cancel-button-text')}
              </Button>
            </Fade>
          </SectionLoader>
        </Box>
      </Fade>
    </App>
  );
}

export default braincare(AdminOrganizationInactivityPolicy);
