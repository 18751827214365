import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Loader = ({ className }) => (
  <div className={classNames(styles.loader, className)}>
    <div />
    <div />
    <div />
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
};

export default Loader;
