import { useTranslation } from 'react-i18next';
import MobileDeviceRegistration from '../../../../components/MobileDeviceRegistration';

function MobileDeviceCreation() {
  const { t } = useTranslation();
  const ADD_DEVICE = t('organization.breadcrumbs.add-organization-mobile-device');
  return <MobileDeviceRegistration title={ADD_DEVICE} currentNav={ADD_DEVICE} />;
}

export default MobileDeviceCreation;
