import { Box, Typography } from '@mui/material';
import { updateOrganization } from 'api/portal';
import Subtitle from 'components/Subtitle';
import SectionLoader from 'components/SectionLoader';
import Button from 'components/Button';
import braincare from 'components/routes/braincare';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import App from 'pages/braincare/organizations/template';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PasswordPolicy from 'utils/password-validators/PasswordPolicy';
import PasswordPolicyBuilder from 'utils/password-validators/PasswordPolicyBuilder';
import { castValueToNumber } from 'utils/inputs';
import { isBetween } from 'utils/math';
import { useFetchOrganizationAdmin } from 'hooks/useFetchOrganizationAdmin';
import PolicyTemplates from 'components/PolicyTemplates';
import LengthPolicyForm from 'components/forms/LengthPolicyForm';
import NonAlphanumericPolicyForm from 'components/forms/NonAlphanumericPolicyForm';
import DigitsPolicyForm from 'components/forms/DigitsPolicyForm';
import UppercasePolicyForm from 'components/forms/UppercasePolicyForm';
import LowercasePolicyForm from 'components/forms/LowercasePolicyForm';
import SequentialTextPolicyForm from 'components/forms/SequentialTextPolicyForm';
import PersonalDataPolicyForm from 'components/forms/PersonalDataPolicyForm';
import ForbiddenWordsPolicyForm from 'components/forms/ForbiddenWordsPolicyForm';
import RepeatedCharsPolicyForm from 'components/forms/RepeatedCharsPolicyForm';
import PasswordReusePolicyForm from 'components/forms/PasswordReusePolicyForm';
import CommonPasswordsForm from 'components/forms/CommonPasswordsForm';
import PasswordExpirationForm from 'components/forms/PasswordExpirationForm';
import Fade from 'components/Fade';

function AdminOrganizationPasswordPolicy() {
  const [organization, setOrganization] = useState();

  const [name, setName] = useState('');

  const [maxLength, setMaxLength] = useState('');
  const [minLength, setMinLength] = useState('');

  const [maxNonAlphanumeric, setMaxNonAlphanumeric] = useState('');
  const [minNonAlphanumeric, setMinNonAlphanumeric] = useState('');

  const [maxDigits, setMaxDigits] = useState('');
  const [minDigits, setMinDigits] = useState('');

  const [maxUppercase, setMaxUppercase] = useState('');
  const [minUppercase, setMinUppercase] = useState('');

  const [maxLowercase, setMaxLowercase] = useState('');
  const [minLowercase, setMinLowercase] = useState('');

  const [maxLengthSeqStrings, setMaxLengthSeqStrings] = useState('');
  const [caseSensitiveSeqStrings, setCaseSensitiveSeqStrings] = useState(false);
  const [reverseOrderSeqStrings, setReverseOrderSeqStrings] = useState(false);
  const [repeatedLettersSeqStrings, setRepeatedLettersSeqStrings] = useState(false);
  const [loopingSeqStrings, setLoopingSeqStrings] = useState(false);

  const [similarityRatio, setSimilarityRatio] = useState('');
  const [personalData, setPersonalData] = useState([]);

  const [forbiddenWord, setForbiddenWord] = useState('');
  const [listForbiddenWords, setListForbiddenWords] = useState([]);
  const [caseSensitiveForbiddenWords, setCaseSensitiveForbiddenWords] = useState(false);

  const [excludeCommonPasswords, setExcludeCommonPasswords] = useState(false);

  const [maxLengthRepeatedChars, setMaxLengthRepeatedChars] = useState('');
  const [caseSensitiveRepeatedChars, setCaseSensitiveRepeatedChars] = useState(false);

  const [maxRepetitions, setMaxRepetitions] = useState('');

  const [enablePasswordExpiration, setEnablePasswordExpiration] = useState(false);
  const [passwordExpirationPeriod, setPasswordExpirationPeriod] = useState('');
  const [numberSkips, setNumberSkips] = useState('');
  const [skippingPeriod, setSkippingPeriod] = useState('');

  const [errors, setErrors] = useState(new Map());

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();

  const ERROR_RANGE = t('organization.organization-settings.password-policy.errors.range');
  const ERROR_RANGE_2 = t('organization.organization-settings.password-policy.errors.range-2');
  const ERROR_RANGE_3 = t('organization.organization-settings.password-policy.errors.range-3');
  const ERROR_RANGE_4 = t('organization.organization-settings.password-policy.errors.range-4');
  const ERROR_RANGE_5 = t('organization.organization-settings.password-policy.errors.range-5');
  const ERROR_MAX_LENGTH = t('organization.organization-settings.password-policy.errors.max-length');
  const ERROR_MAX_NON_ALPHANUMERIC = t(
    'organization.organization-settings.password-policy.errors.max-non-alphanumeric-chars',
  );
  const ERROR_MAX_DIGITS = t('organization.organization-settings.password-policy.errors.max-digits');
  const ERROR_MAX_UPPERCASE = t('organization.organization-settings.password-policy.errors.max-uppercase');
  const ERROR_MAX_LOWERCASE = t('organization.organization-settings.password-policy.errors.max-lowercase');

  const ERROR_PASSWORD_EXPIRATION_PERIOD = t(
    'organization.organization-settings.password-policy.errors.password-expiration-period',
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { orgId } = params;

  const fillFormWithTemplate = (template) => {
    const passwordPolicy = new PasswordPolicy(template);

    const {
      sizeRange,
      nonAlphanumericRange,
      digitsRange,
      uppercaseRange,
      lowercaseRange,
      sequentialStrings,
      excludePersonalInformation,
      forbiddenWords,
      repeatedCharSequences,
    } = passwordPolicy;

    setMaxLength(sizeRange.max);
    setMinLength(sizeRange.min);

    setMaxNonAlphanumeric(nonAlphanumericRange.max);
    setMinNonAlphanumeric(nonAlphanumericRange.min);

    setMaxDigits(digitsRange.max);
    setMinDigits(digitsRange.min);

    setMaxUppercase(uppercaseRange.max);
    setMinUppercase(uppercaseRange.min);

    setMaxLowercase(lowercaseRange.max);
    setMinLowercase(lowercaseRange.min);

    setMaxLengthSeqStrings(sequentialStrings.max ? sequentialStrings.max : '');
    setCaseSensitiveSeqStrings(sequentialStrings.is_case_sensitive);
    setReverseOrderSeqStrings(sequentialStrings.reverse_order);
    setRepeatedLettersSeqStrings(sequentialStrings.repeated_letters);
    setLoopingSeqStrings(sequentialStrings.looping);

    setSimilarityRatio(String(excludePersonalInformation.similarity_ratio));
    setPersonalData(excludePersonalInformation.fields);

    setForbiddenWord('');
    setListForbiddenWords(forbiddenWords.words);
    setCaseSensitiveForbiddenWords(forbiddenWords.is_case_sensitive);

    setExcludeCommonPasswords(passwordPolicy.excludeCommonPasswords);

    setMaxLengthRepeatedChars(repeatedCharSequences.max ? repeatedCharSequences.max : '');
    setCaseSensitiveRepeatedChars(repeatedCharSequences.is_case_sensitive);

    setMaxRepetitions(passwordPolicy.passwordReuse || '');

    setErrors(new Map());
  };

  useFetchOrganizationAdmin({
    orgId,
    onSuccess: (data) => {
      setOrganization(data);
      setName(data.name);
      fillFormWithTemplate(data.password_policy);
      setEnablePasswordExpiration(data.enable_password_renewal);
      setPasswordExpirationPeriod(data.password_renewal_period || '');
      setNumberSkips(data.skip_password_renewal || '');
      setSkippingPeriod(data.skip_password_renewal_period || '');
    },
    onFailure: () => {
      navigate('/braincare/organizations');
      dispatch(showErrorToast('messages.load-failure'));
    },
  });

  const validateLength = () => {
    const errors = new Map();

    const minLengthNumber = castValueToNumber(minLength);
    const maxLengthNumber = castValueToNumber(maxLength);
    // Required fields
    if (maxLengthNumber === undefined) {
      errors.set('maxLength', ERROR_RANGE);
    }

    if (minLengthNumber === undefined) {
      errors.set('minLength', ERROR_RANGE);
    }
    // Range of the max and min length
    if (maxLengthNumber !== undefined && !isBetween(maxLengthNumber, 6, 128)) {
      errors.set('maxLength', ERROR_RANGE);
    }

    if (minLengthNumber !== undefined && !isBetween(minLengthNumber, 6, 128)) {
      errors.set('minLength', ERROR_RANGE);
    }
    // Checking that the min lengh < max length
    if (!errors.has('maxLength') && !errors.has('minLength')) {
      if (maxLengthNumber !== undefined && minLengthNumber !== undefined && maxLengthNumber < minLengthNumber) {
        errors.set('maxLength', ERROR_MAX_LENGTH);
      }
    }

    return errors;
  };

  const validateDigits = () => {
    const errors = new Map();

    const minDigitsNumber = castValueToNumber(minDigits);
    const maxDigitsNumber = castValueToNumber(maxDigits);
    // Range of the max and min number of digits
    if (maxDigitsNumber !== undefined && !isBetween(maxDigitsNumber, 0, 128)) {
      errors.set('maxDigits', ERROR_RANGE_2);
    }

    if (minDigitsNumber !== undefined && !isBetween(minDigitsNumber, 0, 128)) {
      errors.set('minDigits', ERROR_RANGE_2);
    }
    // Checking that the min number of digits < max number of digits
    if (!errors.has('maxDigits') && !errors.has('minDigits')) {
      if (maxDigitsNumber !== undefined && minDigitsNumber !== undefined && maxDigitsNumber < minDigitsNumber) {
        errors.set('maxDigits', ERROR_MAX_DIGITS);
      }
    }

    return errors;
  };

  const validateNonAlphanumeric = () => {
    const errors = new Map();

    const minNonAlphanumericNumber = castValueToNumber(minNonAlphanumeric);
    const maxNonAlphanumericNumber = castValueToNumber(maxNonAlphanumeric);
    // Range of the max and min number of non-alphanumeric chars
    if (maxNonAlphanumericNumber !== undefined && !isBetween(maxNonAlphanumericNumber, 0, 128)) {
      errors.set('maxNonAlphanumeric', ERROR_RANGE_2);
    }

    if (minNonAlphanumericNumber !== undefined && !isBetween(minNonAlphanumericNumber, 0, 128)) {
      errors.set('minNonAlphanumeric', ERROR_RANGE_2);
    }
    // Checking that the min number of non-alphanumeric chars < max number of non-alphanumeric chars
    if (!errors.has('maxNonAlphanumeric') && !errors.has('minNonAlphanumeric')) {
      if (
        maxNonAlphanumericNumber !== undefined &&
        minNonAlphanumericNumber !== undefined &&
        maxNonAlphanumericNumber < minNonAlphanumericNumber
      ) {
        errors.set('maxNonAlphanumeric', ERROR_MAX_NON_ALPHANUMERIC);
      }
    }

    return errors;
  };

  const validateLowercaseLetters = () => {
    const errors = new Map();

    const minLowercaseNumber = castValueToNumber(minLowercase);
    const maxLowercaseNumber = castValueToNumber(maxLowercase);
    // Range of the max and min number of lowercase letters
    if (maxLowercaseNumber !== undefined && !isBetween(maxLowercaseNumber, 0, 128)) {
      errors.set('maxLowercase', ERROR_RANGE_2);
    }

    if (minLowercaseNumber !== undefined && !isBetween(minLowercaseNumber, 0, 128)) {
      errors.set('minLowercase', ERROR_RANGE_2);
    }
    // Checking that the min number of lowercase letters < max number of lowercase letters
    if (!errors.has('maxLowercase') && !errors.has('minLowercase')) {
      if (
        !maxLowercaseNumber !== undefined &&
        !minLowercaseNumber !== undefined &&
        maxLowercaseNumber < minLowercaseNumber
      ) {
        errors.set('maxLowercase', ERROR_MAX_LOWERCASE);
      }
    }

    return errors;
  };

  const validateUppercaseLetters = () => {
    const errors = new Map();

    const minUppercaseNumber = castValueToNumber(minUppercase);
    const maxUppercaseNumber = castValueToNumber(maxUppercase);
    // Range of the max and min number of uppercase letters
    if (maxUppercaseNumber !== undefined && !isBetween(maxUppercaseNumber, 0, 128)) {
      errors.set('maxUppercase', ERROR_RANGE_2);
    }

    if (minUppercaseNumber !== undefined && !isBetween(minUppercaseNumber, 0, 128)) {
      errors.set('minUppercase', ERROR_RANGE_2);
    }
    // Checking that the min number of uppercase letters < max number of uppercase letters
    if (!errors.has('maxUppercase') && !errors.has('minUppercase')) {
      if (
        maxUppercaseNumber !== undefined &&
        minUppercaseNumber !== undefined &&
        maxUppercaseNumber < minUppercaseNumber
      ) {
        errors.set('maxUppercase', ERROR_MAX_UPPERCASE);
      }
    }

    return errors;
  };

  const validateMinOfEachCharType = () => {
    const minLengthNumber = castValueToNumber(minLength);
    const minDigitsNumber = castValueToNumber(minDigits);
    const minNonAlphanumericNumber = castValueToNumber(minNonAlphanumeric);
    const minUppercaseNumber = castValueToNumber(minUppercase);
    const minLowercaseNumber = castValueToNumber(minLowercase);

    const errors = new Map();
    // Checking that the min number of digits is enough to fulfill other minimal requirements
    if (
      !errors.has('minLength') &&
      !errors.has('minDigits') &&
      !errors.has('minNonAlphanumeric') &&
      !errors.has('minUppercase') &&
      !errors.has('minLowercase')
    ) {
      const minToFulfillMinChars = minDigitsNumber + minNonAlphanumericNumber + minUppercaseNumber + minLowercaseNumber;

      if (minLengthNumber < minToFulfillMinChars) {
        const ERROR_MIN_LENGTH = t('organization.organization-settings.password-policy.errors.min-length', {
          min_length: minLengthNumber,
          min_required: minToFulfillMinChars,
        });
        errors.set('minLength', ERROR_MIN_LENGTH);
      }
    }

    return errors;
  };

  const validateSeqStr = () => {
    const errors = new Map();

    const maxLengthSeqStringsNumber = castValueToNumber(maxLengthSeqStrings);
    // Range of the max length of sequential strings
    if (maxLengthSeqStringsNumber !== undefined && !isBetween(maxLengthSeqStringsNumber, 3, 128)) {
      errors.set('maxLengthSeqStrings', ERROR_RANGE_3);
    }

    return errors;
  };

  const validateRepeatedChars = () => {
    const errors = new Map();

    const maxLengthRepeatedCharsNumber = castValueToNumber(maxLengthRepeatedChars);
    // Range of the max length of repeated chars
    if (maxLengthRepeatedCharsNumber !== undefined && !isBetween(maxLengthRepeatedCharsNumber, 2, 128)) {
      errors.set('maxLengthRepeatedChars', ERROR_RANGE_4);
    }

    return errors;
  };

  const validatePasswordReuse = () => {
    const errors = new Map();

    const maxRepetitionsNumber = castValueToNumber(maxRepetitions);
    // Range of the number of passwords whose reuse must be forbidden
    if (maxRepetitionsNumber !== undefined && !isBetween(maxRepetitionsNumber, 0, 20)) {
      errors.set('maxRepetitions', ERROR_RANGE_5);
    }

    return errors;
  };

  const validatePasswordExpiration = () => {
    const errors = new Map();

    if (enablePasswordExpiration && !passwordExpirationPeriod) {
      errors.set('passwordExpirationPeriod', ERROR_PASSWORD_EXPIRATION_PERIOD);
    }

    return errors;
  };

  const buildPasswordPolicy = () => {
    const policy = new PasswordPolicyBuilder();

    const minLengthNumber = castValueToNumber(minLength);
    const maxLengthNumber = castValueToNumber(maxLength);

    const minDigitsNumber = castValueToNumber(minDigits);
    const maxDigitsNumber = castValueToNumber(maxDigits);

    const minNonAlphanumericNumber = castValueToNumber(minNonAlphanumeric);
    const maxNonAlphanumericNumber = castValueToNumber(maxNonAlphanumeric);

    const minUppercaseNumber = castValueToNumber(minUppercase);
    const maxUppercaseNumber = castValueToNumber(maxUppercase);

    const minLowercaseNumber = castValueToNumber(minLowercase);
    const maxLowercaseNumber = castValueToNumber(maxLowercase);

    const maxLengthSeqStringsNumber = castValueToNumber(maxLengthSeqStrings);

    const similarityRatioNumber = castValueToNumber(similarityRatio);

    const maxLengthRepeatedCharsNumber = castValueToNumber(maxLengthRepeatedChars);

    const maxRepetitionsNumber = castValueToNumber(maxRepetitions);

    policy.addLengthPolicy(minLengthNumber, maxLengthNumber);
    policy.addDigitsPolicy(minDigitsNumber, maxDigitsNumber);
    policy.addNonAlphanumericPolicy(minNonAlphanumericNumber, maxNonAlphanumericNumber);
    policy.addUppercasePolicy(minUppercaseNumber, maxUppercaseNumber);
    policy.addLowercasePolicy(minLowercaseNumber, maxLowercaseNumber);
    policy.addForbiddenWordsPolicy(listForbiddenWords, caseSensitiveForbiddenWords);
    policy.addSequentialStringPolicy(
      maxLengthSeqStringsNumber,
      loopingSeqStrings,
      reverseOrderSeqStrings,
      repeatedLettersSeqStrings,
      caseSensitiveSeqStrings,
    );
    policy.addCommonPasswordsPolicy(excludeCommonPasswords);
    policy.addExcludePersonalInformation(personalData, similarityRatioNumber);
    policy.addRepeatedCharSequencesPolicy(maxLengthRepeatedCharsNumber, caseSensitiveRepeatedChars);
    policy.addPasswordReusePolicy(maxRepetitionsNumber);

    return policy;
  };

  const onSubmit = () => {
    const errors = new Map([
      ...validateLength(),
      ...validateDigits(),
      ...validateNonAlphanumeric(),
      ...validateLowercaseLetters(),
      ...validateUppercaseLetters(),
      ...validateMinOfEachCharType(),
      ...validateSeqStr(),
      ...validateRepeatedChars(),
      ...validatePasswordReuse(),
      ...validatePasswordExpiration(),
    ]);

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      const policy = buildPasswordPolicy();

      setIsSubmitting(true);

      updateOrganization(orgId, {
        password_policy: policy.passwordPolicy,
        enable_password_renewal: enablePasswordExpiration,
        password_renewal_period: passwordExpirationPeriod,
        skip_password_renewal: numberSkips === '' ? null : numberSkips,
        skip_password_renewal_period: skippingPeriod === '' ? null : skippingPeriod,
      })
        .then(() => {
          setIsSubmitting(false);
          dispatch(showSuccessToast('organization.organization-settings.save-success-message'));
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(showErrorToast('organization.organization-settings.save-failure-message'));
        });
    } else {
      dispatch(showErrorToast('messages.fix-fields'));
    }
  };

  return (
    <App title={name}>
      <Fade>
        <Box maxWidth="428px">
          <Subtitle>{t('organization.organization-settings.password-policy.subtitle')}</Subtitle>

          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-settings.password-policy.text')}
          </Typography>

          <SectionLoader loading={!organization}>
            <Fade timeout={1000}>
              <PolicyTemplates onSelect={fillFormWithTemplate} />

              <LengthPolicyForm
                minLength={minLength}
                minLengthError={errors.get('minLength')}
                maxLength={maxLength}
                maxLengthError={errors.get('maxLength')}
                onChange={(min, max) => {
                  setMinLength(min);
                  setMaxLength(max);
                }}
              />

              <NonAlphanumericPolicyForm
                minNonAlphanumeric={minNonAlphanumeric}
                minNonAlphanumericError={errors.get('minNonAlphanumeric')}
                maxNonAlphanumeric={maxNonAlphanumeric}
                maxNonAlphanumericError={errors.get('maxNonAlphanumeric')}
                onChange={(min, max) => {
                  setMinNonAlphanumeric(min);
                  setMaxNonAlphanumeric(max);
                }}
              />

              <DigitsPolicyForm
                minDigits={minDigits}
                minDigitsError={errors.get('minDigits')}
                maxDigits={maxDigits}
                maxDigitsError={errors.get('maxDigits')}
                onChange={(min, max) => {
                  setMinDigits(min);
                  setMaxDigits(max);
                }}
              />

              <UppercasePolicyForm
                minUppercase={minUppercase}
                minUppercaseError={errors.get('minUppercase')}
                maxUppercase={maxUppercase}
                maxUppercaseError={errors.get('maxUppercase')}
                onChange={(min, max) => {
                  setMinUppercase(min);
                  setMaxUppercase(max);
                }}
              />

              <LowercasePolicyForm
                minLowercase={minLowercase}
                minLowercaseError={errors.get('minLowercase')}
                maxLowercase={maxLowercase}
                maxLowercaseError={errors.get('maxLowercase')}
                onChange={(min, max) => {
                  setMinLowercase(min);
                  setMaxLowercase(max);
                }}
              />

              <SequentialTextPolicyForm
                maxLength={maxLengthSeqStrings}
                maxLengthError={errors.get('maxLengthSeqStrings')}
                caseSensitiveSeqStrings={caseSensitiveSeqStrings}
                reverseOrderSeqStrings={reverseOrderSeqStrings}
                repeatedLettersSeqStrings={repeatedLettersSeqStrings}
                loopingSeqStrings={loopingSeqStrings}
                onChange={(
                  maxLengthSeqStrings,
                  caseSensitiveSeqStrings,
                  reverseOrderSeqStrings,
                  repeatedLettersSeqStrings,
                  loopingSeqStrings,
                ) => {
                  setMaxLengthSeqStrings(maxLengthSeqStrings);
                  setCaseSensitiveSeqStrings(caseSensitiveSeqStrings);
                  setReverseOrderSeqStrings(reverseOrderSeqStrings);
                  setRepeatedLettersSeqStrings(repeatedLettersSeqStrings);
                  setLoopingSeqStrings(loopingSeqStrings);
                }}
              />

              <PersonalDataPolicyForm
                personalData={personalData}
                similarityRatio={similarityRatio}
                onChange={(personalData, similarityRatio) => {
                  setPersonalData(personalData);
                  setSimilarityRatio(similarityRatio);
                }}
              />

              <ForbiddenWordsPolicyForm
                forbiddenWord={forbiddenWord}
                forbiddenWords={listForbiddenWords}
                caseSensitiveForbiddenWords={caseSensitiveForbiddenWords}
                onChange={(forbiddenWord, forbiddenWords, caseSensitiveForbiddenWords) => {
                  setForbiddenWord(forbiddenWord);
                  setListForbiddenWords(forbiddenWords);
                  setCaseSensitiveForbiddenWords(caseSensitiveForbiddenWords);
                }}
              />

              <CommonPasswordsForm
                enabled={excludeCommonPasswords}
                onChange={() => setExcludeCommonPasswords(!excludeCommonPasswords)}
              />

              <RepeatedCharsPolicyForm
                maxLength={maxLengthRepeatedChars}
                maxLengthError={errors.get('maxLengthRepeatedChars')}
                isCaseSensitive={caseSensitiveRepeatedChars}
                onChange={(maxLength, isCaseSensitive) => {
                  setMaxLengthRepeatedChars(maxLength);
                  setCaseSensitiveRepeatedChars(isCaseSensitive);
                }}
              />

              <PasswordReusePolicyForm
                maxRepetitions={maxRepetitions}
                maxRepetitionError={errors.get('maxRepetitions')}
                onChange={(maxRepetitions) => setMaxRepetitions(maxRepetitions)}
              />

              <PasswordExpirationForm
                enable={enablePasswordExpiration}
                expirationPeriod={passwordExpirationPeriod}
                expirationPeriodError={errors.get('passwordExpirationPeriod')}
                numberSkips={numberSkips}
                skippingPeriod={skippingPeriod}
                onChange={(enablePasswordExpiration, passwordExpirationPeriod, numberSkips, skippingPeriod) => {
                  setEnablePasswordExpiration(enablePasswordExpiration);
                  setPasswordExpirationPeriod(passwordExpirationPeriod);
                  setNumberSkips(numberSkips);
                  setSkippingPeriod(skippingPeriod);
                }}
              />

              <Box sx={{ mt: '40px' }}>
                <Button loading={isSubmitting} variant="outlined" onClick={onSubmit} data-testid="save-button">
                  {t('organization.organization-settings.password-policy.submit-button-text')}
                </Button>
                <Button
                  sx={{ marginLeft: 2 }}
                  variant="outlined"
                  onClick={() => navigate('/braincare/organizations')}
                  data-testid="cancel-button"
                >
                  {t('organization.organization-settings.password-policy.cancel-button-text')}
                </Button>
              </Box>
            </Fade>
          </SectionLoader>
        </Box>
      </Fade>
    </App>
  );
}

export default braincare(AdminOrganizationPasswordPolicy);
