import { useState } from 'react';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import App from 'pages/organization/settings/App';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMemberOrganization } from 'api/portal';
import { setActiveOrganizationData, setOrganizations } from 'features/userSlice';
import ChipInput from 'components/ChipInput';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { isValidIpAddress } from 'utils/network';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';

function OrganizationIpBlocking() {
  const organization = useSelector((state) => state.user.active_organization);
  const organizations = useSelector((state) => state.user.organizations);

  const [enableIpBlocking, setEnableIpBlocking] = useState(organization.enable_ip_blocking);
  const [ipAllowed, setIpAllowed] = useState('');
  const [listIpAllowed, setListIpAllowed] = useState(organization.ips_allowed || []);

  const [errors, setErrors] = useState(new Map());

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const IP_BLOCKING_EMPTY = t('organization.organization-settings.ip-blocking-policy.ip-blocking-empty');

  const removeIpAllowed = (ip) => {
    setListIpAllowed(listIpAllowed.filter((value) => value !== ip));
  };

  const addIpAllowed = (event) => {
    if (event.key === 'Enter') {
      const ip = event.target.value.trim();

      if (!isValidIpAddress(ip)) {
        dispatch(showErrorToast('organization.organization-settings.ip-blocking-policy.error-invalid-ip'));
        return;
      }

      if (!listIpAllowed.includes(ip)) {
        setListIpAllowed([...listIpAllowed, ip]);
        setIpAllowed('');
      } else {
        dispatch(showErrorToast('organization.organization-settings.ip-blocking-policy.error-ip-exist'));
      }
    }
  };

  const validateForm = () => {
    const errors = new Map();

    if (enableIpBlocking && listIpAllowed.length === 0) {
      errors.set('ipAllowed', IP_BLOCKING_EMPTY);
    }

    return errors;
  };

  const onSubmit = () => {
    const errors = validateForm();

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      updateMemberOrganization({
        enable_ip_blocking: enableIpBlocking,
        ips_allowed: listIpAllowed.length > 0 ? listIpAllowed : null,
      })
        .then((response) => {
          const { data } = response;

          setIsSubmitting(false);

          dispatch(showSuccessToast('organization.organization-settings.save-success-message'));
          dispatch(setActiveOrganizationData(data));
          dispatch(
            setOrganizations(
              organizations.map((item) => {
                if (item.id === data.id) {
                  return data;
                }

                return item;
              }),
            ),
          );
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(showErrorToast('organization.organization-settings.save-failure-message'));
        });
    } else {
      dispatch(showErrorToast('messages.fix-fields'));
    }
  };

  return (
    <App>
      <Fade>
        <Box maxWidth="428px" marginBottom="48px">
          <Subtitle>{t('organization.organization-settings.ip-blocking-policy.ip-blocking-subtitle')}</Subtitle>

          <Typography variant="body2" color="secondary">
            {t('organization.organization-settings.ip-blocking-policy.ip-blocking-text')}
          </Typography>

          <Section>
            <FormControlLabel
              sx={{ marginBottom: '16px' }}
              label={t('organization.organization-settings.ip-blocking-policy.ip-blocking-enable-checkbox')}
              control={
                <Checkbox
                  checked={enableIpBlocking}
                  onClick={() => setEnableIpBlocking(!enableIpBlocking)}
                  inputProps={{ 'data-testid': 'ip-blocking-checkbox' }}
                />
              }
            />

            <ChipInput
              items={listIpAllowed}
              label={t('organization.organization-settings.ip-blocking-policy.ip-blocking-label')}
              onCloseItem={(item) => removeIpAllowed(item)}
              onChange={(event) => setIpAllowed(event.target.value)}
              errorMessage={errors.has('ipAllowed') ? errors.get('ipAllowed') : ''}
              hasError={errors.has('ipAllowed')}
              onKeyPress={addIpAllowed}
              value={ipAllowed}
              data-testid="ip-allowed-input"
            />
          </Section>

          <Button variant="outlined" loading={isSubmitting} onClick={onSubmit} data-testid="save-button">
            {t('organization.organization-settings.ip-blocking-policy.submit-button-text')}
          </Button>
          <Button sx={{ marginLeft: 2 }} variant="outlined" onClick={() => navigate('/')} data-testid="cancel-button">
            {t('organization.organization-settings.ip-blocking-policy.cancel-button-text')}
          </Button>
        </Box>
      </Fade>
    </App>
  );
}

export default OrganizationIpBlocking;
