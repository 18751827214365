import { useEffect, useRef, useState } from 'react';
import Subtitle from 'components/Subtitle';
import MemberUnits from 'components/MemberUnits';
import { Box, Typography } from '@mui/material';
import App from 'pages/account/template';
import Permissions from 'components/Permissions';
import { fetchUnits } from 'api/portal';
import { useSelector } from 'react-redux';
import { STATUS_LOADING } from 'features/userSlice';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';

function CorporatePermissions() {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [units, setUnits] = useState([]);
  const [isLoadingMemberData, setIsLoadingMemberData] = useState(true);
  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const [errorUnits, setErrorUnits] = useState(false);

  const user = useSelector((state) => state.user);
  const ref = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('page_size', 9999);

    fetchUnits(searchParams)
      .then((response) => {
        setIsLoadingUnits(false);

        const { data } = response;

        setUnits(data.results);
        setErrorUnits(false);
      })
      .catch(() => {
        setIsLoadingUnits(false);
        setErrorUnits(true);
      });
  }, []);

  useEffect(() => {
    if (isLoadingMemberData && ref.current === STATUS_LOADING && user.status !== STATUS_LOADING) {
      const { roles, units } = user.organization_profile;

      setIsLoadingMemberData(false);

      setSelectedPermissions(roles.map((role) => role.id));
      setSelectedUnits(units.map((unit) => unit.id));
    }

    ref.current = user.status;
  }, [user.status]);

  return (
    <App>
      <Fade>
        <Box marginBottom="48px">
          <Subtitle>{t('account.member-edit-permissions.title-sub-me-permissions')}</Subtitle>
          <Typography variant="body2" color="secondary" marginBottom="32px">
            {t('account.member-edit-permissions.text-permissions')}
          </Typography>
          <Permissions selected={selectedPermissions} onToggle={() => {}} data-testid="list-roles" />
        </Box>
        <Box marginBottom="48px">
          <Subtitle>{t('account.member-edit-permissions.title-sub-me-units')}</Subtitle>
          <Typography variant="body2" color="secondary" marginBottom="16px">
            {t('account.member-edit-permissions.text-units')}
          </Typography>
          <MemberUnits
            error={errorUnits}
            isLoading={isLoadingUnits}
            onToggle={() => {}}
            selectedUnits={selectedUnits}
            units={units}
            data-testid="list-units"
          />
        </Box>
      </Fade>
    </App>
  );
}

export default CorporatePermissions;
