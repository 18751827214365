import { useState } from 'react';
import Header from 'components/dialogs/DialogHeader';
import Button from 'components/Button';
import { validatePassword as applyPolicyValidation } from 'utils/password-validators';
import i18next from 'i18next';
import { updatePasswordAtLogin } from 'api/portal';
import { useDispatch } from 'react-redux';
import { showErrorToast } from 'features/toastSlice';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import RuledPasswordInput from '../../RuledPasswordInput';

function UpdatePasswordDialog({ credentials, isOpen, onClose, onSuccess, passwordPolicy, skipSettings, type }) {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(undefined);

  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState(undefined);

  const [isConfirmedPasswordVisible, setIsConfirmedPasswordVisible] = useState(false);

  const [passwordPolicyErrors, setPasswordPolicyErrors] = useState(undefined);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const REQUIRED_FIELD_ERROR = t('account.edit-password.fill-required');
  const PASSWORD_MISMATCH_ERROR = t('account.edit-password.mismatch-error');

  const handleClose = () => {
    onClose();
    setPassword('');
    setPasswordError(undefined);
    setPasswordConfirmation('');
    setPasswordConfirmationError(undefined);
    setPasswordPolicyErrors(undefined);
  };

  const validatePassword = (password, errors) => {
    setPassword(password);

    const policyErrors = applyPolicyValidation(passwordPolicy, password);
    setPasswordPolicyErrors(policyErrors);

    if (!errors || errors.length === 0) {
      setPasswordError(undefined);
    } else {
      setPasswordError(policyErrors);
      return false;
    }

    if (password) {
      setPasswordError(undefined);
    } else {
      setPasswordError(REQUIRED_FIELD_ERROR);
      return false;
    }

    if (password === passwordConfirmation) {
      setPasswordConfirmationError(undefined);
    } else {
      setPasswordConfirmationError(PASSWORD_MISMATCH_ERROR);
      return false;
    }

    return true;
  };

  const validateConfirmedPassword = (confirmedPassword) => {
    setPasswordConfirmation(confirmedPassword);

    if (password === confirmedPassword) {
      setPasswordConfirmationError(undefined);
    } else {
      setPasswordConfirmationError(PASSWORD_MISMATCH_ERROR);
      return false;
    }

    return true;
  };

  const onSubmit = (password, skip) => {
    if (skip) {
      setIsSkipping(true);
    } else {
      if (password === credentials.password) {
        dispatch(showErrorToast('account.edit-password.old-equal-new-password'));
        return;
      }

      setIsSubmitting(true);
    }

    updatePasswordAtLogin({
      document: credentials.document,
      password: credentials.password,
      org_id: credentials.orgId,
      new_password: password,
      skip,
    })
      .then((response) => onSuccess(Promise.resolve(response)))
      .catch((error) => {
        if (error) {
          const nonFieldErrors = error.non_field_errors;

          if (Array.isArray(nonFieldErrors)) {
            const policyErrors = nonFieldErrors.map((passwordError) => passwordError.split(']')[0].split('[')[1]);
            setPasswordPolicyErrors(policyErrors);
          }
        }
      })
      .finally(() => {
        setIsSkipping(false);
        setIsSubmitting(false);
      });
  };

  const handleSubmit = (skip) => {
    if (!skip) {
      const isPasswordValid = validatePassword(password, passwordError);
      const isConfirmedPasswordValid = validateConfirmedPassword(passwordConfirmation);

      if (isPasswordValid && isConfirmedPasswordValid) {
        onSubmit(password, skip);
      }

      return;
    }

    setPasswordError(undefined);
    setPasswordConfirmation(undefined);
    onSubmit(password, skip);
  };

  const renderSkipSettings = () => {
    if (skipSettings) {
      return (
        <Typography variant="body2" color="primary.main" marginBottom="24px" data-testid="skip-settings">
          {skipSettings.nb_skips ? t('auth.update-password.number-skips', { nb_skips: skipSettings.nb_skips }) : null}
          {skipSettings.skipping_deadline
            ? t('auth.update-password.deadline-skips', {
                deadline: new Date(skipSettings.skipping_deadline).toLocaleString(i18next.language),
              })
            : null}
          .
        </Typography>
      );
    }

    return null;
  };

  const getBodyText = () => {
    if (type === 'outdated') {
      return (
        <Typography marginBottom="16px" variant="body2" color="secondary" data-testid="outdated-body-text">
          {t('auth.update-password.text-main.outdated')}
        </Typography>
      );
    }

    if (type === 'temporary') {
      return (
        <Typography marginBottom="16px" variant="body2" color="secondary" data-testid="temporary-body-text">
          {t('auth.update-password.text-main.temporary')}
        </Typography>
      );
    }

    return undefined;
  };

  return (
    <Dialog open={isOpen} data-testid="update-password-dialog">
      <Header onClose={handleClose}>{t('auth.update-password.title-main')}</Header>
      <DialogContent>
        {getBodyText()}

        <RuledPasswordInput
          errors={passwordPolicyErrors}
          hasError={passwordError !== undefined}
          onChange={(event) => validatePassword(event.target.value)}
          passwordPolicy={passwordPolicy}
          value={password}
        />

        <FormControl error={passwordConfirmationError !== undefined} fullWidth>
          <InputLabel data-testid="password-confirmation-label">
            {t('auth.define-password.input-confirmed-password')}
          </InputLabel>
          <OutlinedInput
            name="password_confirmation"
            value={passwordConfirmation}
            onChange={(event) => validateConfirmedPassword(event.target.value)}
            onBlur={(event) => validateConfirmedPassword(event.target.value)}
            type={isConfirmedPasswordVisible ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsConfirmedPasswordVisible(!isConfirmedPasswordVisible)}
                  edge="end"
                >
                  {isConfirmedPasswordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{ 'data-testid': 'password-confirmation-input' }}
          />
          <FormHelperText data-testid="password-confirmation-error">{passwordConfirmationError}</FormHelperText>
        </FormControl>

        {renderSkipSettings()}
      </DialogContent>
      <DialogActions>
        <Button
          color="gradient"
          onClick={() => handleSubmit(false)}
          loading={isSubmitting}
          disabled={isSkipping}
          data-testid="update-button"
        >
          {t('auth.update-password.button-update')}
        </Button>
        {skipSettings ? (
          <Button
            sx={{ margin: '0 0 0 16px' }}
            variant="outlined"
            onClick={() => handleSubmit(true)}
            loading={isSkipping}
            disabled={isSubmitting}
            data-testid="skip-button"
          >
            {t('auth.update-password.button-skip')}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

export default UpdatePasswordDialog;
