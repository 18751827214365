import PropTypes from 'prop-types';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumbs = ({ children, ...props }) => (
  <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} {...props} aria-label="breadcrumb">
    {children}
  </MuiBreadcrumbs>
);

Breadcrumbs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Breadcrumbs;
