import { Box, Fade as MuiFade } from '@mui/material';

function Fade({ children, ...props }) {
  return (
    <MuiFade in timeout={500} {...props}>
      <Box>{children}</Box>
    </MuiFade>
  );
}

export default Fade;
