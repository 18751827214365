import i18next from 'i18next';
import PasswordValidator from './PasswordValidator';

function isEdgeCase(char, currentSeqSubstr) {
  const len = currentSeqSubstr.length;

  const isUppercaseEdgeCase = char === 'A' && currentSeqSubstr[len - 1] === 'Z';
  const isLowercaseEdgeCase = char === 'a' && currentSeqSubstr[len - 1] === 'z';
  const isDigitEdgeCase = char === '0' && currentSeqSubstr[len - 1] === '9';

  return isUppercaseEdgeCase || isLowercaseEdgeCase || isDigitEdgeCase;
}

function findSequentialSubstrs(password, maxLength, sequentialStringsConstraint) {
  const isCaseSensitive = sequentialStringsConstraint.is_case_sensitive;
  const { looping } = sequentialStringsConstraint;
  const repeatedLetters = sequentialStringsConstraint.repeated_letters;

  const passwordToBeUsed = isCaseSensitive ? password : password.toLowerCase();

  let currentSeqSubstr = '';
  const seqSubstr = [];

  [...passwordToBeUsed].forEach((char) => {
    const isDigit = '0'.charCodeAt() <= char.charCodeAt() <= '9'.charCodeAt();
    const isLowercase = 'a'.charCodeAt() <= char.charCodeAt() <= 'z'.charCodeAt();
    const isUppercase = 'A'.charCodeAt() <= char.charCodeAt() <= 'Z'.charCodeAt();
    // Checking if string is alphanumeric
    if (isDigit || isLowercase || isUppercase) {
      const lastPosition = currentSeqSubstr.length - 1;
      // If no sequential string was started to be built, insert the current char as the first one
      if (currentSeqSubstr.length === 0) {
        currentSeqSubstr = char;
      } else if (
        char.charCodeAt() === currentSeqSubstr[lastPosition].charCodeAt() + 1 ||
        (looping && isEdgeCase(char, currentSeqSubstr)) ||
        (repeatedLetters && char.charCodeAt() === currentSeqSubstr[lastPosition].charCodeAt())
      ) {
        // If the current char is a continuation for the current sequence, append it
        currentSeqSubstr += char;
      } else {
        // If the sequence was broken, check if the sequential string is longer than the threshold
        if (currentSeqSubstr.length > maxLength) {
          seqSubstr.push(currentSeqSubstr);
        }
        // Start building the next potential sequence
        currentSeqSubstr = char;
      }
    } else {
      // If the sequence was broken, check if the sequential string is longer than the threshold
      if (currentSeqSubstr.length > maxLength) {
        seqSubstr.push(currentSeqSubstr);
      }
      // Resetting search
      currentSeqSubstr = '';
    }
  });

  if (currentSeqSubstr.length > maxLength) {
    seqSubstr.push(currentSeqSubstr);
  }

  return seqSubstr;
}

function reverseString(str) {
  let reversedStr = '';
  const { length } = str;

  for (let i = length; i--; i > 0) {
    reversedStr += str[i];
  }

  return reversedStr;
}

export default class SequentialStringsValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { sequentialStrings } = passwordPolicy;

    const { max } = sequentialStrings;

    let translation = null;

    if (max !== undefined) {
      translation = i18next.t('organization.password-rules.sequential-strings-constraint', { max });
    }

    super(sequentialStrings, 'sequential_strings', false, translation);
  }

  validate(password) {
    if (this.policy) {
      const max = this.policy.max ? this.policy.max : 128;

      const reverseOrder = this.policy.reverse_order;

      const sequentialStrings = findSequentialSubstrs(password, max, this.policy);

      if (reverseOrder) {
        const reversedPassword = reverseString(password);

        const sequentialStringsReverseOrder = findSequentialSubstrs(reversedPassword, max, this.policy);

        sequentialStringsReverseOrder.forEach((item) => sequentialStrings.push(reverseString(item)));
      }

      if (sequentialStrings.length > 0) {
        return false;
      }
    }

    return true;
  }
}
