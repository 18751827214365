import PropTypes from 'prop-types';
import Header from 'components/dialogs/DialogHeader';
import { Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Typography } from '@mui/material';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const TagsSelectDialog = ({ tags = [], selectedTags = [], show = false, onCheckboxClick, onDialogToggle }) => {
  const { t } = useTranslation();

  const memberList = tags.map((tag) => (
    <label key={`member-${tag}`} htmlFor={tag} className={styles.toggleMember}>
      <div className={styles.profileCheckbox}>
        <Typography variant="body2" color="secondary">
          {tag}
        </Typography>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedTags.includes(tag)}
            onChange={() => onCheckboxClick(tag)}
            data-testid={`tag-${tag}`}
          />
        }
      />
    </label>
  ));

  return (
    <Dialog open={show}>
      <Header>{t('physio.physio-personal.dialog-filter-by-tag')}</Header>
      <DialogContent>
        <div className={styles.profileCheckboxWrapper}>{memberList}</div>
        <Typography variant="body2" color="secondary" marginTop="16px">
          {t('physio.dialog-filter-by-tag.box-counter', { count: selectedTags.length })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth color="gradient" onClick={onDialogToggle} data-testid="save-button">
          {t('physio.dialog-filter-by-tag.button-save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TagsSelectDialog.propTypes = {
  tags: PropTypes.instanceOf(Array),
  selectedTags: PropTypes.instanceOf(Array),
  show: PropTypes.bool,
  onCheckboxClick: PropTypes.func.isRequired,
  onDialogToggle: PropTypes.func.isRequired,
};

export default TagsSelectDialog;
