import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
};

const applicationsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    clearApplications() {
      return initialState;
    },
    setApplications(state, action) {
      return {
        ...state,
        data: action.payload.data,
        isLoading: action.payload.isLoading,
      };
    },
  },
});

export const { clearApplications, setApplications } = applicationsSlice.actions;

export default applicationsSlice.reducer;
