import { useState } from 'react';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import SubSubtitle from 'components/SubSubtitle';
import Loader from 'components/SectionLoader';
import { useTranslation } from 'react-i18next';
import App from 'pages/organization/members/template';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearOrganizationSelectedMember, setOrganizationSelectedMember } from 'features/organizationSlice';
import { deleteMember, updateMember } from 'api/portal';
import { Alert, Typography } from '@mui/material';
import Button from 'components/Button';
import { showErrorToast } from 'features/toastSlice';
import { useFetchMember } from 'hooks/useFetchMember';
import SuspendMemberDialog from 'components/dialogs/SuspendMemberDialog';
import DeleteMemberDialog from 'components/dialogs/DeleteMemberDialog';
import Fade from 'components/Fade';

function MemberSuspension() {
  const [member, setMember] = useState({});

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isToShowSuspendDialog, setIsToShowSuspendDialog] = useState(false);
  const [isToShowDeleteDialog, setIsToShowDeleteDialog] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { memberId } = params;

  useFetchMember({
    memberId,
    onSuccess: (data) => {
      setMember(data);
    },
    onFailure: () => {
      dispatch(showErrorToast('messages.load-failure'));
      navigate('/organization/members');
    },
  });

  const changeAccountStatus = (newStatus) => {
    setIsUpdating(true);
    setIsToShowSuspendDialog(false);

    updateMember(memberId, { status: newStatus })
      .then((response) => {
        setIsUpdating(false);

        const { data } = response;

        const newStatus = data.status;

        dispatch(setOrganizationSelectedMember(newStatus));
        setMember({
          ...member,
          status: newStatus,
        });
      })
      .catch(() => {
        dispatch(showErrorToast('organization.organization-members-edit-suspend-exclude-toasts.update-failure'));
      });
  };

  const deleteMemberAccount = () => {
    setIsDeleting(true);
    setIsToShowDeleteDialog(false);

    deleteMember(memberId)
      .then(() => {
        setIsDeleting(false);
        dispatch(clearOrganizationSelectedMember());
        navigate('/organization/members');
      })
      .catch(() => {
        dispatch(showErrorToast('organization.organization-members-edit-suspend-exclude-toasts.delete-failure'));
      });
  };

  const renderSuspendAccount = () => (
    <div>
      <SubSubtitle>{t('organization.organization-members-edit-suspend.title-sub-member-suspend')}</SubSubtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-members-edit-suspend.text-organization-members-suspend')}
      </Typography>
      <Button
        variant="outlined"
        loading={!member.id || isUpdating}
        onClick={() => setIsToShowSuspendDialog(true)}
        data-testid="suspend-button"
      >
        {t('organization.organization-members-edit-suspend.button-member-suspend')}
      </Button>
    </div>
  );

  const renderUnsuspendAccount = () => (
    <Alert variant="outlined" icon={false} severity="warning">
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-members-edit-suspend-revert.title-box-member-suspended')}
      </Typography>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-members-edit-suspend-revert.text-box-organization-member-suspend')}
      </Typography>
      <Button
        variant="outlined"
        color="warning"
        onClick={() => changeAccountStatus('ACTIVE')}
        data-testid="unsuspend-button"
      >
        {t('organization.organization-members-edit-suspend-revert.button-suspend-revert')}
      </Button>
    </Alert>
  );

  const renderStatusSection = () => (member.status === 'SUSPENDED' ? renderUnsuspendAccount() : renderSuspendAccount());

  return (
    <App>
      <Fade>
        <Subtitle>{t('organization.organization-members-edit-suspend.title-main-member-suspend')}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-members-edit-suspend.text-organization-members-suspend-exclude')}
        </Typography>
        <Section>
          <Loader loading={!member.id || isUpdating}>{renderStatusSection()}</Loader>
        </Section>

        <Section>
          <SubSubtitle>{t('organization.organization-members-edit-suspend.title-sub-member-exclude')}</SubSubtitle>
          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-members-edit-suspend.text-organization-members-exclude')}
          </Typography>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setIsToShowDeleteDialog(true)}
            loading={isDeleting}
            disabled={!member.id || isUpdating}
            data-testid="delete-button"
          >
            {t('organization.organization-members-edit-suspend.button-member-exclude')}
          </Button>
        </Section>
        <SuspendMemberDialog
          open={isToShowSuspendDialog}
          onClose={() => setIsToShowSuspendDialog(false)}
          onSuspend={() => changeAccountStatus('SUSPENDED')}
        />
        <DeleteMemberDialog
          open={isToShowDeleteDialog}
          onClose={() => setIsToShowDeleteDialog(false)}
          onDelete={deleteMemberAccount}
        />
        <Button variant="outlined" onClick={() => navigate('/organization/members')} data-testid="cancel-button">
          {t('organization.organization-members-edit-suspend.button-cancel')}
        </Button>
      </Fade>
    </App>
  );
}

export default MemberSuspension;
