import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const MenuItem = ({ children, sx, ...props }) => (
  <Box
    {...props}
    sx={{
      fontSize: '14px',
      transition: '0.2s ease-in-out',
      color: 'primary.dark',
      '&:hover': {
        color: 'primary.light',
      },
      '& .Mui-active': {
        color: 'primary.dark',
      },
      cursor: 'pointer',
      marginRight: '16px',
    }}
    display="flex"
    alignItems="center"
  >
    {children}
  </Box>
);

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MenuItem;
