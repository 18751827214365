import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import Hydra from 'components/Hydra';
import SectionLoader from 'components/SectionLoader';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';
import styles from './styles.module.css';

function UnitCard({ members, onClick, unit, ...props }) {
  const { t } = useTranslation();

  let avatarList = [];
  let count = 0;
  let loaded = false;

  if (members[unit.id]) {
    ({ count } = members[unit.id]);
    avatarList = members[unit.id].results.map((member) => (member.photo ? member.photo : ''));
    ({ loaded } = members[unit.id]);
  }

  return (
    <Card onClick={onClick} {...props}>
      <CardHeader
        title={
          <Typography variant="h6" color="primary.light">
            {unit.name}
          </Typography>
        }
        sx={{ padding: '0 0 4px 0' }}
        data-testid={`unit-${unit.id}`}
      />
      <CardContent sx={{ padding: '0' }}>
        <Typography variant="body2" color="primary.main" marginBottom="24px">
          {loaded
            ? t('organization.organization-units.card-member-members', { count })
            : t('organization.organization-units.card-member-members-loading')}
        </Typography>
        <SectionLoader className={styles.cardLoader} loading={!loaded}>
          <Fade>
            <Hydra avatarList={avatarList} count={count} />
          </Fade>
        </SectionLoader>
      </CardContent>
    </Card>
  );
}

export default UnitCard;
