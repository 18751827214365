import PropTypes from 'prop-types';
import AppMenu from 'components/templates/app-menu';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const App = ({ title, children }) => {
  const params = useParams();

  const { t } = useTranslation();

  const { pathname } = window.location;

  const { userId } = params;

  const HOME = t('braincare.breadcrumbs.home');
  const USERS = t('braincare.breadcrumbs.users');
  const TITLE = t('braincare.breadcrumbs.users-edit');

  const INFO = t('braincare.users-edit.menu-item-user-info');
  const SUSPEND = t('braincare.users-edit.menu-item-suspend');

  const INFO_URL = `/braincare/users/${userId}/info`;
  const SUSPEND_URL = `/braincare/users/${userId}/suspend`;

  const SIDE_MENU = [
    {
      href: INFO_URL,
      text: INFO,
      isActive: pathname === INFO_URL,
    },
    {
      href: SUSPEND_URL,
      text: SUSPEND,
      isActive: pathname === SUSPEND_URL,
    },
  ];

  const getBreadcrumb = (path) => {
    switch (path) {
      case INFO_URL:
        return INFO;
      case SUSPEND_URL:
        return SUSPEND;
      default:
        return '';
    }
  };

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/users',
      title: USERS,
    },
    {
      path: pathname,
      title: getBreadcrumb(pathname),
      isActive: true,
    },
  ];

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={title || TITLE} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default App;
