import { Box, Card, CardContent, Typography } from '@mui/material';
import appPlaceholder from 'assets/images/app-placeholder.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ApplicationCard({ application, ...props }) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Card onClick={() => navigate(application.href)} {...props}>
      <Box display="flex" paddingTop="8px" justifyContent="center">
        <img
          width="40px"
          height="40px"
          src={application.image || appPlaceholder}
          alt={application.name}
          data-testid="app-image"
        />
      </Box>
      <CardContent>
        <Box display="flex" justifyContent="center">
          <Typography color="primary.main" variant="body2" fontWeight={600} textAlign="center">
            {t(application.name)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ApplicationCard;
