import PropTypes from 'prop-types';
import IconSuccess from 'assets/images/ic_success.svg';
import IconWarning from 'assets/images/ic_warning.svg';
import IconError from 'assets/images/ic_error.svg';
import IconInfo from 'assets/images/ic_info.svg';
import { Box, DialogTitle, Grid, IconButton } from '@mui/material';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const getSvg = (type) => {
  switch (type) {
    case 'success':
      return IconSuccess;
    case 'warning':
      return IconWarning;
    case 'error':
      return IconError;
    default:
      return IconInfo;
  }
};

const DialogHeader = ({ children, type = 'default', onClose, ...props }) => (
  <DialogTitle
    sx={{
      padding: '0 0 24px 0',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: 1,
      color: 'var(--color-primary)',
    }}
    {...props}
  >
    {type !== 'default' ? (
      <Box
        sx={{
          width: '75px',
          height: '75px',
          margin: '0 auto 24px auto',
          backgroundColor: `var(--color-${type})`,
          maskImage: `url(${getSvg(type)})`,
        }}
      />
    ) : null}
    <Grid container alignItems="center">
      <Grid item flexGrow="1">
        {children}
      </Grid>
      {onClose && (
        <Grid item flexGrow="0">
          <IconButton onClick={onClose}>
            <CloseSharpIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  </DialogTitle>
);

DialogHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  type: PropTypes.string,
};

export default DialogHeader;
