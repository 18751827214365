import axios from 'axios';
import { ANALYTICS_URL, API_URL, CLIENT_PHI_URL, ENVIRONMENT, QA_TOKEN } from 'config/settings';
import Cookie from 'js-cookie';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, getToken } from 'utils/tokens';
import { jwtDecode } from 'jwt-decode';
import { debugLog } from 'utils/logging';

// Variable used to avoid concurrent refresh token requests
let refreshPromise;

const tokenInterceptor = async (config) => {
  const { url } = config;
  // Endpoints that don't require authentication via JWT token
  const publicEndpoints = new Map([
    ['/direct-login', true],
    ['/professions', true],
    ['/user/reset-password', true],
    ['/member/reset-password', true],
    ['/confirm-password', true],
    ['/signup', true],
    ['/password-policy', true],
    ['/edu/login', true],
    ['/edu/signup', true],
    ['/edu/reset-password', true],
    ['/logout', true],
    ['/auth/update-password', true],
  ]);

  if (!publicEndpoints.has(url)) {
    const accessToken = getToken(ACCESS_TOKEN_KEY);

    let isExpired = true;

    try {
      const currentTime = new Date().getTime() / 1000;
      const decoded = jwtDecode(accessToken);
      isExpired = currentTime + 60 > decoded.exp;
    } catch {
      isExpired = true;
    }

    if (isExpired) {
      const refreshToken = Cookie.get(REFRESH_TOKEN_KEY);
      // Request to refresh the token of the current session
      try {
        refreshPromise ??= axios.post(`${API_URL}/refresh`, {
          access_token: accessToken,
          refresh_token: refreshToken,
        });
        const response = await refreshPromise;

        const { data } = response;

        Cookie.set(ACCESS_TOKEN_KEY, data.access_token, { sameSite: 'strict', expires: 365 });
        Cookie.set(REFRESH_TOKEN_KEY, data.refresh_token, { sameSite: 'strict', expires: 365 });

        refreshPromise = undefined;

        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${data.access_token}`,
          },
        };
      } catch (e) {
        refreshPromise = undefined;
        debugLog('Logout', e);
        window.location.assign('/auth/logout');
      }
    }
  }

  return config;
};

export const portalApi = ({ statusException } = { statusException: true }) => {
  const config = { baseURL: API_URL };

  const token = getToken(ACCESS_TOKEN_KEY);

  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }

  if (!statusException) {
    config.validateStatus = () => true;
  }

  const instance = axios.create(config);
  instance.interceptors.request.use(tokenInterceptor);

  return instance;
};

export const clientPhiApi = (orgId, statusException = false) => {
  const token = ENVIRONMENT === 'dev' ? QA_TOKEN : getToken(ACCESS_TOKEN_KEY);

  const config = {
    baseURL: CLIENT_PHI_URL.replace('orgId', orgId),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (!statusException) {
    config.validateStatus = () => true;
  }

  const instance = axios.create(config);
  instance.interceptors.request.use(tokenInterceptor);

  return instance;
};

export const analyticsApi = ({ statusException } = { statusException: true }) => {
  const token = ENVIRONMENT === 'dev' ? QA_TOKEN : getToken(ACCESS_TOKEN_KEY);

  const config = {
    baseURL: ANALYTICS_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (!statusException) {
    config.validateStatus = () => true;
  }

  const instance = axios.create(config);
  instance.interceptors.request.use(tokenInterceptor);

  return instance;
};
