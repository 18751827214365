import App from 'components/templates/app';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import ApplicationCard from 'components/cards/ApplicationCard';
import AuthTitle from 'components/AuthTitle';
import homeImage from 'assets/images/dashboard-home-image.png';
import IcpCurve from 'assets/images/icp-curve.png';
import { useSelector } from 'react-redux';
import SectionLoader from 'components/SectionLoader';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';
import styles from './styles.module.css';

function Home() {
  const applications = useSelector((state) => state.applications);

  const { t } = useTranslation();

  return (
    <App>
      <Fade>
        <Box
          sx={{
            margin: '40px 0',
            height: 'calc(100% - 48px)',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Grid sx={{ width: '100%', maxWidth: '720px' }} container data-testid="welcome-banner">
            <Grid sx={{ display: 'flex', justifyContent: 'center' }} item xs={12} md={4}>
              <Box sx={{ marginBottom: { xs: '16px', md: '0' } }} position="relative">
                <Avatar sx={{ width: 200, height: 200 }} src={homeImage} alt="profile image" />
                <img alt="icp-curve" className={styles.curve} src={IcpCurve} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <AuthTitle>{t('auth.account-personal.title-main-account-center')}</AuthTitle>
              <Typography variant="body2" color="secondary" marginBottom="52px">
                {t('auth.account-personal.text-account-center')}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, marginTop: '32px', width: '100%', maxWidth: '720px' }}>
            <SectionLoader loading={applications.isLoading}>
              <Grid container rowSpacing="4px" columnSpacing="4px">
                {applications.data.map((application) => (
                  <Grid key={application.id} item xs={12} sm={4}>
                    <ApplicationCard
                      sx={{
                        height: '100%',
                      }}
                      application={application}
                      data-testid={`application-card-${application.id}`}
                    />
                  </Grid>
                ))}
              </Grid>
            </SectionLoader>
          </Box>
        </Box>
      </Fade>
    </App>
  );
}

export default Home;
