import { useEffect, useState } from 'react';
import Subtitle from 'components/Subtitle';
import { useNavigate, useParams } from 'react-router-dom';
import { adminSearchUser } from 'api/portal';
import Button from 'components/Button';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  TextField,
  Box,
} from '@mui/material';
import Autocomplete from 'components/Autocomplete';
import { formatToCpfUsername } from 'utils/format';
import { isCpf } from 'utils/validation';
import { useDispatch } from 'react-redux';
import { showErrorToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';

function PersonalInfo({ errors, initialData, onSave }) {
  const [userId, setUserId] = useState(initialData.userId);

  const [users, setUsers] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);

  const [username, setUsername] = useState(initialData.document);
  const [firstName, setFirstName] = useState(initialData.firstName);
  const [lastName, setLastName] = useState(initialData.lastName);
  const [birthYear, setBirthYear] = useState(initialData.birthYear);
  const [notificationLanguage, setNotificationLanguage] = useState(initialData.notificationLanguage);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const params = useParams();
  const { orgId } = params;

  const clearUserData = () => {
    setSelectedUser(null);
    setUserId(null);
    setFirstName('');
    setLastName('');
    setBirthYear('');
    setNotificationLanguage('pt-br');
  };

  useEffect(() => {
    adminSearchUser(orgId)
      .then((response) => {
        setUsers(response.data);
      })
      .catch(() => {
        dispatch(showErrorToast('messages.fix-fields'));
      });
  }, []);

  const onSelectUser = (value) => {
    if (value) {
      setSelectedUser(value);
      setUserId(value.id);
      setFirstName(value.first_name);
      setLastName(value.last_name);
      return;
    }

    clearUserData();
  };

  const userOptions = users.map((user) => ({
    ...user,
    label: `${user.first_name} ${user.last_name}`,
  }));

  return (
    <>
      <Box maxWidth="428px" marginBottom="48px">
        <Subtitle>{t('organization.organization-members-create.title-sub-member-username')}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-members-create.text-member-create-search-name')}
        </Typography>

        <Autocomplete
          disablePortal
          value={selectedUser}
          onChange={(event, newValue) => onSelectUser(newValue)}
          options={userOptions}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => (
            <Typography
              {...props}
              variant="body2"
              color="secondary"
              key={option.id}
              data-testid={`user-option-${option.id}`}
            >
              {option.label}
            </Typography>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              data-testid="name-text-field"
              label={t('braincare.organizations-create.step-add-users.dialog-input-user')}
            />
          )}
          data-testid="name-select"
        />

        <Grid container flexDirection="column" marginTop="16px" alignItems="center" justifyContent="center">
          <Grid item>
            <Typography variant="body2" color="secondary" fontWeight={600} marginBottom="24px">
              {t('organization.organization-members-create.text-member-create-or')}
            </Typography>
          </Grid>
          <Grid item>
            <Button color="gradient" onClick={clearUserData} data-testid="new-user-button">
              {t('organization.organization-members-create.button-new-user')}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box maxWidth="428px" marginBottom="48px">
        <Subtitle>{t('organization.organization-members-create.title-sub-member-create')}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-members-create.text-member-create-main')}
        </Typography>

        <TextField
          id="name"
          sx={{ margin: '0 0 16px 0' }}
          value={firstName}
          label={t('organization.organization-members-create.input-name')}
          variant="outlined"
          onChange={(event) => setFirstName(event.target.value)}
          data-testid="first-name-text-field"
          inputProps={{ 'data-testid': 'first-name-input' }}
          FormHelperTextProps={{ 'data-testid': 'first-name-error' }}
          error={errors.has('firstName')}
          helperText={errors.get('firstName')}
          disabled={userId !== null}
          fullWidth
        />

        <TextField
          id="last_name"
          sx={{ margin: '16px 0 16px 0' }}
          value={lastName}
          label={t('organization.organization-members-create.input-surname')}
          variant="outlined"
          onChange={(event) => setLastName(event.target.value)}
          data-testid="last-name-text-field"
          inputProps={{ 'data-testid': 'last-name-input' }}
          FormHelperTextProps={{ 'data-testid': 'last-name-error' }}
          error={errors.has('lastName')}
          helperText={errors.get('lastName')}
          disabled={userId !== null}
          fullWidth
        />

        {userId === null ? (
          <>
            <TextField
              id="document"
              sx={{ margin: '16px 0 16px 0' }}
              value={username}
              label={t('organization.organization-members-create.input-document')}
              variant="outlined"
              onChange={(event) => {
                const { value } = event.target;
                const username = isCpf(value) ? formatToCpfUsername(value) : value;
                setUsername(username);
              }}
              data-testid="document-text-field"
              inputProps={{ 'data-testid': 'document-input' }}
              FormHelperTextProps={{ 'data-testid': 'document-error' }}
              error={errors.has('document')}
              helperText={errors.get('document')}
              disabled={userId !== null}
              fullWidth
            />

            <TextField
              id="birth_year"
              sx={{ margin: '16px 0 16px 0' }}
              value={birthYear}
              label={t('account.me-edit-info.input-birth-year')}
              variant="outlined"
              onChange={(event) => setBirthYear(event.target.value.replace(/\D/g, ''))}
              data-testid="birth-year-text-field"
              inputProps={{ 'data-testid': 'birth-year-input' }}
              FormHelperTextProps={{ 'data-testid': 'birth-year-error' }}
              error={errors.has('birthYear')}
              helperText={errors.get('birthYear')}
              fullWidth
            />

            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                {t('auth.signup.text-doc-choice-language')}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={notificationLanguage}
                value={notificationLanguage}
                onChange={(event) => setNotificationLanguage(event.target.value)}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="pt-br"
                  control={<Radio inputProps={{ 'data-testid': 'pt-br-radio' }} />}
                  label={t('auth.signup.input-language-pt-br')}
                />
                <FormControlLabel
                  value="en-us"
                  control={<Radio inputProps={{ 'data-testid': 'en-us-radio' }} />}
                  label={t('auth.signup.input-language-en-us')}
                />
              </RadioGroup>
            </FormControl>
          </>
        ) : null}
      </Box>

      <Grid container spacing="16px">
        <Grid item>
          <Button
            color="gradient"
            onClick={() => {
              onSave({
                userId,
                document: username,
                firstName,
                lastName,
                birthYear,
                notificationLanguage,
              });
            }}
            disabled={userId === undefined}
            data-testid="continue-button"
          >
            {t('organization.organization-members-create.button-continue')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => navigate('/organization/members')} data-testid="cancel-button">
            {t('organization.organization-members-create.button-cancel')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default PersonalInfo;
