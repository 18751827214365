import { useState } from 'react';
import Subtitle from 'components/Subtitle';
import SubSubtitle from 'components/SubSubtitle';
import { Alert, Box, Typography } from '@mui/material';
import Button from 'components/Button';
import Loader from 'components/SectionLoader';
import { useTranslation } from 'react-i18next';
import App from 'pages/braincare/members/template';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearOrganizationSelectedMember, setOrganizationSelectedMember } from 'features/organizationSlice';
import { deleteMemberByOrganization, updateMemberByOrganization } from 'api/portal';
import braincare from 'components/routes/braincare';
import { showErrorToast } from 'features/toastSlice';
import { useFetchMemberForAdmin } from 'hooks/useFetchMemberAdmin';
import Fade from 'components/Fade';
import DeleteMemberDialog from 'components/dialogs/DeleteMemberDialog';
import SuspendMemberDialog from 'components/dialogs/SuspendMemberDialog';

function AdminMemberSuspension() {
  const [member, setMember] = useState({});

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isToShowSuspendDialog, setIsToShowSuspendDialog] = useState(false);
  const [isToShowDeleteDialog, setIsToShowDeleteDialog] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { orgId, memberId } = params;

  const { t } = useTranslation();

  useFetchMemberForAdmin({
    orgId,
    memberId,
    onSuccess: (data) => {
      setMember(data);
    },
    onFailure: () => {
      dispatch(showErrorToast('messages.load-failure'));
      navigate(`/braincare/organizations/${orgId}/members`);
    },
  });

  const changeAccountStatus = (newStatus) => {
    setIsUpdating(true);
    setIsToShowSuspendDialog(false);

    updateMemberByOrganization(orgId, memberId, {
      status: newStatus,
    })
      .then((response) => {
        const { data } = response;
        setIsUpdating(false);
        dispatch(setOrganizationSelectedMember(data));
        setMember({ ...member, status: newStatus });
      })
      .catch(() => {
        setIsUpdating(false);
        dispatch(showErrorToast('messages.generic-error'));
      });
  };

  const deleteMemberAccount = () => {
    setIsDeleting(true);
    setIsToShowDeleteDialog(false);

    deleteMemberByOrganization(orgId, memberId)
      .then(() => {
        setIsDeleting(false);
        dispatch(clearOrganizationSelectedMember());
        navigate(`/braincare/organizations/${orgId}/members`);
      })
      .catch(() => {
        setIsDeleting(false);
        dispatch(showErrorToast('messages.generic-error'));
      });
  };

  const renderSuspendDialog = () => (
    <SuspendMemberDialog
      open={isToShowSuspendDialog}
      onClose={() => setIsToShowSuspendDialog(false)}
      onSuspend={() => changeAccountStatus('SUSPENDED')}
      data-testid="suspend-dialog"
    />
  );

  const renderDeleteDialog = () => (
    <DeleteMemberDialog
      open={isToShowDeleteDialog}
      onClose={() => setIsToShowDeleteDialog(false)}
      onDelete={deleteMemberAccount}
      data-testid="delete-dialog"
    />
  );

  const renderSuspendAccount = () => (
    <Box>
      <SubSubtitle data-testid="suspend-member-subsubtitle">
        {t('organization.organization-members-edit-suspend.title-sub-member-suspend')}
      </SubSubtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-members-edit-suspend.text-organization-members-suspend')}
      </Typography>
      <Button
        variant="outlined"
        loading={!member.id || isUpdating}
        onClick={() => setIsToShowSuspendDialog(true)}
        data-testid="suspend-button"
      >
        {t('organization.organization-members-edit-suspend.button-member-suspend')}
      </Button>
    </Box>
  );

  const renderUnsuspendAccount = () => (
    <Alert variant="outlined" icon={false} severity="warning">
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-members-edit-suspend-revert.title-box-member-suspended')}
      </Typography>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-members-edit-suspend-revert.text-box-organization-member-suspend')}
      </Typography>
      <Button
        variant="outlined"
        color="warning"
        onClick={() => changeAccountStatus('ACTIVE')}
        data-testid="unsuspend-button"
      >
        {t('organization.organization-members-edit-suspend-revert.button-suspend-revert')}
      </Button>
    </Alert>
  );

  const renderStatusSection = () => (member.status === 'SUSPENDED' ? renderUnsuspendAccount() : renderSuspendAccount());

  return (
    <App>
      <Fade>
        <Subtitle data-testid="suspend-and-delete-member-subtitle">
          {t('organization.organization-members-edit-suspend.title-main-member-suspend')}
        </Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-members-edit-suspend.text-organization-members-suspend-exclude')}
        </Typography>
        <Box marginBottom="48px">
          <Loader loading={!member.id || isUpdating}>{renderStatusSection()}</Loader>
        </Box>

        <Box marginBottom="48px">
          <SubSubtitle data-testid="delete-member-subsubtitle">
            {t('organization.organization-members-edit-suspend.title-sub-member-exclude')}
          </SubSubtitle>
          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-members-edit-suspend.text-organization-members-exclude')}
          </Typography>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setIsToShowDeleteDialog(true)}
            loading={isDeleting}
            disabled={!member.id || isUpdating}
            data-testid="delete-button"
          >
            {t('organization.organization-members-edit-suspend.button-member-exclude')}
          </Button>
        </Box>
        {renderSuspendDialog()}
        {renderDeleteDialog()}
        <Button
          variant="outlined"
          onClick={() => navigate(`/braincare/organizations/${orgId}/members`)}
          data-testid="back-button"
        >
          {t('organization.organization-members-edit-suspend.button-cancel')}
        </Button>
      </Fade>
    </App>
  );
}

export default braincare(AdminMemberSuspension);
