import AuthTitle from 'components/AuthTitle';
import Section from 'components/Section';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';

function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Section>
      <Fade>
        <Box display="flex" flexDirection="column" textAlign="center" justifyContent="center" alignItems="center">
          <AuthTitle>{t('not-found.title')}</AuthTitle>
          <Typography variant="body2" color="secondary">
            {t('not-found.text')}
          </Typography>
          <Button sx={{ margin: '32px 0 0 0' }} color="gradient" onClick={() => navigate('/')}>
            {t('not-found.button-back')}
          </Button>
        </Box>
      </Fade>
    </Section>
  );
}

export default NotFound;
