import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorToast } from 'features/toastSlice';
import { formatToCpfUsername, formatToDate, unformatCpf } from 'utils/format';
import { isCpf, validateCpf, validateEmail, validateDate } from 'utils/validation';
import { useTranslation } from 'react-i18next';
import Subtitle from 'components/Subtitle';
import AuthTitle from 'components/AuthTitle';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'components/DatePicker';
import { fetchProfessions, signup } from 'api/portal';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import Button from 'components/Button';
import styles from '../styles.module.css';

function EduSignup() {
  const [stage, setStage] = useState('Form');
  const [username, setUsername] = useState('');
  const [profession, setProfession] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [date, setDate] = useState();
  const [notificationLanguage, setNotificationLanguage] = useState('en-us');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidDate, setIsValidDate] = useState(true);
  const [result, setResult] = useState(null);
  const [professions, setProfessions] = useState([]);

  const [errors, setErrors] = useState(new Map());

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const SIGNUP_ERROR = t('auth.accept-terms.title');
  const ACCOUNT_EXISTS = t('messages.account-exists');
  const SIGNUP_SUCCESS_TITLE = t('auth.signup-success.title');
  const SIGNUP_SUCCESS_MESSAGE = t('auth.signup-success.text');
  const INVALID_USERNAME_ERROR = t('messages.invalid-document');
  const INVALID_EMAIL_ERROR = t('messages.invalid-email');
  const INVALID_DATE_ERROR = t('messages.invalid-date');
  const FILL_REQUIRED = t('messages.fill-required');

  useEffect(() => {
    fetchProfessions()
      .then((response) => {
        const { data } = response;

        const professions = [];

        const returnedProfessions = data.professions;

        returnedProfessions.forEach((returnedProfession) => {
          professions.push(
            <MenuItem
              key={returnedProfession}
              value={returnedProfession}
              data-testid={`profession-option-${returnedProfession}`}
            >
              {t(`professions.text-${returnedProfession}`)}
            </MenuItem>,
          );
        });

        setProfessions(professions);
      })
      .catch(() => {
        dispatch(showErrorToast('messages.error-professions'));
      });
  }, []);

  const handleChangeDate = (date) => {
    setIsValidDate(date === '' || validateDate(date));
    setDate(date);
  };

  const cancel = () => {
    setStage('Form');
    setUsername('');
    setProfession(null);
    setEmail('');
    setFirstName('');
    setLastName('');
    setDate(undefined);
    setNotificationLanguage('en-us');
    setIsLoading(false);
    setIsValidUsername(true);
    setIsValidEmail(true);
    setIsValidDate(true);
    setResult(null);
    navigate('/auth/edu/login');
  };

  const handleSubmit = () => {
    setIsLoading(true);

    signup({
      accept_terms: true,
      document: isCpf(username) ? unformatCpf(username) : username,
      doctor_document: null,
      profession,
      email,
      first_name: firstName,
      last_name: lastName,
      birth_date: formatToDate(date),
      has_read_privacy_policy: true,
      has_read_terms_and_conditions: true,
      notification_language: notificationLanguage,
    })
      .then(() => {
        setIsLoading(false);
        setStage('Result');
        setResult([SIGNUP_SUCCESS_TITLE, SIGNUP_SUCCESS_MESSAGE]);
      })
      .catch((error) => {
        setIsLoading(false);

        const { response } = error;
        const { data } = response;

        if (data.document || data.email) {
          setStage('Result');
          setResult([SIGNUP_ERROR, ACCOUNT_EXISTS]);
        } else {
          dispatch(showErrorToast('messages.save-failure'));
          cancel();
        }
      });
  };

  const validateUsername = (event) => {
    const { value } = event.target;
    setIsValidUsername(value === null || validateCpf(value) || validateEmail(value));
  };

  const validateFields = () => {
    const requiredFields = {
      firstName,
      lastName,
      username,
      email,
      date,
      notificationLanguage,
    };

    const errors = new Map();

    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    setErrors(errors);

    if (!errors.size && isValidUsername && isValidEmail && isValidDate) {
      setStage('Terms');
      return;
    }

    dispatch(showErrorToast('messages.fix-fields'));
  };

  const renderForm = () => (
    <div className={styles.webview_container_form}>
      <AuthTitle className={styles.signupTitle}>{t('auth.signup.title-main-signup')}</AuthTitle>

      <TextField
        id="first_name"
        sx={{ margin: '16px 0 16px 0' }}
        value={firstName}
        placeholder="e.g.: John"
        label={t('auth.signup.input-name')}
        onChange={(event) => setFirstName(event.target.value)}
        variant="outlined"
        data-testid="first-name-text-field"
        inputProps={{ 'data-testid': 'first-name-input' }}
        error={errors.has('firstName')}
        helperText={errors.get('firstName')}
        FormHelperTextProps={{ 'data-testid': 'first-name-error' }}
        fullWidth
      />

      <TextField
        id="last_name"
        sx={{ margin: '16px 0 16px 0' }}
        value={lastName}
        placeholder="e.g.: Doe"
        label={t('auth.signup.input-surname')}
        onChange={(event) => setLastName(event.target.value)}
        variant="outlined"
        data-testid="last-name-text-field"
        inputProps={{ 'data-testid': 'last-name-input' }}
        error={errors.has('lastName')}
        helperText={errors.get('lastName')}
        FormHelperTextProps={{ 'data-testid': 'last-name-error' }}
        fullWidth
      />

      <TextField
        id="email"
        sx={{ margin: '16px 0 16px 0' }}
        value={email}
        placeholder="eg.: johndoe@yourorganization.com"
        label={t('auth.signup.input-email')}
        onChange={(event) => setEmail(event.target.value)}
        onBlur={() => setIsValidEmail(email === '' || validateEmail(email))}
        variant="outlined"
        data-testid="email-text-field"
        inputProps={{ 'data-testid': 'email-input' }}
        error={!isValidEmail || errors.has('email')}
        helperText={(!isValidEmail && INVALID_EMAIL_ERROR) || (errors.has('email') ? errors.get('email') : '')}
        FormHelperTextProps={{ 'data-testid': 'email-error' }}
        fullWidth
      />

      <DatePicker
        sx={{ margin: '16px 0' }}
        label={t('auth.signup.input-birthdate')}
        date={date}
        format="yyyy-MM-dd"
        onChange={handleChangeDate}
        slotProps={{
          textField: {
            fullWidth: true,
            error: !isValidDate || Boolean(errors && errors.get('date')),
            helperText:
              (!isValidDate && INVALID_DATE_ERROR) || (errors && errors.get('date') ? errors.get('date') : ''),
            'data-testid': 'birth-date-text-field',
            InputProps: {
              'data-testid': 'birth-date-input',
            },
            FormHelperTextProps: {
              'data-testid': 'birth-date-error',
            },
          },
        }}
      />

      <FormControl sx={{ margin: '16px 0' }} fullWidth>
        <InputLabel htmlFor="profession">{t('auth.signup.input-profession')}</InputLabel>
        <Select
          id="profession"
          onChange={(event) => setProfession(event.target.value)}
          value={profession}
          data-testid="profession-select"
        >
          {professions}
        </Select>
      </FormControl>

      <TextField
        id="username"
        sx={{ margin: '16px 0 16px 0' }}
        value={username}
        label={t('auth.signup.input-document')}
        onChange={(event) => {
          const { value } = event.target;
          setUsername(isCpf(value) ? formatToCpfUsername(value) : value);
        }}
        onBlur={validateUsername}
        variant="outlined"
        data-testid="document-text-field"
        inputProps={{ 'data-testid': 'document-input' }}
        error={!isValidUsername || errors.has('username')}
        helperText={
          (!isValidUsername && INVALID_USERNAME_ERROR) || (errors.has('username') ? errors.get('username') : '')
        }
        FormHelperTextProps={{ 'data-testid': 'document-error' }}
        fullWidth
      />

      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">{t('auth.signup.text-doc-choice-language')}</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={notificationLanguage}
          value={notificationLanguage}
          onChange={(event) => setNotificationLanguage(event.target.value)}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="pt-br"
            control={<Radio inputProps={{ 'data-testid': 'pt-br-radio' }} />}
            label={t('auth.signup.input-language-pt-br')}
          />
          <FormControlLabel
            value="en-us"
            control={<Radio inputProps={{ 'data-testid': 'en-us-radio' }} />}
            label={t('auth.signup.input-language-en-us')}
          />
        </RadioGroup>
      </FormControl>

      <Typography variant="body2" color="secondary" fontWeight={600} marginTop="4px" textAlign="center">
        {t('auth.accept-terms.text')}
      </Typography>

      <Button
        color="success"
        sx={{ margin: '32px 0 16px 0' }}
        size="large"
        variant="outlined"
        fullWidth
        onClick={validateFields}
        data-testid="continue-button"
      >
        {t('auth.accept-terms.button-continue')}
      </Button>

      <Button
        color="error"
        sx={{ margin: '32px 0 16px 0' }}
        size="large"
        variant="outlined"
        fullWidth
        onClick={() => navigate('/auth/edu/login')}
        data-testid="cancel-button"
      >
        {t('auth.accept-terms.button-cancel')}
      </Button>
    </div>
  );

  const renderTerms = () => (
    <div className={styles.termsMobile} data-testid="terms-and-services">
      <AuthTitle>{t('auth.terms-and-service.title00')}</AuthTitle>

      <Subtitle>{t('auth.terms-and-service.title01')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text00')}
        <br />
        <br />
        {t('auth.terms-and-service.text01')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title02')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        <span className={styles.warning}>{t('auth.terms-and-service.text02')}</span>
        <br />
        <br />
        {t('auth.terms-and-service.text03')}
        <br />
        <br />
        {t('auth.terms-and-service.text04')}
        <br />
        <br />
        {t('auth.terms-and-service.text05')}
        <br />
        <br />
        {t('auth.terms-and-service.text06')}
        <br />
        <br />
        {t('auth.terms-and-service.text07')}
        <br />
        <br />
        {t('auth.terms-and-service.text08')}
        <br />
        <br />
        {t('auth.terms-and-service.text09')}
        <br />
        <br />
        {t('auth.terms-and-service.text10')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title03')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text11')}
        <br />
        <br />
        {t('auth.terms-and-service.text12')}
        <br />
        <br />
        {t('auth.terms-and-service.text13')}
        <br />
        <br />
        {t('auth.terms-and-service.text14')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title04')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text15')}
        <br />
        <br />
        {t('auth.terms-and-service.text16')}
        <br />
        <br />
        {t('auth.terms-and-service.text17')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title05')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text18')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title06')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text19')}
        <br />
        <br />
        {t('auth.terms-and-service.text20')}
        <br />
        <br />
        {t('auth.terms-and-service.text21')}
        <br />
        <br />
        {t('auth.terms-and-service.text22')}
        <br />
        <br />
        {t('auth.terms-and-service.text23')}
        <br />
        <br />
        {t('auth.terms-and-service.text24')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title07')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text25')}
        <br />
        <br />
        {t('auth.terms-and-service.text26')}
        <br />
        <br />
        {t('auth.terms-and-service.text27')}
        <br />
        <br />
        {t('auth.terms-and-service.text28')}
        <br />
        <br />
        {t('auth.terms-and-service.text29')}
        <br />
        <br />
        {t('auth.terms-and-service.text30')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title08')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text31')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title09')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text32')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title10')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text33')}
        <br />
        <br />
        {t('auth.terms-and-service.text34')}
        <br />
        <br />
        {t('auth.terms-and-service.text35')}
        <br />
        <br />
        {t('auth.terms-and-service.text36')}
        <br />
        <br />
        {t('auth.terms-and-service.text37')}
        <br />
        <br />
      </Typography>

      <Button
        color="success"
        sx={{ margin: '32px 0 16px 0' }}
        size="large"
        fullWidth
        variant="outlined"
        onClick={() => {
          setStage('Privacy');
        }}
        data-testid="accept-terms-button"
      >
        {t('auth.accept-terms.button-accept')}
      </Button>

      <Button
        color="error"
        sx={{ margin: '32px 0 16px 0' }}
        size="large"
        fullWidth
        variant="outlined"
        onClick={cancel}
        data-testid="decline-terms-button"
      >
        {t('auth.accept-terms.button-cancel')}
      </Button>
    </div>
  );

  const renderPrivacy = () => {
    window.scrollTo(0, 0);
    return (
      <div className={styles.termsMobile} data-testid="privacy-policy">
        <AuthTitle>{t('auth.privacy-policy.title00')}</AuthTitle>

        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('auth.privacy-policy.text00')}
          <br />
          <br />
          {t('auth.privacy-policy.text01')}
          <br />
          <br />
          {t('auth.privacy-policy.text02')}
          <br />
          <br />
          {t('auth.privacy-policy.text03')}
          <br />
          <br />
        </Typography>

        <Subtitle>{t('auth.privacy-policy.title01')}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('auth.privacy-policy.text04')}
          <br />
          <br />
          <span className={styles.warning}>{t('auth.privacy-policy.text05')}</span>
          <br />
          <br />
          {t('auth.privacy-policy.text06')}
          <br />
          <br />
          {t('auth.privacy-policy.text07')}
          <br />
          <br />
        </Typography>

        <Subtitle>{t('auth.privacy-policy.title03')}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('auth.privacy-policy.text08')}
          <br />
          <br />
          {t('auth.privacy-policy.text09')}
          <br />
          <br />
          {t('auth.privacy-policy.text10')}
          <br />
          <br />
          {t('auth.privacy-policy.text11')}
          <br />
          <br />
        </Typography>

        <Subtitle>{t('auth.privacy-policy.title04')}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('auth.privacy-policy.text12')}
          <br />
          <br />
          {t('auth.privacy-policy.text13')}
          <br />
          <br />
          {t('auth.privacy-policy.text14')}
          <br />
          <br />
          {t('auth.privacy-policy.text15')}
          <br />
          <br />
        </Typography>

        <Button
          color="success"
          sx={{ margin: '32px 0 16px 0' }}
          size="large"
          fullWidth
          loading={isLoading}
          variant="outlined"
          onClick={() => {
            handleSubmit();
          }}
          data-testid="accept-privacy-policy-button"
        >
          {t('auth.accept-terms.button-accept')}
        </Button>

        <Button
          color="error"
          sx={{ margin: '32px 0 16px 0' }}
          size="large"
          fullWidth
          variant="outlined"
          onClick={cancel}
          data-testid="decline-privacy-policy-button"
        >
          {t('auth.accept-terms.button-cancel')}
        </Button>
      </div>
    );
  };

  const renderResult = () => (
    <div className={styles.webview_container}>
      <div className={styles.signUpResult}>
        <AuthTitle data-testid="result-title">{result[0]}</AuthTitle>
        <Typography variant="body2" color="secondary" marginBottom="24px" data-testid="result-text">
          {result[1]}
        </Typography>
        <Button color="gradient" size="large" fullWidth onClick={cancel} data-testid="success-button">
          {t('auth.signup-success.button-ok')}
        </Button>
      </div>
    </div>
  );

  switch (stage) {
    case 'Terms':
      return <div>{renderTerms()}</div>;
    case 'Privacy':
      return <div>{renderPrivacy()}</div>;
    case 'Result':
      return <div>{renderResult()}</div>;
    default:
      return <div className={styles.webview_container}>{renderForm()}</div>;
  }
}

export default EduSignup;
