import Badge from 'components/Badge';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { isTrustedDevice } from '../../utils/mobile-devices';

const BADGE_STATUS = {
  TRUSTED: 'success',
  NORMAL: 'default',
  BLOCKED: 'danger',
};

function MobileDeviceDetails({ device }) {
  const { t } = useTranslation();

  const TEXT_NAME = t('organization.organization-mobile-devices-info.name');
  const TEXT_MODEL = t('organization.organization-mobile-devices-info.model');
  const TEXT_OS_VERSION = t('organization.organization-mobile-devices-info.operating-system');
  const TEXT_APP_VERSION = t('organization.organization-mobile-devices-info.app-version');
  const TEXT_ORG_DEVICE_ID = t('organization.organization-mobile-devices-info.org-device-id');
  const TEXT_LAST_ACCESS = t('organization.organization-mobile-devices-info.text-last-access', { last_access: '' });
  const TEXT_EXPIRATION = t('organization.organization-mobile-devices-info.text-expiration');
  const TEXT_PENDING_SYNCS = t('organization.organization-mobile-devices-info.text-pending-syncs');
  const TEXT_OF = t('organization.organization-mobile-devices-info.text-of');
  const TEXT_STATUS = t('organization.organization-mobile-devices-info.device-status');

  function renderStatusBadge() {
    const { status } = device;

    if (status) {
      return (
        <Badge type={BADGE_STATUS[status]}>
          {t(`organization.organization-mobile-devices-info.${status.toLowerCase()}`)}
        </Badge>
      );
    }

    return null;
  }

  function renderExplanationForStatus() {
    const { status } = device;

    if (status === 'TRUSTED') {
      return (
        <Box data-testid="trusted-description">
          <Typography variant="body2" color="secondary">
            {t('organization.organization-mobile-devices-info.text-trusted-part-1')}
          </Typography>
          <Typography variant="body2" color="secondary">
            {t('organization.organization-mobile-devices-info.text-trusted-part-2')}
          </Typography>
          <Typography variant="body2" color="secondary">
            {t('organization.organization-mobile-devices-info.text-trusted-part-3')}
          </Typography>
          <Typography variant="body2" color="secondary">
            {t('organization.organization-mobile-devices-info.text-trusted-part-4')}
          </Typography>
        </Box>
      );
    }

    if (status === 'NORMAL') {
      return (
        <Box data-testid="normal-description">
          <Typography variant="body2" color="secondary">
            {t('organization.organization-mobile-devices-info.text-normal-part-1')}
          </Typography>
          <Typography variant="body2" color="secondary">
            {t('organization.organization-mobile-devices-info.text-normal-part-2')}
          </Typography>
        </Box>
      );
    }

    return (
      <Box data-testid="blocked-description">
        <Typography variant="body2" color="secondary">
          {t('organization.organization-mobile-devices-info.text-blocked-part-1')}
        </Typography>
        <Typography variant="body2" color="secondary">
          {t('organization.organization-mobile-devices-info.text-blocked-part-2')}
        </Typography>
      </Box>
    );
  }

  const syncedMembers = device.synced_members;

  return (
    <>
      <Box marginBottom="24px">
        <Typography variant="body2" color="secondary" data-testid="device-name">
          <b>{TEXT_NAME}</b>
          {device.name}
        </Typography>
        <Typography variant="body2" color="secondary" data-testid="device-model">
          <b>{TEXT_MODEL}</b>
          {device.model}
        </Typography>
        <Typography variant="body2" color="secondary" data-testid="device-os-version">
          <b>{TEXT_OS_VERSION}</b>
          {device.os_version}
        </Typography>
        <Typography variant="body2" color="secondary" data-testid="device-app-version">
          <b>{TEXT_APP_VERSION}</b>
          {device.app_version}
        </Typography>
        <Typography variant="body2" color="secondary" marginBottom="24px" data-testid="device-org-device-id">
          <b>{TEXT_ORG_DEVICE_ID}</b>
          {device.org_device_id}
        </Typography>
      </Box>

      <Box marginBottom="24px">
        <Typography variant="body2" color="secondary" data-testid="device-last-access">
          <b>{TEXT_LAST_ACCESS}</b>
          {device.last_access ? new Date(device.last_access).toLocaleString(i18next.language) : '-'}
        </Typography>
        {isTrustedDevice(device.status) ? (
          <>
            <Typography variant="body2" color="secondary" data-testid="device-expiration">
              <b>{TEXT_EXPIRATION}</b>
              {device.expiration ? new Date(device.expiration).toLocaleString(i18next.language) : '-'}
            </Typography>
            {syncedMembers ? (
              <Typography variant="body2" color="secondary" marginBottom="24px" data-testid="device-synced-members">
                <b>{TEXT_PENDING_SYNCS}:</b> {syncedMembers.nb_synced_members} {TEXT_OF} {syncedMembers.nb_members}
              </Typography>
            ) : null}
          </>
        ) : null}
      </Box>
      <Box marginBottom="24px">
        <Typography variant="body2" color="secondary">
          <b>{TEXT_STATUS}</b>
          {renderStatusBadge()}
        </Typography>
      </Box>

      <Box marginBottom="24px">{renderExplanationForStatus()}</Box>
    </>
  );
}

export default MobileDeviceDetails;
