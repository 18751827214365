import { styled } from '@mui/material/styles';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

const DatePicker = styled(MuiDatePicker)(({ theme }) => ({
  '& .MuiInputLabel-formControl': {
    '&.Mui-error': {
      color: theme.palette.error.light,
    },
  },
  '& .MuiInputBase-formControl': {
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.error.light}`,
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.colorGreyLight.light}`,
    },
    '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.colorGreyLight.light}`,
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
  },
}));

export default DatePicker;
