import { createTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const primary = {
  main: '#002f72',
  light: '#0080e5',
  dark: '#110e2d',
  contrastText: '#ffffff',
};

const error = {
  main: '#f84949',
  light: '#f84949',
  dark: '#cc1818',
  contrastText: '#ffffff',
};

const secondary = {
  main: '#888696',
  light: '#888696',
  dark: '#888696',
  contrastText: '#ffffff',
};

const success = {
  main: '#01d6a5',
  light: '#01d6a5',
  dark: '#01d6a5',
  contrastText: '#ffffff',
};

const disabled = {
  main: '#bababa',
  light: '#bababa',
  dark: '#bababa',
  contrastText: '#bababa',
};

const colorGrey = {
  main: '#d5d5d5',
  light: '#d5d5d5',
  dark: '#d5d5d5',
  contrastText: '#d5d5d5',
};

const colorGreyLight = {
  main: '#ededf0',
  light: '#ededf0',
  dark: '#ededf0',
  contrastText: '#ededf0',
};

const colorGreyDark = {
  main: '#888696',
  light: '#888696',
  dark: '#888696',
  contrastText: '#888696',
};

function arrowIcon(props) {
  return <ExpandMoreIcon {...props} />;
}

const theme = createTheme({
  palette: {
    primary,
    error,
    secondary,
    success,
    disabled,
    colorGrey,
    colorGreyLight,
    colorGreyDark,
  },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
  },
  border: {
    half: 20,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          border: 'solid 1px #d5d5d5',
          boxShadow: 'none',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          fontSize: 13,
          background: 'white',
          color: colorGreyDark.light,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: 'solid 1px var(--color-grey-light)',
          '&:hover': {
            border: 'solid 1px var(--color-primary-light)',
          },
          '&:active': {
            border: 'solid 1px var(--color-grey-light)',
          },
          transition: 'var(--transition-default)',
          boxShadow: 'var(--shadow-default)',
          padding: '24px',
          cursor: 'pointer',
          position: 'relative',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '0 0 16px 0',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px 0 0 0',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: 'var(--color-primary-light)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderTop: '1px solid var(--color-grey-light)',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '40px 0',
          fill: 'var(--color-grey-dark)',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: 'var(--color-grey-dark)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 13,
          background: 'white',
          color: colorGreyDark.light,
          '&:hover': { background: colorGreyLight.light },
          '&.Mui-selected': {
            background: 'white',
            color: primary.light,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: secondary.main,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: primary.light,
          '.MuiTableCell-root': {
            color: 'white',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          borderRadius: 40,
          padding: '7px 16px',
          textTransform: 'none',
          '&.MuiButton-outlinedPrimary': {
            border: `solid 1px ${primary.main}`,
          },
          '&.MuiButton-outlinedError': {
            border: `solid 1px ${error.main}`,
            '&:hover': {
              backgroundColor: error.main,
              color: 'white',
            },
          },
          '&.MuiButton-outlinedSuccess': {
            border: `solid 1px ${success.main}`,
            '&:hover': {
              backgroundColor: success.main,
              color: 'white',
            },
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            border: `solid 1px ${disabled.main}`,
            cursor: 'not-allowed',
            pointerEvents: 'auto',
            '&:hover': {
              backgroundColor: 'transparent',
              color: disabled.main,
            },
          },
          '&.MuiButton-sizeLarge': {
            height: '64px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': { padding: '30px 30px 24px 30px', width: '508px' },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '40px 0 0 0',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 13,
          color: colorGreyDark.light,
          borderRadius: 40,
          '& .MuiOutlinedInput-notchedOutline': {
            border: `solid 1px ${colorGrey.light}`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `solid 1px ${primary.light}`,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `solid 1px ${colorGreyDark.light}`,
          },
          '& .MuiOutlinedInput-input': {
            padding: '12px',
          },
          '& .Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: `solid 1px ${colorGreyLight.light}`,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: `solid 1px ${error.light}`,
          },
          '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
            border: `solid 1px ${colorGreyLight.light}`,
            cursor: 'not-allowed',
            pointerEvents: 'auto',
          },
          '& .MuiSelect-iconOpen': {
            transform: 'none',
          },
          '& .MuiSelect-select': {
            padding: '12px',
          },
        },
      },
      defaultProps: {
        IconComponent: arrowIcon,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          color: primary.main,
          transform: 'translate(14px, -9px) scale(0.75)',
          backgroundColor: primary.contrastText,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          color: primary.main,
          margin: '0 0 4px 0',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0 0 16px 0',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          color: primary.main,
          '&.Mui-error': {
            color: error.light,
          },
          '& .MuiFormControlLabel-label': {
            fontSize: '13px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-formControl': {
            '&.Mui-error': {
              color: error.light,
            },
          },
          '& .MuiInputBase-formControl': {
            fontSize: 13,
            color: colorGreyDark.light,
            borderRadius: 40,
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: `solid 1px ${error.light}`,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              border: `solid 1px ${colorGreyLight.light}`,
            },
            '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
              border: `solid 1px ${colorGreyLight.light}`,
              cursor: 'not-allowed',
              pointerEvents: 'auto',
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          padding: 24,
          '& .MuiAlert-message': { width: '100%', textAlign: 'center', padding: 0 },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
});

export default theme;
