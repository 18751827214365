import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import appPlaceholder from 'assets/images/app-placeholder.svg';
import { Box, Typography } from '@mui/material';

const AppItem = ({ text, image, href, sx, ...props }) => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        cursor: 'pointer',
        height: '100%',
        padding: '8px',
        '&:hover': {
          backgroundColor: 'var(--color-grey-light)',
          borderRadius: '8px',
        },
        ...sx,
      }}
      onClick={() => navigate(href)}
      {...props}
    >
      <img width="40px" height="40px" src={image || appPlaceholder} alt="" />
      <Typography sx={{ fontWeight: '600' }} textAlign="center" marginTop="7px" variant="body2" color="primary">
        {text}
      </Typography>
    </Box>
  );
};

AppItem.propTypes = {
  text: PropTypes.any.isRequired,
  image: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default AppItem;
