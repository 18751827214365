import { Avatar, Box, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import protocolIcon from 'assets/images/ic_monitorization.svg';
import Loader from 'components/SectionLoader';
import MenuIcon from '@mui/icons-material/Menu';
import { formatDuration, formatToDate, formatToTime } from 'utils/format';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

function AnonymizedAcquisitionRow({ loadingDoctor, acquisition, doctors, onClickMenu, onClickProtocol }) {
  const { t } = useTranslation();

  const LOADING = t('messages.loading');

  const protocolId = acquisition.acquisition_id;
  const startTime = acquisition.start_time;
  const { duration } = acquisition;

  const getDoctorName = (acquisition) => {
    const doctor = doctors.find((curr) => Number(acquisition.owner) === Number(curr.id)) || {};
    return doctor.full_name || '';
  };

  return (
    <TableRow data-testid={`acquisition-${protocolId}`}>
      <TableCell>
        <Box display="flex" alignItems="center">
          <img src={protocolIcon} alt="protocol icon" className={styles.protocolIcon} />
          <div className={styles.acquisitionLink} onClick={() => onClickProtocol(acquisition)} role="presentation">
            {protocolId}
          </div>
        </Box>
      </TableCell>
      <TableCell>{`${formatToDate(new Date(startTime))} ${formatToTime(startTime, true)}`}</TableCell>
      <TableCell>{formatDuration(duration)}</TableCell>
      <TableCell>
        {loadingDoctor ? (
          LOADING
        ) : (
          <Box display="flex" alignItems="center">
            <Avatar sx={{ width: 32, height: 32, margin: '0 8px 0 0' }} />
            <Typography variant="body2" color="secondary">
              {getDoctorName(acquisition)}
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell>
        <Loader loading={acquisition.isDownloading} padding="0px" width="16px">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={(event) => onClickMenu(event, acquisition)}
            data-testid="menu-acquisition"
          >
            <MenuIcon
              sx={{
                width: '28px',
                height: '28px',
              }}
              color="primary"
            />
          </IconButton>
        </Loader>
      </TableCell>
    </TableRow>
  );
}

export default AnonymizedAcquisitionRow;
