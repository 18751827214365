import classNames from 'classnames';
import { Chip, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import styles from './styles.module.css';

function ChipInput({
  disabled = false,
  errorMessage,
  hasError,
  items,
  label,
  onCloseItem,
  onChange = () => {},
  value,
  ...props
}) {
  return (
    <>
      <FormControl fullWidth {...props}>
        <InputLabel id="similarity-ratio-label">{label}</InputLabel>
        <div className={classNames(styles.labeledInput, { [styles.error]: hasError })}>
          {items.map((item) => (
            <Chip
              key={item}
              label={item}
              onDelete={() => onCloseItem(item)}
              sx={{ margin: 1 }}
              data-testid={`chip-${item}`}
            />
          ))}

          <OutlinedInput
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              flexGrow: 1,
            }}
            disabled={disabled}
            value={value}
            onChange={onChange}
            data-testid="chip-input"
          />
        </div>
      </FormControl>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </>
  );
}

export default ChipInput;
