import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, CircularProgress, Typography } from '@mui/material';
import styles from './styles.module.css';

const NotificationItem = ({ text, date, disabled = false, hasLink, read, loading, onClick, ...props }) => (
  <Box
    className={classNames(styles.notifications, {
      [styles.read]: read,
      [styles.disabled]: disabled,
    })}
    onClick={(event) => {
      if (!disabled) {
        onClick(event);
      }
    }}
    role="presentation"
    {...props}
  >
    {loading ? (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size="24px" />
      </Box>
    ) : (
      <>
        <Typography
          color="primary.light"
          variant="body2"
          fontWeight={read ? 'none' : 'bold'}
          data-testid="main-text-notification"
        >
          {text}
        </Typography>
        <Typography color="secondary" variant="body2">
          {date}
        </Typography>
      </>
    )}
  </Box>
);

NotificationItem.propTypes = {
  text: PropTypes.any.isRequired,
  date: PropTypes.string.isRequired,
  hasLink: PropTypes.bool.isRequired,
  read: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default NotificationItem;
