import AuthTitle from 'components/AuthTitle';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.css';

export default function TermsAndServicesEnglish() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <AuthTitle>{t('auth.terms-and-service-en.title00')}</AuthTitle>

      <Subtitle>{t('auth.terms-and-service-en.title01')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text00')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text01')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text02')}
      </Typography>

      <Subtitle>{t('auth.terms-and-service-en.title02')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text03')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text04')}
      </Typography>

      <Subtitle>{t('auth.terms-and-service-en.title03')}</Subtitle>
      <Typography variant="body2" color="secondary">
        {t('auth.terms-and-service-en.text05')}
      </Typography>
      <br />
      <Typography variant="body2" color="secondary">
        {t('auth.terms-and-service-en.text06')}
      </Typography>
      <br />
      <Typography variant="body2" color="secondary">
        {t('auth.terms-and-service-en.text07')}
      </Typography>
      <br />
      <ul>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text08')}</li>
        </Typography>

        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text09')}</li>
        </Typography>

        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text10')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text11')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text12')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text13')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text14')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text15')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text16')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text17')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text18')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text19')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text20')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text21')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text22')}</li>
        </Typography>
        <Typography variant="body2" color="secondary">
          <li>{t('auth.terms-and-service-en.text23')}</li>
        </Typography>
      </ul>
      <Typography variant="body2" color="secondary">
        {t('auth.terms-and-service-en.text24')}
      </Typography>
      <br />
      <Typography variant="body2" color="secondary">
        {t('auth.terms-and-service-en.text25')}
      </Typography>
      <br />
      <Typography variant="body2" color="secondary">
        {t('auth.terms-and-service-en.text26')}
      </Typography>
      <br />

      <Subtitle>{t('auth.terms-and-service-en.title04')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text27')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text28')}
        <br />
        <br />
      </Typography>
      <Typography variant="body2" color="secondary" marginBottom="24px" textAlign="center">
        <strong>{t('auth.terms-and-service-en.title05')}</strong>
        <br />
        {t('auth.terms-and-service-en.text29')}
        <br />
        {t('auth.terms-and-service-en.text30')}
      </Typography>
      <Typography variant="body2" color="secondary" marginBottom="24px" textAlign="center">
        <strong>{t('auth.terms-and-service-en.title06')}</strong>
        <br />
        {t('auth.terms-and-service-en.text31')}
        <br />
        {t('auth.terms-and-service-en.text32')}
        <br />
        <br />
      </Typography>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text33')}
      </Typography>

      <Subtitle>{t('auth.terms-and-service-en.title07')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text34')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text35')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text36')}
      </Typography>

      <Subtitle>{t('auth.terms-and-service-en.title08')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text37')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text38')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text39')}
      </Typography>

      <Subtitle>{t('auth.terms-and-service-en.title09')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text40')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text41')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text42')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text43')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text44')}
        <br />
        {t('auth.terms-and-service-en.text45')}
      </Typography>

      <Subtitle>{t('auth.terms-and-service-en.title10')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text46')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text47')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text48')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text49')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text50')}
      </Typography>

      <Subtitle>{t('auth.terms-and-service-en.title11')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text51')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text52')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text53')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text54')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text55')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text56')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text57')}
      </Typography>

      <Subtitle>{t('auth.terms-and-service-en.title12')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text58')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text59')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text60')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text61')}
      </Typography>

      <Subtitle>{t('auth.terms-and-service-en.title13')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text62')}
      </Typography>

      <Subtitle>{t('auth.terms-and-service-en.title14')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service-en.text63')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text64')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text65')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text66')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text67')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text68')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text69')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text70')}
        <br />
        <br />
        {t('auth.terms-and-service-en.text71')}
      </Typography>
    </Section>
  );
}
