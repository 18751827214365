import { updateOrganization } from 'api/portal';
import DialogHeader from 'components/dialogs/DialogHeader';
import SubSubtitle from 'components/SubSubtitle';
import Subtitle from 'components/Subtitle';
import SectionLoader from 'components/SectionLoader';
import Button from 'components/Button';
import braincare from 'components/routes/braincare';
import App from 'pages/braincare/organizations/template';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';
import { useFetchOrganizationAdmin } from 'hooks/useFetchOrganizationAdmin';
import Fade from 'components/Fade';

function AdminOrganizationReportsPolicies() {
  const [organization, setOrganization] = useState();

  const [name, setName] = useState('');

  const [globalAutoShare, setGlobalAutoShare] = useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [autoPurge, setAutoPurge] = useState(false);
  const [autoPurgePeriod, setAutoPurgePeriod] = useState('');

  const [errors, setErrors] = useState(new Map());

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { orgId } = params;

  const { t } = useTranslation();

  const AUTO_PURGE_PERIOD_EMPTY = t('organization.organization-settings.reports.auto-purge-period-empty');

  useFetchOrganizationAdmin({
    orgId,
    onSuccess: (data) => {
      setOrganization(data);
      setName(data.name || '');
      setGlobalAutoShare(data.global_auto_share || false);
      setAutoPurge(data.auto_purge || false);
      setAutoPurgePeriod(data.expiration_days || '');
    },
    onFailure: () => {
      navigate('/braincare/organizations');
      dispatch(showErrorToast('messages.load-failure'));
    },
  });

  const validateForm = () => {
    const errors = new Map();

    if (autoPurge) {
      if (autoPurgePeriod === '') {
        errors.set('autoPurgePeriod', AUTO_PURGE_PERIOD_EMPTY);
      }
    }

    return errors;
  };

  const onSubmit = () => {
    const errors = validateForm();

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      updateOrganization(orgId, {
        global_auto_share: globalAutoShare,
        auto_purge: autoPurge,
        expiration_days: autoPurgePeriod === '' ? null : autoPurgePeriod,
      })
        .then(() => {
          setIsSubmitting(false);
          dispatch(showSuccessToast('organization.organization-settings.save-success-message'));
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(showErrorToast('organization.organization-settings.save-failure-message'));
        });
    } else {
      dispatch(showErrorToast('messages.fix-fields'));
    }
  };

  return (
    <App title={name}>
      <Fade>
        <Box maxWidth="428px">
          <Subtitle>{t('organization.organization-settings.reports.subtitle')}</Subtitle>

          <Dialog open={isConfirmDialogOpen} data-testid="confirm-auto-purge-dialog">
            <DialogHeader type="warning">
              {t('organization.organization-settings.reports.auto-purge-confirmation-title')}
            </DialogHeader>
            <DialogContent>
              <Typography variant="body2" color="secondary" marginBottom="24px">
                {t('organization.organization-settings.reports.auto-purge-confirmation-body')}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button fullWidth onClick={() => setIsConfirmDialogOpen(false)}>
                {t('organization.organization-settings.reports.auto-purge-confirmation-cancel-button')}
              </Button>
              <Button
                fullWidth
                color="gradient"
                onClick={() => {
                  setIsConfirmDialogOpen(false);
                  setAutoPurge(true);
                }}
                loading={false}
                data-testid="confirm-auto-purge-button"
              >
                {t('organization.organization-settings.reports.auto-purge-confirmation-confirm-button')}
              </Button>
            </DialogActions>
          </Dialog>

          <SectionLoader loading={!organization}>
            <Fade>
              <Box marginBottom="48px">
                <br />
                <SubSubtitle>
                  {t('organization.organization-settings.reports.global-auto-share-subsubtitle')}
                </SubSubtitle>

                <Typography variant="body2" color="secondary" marginBottom="24px">
                  {t('organization.organization-settings.reports.global-auto-share-text')}
                </Typography>

                <FormControlLabel
                  label={t('organization.organization-settings.reports.global-auto-share-checkbox')}
                  control={
                    <Checkbox
                      checked={globalAutoShare}
                      inputProps={{ 'data-testid': 'auto-share-checkbox-input' }}
                      onClick={() => setGlobalAutoShare(!globalAutoShare)}
                      data-testid="auto-share-checkbox"
                    />
                  }
                />
              </Box>

              <Box marginBottom="48px">
                <SubSubtitle>{t('organization.organization-settings.reports.auto-purge-subsubtitle')}</SubSubtitle>

                <Typography variant="body2" color="secondary" marginBottom="24px">
                  {t('organization.organization-settings.reports.auto-purge-text')}
                </Typography>

                <FormControlLabel
                  label={t('organization.organization-settings.reports.auto-purge-checkbox')}
                  control={
                    <Checkbox
                      checked={autoPurge}
                      inputProps={{ 'data-testid': 'auto-purge-checkbox-input' }}
                      onClick={() => {
                        if (autoPurge) {
                          setAutoPurge(false);
                        } else {
                          setIsConfirmDialogOpen(true);
                        }
                      }}
                      data-testid="auto-purge-checkbox"
                    />
                  }
                />

                <TextField
                  id="autoPurgePeriod"
                  sx={{ marginTop: 2 }}
                  value={autoPurgePeriod}
                  onChange={(event) => setAutoPurgePeriod(filterNumbers(event.target.value))}
                  label={t('organization.organization-settings.reports.auto-purge-input')}
                  variant="outlined"
                  error={errors.has('autoPurgePeriod')}
                  helperText={errors.get('autoPurgePeriod')}
                  data-testid="auto-purge-text-field"
                  inputProps={{ 'data-testid': 'auto-purge-input' }}
                  FormHelperTextProps={{ 'data-testid': 'auto-purge-error' }}
                  fullWidth
                />
              </Box>

              <Button variant="outlined" loading={isSubmitting} onClick={onSubmit} data-testid="save-button">
                {t('organization.organization-settings.reports.submit-button-text')}
              </Button>
              <Button
                sx={{ marginLeft: 2 }}
                variant="outlined"
                onClick={() => navigate('/braincare/organizations')}
                data-testid="cancel-button"
              >
                {t('organization.organization-settings.reports.cancel-button-text')}
              </Button>
            </Fade>
          </SectionLoader>
        </Box>
      </Fade>
    </App>
  );
}

export default braincare(AdminOrganizationReportsPolicies);
