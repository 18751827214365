import Footer from 'components/Footer';
import Timeout from 'components/routes/timeout';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { debugLog } from 'utils/logging';
import { getToken } from 'utils/tokens';

function AuthPage() {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const accessToken = getToken('access_token');
  const refreshToken = getToken('refresh_token');

  const activeOrganization = user.active_organization;

  const autoLogoutPortal = activeOrganization.auto_logout_portal;
  const autoLogoutTimePortal = activeOrganization.auto_logout_time_portal;

  useEffect(() => {
    if (accessToken === undefined || refreshToken === undefined) {
      debugLog('No tokens', accessToken, refreshToken);
      navigate('/auth/logout');
    }
  }, []);

  const renderMainContent = () => (
    <>
      <Outlet />
      <Footer />
    </>
  );

  if (autoLogoutPortal && autoLogoutTimePortal) {
    return (
      <Timeout timeout={autoLogoutTimePortal * 60 - 30} warningTime={30}>
        {renderMainContent()}
      </Timeout>
    );
  }

  return renderMainContent();
}

export default AuthPage;
