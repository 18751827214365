import Section from 'components/Section';
import AuthTitle from 'components/AuthTitle';
import { Typography } from '@mui/material';
import Subtitle from 'components/Subtitle';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.css';

export default function PrivacyPolicyPortuguese() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <AuthTitle>{t('auth.privacy-policy.title00')}</AuthTitle>

      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.privacy-policy.text00')}
        <br />
        <br />
        {t('auth.privacy-policy.text01')}
        <br />
        <br />
        {t('auth.privacy-policy.text02')}
        <br />
        <br />
        {t('auth.privacy-policy.text03')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.privacy-policy.title01')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.privacy-policy.text04')}
        <br />
        <br />
        <span className={styles.warning}>{t('auth.privacy-policy.text05')}</span>
        <br />
        <br />
        {t('auth.privacy-policy.text06')}
        <br />
        <br />
        {t('auth.privacy-policy.text07')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.privacy-policy.title03')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.privacy-policy.text08')}
        <br />
        <br />
        {t('auth.privacy-policy.text09')}
        <br />
        <br />
        {t('auth.privacy-policy.text10')}
        <br />
        <br />
        {t('auth.privacy-policy.text11')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.privacy-policy.title04')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.privacy-policy.text12')}
        <br />
        <br />
        {t('auth.privacy-policy.text13')}
        <br />
        <br />
        {t('auth.privacy-policy.text14')}
        <br />
        <br />
        {t('auth.privacy-policy.text15')}
        <br />
        <br />
      </Typography>
    </Section>
  );
}
