import { fetchBuUser } from 'api/portal';
import { useEffect } from 'react';

export function useFetchUserAdmin({ userId, onSuccess = () => {}, onFailure = () => {} }) {
  useEffect(() => {
    fetchBuUser(userId)
      .then((response) => {
        const { data } = response;
        onSuccess(data);
      })
      .catch(() => {
        onFailure();
      });
  }, []);
}
