import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import braincare from 'components/routes/braincare';
import { showErrorToast } from 'features/toastSlice';
import App from 'components/templates/app';
import Title from 'components/AuthTitle';
import Section from 'components/Section';
import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Pagination, Select, Typography } from '@mui/material';
import Loader from 'components/SectionLoader';
import OrganizationCard from 'components/cards/OrganizationCard';
import { useNavigate } from 'react-router-dom';
import { fetchOrganizations } from 'api/portal';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';

const PAGE_SIZE = 8;

function AdminOrganizations() {
  const [filterValue, setFilterValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('ALL');
  const [organizations, setOrganizations] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const LOAD_ORGANIZATIONS_FAILURE = t('braincare.organizations.load-organizations-failure');

  const HOME = t('braincare.breadcrumbs.home');
  const ORGANIZATIONS = t('braincare.breadcrumbs.organizations');
  const TITLE = t('braincare.organizations.title');

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/organizations',
      title: ORGANIZATIONS,
      isActive: true,
    },
  ];

  const ORGANIZATION_STATUS = [
    { id: 'ACTIVE', text: t('utils.status.active') },
    { id: 'INACTIVE', text: t('utils.status.inactive') },
  ];

  const fetchOrganizationsHandler = (searchParams) => {
    setIsLoading(true);
    setErrorLoading(false);

    fetchOrganizations(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setErrorLoading(false);
        setOrganizations(data.results);
        setCount(Math.ceil(data.count / PAGE_SIZE));
        setCurrentPage(Number(searchParams.get('page')));
      })
      .catch(() => {
        setIsLoading(false);
        setErrorLoading(true);
        dispatch(showErrorToast('braincare.organizations.load-organizations-failure'));
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', 1);
    searchParams.set('page_size', PAGE_SIZE);

    fetchOrganizationsHandler(searchParams);
  }, []);

  const handleClearFilterClick = () => {
    setFilterValue('');
    setSelectedStatus('');

    const searchParams = new URLSearchParams();

    searchParams.set('page', 1);
    searchParams.set('page_size', PAGE_SIZE);

    fetchOrganizationsHandler(searchParams);
  };

  const openDetailPage = (id) => navigate(`/braincare/organizations/${id}/edit/info`);

  const renderOrganizations = () => {
    if (errorLoading) {
      return (
        <Typography variant="body2" color="error" data-testid="error-message">
          {LOAD_ORGANIZATIONS_FAILURE}
        </Typography>
      );
    }

    if (organizations.length === 0) {
      return (
        <Typography
          display="flex"
          justifyContent="center"
          variant="body2"
          color="secondary"
          data-testid="no-organization"
        >
          {t('braincare.organizations.no-organizations-found')}
        </Typography>
      );
    }

    return (
      <Grid container spacing="16px">
        {organizations.map((organization) => (
          <Grid key={`organization-card-${organization.id}`} item xs={12} sm={6} md={3}>
            <OrganizationCard
              organization={organization}
              onClick={() => openDetailPage(organization.id)}
              data-testid={`organization-card-${organization.id}`}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <App breadcrumbs={breadcrumbs}>
      <Section>
        <Title>{TITLE}</Title>
      </Section>

      <Section>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <Grid marginBottom="16px" container spacing="12px">
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth sx={{ margin: 0 }}>
                <InputLabel>{t('braincare.organizations.input-filter-name')}</InputLabel>
                <OutlinedInput
                  name="search"
                  value={filterValue}
                  onChange={(event) => setFilterValue(event.target.value)}
                  inputProps={{ 'data-testid': 'name-input' }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl fullWidth sx={{ margin: 0 }}>
                <InputLabel htmlFor="status" data-testid="role-filter-label">
                  {t('braincare.organizations.input-filter-status')}
                </InputLabel>
                <Select
                  id="status"
                  onChange={(event) => setSelectedStatus(event.target.value)}
                  value={selectedStatus}
                  data-testid="status-select"
                >
                  <MenuItem value="ALL">{t('braincare.organizations.dropdown-filter-status')}</MenuItem>
                  {ORGANIZATION_STATUS.map((status) => (
                    <MenuItem key={`filter-${status.id}`} value={status.id} data-testid={`status-option-${status.id}`}>
                      {status.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Button fullWidth variant="outlined" onClick={handleClearFilterClick} data-testid="clear-filters-button">
                {t('braincare.organizations.button-clear-filters')}
              </Button>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Button
                fullWidth
                onClick={() => {
                  const searchParams = new URLSearchParams();

                  searchParams.set('page', 1);
                  searchParams.set('page_size', PAGE_SIZE);
                  searchParams.set('search_name', filterValue);

                  if (selectedStatus !== 'ALL') {
                    searchParams.set('is_active', selectedStatus === 'ACTIVE');
                  }

                  fetchOrganizationsHandler(searchParams);
                }}
                color="gradient"
                type="submit"
                data-testid="apply-filters-button"
              >
                {t('braincare.organizations.button-apply-filters')}
              </Button>
            </Grid>
          </Grid>
        </form>

        <Loader loading={isLoading}>
          <Fade>{renderOrganizations()}</Fade>
          <Pagination
            count={count}
            page={currentPage}
            onChange={(event, value) => {
              const searchParams = new URLSearchParams();

              searchParams.set('page', value);
              searchParams.set('page_size', 8);
              searchParams.set('search_name', filterValue);

              if (selectedStatus !== 'ALL') {
                searchParams.set('is_active', selectedStatus === 'ACTIVE');
              }

              fetchOrganizationsHandler(searchParams);
            }}
          />
        </Loader>
      </Section>
    </App>
  );
}

export default braincare(AdminOrganizations);
