import AppMenu from 'components/templates/app-menu';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isTrustedDevice } from '../../../../utils/mobile-devices';

export default function App({ children, device }) {
  const params = useParams();

  const { t } = useTranslation();

  const { pathname, search } = window.location;
  const { deviceId } = params;

  const DEVICE_DETAILS_URL = `/org-mobile-devices/devices/${deviceId}/info`;
  const UPDATE_PIN_POLICY_URL = `/org-mobile-devices/devices/${deviceId}/pin-policy`;
  const PIN_REDEFINITION_URL = `/org-mobile-devices/devices/${deviceId}/pin-edit`;

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_DEVICES = t('organization.breadcrumbs.manage-devices');

  const DEVICE_INFO = t('organization.organization-mobile-devices-edit.title-sub-device-info');
  const DEVICE_DETAILS = t('organization.organization-mobile-devices-info.title-sub-info-device');
  const UPDATE_PIN_POLICY = t('organization.organization-mobile-devices-edit.title-sub-device-pin-policy');
  const PIN_REDEFINITION = t('organization.organization-mobile-devices-edit.title-sub-device-member-pin-edit');

  const SIDE_MENU = isTrustedDevice(device.status)
    ? [
        {
          href: DEVICE_DETAILS_URL,
          text: DEVICE_INFO,
          isActive: pathname === DEVICE_DETAILS_URL,
        },
        {
          href: UPDATE_PIN_POLICY_URL,
          text: UPDATE_PIN_POLICY,
          isActive: pathname === UPDATE_PIN_POLICY_URL,
        },
        {
          href: PIN_REDEFINITION_URL,
          text: PIN_REDEFINITION,
          isActive: pathname === PIN_REDEFINITION_URL,
        },
      ]
    : [
        {
          href: DEVICE_DETAILS_URL,
          text: DEVICE_INFO,
          isActive: pathname === DEVICE_DETAILS_URL,
        },
      ];

  const BREADCRUMBS = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/org-mobile-devices/devices',
      title: MANAGE_DEVICES,
    },
    {
      path: `${pathname}${search}`,
      title: device.name ? device.name : DEVICE_DETAILS,
      isActive: true,
    },
  ];

  return (
    <AppMenu title={device.name ? device.name : DEVICE_DETAILS} breadcrumbs={BREADCRUMBS} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
}
