import PropTypes from 'prop-types';
import { Chip, Typography } from '@mui/material';

const OrganizationAggregate = ({ icon, value = '', label = '' }) => (
  <Chip
    sx={{
      marginBottom: '12px',
      padding: '8px 16px',
      '& .MuiChip-label': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'var(--color-secondary-grey-light)',
      },
    }}
    label={
      <>
        {icon}
        <Typography sx={{ marginLeft: '16px' }} variant="body2">
          <strong>{value}&nbsp;</strong>
          {label}
        </Typography>
      </>
    }
  />
);

OrganizationAggregate.propTypes = {
  icon: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

export default OrganizationAggregate;
