import PropTypes from 'prop-types';
import classNames from 'classnames';
import Brand from 'components/Brand';
import Translator from 'components/Translator';
import LogoBraincare from 'assets/images/logo-braincare.png';
import styles from './styles.module.css';

const Auth = ({ className, children, maxWidth }) => (
  <div className={classNames(className, styles.container)}>
    <Brand className={styles.brand} image={LogoBraincare} title="logo braincare" />
    <div className={styles.content} style={{ maxWidth }}>
      {children}
      <Translator />
    </div>
  </div>
);

Auth.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
};

export default Auth;
