import { useState } from 'react';
import { showErrorToast } from 'features/toastSlice';
import Auth from 'components/templates/auth';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import AuthTitle from 'components/AuthTitle';
import Button from 'components/Button';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearAllTokens } from 'utils/tokens';
import { clearProfileData, setOrganizations } from 'features/userSlice';
import { acceptTerms, fetchProfiles } from 'api/portal';
import styles from './styles.module.css';
import PrivacyPolicyPortuguese from '../../public/PrivacyPolicy/pt';
import TermsAndServicePortuguese from '../../public/TermsAndServices/pt';
import TermsAndServiceEnglish from '../../public/TermsAndServices/en';
import PrivacyPolicyEnglish from '../../public/PrivacyPolicy/en';

function AcceptTerms() {
  const [loadMessage, setLoadMessage] = useState(true);
  const [readPrivacyPolicy, setReadPrivacyPolicy] = useState(false);
  const [readTermsAndConditions, setReadTermsAndConditions] = useState(false);

  const user = useSelector((state) => state.user);

  const organizationProfile = user.organization_profile;
  const { roles } = organizationProfile;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const getOrganizationProfiles = () => {
    fetchProfiles()
      .then((response) => {
        const { data } = response;
        dispatch(setOrganizations(data));

        if (roles.length === 1) {
          const role = roles[0];

          if (role.id === 1) {
            navigate('/organization/members');
            return;
          }

          if (role.id === 3) {
            navigate('/organization/physio');
            return;
          }
        }

        navigate('/');

        navigate('/');
      })
      .catch(() => {
        dispatch(clearProfileData());
        clearAllTokens();
        dispatch(showErrorToast('messages.load-failure'));
        navigate('/');
      });
  };

  const updateTerms = () => {
    acceptTerms()
      .then(() => {
        getOrganizationProfiles();
      })
      .catch(() => {
        dispatch(showErrorToast('messages.accept-terms-failed'));
      });
  };

  const cancel = () => {
    dispatch(clearProfileData());
    clearAllTokens();
    navigate('/');
  };

  const renderMessage = () => (
    <Auth className={styles.container}>
      <Section maxWidth="512px">
        <Subtitle>{t('auth.accept-terms.title')}</Subtitle>
        <Typography color="secondary" size="big">
          <br />
          {t('auth.accept-terms.text')}
          <br />
        </Typography>

        <Button
          color="success"
          variant="outlined"
          size="large"
          fullWidth
          sx={{ margin: '32px 0 16px 0 ' }}
          onClick={() => setLoadMessage(false)}
          data-testid="continue-button"
        >
          {t('auth.accept-terms.button-continue')}
        </Button>

        <Button
          color="error"
          variant="outlined"
          size="large"
          fullWidth
          sx={{ margin: '32px 0 16px 0 ' }}
          onClick={cancel}
          data-testid="cancel-button"
        >
          {t('auth.accept-terms.button-cancel')}
        </Button>
      </Section>
    </Auth>
  );

  const renderTerms = () => (
    <Auth className={styles.container} maxWidth="90vw">
      <Section>
        {user.language === 'pt-br' ? <TermsAndServicePortuguese /> : <TermsAndServiceEnglish />}

        <Box display="flex" justifyContent="center">
          <Button
            color="gradient"
            sx={{ margin: '32px 16px 16px 16px ' }}
            onClick={() => setReadTermsAndConditions(true)}
            data-testid="accept-terms-button"
          >
            {t('auth.accept-terms.button-accept')}
          </Button>

          <Button
            color="error"
            sx={{ margin: '32px 16px 16px 16px ' }}
            variant="outlined"
            onClick={cancel}
            data-testid="cancel-terms-button"
          >
            {t('auth.accept-terms.button-cancel')}
          </Button>
        </Box>
      </Section>
    </Auth>
  );

  const renderPolicy = () => {
    window.scrollTo(0, 0);
    return (
      <Auth className={styles.container} maxWidth="90vw">
        <Section>
          {user.language === 'pt-br' ? <PrivacyPolicyPortuguese /> : <PrivacyPolicyEnglish />}

          <Box display="flex" justifyContent="center">
            <Button
              color="gradient"
              sx={{ margin: '32px 16px 16px 16px ' }}
              onClick={() => {
                setReadPrivacyPolicy(true);
                updateTerms();
              }}
              data-testid="accept-privacy-policy-button"
            >
              {t('auth.accept-terms.button-accept')}
            </Button>

            <Button
              color="error"
              sx={{ margin: '32px 16px 16px 16px ' }}
              variant="outlined"
              onClick={cancel}
              data-testid="cancel-privacy-policy-button"
            >
              {t('auth.accept-terms.button-cancel')}
            </Button>
          </Box>
        </Section>
      </Auth>
    );
  };

  if (loadMessage) {
    return renderMessage();
  }

  if (!readTermsAndConditions) {
    return renderTerms();
  }

  if (!readPrivacyPolicy) {
    return renderPolicy();
  }

  return (
    <Auth className={styles.container}>
      <Section maxWidth="512px">
        <AuthTitle>{t('auth.accept-terms.thanks-title')}</AuthTitle>
        <Typography color="secondary" size="big">
          {t('auth.accept-terms.thanks-text')}
        </Typography>
      </Section>
    </Auth>
  );
}

export default AcceptTerms;
