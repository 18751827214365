import PropTypes from 'prop-types';
import { formatToDatetime } from 'utils/format';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';

function LogTable({ logObjs = undefined, membersMap, operationsMap, onClick }) {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('logs.activities-report.table-header.date')}</TableCell>
            <TableCell align="left">{t('logs.activities-report.table-header.user')}</TableCell>
            <TableCell align="left">{t('logs.activities-report.table-header.action')}</TableCell>
            <TableCell align="left">{t('logs.activities-report.table-header.data')}</TableCell>
            <TableCell align="left">{t('logs.activities-report.table-header.resource')}</TableCell>
            <TableCell align="left">{t('logs.activities-report.table-header.ip')}</TableCell>
            <TableCell align="left">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logObjs &&
            logObjs.map((logObj) => (
              <TableRow key={logObj['@ptr']} data-testid={logObj['@ptr']}>
                <TableCell>{formatToDatetime(logObj.timestamp)}</TableCell>
                <TableCell>{membersMap.get(Number(logObj.user_id))}</TableCell>
                <TableCell>{operationsMap.get(logObj.action)}</TableCell>
                <TableCell>
                  <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '64px' }}>
                    {logObj.additional_data}
                  </Box>
                </TableCell>
                <TableCell>{logObj.resource.replace('/api/v1/', '')}</TableCell>
                <TableCell>{logObj.ip}</TableCell>
                <TableCell>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => {
                      onClick(logObj);
                    }}
                    data-testid="details-button"
                  >
                    <MenuIcon
                      sx={{
                        width: '28px',
                        height: '28px',
                      }}
                      color="primary"
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

LogTable.propTypes = {
  logObjs: PropTypes.arrayOf(PropTypes.object.isRequired),
  membersMap: PropTypes.object.isRequired,
  operationsMap: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LogTable;
