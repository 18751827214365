import i18next from 'i18next';
import PasswordValidator from './PasswordValidator';

export default class CommonPasswordValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { excludeCommonPasswords } = passwordPolicy;

    super(
      excludeCommonPasswords,
      'exclude_common_passwords',
      true,
      excludeCommonPasswords ? i18next.t('organization.password-rules.exclude-common-passwords') : null,
    );
  }
}
