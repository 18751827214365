export const filterPhoneChars = (value) => value.replace(/[^1234567890+()-\s]/g, '');

export const filterNumbers = (value) => value.replace(/\D/g, '');

export const castValueToNumber = (value) => {
  if (value === undefined) {
    return value;
  }

  if (value === '') {
    return undefined;
  }

  return Number(value);
};
