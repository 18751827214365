import Subtitle from 'components/Subtitle';
import App from 'pages/organization/settings/App';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PasswordPolicy from 'utils/password-validators/PasswordPolicy';
import PasswordPolicyBuilder from 'utils/password-validators/PasswordPolicyBuilder';
import { setActiveOrganizationData, setOrganizations } from 'features/userSlice';
import { updateMemberOrganization } from 'api/portal';
import Button from 'components/Button';
import { Box, Typography } from '@mui/material';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';
import { castValueToNumber } from 'utils/inputs';
import PolicyTemplates from 'components/PolicyTemplates';
import { isBetween } from 'utils/math';
import LengthPolicyForm from 'components/forms/LengthPolicyForm';
import NonAlphanumericPolicyForm from 'components/forms/NonAlphanumericPolicyForm';
import DigitsPolicyForm from 'components/forms/DigitsPolicyForm';
import UppercasePolicyForm from 'components/forms/UppercasePolicyForm';
import LowercasePolicyForm from 'components/forms/LowercasePolicyForm';
import SequentialTextPolicyForm from 'components/forms/SequentialTextPolicyForm';
import PersonalDataPolicyForm from 'components/forms/PersonalDataPolicyForm';
import ForbiddenWordsPolicyForm from 'components/forms/ForbiddenWordsPolicyForm';
import CommonPasswordsForm from 'components/forms/CommonPasswordsForm';
import RepeatedCharsPolicyForm from 'components/forms/RepeatedCharsPolicyForm';
import PasswordReusePolicyForm from 'components/forms/PasswordReusePolicyForm';
import PasswordExpirationForm from 'components/forms/PasswordExpirationForm';
import Fade from 'components/Fade';

function OrganizationPasswordPolicy() {
  const organization = useSelector((state) => state.user.active_organization);
  const passwordPolicy = new PasswordPolicy(organization.password_policy);

  const organizations = useSelector((state) => state.user.organizations);

  const {
    sizeRange,
    nonAlphanumericRange,
    digitsRange,
    uppercaseRange,
    lowercaseRange,
    sequentialStrings,
    excludePersonalInformation,
    forbiddenWords,
    repeatedCharSequences,
  } = passwordPolicy;

  const [maxLength, setMaxLength] = useState(sizeRange.max);
  const [minLength, setMinLength] = useState(sizeRange.min);

  const [maxNonAlphanumeric, setMaxNonAlphanumeric] = useState(nonAlphanumericRange.max);
  const [minNonAlphanumeric, setMinNonAlphanumeric] = useState(nonAlphanumericRange.min);

  const [maxDigits, setMaxDigits] = useState(digitsRange.max);
  const [minDigits, setMinDigits] = useState(digitsRange.min);

  const [maxUppercase, setMaxUppercase] = useState(uppercaseRange.max);
  const [minUppercase, setMinUppercase] = useState(uppercaseRange.min);

  const [maxLowercase, setMaxLowercase] = useState(lowercaseRange.max);
  const [minLowercase, setMinLowercase] = useState(lowercaseRange.min);

  const [maxLengthSeqStrings, setMaxLengthSeqStrings] = useState(sequentialStrings.max);
  const [caseSensitiveSeqStrings, setCaseSensitiveSeqStrings] = useState(sequentialStrings.is_case_sensitive);
  const [reverseOrderSeqStrings, setReverseOrderSeqStrings] = useState(passwordPolicy.sequentialStrings.reverse_order);
  const [repeatedLettersSeqStrings, setRepeatedLettersSeqStrings] = useState(sequentialStrings.repeated_letters);
  const [loopingSeqStrings, setLoopingSeqStrings] = useState(sequentialStrings.looping);

  const [similarityRatio, setSimilarityRatio] = useState(String(excludePersonalInformation.similarity_ratio));
  const [personalData, setPersonalData] = useState(excludePersonalInformation.fields);

  const [forbiddenWord, setForbiddenWord] = useState('');
  const [listForbiddenWords, setListForbiddenWords] = useState(forbiddenWords.words);
  const [caseSensitiveForbiddenWords, setCaseSensitiveForbiddenWords] = useState(forbiddenWords.is_case_sensitive);

  const [excludeCommonPasswords, setExcludeCommonPasswords] = useState(passwordPolicy.excludeCommonPasswords);

  const [maxLengthRepeatedChars, setMaxLengthRepeatedChars] = useState(repeatedCharSequences.max);
  const [caseSensitiveRepeatedChars, setCaseSensitiveRepeatedChars] = useState(repeatedCharSequences.is_case_sensitive);

  const [maxRepetitions, setMaxRepetitions] = useState(passwordPolicy.passwordReuse);

  const [enablePasswordExpiration, setEnablePasswordExpiration] = useState(organization.enable_password_renewal);
  const [passwordExpirationPeriod, setPasswordExpirationPeriod] = useState(organization.password_renewal_period || '');
  const [numberSkips, setNumberSkips] = useState(organization.skip_password_renewal || '');
  const [skippingPeriod, setSkippingPeriod] = useState(organization.skip_password_renewal_period || '');

  const [errors, setErrors] = useState(new Map());

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const ERROR_RANGE = t('organization.organization-settings.password-policy.errors.range');
  const ERROR_RANGE_2 = t('organization.organization-settings.password-policy.errors.range-2');
  const ERROR_RANGE_3 = t('organization.organization-settings.password-policy.errors.range-3');
  const ERROR_RANGE_4 = t('organization.organization-settings.password-policy.errors.range-4');
  const ERROR_RANGE_5 = t('organization.organization-settings.password-policy.errors.range-5');
  const ERROR_MAX_LENGTH = t('organization.organization-settings.password-policy.errors.max-length');
  const ERROR_MAX_NON_ALPHANUMERIC = t(
    'organization.organization-settings.password-policy.errors.max-non-alphanumeric-chars',
  );
  const ERROR_MAX_DIGITS = t('organization.organization-settings.password-policy.errors.max-digits');
  const ERROR_MAX_UPPERCASE = t('organization.organization-settings.password-policy.errors.max-uppercase');
  const ERROR_MAX_LOWERCASE = t('organization.organization-settings.password-policy.errors.max-lowercase');

  const ERROR_PASSWORD_EXPIRATION_PERIOD = t(
    'organization.organization-settings.password-policy.errors.password-expiration-period',
  );

  const validateLength = () => {
    const errors = new Map();

    const minLengthNumber = castValueToNumber(minLength);
    const maxLengthNumber = castValueToNumber(maxLength);
    // Required fields
    if (maxLengthNumber === undefined) {
      errors.set('maxLength', ERROR_RANGE);
    }

    if (minLengthNumber === undefined) {
      errors.set('minLength', ERROR_RANGE);
    }
    // Range of the max and min length
    if (maxLengthNumber !== undefined && !isBetween(maxLengthNumber, 6, 128)) {
      errors.set('maxLength', ERROR_RANGE);
    }

    if (minLengthNumber !== undefined && !isBetween(minLengthNumber, 6, 128)) {
      errors.set('minLength', ERROR_RANGE);
    }
    // Checking that the min lengh < max length
    if (!errors.has('maxLength') && !errors.has('minLength')) {
      if (maxLengthNumber !== undefined && minLengthNumber !== undefined && maxLengthNumber < minLengthNumber) {
        errors.set('maxLength', ERROR_MAX_LENGTH);
      }
    }

    return errors;
  };

  const validateDigits = () => {
    const errors = new Map();

    const minDigitsNumber = castValueToNumber(minDigits);
    const maxDigitsNumber = castValueToNumber(maxDigits);
    // Range of the max and min number of digits
    if (maxDigitsNumber !== undefined && !isBetween(maxDigitsNumber, 0, 128)) {
      errors.set('maxDigits', ERROR_RANGE_2);
    }

    if (minDigitsNumber !== undefined && !isBetween(minDigitsNumber, 0, 128)) {
      errors.set('minDigits', ERROR_RANGE_2);
    }
    // Checking that the min number of digits < max number of digits
    if (!errors.has('maxDigits') && !errors.has('minDigits')) {
      if (maxDigitsNumber !== undefined && minDigitsNumber !== undefined && maxDigitsNumber < minDigitsNumber) {
        errors.set('maxDigits', ERROR_MAX_DIGITS);
      }
    }

    return errors;
  };

  const validateNonAlphanumeric = () => {
    const errors = new Map();

    const minNonAlphanumericNumber = castValueToNumber(minNonAlphanumeric);
    const maxNonAlphanumericNumber = castValueToNumber(maxNonAlphanumeric);
    // Range of the max and min number of non-alphanumeric chars
    if (maxNonAlphanumericNumber !== undefined && !isBetween(maxNonAlphanumericNumber, 0, 128)) {
      errors.set('maxNonAlphanumeric', ERROR_RANGE_2);
    }

    if (minNonAlphanumericNumber !== undefined && !isBetween(minNonAlphanumericNumber, 0, 128)) {
      errors.set('minNonAlphanumeric', ERROR_RANGE_2);
    }
    // Checking that the min number of non-alphanumeric chars < max number of non-alphanumeric chars
    if (!errors.has('maxNonAlphanumeric') && !errors.has('minNonAlphanumeric')) {
      if (
        maxNonAlphanumericNumber !== undefined &&
        minNonAlphanumericNumber !== undefined &&
        maxNonAlphanumericNumber < minNonAlphanumericNumber
      ) {
        errors.set('maxNonAlphanumeric', ERROR_MAX_NON_ALPHANUMERIC);
      }
    }

    return errors;
  };

  const validateUppercaseLetters = () => {
    const errors = new Map();

    const minUppercaseNumber = castValueToNumber(minUppercase);
    const maxUppercaseNumber = castValueToNumber(maxUppercase);
    // Range of the max and min number of uppercase letters
    if (maxUppercaseNumber !== undefined && !isBetween(maxUppercaseNumber, 0, 128)) {
      errors.set('maxUppercase', ERROR_RANGE_2);
    }

    if (minUppercaseNumber !== undefined && !isBetween(minUppercaseNumber, 0, 128)) {
      errors.set('minUppercase', ERROR_RANGE_2);
    }
    // Checking that the min number of uppercase letters < max number of uppercase letters
    if (!errors.has('maxUppercase') && !errors.has('minUppercase')) {
      if (
        maxUppercaseNumber !== undefined &&
        minUppercaseNumber !== undefined &&
        maxUppercaseNumber < minUppercaseNumber
      ) {
        errors.set('maxUppercase', ERROR_MAX_UPPERCASE);
      }
    }

    return errors;
  };

  const validateLowercaseLetters = () => {
    const errors = new Map();

    const minLowercaseNumber = castValueToNumber(minLowercase);
    const maxLowercaseNumber = castValueToNumber(maxLowercase);
    // Range of the max and min number of lowercase letters
    if (maxLowercaseNumber !== undefined && !isBetween(maxLowercaseNumber, 0, 128)) {
      errors.set('maxLowercase', ERROR_RANGE_2);
    }

    if (minLowercaseNumber !== undefined && !isBetween(minLowercaseNumber, 0, 128)) {
      errors.set('minLowercase', ERROR_RANGE_2);
    }
    // Checking that the min number of lowercase letters < max number of lowercase letters
    if (!errors.has('maxLowercase') && !errors.has('minLowercase')) {
      if (
        !maxLowercaseNumber !== undefined &&
        !minLowercaseNumber !== undefined &&
        maxLowercaseNumber < minLowercaseNumber
      ) {
        errors.set('maxLowercase', ERROR_MAX_LOWERCASE);
      }
    }

    return errors;
  };

  const validateMinOfEachCharType = () => {
    const errors = new Map();

    const minLengthNumber = castValueToNumber(minLength);
    const minDigitsNumber = castValueToNumber(minDigits);
    const minNonAlphanumericNumber = castValueToNumber(minNonAlphanumeric);
    const minUppercaseNumber = castValueToNumber(minUppercase);
    const minLowercaseNumber = castValueToNumber(minLowercase);
    // Checking that the min number of digits is enough to fulfill other minimal requirements
    if (
      !errors.has('minLength') &&
      !errors.has('minDigits') &&
      !errors.has('minNonAlphanumeric') &&
      !errors.has('minUppercase') &&
      !errors.has('minLowercase')
    ) {
      const minToFulfillMinChars = minDigitsNumber + minNonAlphanumericNumber + minUppercaseNumber + minLowercaseNumber;

      if (minLengthNumber < minToFulfillMinChars) {
        const ERROR_MIN_LENGTH = t('organization.organization-settings.password-policy.errors.min-length', {
          min_length: minLengthNumber,
          min_required: minToFulfillMinChars,
        });
        errors.set('minLength', ERROR_MIN_LENGTH);
      }
    }

    return errors;
  };

  const validateSeqStr = () => {
    const errors = new Map();

    const maxLengthSeqStringsNumber = castValueToNumber(maxLengthSeqStrings);
    // Range of the max length of sequential strings
    if (maxLengthSeqStringsNumber !== undefined && !isBetween(maxLengthSeqStringsNumber, 3, 128)) {
      errors.set('maxLengthSeqStrings', ERROR_RANGE_3);
    }

    return errors;
  };

  const validateRepeatedChars = () => {
    const errors = new Map();

    const maxLengthRepeatedCharsNumber = castValueToNumber(maxLengthRepeatedChars);
    // Range of the max length of repeated chars
    if (maxLengthRepeatedCharsNumber !== undefined && !isBetween(maxLengthRepeatedCharsNumber, 2, 128)) {
      errors.set('maxLengthRepeatedChars', ERROR_RANGE_4);
    }

    return errors;
  };

  const validatePasswordReuse = () => {
    const errors = new Map();

    const maxRepetitionsNumber = castValueToNumber(maxRepetitions);
    // Range of the number of passwords whose reuse must be forbidden
    if (maxRepetitionsNumber !== undefined && !isBetween(maxRepetitionsNumber, 0, 20)) {
      errors.set('maxRepetitions', ERROR_RANGE_5);
    }

    return errors;
  };

  const validatePasswordExpiration = () => {
    const errors = new Map();

    if (enablePasswordExpiration && !passwordExpirationPeriod) {
      errors.set('passwordExpirationPeriod', ERROR_PASSWORD_EXPIRATION_PERIOD);
    }

    return errors;
  };

  const buildPasswordPolicy = () => {
    const policy = new PasswordPolicyBuilder();

    const minLengthNumber = castValueToNumber(minLength);
    const maxLengthNumber = castValueToNumber(maxLength);

    const minDigitsNumber = castValueToNumber(minDigits);
    const maxDigitsNumber = castValueToNumber(maxDigits);

    const minNonAlphanumericNumber = castValueToNumber(minNonAlphanumeric);
    const maxNonAlphanumericNumber = castValueToNumber(maxNonAlphanumeric);

    const minUppercaseNumber = castValueToNumber(minUppercase);
    const maxUppercaseNumber = castValueToNumber(maxUppercase);

    const minLowercaseNumber = castValueToNumber(minLowercase);
    const maxLowercaseNumber = castValueToNumber(maxLowercase);

    const maxLengthSeqStringsNumber = castValueToNumber(maxLengthSeqStrings);

    const similarityRatioNumber = castValueToNumber(similarityRatio);

    const maxLengthRepeatedCharsNumber = castValueToNumber(maxLengthRepeatedChars);

    const maxRepetitionsNumber = castValueToNumber(maxRepetitions);

    policy.addLengthPolicy(minLengthNumber, maxLengthNumber);
    policy.addDigitsPolicy(minDigitsNumber, maxDigitsNumber);
    policy.addNonAlphanumericPolicy(minNonAlphanumericNumber, maxNonAlphanumericNumber);
    policy.addUppercasePolicy(minUppercaseNumber, maxUppercaseNumber);
    policy.addLowercasePolicy(minLowercaseNumber, maxLowercaseNumber);
    policy.addForbiddenWordsPolicy(listForbiddenWords, caseSensitiveForbiddenWords);
    policy.addSequentialStringPolicy(
      maxLengthSeqStringsNumber,
      loopingSeqStrings,
      reverseOrderSeqStrings,
      repeatedLettersSeqStrings,
      caseSensitiveSeqStrings,
    );
    policy.addCommonPasswordsPolicy(excludeCommonPasswords);
    policy.addExcludePersonalInformation(personalData, similarityRatioNumber);
    policy.addRepeatedCharSequencesPolicy(maxLengthRepeatedCharsNumber, caseSensitiveRepeatedChars);
    policy.addPasswordReusePolicy(maxRepetitionsNumber);

    return policy;
  };

  const onSubmit = () => {
    const errors = new Map([
      ...validateLength(),
      ...validateDigits(),
      ...validateNonAlphanumeric(),
      ...validateLowercaseLetters(),
      ...validateUppercaseLetters(),
      ...validateMinOfEachCharType(),
      ...validateSeqStr(),
      ...validateRepeatedChars(),
      ...validatePasswordReuse(),
      ...validatePasswordExpiration(),
    ]);

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      const policy = buildPasswordPolicy();

      updateMemberOrganization({
        password_policy: policy.passwordPolicy,
        enable_password_renewal: enablePasswordExpiration,
        password_renewal_period: passwordExpirationPeriod,
        skip_password_renewal: numberSkips === '' ? null : numberSkips,
        skip_password_renewal_period: skippingPeriod === '' ? null : skippingPeriod,
      })
        .then((response) => {
          const { data } = response;

          setIsSubmitting(false);

          dispatch(showSuccessToast('organization.organization-settings.save-success-message'));
          dispatch(setActiveOrganizationData(data));
          dispatch(
            setOrganizations(
              organizations.map((item) => {
                if (item.id === data.id) {
                  return data;
                }

                return item;
              }),
            ),
          );
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(showErrorToast('organization.organization-settings.save-failure-message'));
        });
    } else {
      dispatch(showErrorToast('messages.fix-fields'));
    }
  };

  const fillFormWithTemplate = (template) => {
    const passwordPolicy = new PasswordPolicy(template);

    const {
      sizeRange,
      nonAlphanumericRange,
      digitsRange,
      uppercaseRange,
      lowercaseRange,
      sequentialStrings,
      excludePersonalInformation,
      forbiddenWords,
      repeatedCharSequences,
    } = passwordPolicy;

    setMaxLength(sizeRange.max);
    setMinLength(sizeRange.min);

    setMaxNonAlphanumeric(nonAlphanumericRange.max);
    setMinNonAlphanumeric(nonAlphanumericRange.min);

    setMaxDigits(digitsRange.max);
    setMinDigits(digitsRange.min);

    setMaxUppercase(uppercaseRange.max);
    setMinUppercase(uppercaseRange.min);

    setMaxLowercase(lowercaseRange.max);
    setMinLowercase(lowercaseRange.min);

    setMaxLengthSeqStrings(sequentialStrings.max ? sequentialStrings.max : '');
    setCaseSensitiveSeqStrings(sequentialStrings.is_case_sensitive);
    setReverseOrderSeqStrings(sequentialStrings.reverse_order);
    setRepeatedLettersSeqStrings(sequentialStrings.repeated_letters);
    setLoopingSeqStrings(sequentialStrings.looping);

    setSimilarityRatio(String(excludePersonalInformation.similarity_ratio));
    setPersonalData(excludePersonalInformation.fields);

    setForbiddenWord('');
    setListForbiddenWords(forbiddenWords.words);
    setCaseSensitiveForbiddenWords(forbiddenWords.is_case_sensitive);

    setExcludeCommonPasswords(passwordPolicy.excludeCommonPasswords);

    setMaxLengthRepeatedChars(repeatedCharSequences.max ? repeatedCharSequences.max : '');
    setCaseSensitiveRepeatedChars(repeatedCharSequences.is_case_sensitive);

    setMaxRepetitions(passwordPolicy.passwordReuse);

    setErrors(new Map());
  };

  return (
    <App>
      <Fade timeout={1000}>
        <Box maxWidth="428px" marginBottom="48px">
          <Subtitle>{t('organization.organization-settings.password-policy.subtitle')}</Subtitle>

          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-settings.password-policy.text')}
          </Typography>

          <PolicyTemplates onSelect={fillFormWithTemplate} />

          <LengthPolicyForm
            minLength={minLength}
            minLengthError={errors.get('minLength')}
            maxLength={maxLength}
            maxLengthError={errors.get('maxLength')}
            onChange={(min, max) => {
              setMinLength(min);
              setMaxLength(max);
            }}
          />

          <NonAlphanumericPolicyForm
            minNonAlphanumeric={minNonAlphanumeric}
            minNonAlphanumericError={errors.get('minNonAlphanumeric')}
            maxNonAlphanumeric={maxNonAlphanumeric}
            maxNonAlphanumericError={errors.get('maxNonAlphanumeric')}
            onChange={(min, max) => {
              setMinNonAlphanumeric(min);
              setMaxNonAlphanumeric(max);
            }}
          />

          <DigitsPolicyForm
            minDigits={minDigits}
            minDigitsError={errors.get('minDigits')}
            maxDigits={maxDigits}
            maxDigitsError={errors.get('maxDigits')}
            onChange={(min, max) => {
              setMinDigits(min);
              setMaxDigits(max);
            }}
          />

          <UppercasePolicyForm
            minUppercase={minUppercase}
            minUppercaseError={errors.get('minUppercase')}
            maxUppercase={maxUppercase}
            maxUppercaseError={errors.get('maxUppercase')}
            onChange={(min, max) => {
              setMinUppercase(min);
              setMaxUppercase(max);
            }}
          />

          <LowercasePolicyForm
            minLowercase={minLowercase}
            minLowercaseError={errors.get('minLowercase')}
            maxLowercase={maxLowercase}
            maxLowercaseError={errors.get('maxLowercase')}
            onChange={(min, max) => {
              setMinLowercase(min);
              setMaxLowercase(max);
            }}
          />

          <SequentialTextPolicyForm
            maxLength={maxLengthSeqStrings}
            maxLengthError={errors.get('maxLengthSeqStrings')}
            caseSensitiveSeqStrings={caseSensitiveSeqStrings}
            reverseOrderSeqStrings={reverseOrderSeqStrings}
            repeatedLettersSeqStrings={repeatedLettersSeqStrings}
            loopingSeqStrings={loopingSeqStrings}
            onChange={(
              maxLengthSeqStrings,
              caseSensitiveSeqStrings,
              reverseOrderSeqStrings,
              repeatedLettersSeqStrings,
              loopingSeqStrings,
            ) => {
              setMaxLengthSeqStrings(maxLengthSeqStrings);
              setCaseSensitiveSeqStrings(caseSensitiveSeqStrings);
              setReverseOrderSeqStrings(reverseOrderSeqStrings);
              setRepeatedLettersSeqStrings(repeatedLettersSeqStrings);
              setLoopingSeqStrings(loopingSeqStrings);
            }}
          />

          <PersonalDataPolicyForm
            personalData={personalData}
            similarityRatio={similarityRatio}
            onChange={(personalData, similarityRatio) => {
              setPersonalData(personalData);
              setSimilarityRatio(similarityRatio);
            }}
          />

          <ForbiddenWordsPolicyForm
            forbiddenWord={forbiddenWord}
            forbiddenWords={listForbiddenWords}
            caseSensitiveForbiddenWords={caseSensitiveForbiddenWords}
            onChange={(forbiddenWord, forbiddenWords, caseSensitiveForbiddenWords) => {
              setForbiddenWord(forbiddenWord);
              setListForbiddenWords(forbiddenWords);
              setCaseSensitiveForbiddenWords(caseSensitiveForbiddenWords);
            }}
          />

          <CommonPasswordsForm
            enabled={excludeCommonPasswords}
            onChange={() => setExcludeCommonPasswords(!excludeCommonPasswords)}
          />

          <RepeatedCharsPolicyForm
            maxLength={maxLengthRepeatedChars}
            maxLengthError={errors.get('maxLengthRepeatedChars')}
            isCaseSensitive={caseSensitiveRepeatedChars}
            onChange={(maxLength, isCaseSensitive) => {
              setMaxLengthRepeatedChars(maxLength);
              setCaseSensitiveRepeatedChars(isCaseSensitive);
            }}
          />

          <PasswordReusePolicyForm
            maxRepetitions={maxRepetitions}
            maxRepetitionError={errors.get('maxRepetitions')}
            onChange={(maxRepetitions) => setMaxRepetitions(maxRepetitions)}
          />

          <PasswordExpirationForm
            enable={enablePasswordExpiration}
            expirationPeriod={passwordExpirationPeriod}
            expirationPeriodError={errors.get('passwordExpirationPeriod')}
            numberSkips={numberSkips}
            skippingPeriod={skippingPeriod}
            onChange={(enablePasswordExpiration, passwordExpirationPeriod, numberSkips, skippingPeriod) => {
              setEnablePasswordExpiration(enablePasswordExpiration);
              setPasswordExpirationPeriod(passwordExpirationPeriod);
              setNumberSkips(numberSkips);
              setSkippingPeriod(skippingPeriod);
            }}
          />

          <Box sx={{ mt: '40px' }}>
            <Button loading={isSubmitting} variant="outlined" onClick={onSubmit} data-testid="save-button">
              {t('organization.organization-settings.password-policy.submit-button-text')}
            </Button>
            <Button sx={{ marginLeft: 2 }} variant="outlined" onClick={() => navigate('/')} data-testid="cancel-button">
              {t('organization.organization-settings.password-policy.cancel-button-text')}
            </Button>
          </Box>
        </Box>
      </Fade>
    </App>
  );
}

export default OrganizationPasswordPolicy;
