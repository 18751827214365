import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enTranslations from 'locales/en/translation.json';
import ptTranslations from 'locales/pt/translation.json';
import { ENVIRONMENT } from 'config/settings';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      pt: {
        translation: ptTranslations,
      },
    },
    fallbackLng: 'en-us',
    debug: ENVIRONMENT === 'dev',
    supportedLngs: ['en', 'pt'],
    nonExplicitSupportedLngs: true,
    lowerCaseLng: true,
    // language detection settings
    detection: {
      // order and from where user language should be detected
      order: ['cookie'],
      // keys or params to lookup language from
      lookupCookie: 'language',
      // optional set cookie options, reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
  });

export default i18n;
