import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

function AcquisitionMenuItem({ icon, onClick, text, ...props }) {
  return (
    <ListItemButton onClick={onClick} {...props}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText secondary={text} />
    </ListItemButton>
  );
}

export default AcquisitionMenuItem;
