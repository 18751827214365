import PropTypes from 'prop-types';
import Header from 'components/dialogs/DialogHeader';
import Button from 'components/Button';
import {
  Avatar,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const MembersSelectDialog = ({
  title,
  allMembers = [],
  selectedIds = [],
  isSavingMembers = false,
  show = false,
  onCheckboxClick,
  onDialogToggle,
  onAddClick,
}) => {
  const { t } = useTranslation();

  const memberList = allMembers.map((member) => (
    <Box
      key={`member-${member.id}`}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '12px',
        padding: '4px 12px',
        transition: 'var(--transition-default)',
        '&:hover': {
          backgroundColor: 'var(--color-grey-light)',
        },
      }}
      data-testid={`member-${member.id}`}
    >
      <Box display="flex" alignItems="center">
        <Avatar sx={{ margin: '0 12px 0 0' }} src={member.user.photo} alt="Member profile image" />
        <Typography
          color="secondary"
          variant="body2"
        >{`${member.user.first_name} ${member.user.last_name}`}</Typography>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedIds.includes(member.id)}
            onChange={() => onCheckboxClick(member.id)}
            inputProps={{ 'data-testid': `member-${member.id}-checkbox` }}
          />
        }
      />
    </Box>
  ));

  return (
    <Dialog open={show}>
      <Header>{title}</Header>
      <DialogContent>
        <Box
          sx={{
            border: 'solid 1px var(--color-grey)',
            borderRadius: '4px',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
            maxHeight: '333px',
            overflowY: 'scroll',
          }}
        >
          {memberList}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography color="secondary" variant="body2" data-testid="dialog-member-counter">
          {t('organization.organization-units-members.box-counter', { count: selectedIds.length })}
        </Typography>
        <Box display="flex" flexGrow={1} alignItems="center" justifyContent="flex-end">
          <Button fullWidth onClick={onDialogToggle} data-testid="dialog-button-cancel">
            {t('organization.organization-units-members.button-cancel-box')}
          </Button>
          <Button
            fullWidth
            color="gradient"
            onClick={onAddClick}
            loading={isSavingMembers}
            data-testid="dialog-button-save"
          >
            {t('organization.organization-units-members.button-save-box')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

MembersSelectDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  allMembers: PropTypes.instanceOf(Array),
  selectedIds: PropTypes.instanceOf(Array),
  isSavingMembers: PropTypes.bool,
  show: PropTypes.bool,
  onCheckboxClick: PropTypes.func.isRequired,
  onDialogToggle: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

export default MembersSelectDialog;
