import { Box, Grid, TextField } from '@mui/material';
import SubSubtitle from 'components/SubSubtitle';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';

function DigitsPolicyForm({ minDigits, minDigitsError, maxDigits, maxDigitsError, onChange }) {
  const { t } = useTranslation();

  return (
    <Box marginBottom="48px">
      <SubSubtitle>{t('organization.organization-settings.password-policy.subsubtitle-digits')}</SubSubtitle>

      <Grid container direction="row" rowSpacing="16px" columnSpacing={1} sx={{ marginTop: '4px' }}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="min-digits"
            value={minDigits}
            onChange={(event) => onChange(filterNumbers(event.target.value), maxDigits)}
            label={t('organization.organization-settings.password-policy.min-label')}
            variant="outlined"
            error={Boolean(minDigitsError)}
            helperText={minDigitsError}
            data-testid="min-digits-text-field"
            inputProps={{ 'data-testid': 'min-digits-input' }}
            FormHelperTextProps={{ 'data-testid': 'min-digits-error' }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="max-digits"
            value={maxDigits}
            onChange={(event) => onChange(minDigits, filterNumbers(event.target.value))}
            label={t('organization.organization-settings.password-policy.max-label')}
            variant="outlined"
            error={Boolean(maxDigitsError)}
            helperText={maxDigitsError}
            data-testid="max-digits-text-field"
            inputProps={{ 'data-testid': 'max-digits-input' }}
            FormHelperTextProps={{ 'data-testid': 'max-digits-error' }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DigitsPolicyForm;
