import { getPasswordValidators } from 'utils/password-validators';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

function PasswordRequirements({ nonFulfilledRules, passwordPolicy }) {
  const { t } = useTranslation();

  const validators = getPasswordValidators(passwordPolicy);

  const nonFulfilledRulesMap = new Map();

  if (nonFulfilledRules) {
    nonFulfilledRules.forEach((field) => nonFulfilledRulesMap.set(field, true));
  }

  return (
    <Box marginBottom="32px">
      <Typography variant="body2" color="secondary" data-testid="base-text">
        {t('organization.password-rules.password-requirements-base-text')}
      </Typography>
      <ul>
        {validators.map((validator) => {
          let variant = '';

          if (nonFulfilledRules) {
            variant = nonFulfilledRulesMap.has(validator.policyCode) ? 'error' : 'success';
          }

          if (validator.message) {
            return (
              <Typography key={validator.policyCode} variant="body2" color="secondary">
                <li className={styles[variant]} data-testid={`rule-${validator.policyCode}`}>
                  {validator.message}
                </li>
              </Typography>
            );
          }

          return null;
        })}
      </ul>
    </Box>
  );
}

export default PasswordRequirements;
