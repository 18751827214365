import AuthTitle from 'components/AuthTitle';
import Section from 'components/Section';
import { Typography } from '@mui/material';
import Subtitle from 'components/Subtitle';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.css';

export default function TermsAndServicesPortuguese() {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <AuthTitle>{t('auth.terms-and-service.title00')}</AuthTitle>

      <Subtitle>{t('auth.terms-and-service.title01')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text00')}
        <br />
        <br />
        {t('auth.terms-and-service.text01')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title02')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text02')}
        <br />
        <br />
        {t('auth.terms-and-service.text03')}
        <br />
        <br />
        {t('auth.terms-and-service.text04')}
        <br />
        <br />
        {t('auth.terms-and-service.text05')}
        <br />
        <br />
        {t('auth.terms-and-service.text06')}
        <br />
        <br />
        {t('auth.terms-and-service.text07')}
        <br />
        <br />
        {t('auth.terms-and-service.text08')}
        <br />
        <br />
        {t('auth.terms-and-service.text09')}
        <br />
        <br />
        {t('auth.terms-and-service.text10')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title03')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text11')}
        <br />
        <br />
        {t('auth.terms-and-service.text12')}
        <br />
        <br />
        {t('auth.terms-and-service.text13')}
        <br />
        <br />
        {t('auth.terms-and-service.text14')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title04')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text15')}
        <br />
        <br />
        {t('auth.terms-and-service.text16')}
        <br />
        <br />
        {t('auth.terms-and-service.text17')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title05')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text18')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title06')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text19')}
        <br />
        <br />
        {t('auth.terms-and-service.text20')}
        <br />
        <br />
        {t('auth.terms-and-service.text21')}
        <br />
        <br />
        {t('auth.terms-and-service.text22')}
        <br />
        <br />
        {t('auth.terms-and-service.text23')}
        <br />
        <br />
        {t('auth.terms-and-service.text24')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title07')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text25')}
        <br />
        <br />
        {t('auth.terms-and-service.text26')}
        <br />
        <br />
        {t('auth.terms-and-service.text27')}
        <br />
        <br />
        {t('auth.terms-and-service.text28')}
        <br />
        <br />
        {t('auth.terms-and-service.text29')}
        <br />
        <br />
        {t('auth.terms-and-service.text30')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title08')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text31')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title09')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text32')}
        <br />
        <br />
      </Typography>

      <Subtitle>{t('auth.terms-and-service.title10')}</Subtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('auth.terms-and-service.text33')}
        <br />
        <br />
        {t('auth.terms-and-service.text34')}
        <br />
        <br />
        {t('auth.terms-and-service.text35')}
        <br />
        <br />
        {t('auth.terms-and-service.text36')}
        <br />
        <br />
        {t('auth.terms-and-service.text37')}
        <br />
        <br />
      </Typography>
    </Section>
  );
}
