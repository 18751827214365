import Cookie from 'js-cookie';

export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

export const setToken = (type, token) => {
  Cookie.set(type, token, { sameSite: 'strict', expires: 365 });
};

export const getToken = (type) => Cookie.get(type);

export const clearAllTokens = () => {
  Cookie.remove(ACCESS_TOKEN_KEY);
  Cookie.remove(REFRESH_TOKEN_KEY);
};
