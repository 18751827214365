import i18next from 'i18next';
import PasswordValidator from './PasswordValidator';

export default class LengthValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { sizeRange } = passwordPolicy;

    const { min, max } = sizeRange;

    let translation = null;

    if (min !== undefined && max !== undefined) {
      translation = i18next.t('organization.password-rules.size-constraint-1', { min, max });
    } else if (min !== undefined) {
      translation = i18next.t('organization.password-rules.size-constraint-2', { min });
    } else if (max !== undefined) {
      translation = i18next.t('organization.password-rules.size-constraint-3', { max });
    }

    super(sizeRange, 'size_range', false, translation);
  }

  validate(password) {
    if (this.policy) {
      const { min, max } = this.policy;
      const lengthPassword = password.length;

      if (min > lengthPassword || max < lengthPassword) {
        return false;
      }
    }

    return true;
  }
}
