import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from 'components/Button';
import DialogHeader from 'components/dialogs/DialogHeader';
import { useTranslation } from 'react-i18next';

function SuspendUserDialog({ open, onClose, onSuspend, ...props }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} data-testid="suspend-dialog" {...props}>
      <DialogHeader>{t('braincare.users-edit-suspend-box.title-box-member-suspend')}</DialogHeader>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('braincare.users-edit-suspend-box.box-text-organization-members-exclude')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} data-testid="dismiss-button">
          {t('braincare.users-edit-suspend-box.button-cancel')}
        </Button>
        <Button fullWidth color="gradient" onClick={onSuspend} data-testid="confirm-suspension-button">
          {t('braincare.users-edit-suspend-box.button-suspend')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SuspendUserDialog;
