import { useEffect, useRef, useState } from 'react';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import CloseIcon from '@mui/icons-material/Close';
import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import { validateEmail } from 'utils/validation';
import App from 'pages/account/template';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS_LOADING, setOrganizationProfileData } from 'features/userSlice';
import { requestRemoval, updateCorporateAccount } from 'api/portal';
import { Box, TextField } from '@mui/material';
import Loader from 'components/SectionLoader';
import { useTranslation } from 'react-i18next';
import { filterPhoneChars } from 'utils/inputs';
import Fade from 'components/Fade';

function CorporateProfileInfo() {
  const user = useSelector((state) => state.user);
  const ref = useRef();

  const organizationProfile = user.organization_profile;

  const [email, setEmail] = useState(organizationProfile.email);
  const [phoneNumber, setPhoneNumber] = useState(organizationProfile.phone_number);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(new Map());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (isLoading && ref.current === STATUS_LOADING && user.status !== STATUS_LOADING) {
      setIsLoading(false);

      setEmail(organizationProfile.email);
      setPhoneNumber(organizationProfile.phone_number);
    }

    ref.current = user.status;
  }, [user.status]);

  const onClickRemoveButton = () => {
    requestRemoval()
      .then(() => dispatch(showSuccessToast('messages.request-success')))
      .catch(() => dispatch(showErrorToast('messages.request-failure')));
  };

  const validateForm = () => {
    const error = new Map();

    if (email === '' || !validateEmail(email)) {
      error.set('email', t('messages.invalid-email'));
    }

    return error;
  };

  const handleOnSaveChanges = () => {
    const error = validateForm();

    setError(error);

    if (error.size > 0) {
      dispatch(showErrorToast('messages.fix-fields'));
      return;
    }

    setIsSaving(true);

    updateCorporateAccount({
      email,
      phone_number: phoneNumber,
    })
      .then((response) => {
        setIsSaving(false);

        const { data } = response;

        dispatch(setOrganizationProfileData(data));
        dispatch(showSuccessToast('account.member-edit-info.save-success-message'));
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(showErrorToast('account.member-edit-info.save-failure-message'));
      });
  };

  const onBlur = () => {
    const error = validateForm();
    setError(error);
  };

  return (
    <App>
      <Fade>
        <Box maxWidth="428px">
          <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <Subtitle>{t('account.member-edit-info.menu-title')}</Subtitle>

            <Loader loading={isLoading}>
              <Fade>
                <TextField
                  id="email"
                  sx={{ margin: '16px 0 16px 0' }}
                  value={email}
                  label={t('account.member-edit-info.input-email')}
                  onChange={(event) => setEmail(event.target.value)}
                  onBlur={onBlur}
                  variant="outlined"
                  data-testid="email-text-field"
                  inputProps={{ 'data-testid': 'email-input' }}
                  error={error.has('email')}
                  helperText={error.get('email')}
                  FormHelperTextProps={{ 'data-testid': 'email-error' }}
                  fullWidth
                />

                <TextField
                  id="phone"
                  sx={{ margin: '16px 0 16px 0' }}
                  value={phoneNumber}
                  label={t('account.member-edit-info.input-phone')}
                  onChange={(event) => setPhoneNumber(filterPhoneChars(event.target.value))}
                  onBlur={onBlur}
                  variant="outlined"
                  data-testid="phone-text-field"
                  inputProps={{ 'data-testid': 'phone-input' }}
                  error={error.has('phone')}
                  helperText={error.get('phone')}
                  FormHelperTextProps={{ 'data-testid': 'phone-error' }}
                  fullWidth
                />

                <Box marginBottom="32px">
                  <Button
                    icon="cross"
                    variant="outlined"
                    color="error"
                    onClick={onClickRemoveButton}
                    startIcon={<CloseIcon />}
                    data-testid="request-removal-button"
                  >
                    {t('account.member-edit-info.button-request-remove')}
                  </Button>
                </Box>

                <Button
                  color="gradient"
                  loading={isSaving}
                  onClick={handleOnSaveChanges}
                  type="submit"
                  data-testid="save-button"
                >
                  {t('account.member-edit-info.button-save')}
                </Button>
                <Button
                  sx={{ margin: '0 0 0 16px' }}
                  variant="outlined"
                  onClick={() => navigate('/')}
                  data-testid="cancel-button"
                >
                  {t('account.member-edit-info.button-cancel')}
                </Button>
              </Fade>
            </Loader>
          </form>
        </Box>
      </Fade>
    </App>
  );
}

export default CorporateProfileInfo;
