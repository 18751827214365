import { useEffect, useState } from 'react';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import AuthTitle from 'components/AuthTitle';
import LinkItem from 'components/LinkItem';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from 'components/Button';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from 'components/SectionLoader';
import { validatePassword as applyPolicyValidation } from 'utils/password-validators';
import { useDispatch } from 'react-redux';
import { confirmPassword, fetchPasswordPolicy } from 'api/portal';
import { useTranslation } from 'react-i18next';
import RuledPasswordInput from 'components/RuledPasswordInput';
import Fade from 'components/Fade';

function DefinePassword() {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);
  const [errors, setErrors] = useState(new Map());
  const [passwordPolicyErrors, setPasswordPolicyErrors] = useState(undefined);
  const [hasReadTermsAndConditions, setHasReadTermsAndConditions] = useState(false);
  const [hasReadPrivacyPolicy, setHasReadPrivacyPolicy] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [passwordPolicy, setPasswordPolicy] = useState(undefined);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const { t } = useTranslation();

  const MISMATCH_PASSWORD_ERROR = t('messages.mismatch-error');
  const REQUIRED_FIELD_ERROR = t('account.edit-password.fill-required');

  useEffect(() => {
    const newUser = searchParams.get('new');

    const { uid1, uid2, token } = params;

    fetchPasswordPolicy({ uid_1: uid1, uid_2: uid2, token })
      .then((response) => {
        const { data } = response;

        const currentStep = newUser ? 1 : 2;

        setPasswordPolicy(data.password_policy);
        setCurrentStep(currentStep);
      })
      .catch(() => {
        dispatch(showErrorToast('auth.define-password.define-invalid-token-message'));
        navigate('/auth/direct-login');
      });
  }, []);

  const validatePassword = (password) => {
    const policyErrors = applyPolicyValidation(passwordPolicy, password);
    setPasswordPolicyErrors(policyErrors);

    errors.delete('password');
    errors.delete('passwordConfirmation');

    if (policyErrors.length !== 0) {
      errors.set('password', policyErrors);
    }

    if (!password) {
      errors.set('password', REQUIRED_FIELD_ERROR);
    }

    if (password !== passwordConfirmation) {
      errors.set('passwordConfirmation', MISMATCH_PASSWORD_ERROR);
    }

    setPassword(password);
    setErrors(errors);
  };

  const validatePasswordConfirmation = (passwordConfirmation) => {
    errors.delete('passwordConfirmation');

    if (password !== passwordConfirmation) {
      errors.set('passwordConfirmation', MISMATCH_PASSWORD_ERROR);
    }

    setPasswordConfirmation(passwordConfirmation);
    setErrors(errors);
  };

  const handleOnSubmit = () => {
    validatePassword(password);
    validatePasswordConfirmation(passwordConfirmation);

    if (errors.size > 0) {
      dispatch(showErrorToast('messages.fix-fields'));
      return;
    }

    const { uid1, uid2, token } = params;

    confirmPassword({ uid_1: uid1, uid_2: uid2, token, password })
      .then(() => {
        dispatch(showSuccessToast('auth.define-password.define-success-message'));
        navigate('/auth/direct-login');
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        const nonFieldErrors = data.non_field_errors;

        if (Array.isArray(nonFieldErrors)) {
          const policyErrors = nonFieldErrors.map((passwordError) => passwordError.split(']')[0].split('[')[1]);
          setPasswordPolicyErrors(policyErrors);
        }

        dispatch(showErrorToast('auth.define-password.define-failure-message'));
      });
  };

  const renderIsNew = () => (
    <Fade>
      <Box maxWidth="428px">
        <AuthTitle>{t('auth.define-password-new.title')}</AuthTitle>
        <Typography variant="body2" color="secodary" marginBottom="24px">
          {t('auth.define-password-new.text-terms-conditions')}
          &nbsp;
          <LinkItem href="/public/terms-and-service" onClick={() => setHasReadTermsAndConditions(true)} target="_blank">
            {t('auth.define-password-new.text-terms')}
          </LinkItem>
          {t('auth.define-password-new.text-terms-conditions-between')}
          <LinkItem href="/public/privacy-policy" onClick={() => setHasReadPrivacyPolicy(true)} target="_blank">
            {t('auth.define-password-new.text-conditions')}
          </LinkItem>
          {t('auth.define-password-new.text-terms-accept')}
        </Typography>

        <Button
          color="gradient"
          fullWidth
          size="large"
          onClick={() => setCurrentStep(2)}
          isDisabled={!(hasReadPrivacyPolicy && hasReadTermsAndConditions)}
        >
          {t('auth.define-password-new.button-accept')}
        </Button>
      </Box>
    </Fade>
  );

  const renderDefinePassword = () => (
    <Fade>
      <Box maxWidth="428px">
        <AuthTitle data-testid="title-main-password">{t('auth.define-password.title-main-password')}</AuthTitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('auth.define-password.text-password-format')}
        </Typography>

        <RuledPasswordInput
          errors={passwordPolicyErrors}
          hasError={errors.has('password')}
          passwordPolicy={passwordPolicy}
          onChange={(event) => validatePassword(event.target.value)}
          value={password}
        />

        <FormControl error={errors.has('passwordConfirmation')} fullWidth>
          <InputLabel data-testid="password-confirmation-label">
            {t('auth.define-password.input-confirmed-password')}
          </InputLabel>
          <OutlinedInput
            name="password_confirmation"
            value={passwordConfirmation}
            onChange={(event) => validatePasswordConfirmation(event.target.value)}
            onBlur={(event) => validatePasswordConfirmation(event.target.value)}
            type={isPasswordConfirmationVisible ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)}
                  edge="end"
                >
                  {isPasswordConfirmationVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{ 'data-testid': 'password-confirmation-input' }}
          />
          <FormHelperText data-testid="password-confirmation-error">
            {errors.get('passwordConfirmation')}
          </FormHelperText>
        </FormControl>

        <Button
          sx={{ margin: '24px 0 16px 0' }}
          color="gradient"
          fullWidth
          onClick={handleOnSubmit}
          loading={false}
          data-testid="save-button"
        >
          {t('auth.define-password.button-save')}
        </Button>
        <Button fullWidth variant="outlined" onClick={() => navigate('/auth/direct-login')} data-testid="cancel-button">
          {t('auth.define-password.button-cancel')}
        </Button>
      </Box>
    </Fade>
  );

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return renderIsNew();
      case 2:
        return renderDefinePassword();
      default:
        return null;
    }
  };

  return <Loader loading={passwordPolicy === undefined}>{renderCurrentStep()}</Loader>;
}

export default DefinePassword;
