import i18next from 'i18next';
import PasswordValidator from './PasswordValidator';

export default class DigitsValidator extends PasswordValidator {
  constructor(passwordPolicy) {
    const { digitsRange } = passwordPolicy;

    const { min, max } = digitsRange;

    let translation = null;

    if (min !== undefined && max !== undefined) {
      translation = i18next.t('organization.password-rules.digits-constraint-1', { min, max });
    } else if (min !== undefined) {
      translation = i18next.t('organization.password-rules.digits-constraint-2', { min });
    } else if (max !== undefined) {
      translation = i18next.t('organization.password-rules.digits-constraint-3', { max });
    }

    super(digitsRange, 'digits_range', false, translation);
  }

  validate(password) {
    if (this.policy) {
      const digitsChars = password.match(/\d/g);

      const min = this.policy.min ? this.policy.min : 0;
      const max = this.policy.max ? this.policy.max : 128;

      const numberDigits = digitsChars ? digitsChars.length : 0;

      if (min > numberDigits || max < numberDigits) {
        return false;
      }
    }

    return true;
  }
}
