export const isCpf = (username) =>
  /^(\d(\d(\d(\.?(\d(\d(\d(\.?(\d(\d(\d(-?(\d(\d)?)?)?)?)?)?)?)?)?)?)?)?)?)?$/.test(username);

export const validateCpf = (username) => {
  if (!isCpf(username)) {
    return false;
  }

  const cpf = username.replace(/[^\d]+/g, '');
  if (cpf === '') return false;

  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }

  let add = 0;
  for (let i = 0; i < 9; i += 1) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }

  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(9), 10)) {
    return false;
  }

  add = 0;
  for (let i = 0; i < 10; i += 1) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
  }

  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(10), 10)) {
    return false;
  }

  return true;
};

/* eslint-disable */
export const validateEmail = (email) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const validateDate = (value) => {
  const date = new Date(value);
  return date instanceof Date && !Number.isNaN(date.getTime());
};

export const validateISOCompleteDate = (value) => {
  // check if value is a String having the form: YYYY-MM-DD
  if (typeof value === 'string') {
    let arr = value.split('-');

    if (arr.length !== 3) return false;

    arr = arr.filter((el) => Number.isInteger(Number(el)));
    if (arr.length !== 3) return false;

    return !Number.isNaN(new Date(value));
  }
  return false;
};

export const hasBulkUploadFileExtension = (file) => {
  const allowed_extensions = ['csv', 'xlsx', 'xls'];

  const filename = file.name;

  const re = /(?:\.([^.]+))?$/;
  const reResults = re.exec(filename);
  const extension = reResults[reResults.length - 1];

  return allowed_extensions.includes(extension);
};
