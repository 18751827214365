import { useEffect, useState } from 'react';
import App from 'components/templates/app';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import Loader from 'components/SectionLoader';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearOrganizationData, setOrganizationSelectedUnit } from 'features/organizationSlice';
import { fetchUnits } from 'api/portal';
import UnitCard from 'components/cards/UnitCard';
import { FormControl, Grid, InputLabel, OutlinedInput, Pagination, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';

const PAGE_SIZE = 8;

function Units() {
  const [isUnitsLoading, setIsUnitsLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [members, setMembers] = useState({});
  const [filterValue, setFilterValue] = useState('');
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_ORGANIZATION = t('organization.breadcrumbs.manage-organization');

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/organization/units',
      title: MANAGE_ORGANIZATION,
      isActive: true,
    },
  ];

  const linkItems = [
    {
      path: '/organization/members',
      title: t('organization.organization-members.title-sub-member'),
    },
    {
      path: '/organization/units',
      title: t('organization.organization-units.title-sub-unit-edit'),
    },
  ];

  const actionButton = {
    text: t('organization.organization-units.button-add-unit'),
    href: '/organization/units/create',
  };

  const getUnits = (page, filterValue = '') => {
    setIsUnitsLoading(true);

    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', PAGE_SIZE);

    if (filterValue !== '') {
      searchParams.set('search_name', filterValue);
    }

    fetchUnits(searchParams).then((response) => {
      const { data } = response;

      const units = data.results;

      setUnits(units);
      setCount(Math.ceil(data.count / PAGE_SIZE));
      setCurrentPage(page);
      setIsUnitsLoading(false);

      const membersMap = {};

      units.forEach((unit) => {
        const { members } = unit;

        membersMap[unit.id] = {
          results: members,
          count: members.length,
          loaded: true,
        };
      });

      setMembers(membersMap);
    });
  };

  const onClearFilterClick = () => {
    setFilterValue('');
    getUnits(1);
  };

  useEffect(() => {
    dispatch(clearOrganizationData());
    getUnits(1);
  }, []);

  const openEditUnitPage = (unit) => {
    dispatch(setOrganizationSelectedUnit(unit));
    navigate(`/organization/units/${unit.id}/edit`);
  };

  return (
    <App
      title={t('organization.organization-units.title-main-units')}
      breadcrumbs={breadcrumbs}
      linkItems={linkItems}
      actionButton={actionButton}
    >
      <Section>
        <Subtitle>{t('organization.organization-units.title-filter-units')}</Subtitle>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <Grid container marginTop="8px" marginBottom="16px" spacing={1}>
            <Grid item xs={12} sm={4} md={3}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel htmlFor="filter">{t('organization.organization-units.input-filter-name')}</InputLabel>
                <OutlinedInput
                  value={filterValue}
                  onChange={(event) => setFilterValue(event.target.value)}
                  placeholder={t('organization.organization-units.dropdown-filter-name')}
                  inputProps={{ 'data-testid': 'input-filter-name' }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button variant="outlined" onClick={onClearFilterClick} fullWidth data-testid="clear-filter-button">
                {t('organization.organization-units.button-filter-reset')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                color="gradient"
                loading={false}
                onClick={() => getUnits(1, filterValue)}
                type="submit"
                fullWidth
                data-testid="apply-filter-button"
              >
                {t('organization.organization-units.button-filter-apply')}
              </Button>
            </Grid>
          </Grid>
        </form>

        <Loader loading={isUnitsLoading}>
          <Fade>
            {units.length === 0 ? (
              <Typography display="flex" justifyContent="center" variant="body2" color="secondary">
                {t('messages.no-results-found')}
              </Typography>
            ) : (
              <Grid container spacing="24px">
                {units.map((unit) => (
                  <Grid key={unit.id} item xs={12} sm={6} md={3}>
                    <UnitCard
                      sx={{ height: '100%' }}
                      members={members}
                      onClick={() => openEditUnitPage(unit)}
                      unit={unit}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Fade>
          <Pagination count={count} page={currentPage} onChange={(event, value) => getUnits(value, filterValue)} />
        </Loader>
      </Section>
    </App>
  );
}

export default Units;
