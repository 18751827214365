import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'assets/styles/variables.css';
import 'assets/styles/normalize.css';
import 'assets/styles/index.css';
import SnackbarContainer from 'components/SnackbarContainer';
import NotFound from 'pages/NotFound';
import Index from 'pages/Home';
import Security from 'pages/public/Security';
import TermsAndServices from 'pages/public/TermsAndServices';
import PrivacyPolicy from 'pages/public/PrivacyPolicy';
import ForgotPassword from 'pages/auth/ForgotPassword';
import DefinePassword from 'pages/auth/DefinePassword';
import EduLogin from 'pages/auth/EduLogin';
import EduSignup from 'pages/auth/EduSignup';
import AcceptTerms from 'pages/auth/AcceptTerms';
import Logout from 'pages/auth/Logout';
import DirectLogin from 'pages/auth/DirectLogin';
import CorporatePermissions from 'pages/account/CorporatePermissions';
import CorporateInfo from 'pages/account/CorporateInfo';
import PersonalInfo from 'pages/account/PersonalInfo';
import CorporatePassword from 'pages/account/CorporatePassword';
import MemberList from 'pages/organization/members/MemberList';
import MemberPersonalInfo from 'pages/organization/members/MemberPersonalInfo';
import MemberPermissions from 'pages/organization/members/MemberPermissions';
import MemberSuspension from 'pages/organization/members/MemberSuspension';
import MemberPassword from 'pages/organization/members/MemberPassword';
import MemberCreation from 'pages/organization/members/MemberCreation';
import BulkUpload from 'pages/organization/members/BulkUpload';
import UnitList from 'pages/organization/units/UnitList';
import UnitInfo from 'pages/organization/units/UnitInfo';
import UnitMembers from 'pages/organization/units/UnitMembers';
import UnitCreation from 'pages/organization/units/UnitCreation';
import MobileDeviceStatus from 'pages/organization/mobile-devices/MobileDeviceStatus';
import MobileDevicePinPolicy from 'pages/organization/mobile-devices/MobileDevicePinPolicy';
import MobileDeviceList from 'pages/organization/mobile-devices/MobileDeviceList';
import MobileDeviceCreation from 'pages/organization/mobile-devices/MobileDeviceCreation';
import MobileDeviceInfo from 'pages/organization/mobile-devices/MobileDeviceInfo';
import OrganizationReports from 'pages/organization/OrganizationReports';
import AdminOrganizationList from 'pages/braincare/organizations/AdminOrganizationList';
import AdminMemberCreation from 'pages/braincare/members/AdminMemberCreation';
import AdminBulkUpload from 'pages/braincare/members/AdminBulkUpload';
import AdminOrganizationInfo from 'pages/braincare/organizations/AdminOrganizationInfo';
import AdminOrganizationInactivityPolicy from 'pages/braincare/organizations/AdminOrganizationInactivityPolicy';
import AdminOrganizationIpBlocking from 'pages/braincare/organizations/AdminOrganizationIpBlocking';
import AdminOrganizationPasswordPolicy from 'pages/braincare/organizations/AdminOrganizationPasswordPolicy';
import AdminOrganizationReportsPolicies from 'pages/braincare/organizations/AdminOrganizationReportsPolicies';
import AdminUserList from 'pages/braincare/users/AdminUserList';
import AdminUserCreation from 'pages/braincare/users/AdminUserCreation';
import AdminUserSuspension from 'pages/braincare/users/AdminUserSuspension';
import AdminUserInfo from 'pages/braincare/users/AdminUserInfo';
import AdminMemberList from 'pages/braincare/members/AdminMemberList';
import AdminMemberInfo from 'pages/braincare/members/AdminMemberInfo';
import AdminMemberPermissions from 'pages/braincare/members/AdminMemberPermissions';
import AdminMemberSuspension from 'pages/braincare/members/AdminMemberSuspension';
import AdminMemberPassword from 'pages/braincare/members/AdminMemberPassword';
import MobileDevicePinRedefinition from 'pages/organization/mobile-devices/MobileDevicePinRedefinition';
import OrganizationPasswordPolicy from 'pages/organization/settings/OrganizationPasswordPolicy';
import OrganizationInactivityPolicy from 'pages/organization/settings/OrganizationInactivityPolicy';
import OrganizationIpBlocking from 'pages/organization/settings/OrganizationIpBlocking';
import OrganizationReportsPolicies from 'pages/organization/settings/OrganizationReportsPolicies';
import AdminOrganizationReports from 'pages/braincare/organizations/AdminOrganizationReports';
import OrganizationInfo from 'pages/organization/settings/OrganizationInfo';
import PolicyPage from 'pages/public/PolicyPage';
import AuthenticatedPage from 'pages/public/AuthenticatedPage';
import PublicPage from 'pages/public/PublicPage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'theme';
import { Store } from 'store';
import LogsAdmin from 'pages/logs/LogsAdmin';
import LogsMember from 'pages/logs/LogsMember';
import withoutAuth from './components/routes/unsigned-route/withoutAuth';

const App = () => (
  <Provider store={Store}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/auth/edu/login" element={withoutAuth(<EduLogin />)} />
            <Route path="/auth/edu/signup" element={withoutAuth(<EduSignup />)} />
            <Route path="/auth/login" element={withoutAuth(<DirectLogin />)} />
            <Route path="/auth/direct-login" element={withoutAuth(<DirectLogin />)} />
            <Route path="/auth/accept-terms" element={withoutAuth(<AcceptTerms />)} />
            <Route path="/auth/logout" element={<Logout />} />
            <Route element={<PublicPage />}>
              <Route path="/auth/forgot-password/:orgId?" element={<ForgotPassword />} />
              <Route exact path="/auth/define-password/:uid1/:uid2/:token" element={<DefinePassword />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route element={<PolicyPage />}>
              <Route path="/public/security" element={<Security />} />
              <Route path="/public/terms-and-service" element={<TermsAndServices />} />
              <Route path="/public/privacy-policy" element={<PrivacyPolicy />} />
            </Route>
            <Route element={<AuthenticatedPage />}>
              <Route path="/account/member/edit/personal-info" element={<PersonalInfo />} />
              <Route path="/account/member/edit/corporate-info" element={<CorporateInfo />} />
              <Route path="/account/member/edit/permissions" element={<CorporatePermissions />} />
              <Route path="/account/member/edit/password" element={<CorporatePassword />} />
              <Route exact path="/organization/members" element={<MemberList />} />
              <Route path="/organization/members/:memberId/edit/info" element={<MemberPersonalInfo />} />
              <Route path="/organization/members/:memberId/edit/permissions" element={<MemberPermissions />} />
              <Route path="/organization/members/:memberId/edit/suspend" element={<MemberSuspension />} />
              <Route path="/organization/members/:memberId/edit/password" element={<MemberPassword />} />
              <Route path="/organization/members/create" element={<MemberCreation />} />
              <Route path="/organization/members/bulk-upload-instructions" element={<BulkUpload />} />
              <Route exact path="/organization/units" element={<UnitList />} />
              <Route path="/organization/units/:unitId/edit" element={<UnitInfo />} />
              <Route path="/organization/units/:unitId/members" element={<UnitMembers />} />
              <Route path="/organization/units/create" element={<UnitCreation />} />
              <Route path="/org-mobile-devices/devices/add" element={<MobileDeviceCreation />} />
              <Route path="/org-mobile-devices/devices/:deviceId/edit-status" element={<MobileDeviceStatus />} />
              <Route path="/org-mobile-devices/devices/:deviceId/pin-policy" element={<MobileDevicePinPolicy />} />
              <Route path="/org-mobile-devices/devices/:deviceId/pin-edit" element={<MobileDevicePinRedefinition />} />
              <Route exact path="/org-mobile-devices/devices" element={<MobileDeviceList />} />
              <Route path="/org-mobile-devices/devices/:deviceId/info" element={<MobileDeviceInfo />} />
              <Route exact path="/organization/physio" element={<OrganizationReports />} />
              <Route exact path="/braincare/organizations" element={<AdminOrganizationList />} />
              <Route path="/braincare/organizations/:orgId/edit/info" element={<AdminOrganizationInfo />} />
              <Route
                path="/braincare/organizations/:orgId/edit/inactivity-policy"
                element={<AdminOrganizationInactivityPolicy />}
              />
              <Route path="/braincare/organizations/:orgId/edit/ip-policy" element={<AdminOrganizationIpBlocking />} />
              <Route
                path="/braincare/organizations/:orgId/edit/password-policy"
                element={<AdminOrganizationPasswordPolicy />}
              />
              <Route
                path="/braincare/organizations/:orgId/edit/reports"
                element={<AdminOrganizationReportsPolicies />}
              />
              <Route exact path="/braincare/users" element={<AdminUserList />} />
              <Route exact path="/braincare/users/create" element={<AdminUserCreation />} />
              <Route path="/braincare/users/:userId/info" element={<AdminUserInfo />} />
              <Route path="/braincare/users/:userId/suspend" element={<AdminUserSuspension />} />
              <Route path="" element={<Index />} />
              <Route exact path="/logs" element={<LogsMember />} />
              <Route exact path="/braincare/logs" element={<LogsAdmin />} />
              <Route exact path="/braincare/organizations/:orgId/members" element={<AdminMemberList />} />
              <Route path="/braincare/organizations/:orgId/members/create" element={<AdminMemberCreation />} />
              <Route
                path="/braincare/organizations/:orgId/members/bulk-upload-instructions"
                element={<AdminBulkUpload />}
              />
              <Route
                exact
                path="/braincare/organizations/:orgId/members/:memberId/edit/info"
                element={<AdminMemberInfo />}
              />
              <Route
                exact
                path="/braincare/organizations/:orgId/members/:memberId/edit/permissions"
                element={<AdminMemberPermissions />}
              />
              <Route
                exact
                path="/braincare/organizations/:orgId/members/:memberId/edit/suspend"
                element={<AdminMemberSuspension />}
              />
              <Route
                exact
                path="/braincare/organizations/:orgId/members/:memberId/edit/password"
                element={<AdminMemberPassword />}
              />
              <Route exact path="/organization/info" element={<OrganizationInfo />} />
              <Route exact path="/organization/password-policy" element={<OrganizationPasswordPolicy />} />
              <Route exact path="/organization/inactivity-policy" element={<OrganizationInactivityPolicy />} />
              <Route exact path="/organization/ip-policy" element={<OrganizationIpBlocking />} />
              <Route exact path="/organization/reports" element={<OrganizationReportsPolicies />} />
              <Route exact path="/braincare/reports" element={<AdminOrganizationReports />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <SnackbarContainer />
      </ThemeProvider>
    </LocalizationProvider>
  </Provider>
);

export default App;
