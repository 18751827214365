import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Allowed(props) {
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.isBraincareUser) {
      navigate('/');
      return;
    }

    setIsLoading(false);
  }, []);

  const { WrappedComponent } = props;
  return isLoading ? null : <WrappedComponent {...props} />;
}

export default (WrappedComponent) =>
  ({ ...props }) => <Allowed WrappedComponent={WrappedComponent} {...props} />;
