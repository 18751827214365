import { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppWizard from 'components/templates/AppWizard';
import braincare from 'components/routes/braincare';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import Subtitle from 'components/Subtitle';
import DatePicker from 'components/DatePicker';
import { formatToCpfUsername, formatToDate, unformatCpf } from 'utils/format';
import { isCpf, validateCpf, validateEmail, validateDate } from 'utils/validation';
import { createBuUser } from 'api/portal';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';

function AdminUserCreate() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState();
  const [username, setUsername] = useState('');
  const [notificationLanguage, setNotificationLanguage] = useState('en-us');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidDate, setIsValidDate] = useState(true);
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [errors, setErrors] = useState(new Map());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const STEPS = [t('braincare.users-create.sidebar.step-user-info')];

  const FILL_REQUIRED = t('messages.fill-required');
  const INVALID_USERNAME_ERROR = t('messages.invalid-document');
  const INVALID_EMAIL_ERROR = t('messages.invalid-email');
  const INVALID_DATE_ERROR = t('messages.invalid-date');

  const HOME = t('braincare.breadcrumbs.home');
  const USERS = t('braincare.breadcrumbs.users');
  const USERS_CREATE = t('braincare.breadcrumbs.users-create');

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/users',
      title: USERS,
    },
    {
      path: '/braincare/users/create',
      title: USERS_CREATE,
      isActive: true,
    },
  ];

  const handleOnCreate = () => {
    setIsSubmitting(true);

    createBuUser({
      first_name: firstName,
      last_name: lastName,
      email,
      birth_date: formatToDate(date),
      document: isCpf(username) ? unformatCpf(username) : username,
      has_read_privacy_policy: false,
      has_read_terms_and_conditions: false,
      accept_terms: true,
      notification_language: notificationLanguage,
    })
      .then(() => {
        setIsSubmitting(false);
        dispatch(showSuccessToast('messages.save-success'));
        navigate('/braincare/users');
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(showErrorToast('messages.save-failure'));
      });
  };

  const handleValidateEmail = (value) => {
    setIsValidEmail(value === '' || validateEmail(value));
  };

  const handleValidateUsername = (event) => {
    const { value } = event.target;
    setIsValidUsername(validateCpf(value) || validateEmail(value));
  };

  const handleChangeBirthDate = (date) => {
    setIsValidDate(date === null || validateDate(date));
    setDate(date);
  };

  const validateFields = () => {
    const requiredFields = {
      firstName,
      lastName,
      date,
      username,
    };

    const errors = new Map();

    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors.set(key, FILL_REQUIRED);
      }
    });

    setErrors(errors);

    if (errors.size === 0 && isValidUsername && isValidDate && isValidEmail) {
      handleOnCreate();
    } else {
      dispatch(showErrorToast('messages.fix-fields'));
    }
  };

  const setFormattedUsername = (value) => {
    const username = isCpf(value) ? formatToCpfUsername(value) : value;
    setUsername(username);
  };

  return (
    <AppWizard title={USERS_CREATE} breadcrumbs={breadcrumbs} steps={STEPS} currentStep={0}>
      <Fade>
        <Box maxWidth="428px" marginBottom="48px">
          <Subtitle>{t('braincare.users-create.sidebar.step-user-info')}</Subtitle>
          <TextField
            id="first_name"
            sx={{ margin: '16px 0 16px 0' }}
            value={firstName}
            placeholder="e.g.: John"
            label={t('braincare.users-create.input-first-name')}
            variant="outlined"
            onChange={(event) => setFirstName(event.target.value)}
            data-testid="first-name-text-field"
            inputProps={{ 'data-testid': 'first-name-input' }}
            FormHelperTextProps={{ 'data-testid': 'first-name-error' }}
            error={errors.has('firstName')}
            helperText={errors.has('firstName') ? errors.get('firstName') : ''}
            fullWidth
          />

          <TextField
            id="last_name"
            sx={{ margin: '16px 0 16px 0' }}
            value={lastName}
            placeholder="e.g.: Doe"
            label={t('braincare.users-create.input-last-name')}
            variant="outlined"
            onChange={(event) => setLastName(event.target.value)}
            data-testid="last-name-text-field"
            inputProps={{ 'data-testid': 'last-name-input' }}
            FormHelperTextProps={{ 'data-testid': 'last-name-error' }}
            error={errors.has('lastName')}
            helperText={errors.has('lastName') ? errors.get('lastName') : ''}
            fullWidth
          />

          <TextField
            id="email"
            sx={{ margin: '16px 0 16px 0' }}
            value={email}
            placeholder="e.g.: johndoe@yourorganization.com"
            label={t('braincare.users-create.input-email')}
            variant="outlined"
            onChange={(event) => setEmail(event.target.value)}
            onBlur={() => handleValidateEmail(email)}
            data-testid="email-text-field"
            inputProps={{ 'data-testid': 'email-input' }}
            FormHelperTextProps={{ 'data-testid': 'email-error' }}
            error={!isValidEmail || errors.has('email')}
            helperText={(!isValidEmail && INVALID_EMAIL_ERROR) || (errors.has('email') ? errors.get('email') : '')}
            fullWidth
          />

          <DatePicker
            sx={{ margin: '16px 0 16px 0' }}
            label={t('braincare.users-create.input-birthdate')}
            value={date}
            onChange={handleChangeBirthDate}
            format="yyyy-MM-dd"
            slotProps={{
              textField: {
                fullWidth: true,
                error: !isValidDate || Boolean(errors && errors.get('date')),
                helperText:
                  (!isValidDate && INVALID_DATE_ERROR) || (errors && errors.get('date') ? errors.get('date') : ''),
                'data-testid': 'birth-date-text-field',
                InputProps: {
                  'data-testid': 'birth-date-input',
                },
                FormHelperTextProps: {
                  'data-testid': 'birth-date-error',
                },
              },
            }}
          />

          <TextField
            id="username"
            sx={{ margin: '16px 0 16px 0' }}
            value={username}
            label={t('braincare.users-create.input-document')}
            variant="outlined"
            onChange={(event) => setFormattedUsername(event.target.value)}
            onBlur={handleValidateUsername}
            data-testid="document-text-field"
            inputProps={{ 'data-testid': 'document-input' }}
            FormHelperTextProps={{ 'data-testid': 'document-error' }}
            error={!isValidUsername || errors.has('username')}
            helperText={
              (!isValidUsername && INVALID_USERNAME_ERROR) || (errors.has('username') ? errors.get('username') : '')
            }
            fullWidth
          />

          <FormControl fullWidth>
            <FormLabel id="demo-controlled-radio-buttons-group">{t('auth.signup.text-doc-choice-language')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={notificationLanguage}
              value={notificationLanguage}
              onChange={(event) => setNotificationLanguage(event.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="pt-br"
                control={<Radio inputProps={{ 'data-testid': 'pt-br-radio' }} />}
                label={t('auth.signup.input-language-pt-br')}
              />
              <FormControlLabel
                value="en-us"
                control={<Radio inputProps={{ 'data-testid': 'en-us-radio' }} />}
                label={t('auth.signup.input-language-en-us')}
              />
            </RadioGroup>
          </FormControl>
          <Button color="gradient" loading={isSubmitting} onClick={validateFields} data-testid="save-button">
            {t('braincare.users-create.buttons.button-confirm-and-save')}
          </Button>
          <Button
            sx={{ margin: '0 0 0 16px' }}
            variant="outlined"
            onClick={() => navigate('/braincare/users')}
            data-testid="cancel-button"
          >
            {t('braincare.users-create.buttons.button-cancel')}
          </Button>
        </Box>
      </Fade>
    </AppWizard>
  );
}

export default braincare(AdminUserCreate);
