import { useDispatch, useSelector } from 'react-redux';
import NotificationItem from 'components/NotificationItem';
import { formatToHumanizedDate } from 'utils/format';
import { useEffect, useRef, useState } from 'react';
import { clearNotifications, setNotificationsData, setNotificationsProps } from 'features/notificationsSlice';
import { useNavigate } from 'react-router-dom';
import { fetchNotifications, readNotification } from 'api/portal';
import { showErrorToast } from 'features/toastSlice';
import { Badge, Box, CircularProgress, Popover, Typography } from '@mui/material';
import Button from 'components/Button';
import NotificationHeader from 'components/NotificationHeader';
import MenuItem from 'components/MenuItem';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

function Notifications() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isReloadingNotifications, setIsReloadingNotifications] = useState();
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { t } = useTranslation();

  const notifications = useSelector((state) => state.notifications);

  const savedCallback = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reloadNotifications = () => {
    setIsReloadingNotifications(true);
    setHasError(false);

    fetchNotifications()
      .then((response) => {
        const { data } = response;

        if (data && data.results.length > 0) {
          dispatch(
            setNotificationsProps({
              data: data.results,
              nextNotificationPage: data.next ? 2 : null,
              total: data.count,
              count: data.results.length,
              unreadCount: data.unread_count,
            }),
          );
        }

        // The case below happens when user is logged with patient account
        if (data && data.results.length === 0) {
          dispatch(clearNotifications());
        }

        setIsReloadingNotifications(false);
        setHasError(false);
      })
      .catch(() => {
        dispatch(clearNotifications());
        setHasError(true);
        setIsReloadingNotifications(false);
      });
  };

  const autoReloadNotifications = () => {
    if (anchorEl === null && !isDisabled) {
      reloadNotifications();
    }
  };

  const fetchNotificationsHandler = () => {
    if (!notifications.nextNotificationPage) return;

    setIsLoadingNotifications(true);
    setHasError(false);

    fetchNotifications(notifications.nextNotificationPage)
      .then((response) => {
        const { data } = response;

        setIsLoadingNotifications(false);
        setHasError(false);

        if (data && data.results.length > 0) {
          dispatch(
            setNotificationsProps({
              data: [...notifications.data, ...data.results],
              nextNotificationPage: data.next ? notifications.nextNotificationPage + 1 : null,
              total: data.count,
              count: notifications.count + data.results.length,
              unreadCount: data.unread_count,
            }),
          );
          return;
        }

        // The case below happens when user is logged with patient account
        if (data && data.results.length === 0) {
          dispatch(clearNotifications());
        }
      })
      .catch(() => {
        setHasError(true);
        setIsLoadingNotifications(false);
      });
  };

  useEffect(() => {
    savedCallback.current = autoReloadNotifications;
  });

  useEffect(() => {
    const notificationsPool = setInterval(() => savedCallback.current(), 180000);

    fetchNotificationsHandler();

    return () => clearInterval(notificationsPool);
  }, []);

  const shouldReadNotification = (notification) => {
    if (notification !== null) {
      return !notification.read;
    }

    return true;
  };

  const markAsRead = (notification = null) => {
    if (!shouldReadNotification(notification)) {
      if (notification.link) {
        navigate(notification.link);
      }
      return;
    }

    const notificationId = notification ? notification.id : null;

    if (notificationId) {
      setIsDisabled(true);
    } else {
      setIsReloadingNotifications(true);
    }

    const mappedNotifications = notifications.data.map((item) =>
      item.id === notificationId ? { ...item, loading: true } : item,
    );
    dispatch(setNotificationsData(mappedNotifications));

    readNotification(notificationId)
      .then(() => {
        if (notificationId) {
          const mappedNotifications = notifications.data.map((item) =>
            item.id === notificationId ? { ...item, read: true } : item,
          );
          dispatch(setNotificationsProps({ data: mappedNotifications, unreadCount: notifications.unreadCount - 1 }));
        } else {
          const mappedNotifications = notifications.data.map((item) => ({ ...item, read: true }));
          dispatch(setNotificationsProps({ data: mappedNotifications, unreadCount: 0 }));
        }

        setIsDisabled(false);
        setIsReloadingNotifications(false);
      })
      .catch(() => {
        setIsDisabled(false);
        setIsReloadingNotifications(false);
        dispatch(showErrorToast('auth.account-personal.read-notification-error'));
      });
  };

  const getNotifications = () =>
    notifications.data.map((notification) => {
      const responseMessage = JSON.parse(notification.message);
      return (
        <NotificationItem
          key={notification.id}
          text={t(responseMessage.key_message, responseMessage.values)}
          date={formatToHumanizedDate(notification.created)}
          disabled={isDisabled}
          hasLink={!!notification.link}
          read={notification.read}
          loading={notification.loading}
          onClick={() => markAsRead(notification)}
          data-testid={`notification-${notification.id}`}
        />
      );
    });

  const renderNotifications = () => {
    const allNotifications = getNotifications();

    if (allNotifications.length > 0) {
      return allNotifications;
    }

    if (hasError) {
      return null;
    }

    return (
      <Typography
        sx={{ margin: '12px 0' }}
        color="secondary"
        variant="body2"
        textAlign="center"
        data-testid="notifications-empty-message"
      >
        {t('auth.account-personal.notifications-empty')}
      </Typography>
    );
  };

  const { unreadCount } = notifications;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <MenuItem onClick={(event) => setAnchorEl(event.currentTarget)} data-testid="notifications-item">
        <Badge
          color="warning"
          variant="dot"
          invisible={!unreadCount}
          slotProps={{
            badge: {
              'data-testid': 'unread-notifications-icon',
            },
          }}
        >
          {t('auth.account-personal.text-account-notifications')}
        </Badge>
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <NotificationHeader
          header={
            <>
              <Typography sx={{ display: 'inline' }} variant="body1" data-testid="notifications-counter">
                {t('auth.account-personal.text-notification-counter', { count: unreadCount })}
              </Typography>
              <Box sx={{ float: 'right' }}>
                <Button
                  sx={{ padding: 0 }}
                  type="ghost"
                  className={styles.dropdownHeaderText}
                  onClick={() => reloadNotifications()}
                  data-testid="force-reload-button"
                >
                  {t('auth.account-personal.notifications-ask-reload')}
                </Button>
                {' | '}
                <Button
                  sx={{ padding: 0 }}
                  type="ghost"
                  className={styles.dropdownHeaderText}
                  onClick={() => markAsRead()}
                  data-testid="read-all-button"
                >
                  {t('auth.account-personal.notifications-read-all')}
                </Button>
              </Box>
            </>
          }
        >
          {isReloadingNotifications ? (
            <Typography
              sx={{ margin: '12px 0' }}
              color="secondary"
              variant="body2"
              textAlign="center"
              data-testid="notifications-reload-message"
            >
              {t('auth.account-personal.notifications-reload')}
            </Typography>
          ) : (
            renderNotifications()
          )}

          {hasError ? (
            <div className={styles.errorContainer}>
              <Typography
                sx={{ margin: '12px 0' }}
                color="secondary"
                variant="body2"
                data-testid="notifications-error-message"
              >
                {t('auth.account-personal.notifications-error')}
              </Typography>
              <Button onClick={fetchNotificationsHandler} data-testid="button-try-again">
                {t('auth.account-personal.notifications-try-again')}
              </Button>
            </div>
          ) : null}

          {(isLoadingNotifications || isReloadingNotifications) && (
            <Box sx={{ padding: '16px' }} display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size="24px" />
            </Box>
          )}
        </NotificationHeader>
      </Popover>
    </>
  );
}

export default Notifications;
