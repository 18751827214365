import { useEffect, useRef, useState } from 'react';
import Header from 'components/dialogs/DialogHeader';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

function Timeout({ children, timeout = 30, warningTime = 30 }) {
  // useRef allows to persist references between rerenders
  const savedIntervalCallback = useRef();
  const savedTimeoutCallback = useRef();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(warningTime);
  const [timeoutTracker, setTimeoutTracker] = useState(null);

  const navigate = useNavigate();

  const { t } = useTranslation();

  function timeoutCallback() {
    if (!isDialogOpen) {
      localStorage.setItem('_expiredTime', Date.now() + (warningTime + timeout) * 1000);
    }
  }

  function intervalCallback() {
    const expiredTime = parseInt(localStorage.getItem('_expiredTime') || 0, 10);
    const timeRemaining = expiredTime - Date.now();

    if (timeRemaining <= warningTime * 1000 && timeRemaining > (warningTime - 1) * 1000) {
      setIsDialogOpen(true);
    }

    if (timeRemaining <= warningTime * 1000 && isDialogOpen) {
      setTimeRemaining(Math.ceil(timeRemaining / 1000));
    }

    if (timeRemaining < 0) {
      navigate('/auth/logout');
    }
  }

  const updateExpiredTime = () => {
    // Clears old timeout tracker to avoid having two
    if (timeoutTracker) {
      clearTimeout(timeoutTracker);
    }

    function detectInteraction() {
      savedTimeoutCallback.current();
    }
    // Only updates the expired time if the user remains 300ms without interacting (performance improvement)
    setTimeoutTracker(setTimeout(detectInteraction, 300));
  };

  const cleanUp = () => {
    localStorage.removeItem('_expiredTime');
    window.removeEventListener('mousemove', updateExpiredTime);
    window.removeEventListener('scroll', updateExpiredTime);
    window.removeEventListener('keydown', updateExpiredTime);
  };

  const tracker = () => {
    localStorage.setItem('_expiredTime', Date.now() + (warningTime + timeout) * 1000);
    window.addEventListener('mousemove', updateExpiredTime);
    window.addEventListener('scroll', updateExpiredTime);
    window.addEventListener('keydown', updateExpiredTime);
  };
  // Reset callbacks at every rerender
  useEffect(() => {
    savedIntervalCallback.current = intervalCallback;
    savedTimeoutCallback.current = timeoutCallback;
  });

  useEffect(() => {
    tracker();

    function tick() {
      savedIntervalCallback.current();
    }

    const interval = setInterval(tick, 1000);

    return () => {
      cleanUp();
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {children}
      <Dialog open={isDialogOpen} data-testid="auto-logout-warning">
        <Header data-testid="auto-logout-warning-header">{t('auth.inactivity-dialog.header')}</Header>
        <DialogContent data-testid="auto-logout-warning-body">
          <Typography variant="body2" color="secondary">
            {t('auth.inactivity-dialog.body', { time_remaining: timeRemaining })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="gradient" onClick={() => setIsDialogOpen(false)} data-testid="dismiss-button">
            {t('auth.inactivity-dialog.button-keep-auth')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Timeout;
