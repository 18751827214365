import { Grid, Paper, Typography } from '@mui/material';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { B4C_POLICY, INTERMEDIATE_POLICY, RIGOROUS_POLICY } from 'utils/password-validators';

function PolicyTemplates({ onSelect = () => {} }) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-settings.password-policy.text-2')}
      </Typography>
      <Paper sx={{ padding: 2, marginBottom: 3 }}>
        <Grid container rowSpacing="8px" sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Grid container justifyContent="center" item xs={12} md={4}>
            <Button variant="outlined" onClick={() => onSelect(B4C_POLICY)} data-testid="basic-template-button">
              {t('organization.organization-settings.password-policy.basic-template')}
            </Button>
          </Grid>
          <Grid container justifyContent="center" item xs={12} md={4}>
            <Button
              variant="outlined"
              onClick={() => onSelect(INTERMEDIATE_POLICY)}
              data-testid="intermediate-template-button"
            >
              {t('organization.organization-settings.password-policy.intermediate-template')}
            </Button>
          </Grid>
          <Grid container justifyContent="center" item xs={12} md={4}>
            <Button variant="outlined" onClick={() => onSelect(RIGOROUS_POLICY)} data-testid="rigorous-template-button">
              {t('organization.organization-settings.password-policy.rigorous-template')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default PolicyTemplates;
