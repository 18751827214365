import { Snackbar as MuiSnackbar, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import styles from './styles.module.css';

const TYPE = {
  error: styles.error,
  warning: styles.warning,
  success: styles.success,
};

const ICON = {
  error: <WarningAmberIcon sx={{ color: 'white' }} />,
  warning: <InfoIcon sx={{ color: 'white' }} />,
  success: <CheckIcon sx={{ color: 'white' }} />,
};

function Snackbar({ type = 'error', onClose, children, ...props }) {
  return (
    <MuiSnackbar
      sx={{
        '&.MuiSnackbar-root': {
          backgroundColor: 'white',
          '& .MuiPaper-root': {
            backgroundColor: 'white',
            color: 'var(--color-grey-dark);',
            borderRadius: '28px',
            boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
            padding: '0px',
            '& .MuiSnackbarContent-message': {
              padding: '0px',
            },
          },
        },
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
      onClose={onClose}
      message={
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            sx={{ padding: '12px', borderRadius: '20px 0 0 20px' }}
            display="flex"
            alignItems="center"
            className={TYPE[type]}
          >
            {ICON[type]}
          </Box>
          <Typography margin="12px" variant="body2">
            {children}
          </Typography>
        </Box>
      }
      action={
        <IconButton sx={{ marginRight: '12px' }} aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      {...props}
    />
  );
}

export default Snackbar;
