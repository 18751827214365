import LockIcon from '@mui/icons-material/Lock';
import { Avatar, Box } from '@mui/material';

const variants = {
  danger: 'var(--color-error)',
  secondary: 'var(--color-grey-dark)',
  dark: 'var(--color-black-secondary)',
  success: 'var(--color-success)',
};

function RowBadge({ variant, lock, ...props }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Avatar sx={{ backgroundColor: variants[variant] ? variants[variant] : 'white' }} {...props}>
        {lock ? <LockIcon sx={{ color: 'white' }} /> : <img src="" alt="" />}
      </Avatar>
    </Box>
  );
}

export default RowBadge;
