import Footer from 'components/Footer';
import Auth from 'components/templates/auth';
import App from 'components/templates/app';
import { Outlet } from 'react-router-dom';
import { getToken } from 'utils/tokens';

function PolicyPage() {
  window.scrollTo(0, 0);

  const accessToken = getToken('access_token');
  const refreshToken = getToken('refresh_token');

  const renderMainContent = () => {
    const signed = accessToken !== undefined && refreshToken !== undefined;

    if (signed) {
      return (
        <App>
          <Outlet />
        </App>
      );
    }

    return (
      <Auth maxWidth="90vw">
        <Outlet />
      </Auth>
    );
  };

  return (
    <>
      {renderMainContent()}
      <Footer />
    </>
  );
}

export default PolicyPage;
