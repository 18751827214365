import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  type: '',
  message: '',
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    closeToast() {
      return initialState;
    },
    showSuccessToast(state, action) {
      return {
        ...state,
        message: action.payload,
        open: true,
        type: 'success',
      };
    },
    showErrorToast(state, action) {
      return {
        ...state,
        message: action.payload,
        open: true,
        type: 'error',
      };
    },
    showWarningToast(state, action) {
      return {
        ...state,
        message: action.payload,
        open: true,
        type: 'warning',
      };
    },
  },
});

export const { closeToast, showSuccessToast, showErrorToast, showWarningToast } = toastSlice.actions;

export default toastSlice.reducer;
