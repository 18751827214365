import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  InputLabel,
  MenuItem,
  Typography,
  Select,
} from '@mui/material';
import Button from 'components/Button';
import DropdownItem from 'components/AccountItem';
import { showErrorToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fetchMembersEligibleToPinPolicy } from 'api/portal';
import styles from './styles.module.css';

function ChoosePinPolicy({ isLoading, onClickNextStep }) {
  const { t } = useTranslation();

  const NO_PIN = t('organization.organization-mobile-devices-create.pin-policy-options.no-pin');
  const YEAR_BIRTH_DATE = t('organization.organization-mobile-devices-create.pin-policy-options.year-birth-date');
  const PHONE_LAST_4_DIGITS = t(
    'organization.organization-mobile-devices-create.pin-policy-options.phone-last-4-digits',
  );

  const PIN_POLICY_CHOICES = [
    {
      value: 'NO_PIN',
      description: NO_PIN,
    },
    {
      value: 'YEAR_BIRTH_DATE',
      description: YEAR_BIRTH_DATE,
    },
    {
      value: 'PHONE_LAST_4_DIGITS',
      description: PHONE_LAST_4_DIGITS,
    },
  ];

  const NON_ELIGIBLE_MEMBER_SEARCH = t('organization.organization-mobile-devices-create.loading-non-eligible-members');
  const ERROR_FETCH_NON_ELIGIBLE_MEMBERS = t(
    'organization.organization-mobile-devices-create.load-non-eligible-members-failure',
  );
  const REQUIRE_PIN_CHANGE_CHECKBOX = t(
    'organization.organization-mobile-devices-create.input-require-pin-change-check-box',
  );
  const SUBTITLE_NON_ELIGIBLE_MEMBERS = t('organization.organization-mobile-devices-create.link-non-eligible-members');

  const [pinPolicy, setPinPolicy] = useState(PIN_POLICY_CHOICES[0].value);
  const [requirePinChange, setRequirePinChange] = useState(false);
  const [nonEligibleMembers, setNonEligibleMembers] = useState([]);
  const [isFetchingMembers, setIsFetchingMembers] = useState(false);
  const [errorFetchingMembers, setErrorFetchingMembers] = useState(false);
  const [errorEligibleMembers, setErrorEligibleMembers] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSelectChange = (value) => {
    setPinPolicy(value);
    setIsFetchingMembers(true);
    setErrorFetchingMembers(false);
    setErrorEligibleMembers(false);

    fetchMembersEligibleToPinPolicy(value)
      .then((response) => {
        const { data } = response;
        setNonEligibleMembers(data.non_eligible);
        setIsFetchingMembers(false);
        setErrorFetchingMembers(false);
        setErrorEligibleMembers(Boolean(data.non_eligible.length));
      })
      .catch(() => {
        setNonEligibleMembers([]);
        setIsFetchingMembers(false);
        setErrorFetchingMembers(true);
        setErrorEligibleMembers(true);
        dispatch(showErrorToast('organization.organization-mobile-devices-create.load-non-eligible-members-failure'));
      });
  };

  const renderHint = () => {
    const numberNonEligibleMembers = nonEligibleMembers.length;

    if (errorFetchingMembers) {
      return (
        <Typography variant="caption" data-testid="hint-error">
          {ERROR_FETCH_NON_ELIGIBLE_MEMBERS}
        </Typography>
      );
    }

    if (isFetchingMembers) {
      return (
        <>
          <CircularProgress size="16px" />
          <Typography sx={{ marginLeft: '8px' }} variant="caption">
            {NON_ELIGIBLE_MEMBER_SEARCH}
          </Typography>
        </>
      );
    }

    return numberNonEligibleMembers !== 0 ? (
      <Typography variant="caption">
        {t('organization.organization-mobile-devices-create.error-message-non-eligible-members', {
          number_members: numberNonEligibleMembers,
        })}
      </Typography>
    ) : (
      ''
    );
  };

  const numberNonEligibleMembers = nonEligibleMembers.length;

  return (
    <>
      <Section maxWidth="428px">
        <Subtitle>{t('organization.organization-mobile-devices-create.title-sub-pin-policy-setup')}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-mobile-devices-create.text-pin-policy-setup')}
        </Typography>
        <FormControl fullWidth error={errorEligibleMembers}>
          <InputLabel id="pin-policy-select-label">
            {t('organization.organization-mobile-devices-create.input-pin-policy-choice')}
          </InputLabel>
          <Select
            id="pin_policy"
            onChange={(event) => onSelectChange(event.target.value)}
            value={pinPolicy}
            data-testid="pin-policy-select"
            inputProps={{ 'data-testid': 'pin-policy-select-input' }}
          >
            {PIN_POLICY_CHOICES.map((item) => (
              <MenuItem key={item.value} value={item.value} data-testid={`${item.value}-option`}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText data-testid="pin-policy-select-error">{renderHint()}</FormHelperText>
        </FormControl>
        <FormControlLabel
          label={REQUIRE_PIN_CHANGE_CHECKBOX}
          control={
            <Checkbox
              checked={requirePinChange}
              onClick={() => setRequirePinChange(!requirePinChange)}
              inputProps={{ 'data-testid': 'require-pin-change-checkbox' }}
            />
          }
        />
      </Section>

      {numberNonEligibleMembers !== 0 ? (
        <Section maxWidth="428px">
          <Subtitle isError>
            {t('organization.organization-mobile-devices-create.title-sub-non-eligible-members')}
          </Subtitle>
          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-mobile-devices-create.text-non-eligible-members')}
          </Typography>
          {nonEligibleMembers.map((member) => (
            <DropdownItem
              key={`member-${member.id}`}
              image={member.user ? member.user.photo : null}
              title={`${member.user.first_name} ${member.user.last_name}`}
              subtitle={SUBTITLE_NON_ELIGIBLE_MEMBERS}
              onClick={() => navigate(`/organization/members/${member.id}/edit/info`)}
            />
          ))}
        </Section>
      ) : null}

      <div className={styles.buttonsContainer}>
        <Button
          color="gradient"
          loading={isLoading}
          onClick={() => onClickNextStep({ pinPolicy, requirePinChange })}
          data-testid="confirm-button"
        >
          {t('organization.organization-mobile-devices-create.button-confirm-pin-policy-setup')}
        </Button>
        <Button
          sx={{ margin: '0 0 0 16px' }}
          variant="outlined"
          onClick={() => navigate('/org-mobile-devices/devices')}
          data-testid="back-button"
        >
          {t('organization.organization-mobile-devices-create.button-back')}
        </Button>
      </div>
    </>
  );
}

export default ChoosePinPolicy;
