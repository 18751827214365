import AppWizard from 'components/templates/AppWizard';
import { Box } from '@mui/material';
import { showErrorToast } from 'features/toastSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Fade from 'components/Fade';
import { isTrustedDevice, validateDeviceStatus } from 'utils/mobile-devices';
import { useTranslation } from 'react-i18next';
import ChoosePinPolicy from 'components/MobileDevicePinPolicySelection';
import DeviceQRCode from 'components/MobileDeviceQRCode';
import OperationConcluded from 'components/MobileDeviceSummary';

function MobileDeviceRegistration({ currentNav, device, title }) {
  const [pinPolicy, setPinPolicy] = useState(null);
  const [registeredDevice, setRegisteredDevice] = useState({});
  const [requirePinChange, setRequirePinChange] = useState(null);
  const [step, setStep] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  const { t } = useTranslation();

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_DEVICES = t('organization.breadcrumbs.manage-devices');

  const STEP_1 = t('organization.organization-mobile-devices-create.wizard-pin-policy');
  const STEP_2 = t('organization.organization-mobile-devices-create.wizard-qr-code');
  const STEP_3 = t('organization.organization-mobile-devices-create.wizard-end');

  useEffect(() => {
    if (!validateDeviceStatus(status)) {
      dispatch(showErrorToast('messages.load-failure'));
      navigate('/org-mobile-devices/devices');
    }
  }, []);

  const renderStep = () => {
    const SUBTITLE_REGISTRATION_CONCLUDED = device
      ? t('organization.organization-mobile-devices-edit.title-sub-device-updated')
      : t('organization.organization-mobile-devices-create.title-sub-device-registered');
    const TEXT_REGISTRATION_CONCLUDED = device
      ? t('organization.organization-mobile-devices-edit.text-device-updated')
      : t('organization.organization-mobile-devices-create.text-device-registered');

    switch (isTrustedDevice(status) ? step : step + 1) {
      case 0:
        return (
          <ChoosePinPolicy
            onClickNextStep={(state) => {
              setStep(step + 1);
              setPinPolicy(state.pinPolicy);
              setRequirePinChange(state.requirePinChange);
            }}
          />
        );
      case 1:
        return (
          <DeviceQRCode
            device={device}
            status={status}
            pinPolicy={pinPolicy}
            requirePinChange={requirePinChange}
            onQRCodeRead={(device) => {
              setStep(step + 1);
              setRegisteredDevice(device);
            }}
          />
        );
      case 2:
        return (
          <OperationConcluded
            subtitle={SUBTITLE_REGISTRATION_CONCLUDED}
            text={TEXT_REGISTRATION_CONCLUDED}
            device={registeredDevice}
          />
        );
      default:
        return null;
    }
  };

  const BREADCRUMBS = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/org-mobile-devices/devices',
      title: MANAGE_DEVICES,
    },
    {
      path: `${window.location.pathname}${window.location.search}`,
      title: currentNav,
      isActive: true,
    },
  ];

  const STEPS = isTrustedDevice(status) ? [STEP_1, STEP_2, STEP_3] : [STEP_1, STEP_2];

  return (
    <AppWizard title={title} breadcrumbs={BREADCRUMBS} steps={STEPS} currentStep={step}>
      <Fade>
        <Box marginBottom="48px">{renderStep()}</Box>
      </Fade>
    </AppWizard>
  );
}

export default MobileDeviceRegistration;
