import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMobileDevice, setOrganizationSelectedMobileDevice } from 'features/organizationSlice';
import { fetchMobileDevices } from 'api/portal';
import { showErrorToast } from 'features/toastSlice';
import { isTrustedDevice, validateDeviceStatus } from 'utils/mobile-devices';
import MobileDeviceStatusUpdate from 'components/MobileDeviceStatusUpdate';
import MobileDeviceRegistration from 'components/MobileDeviceRegistration';

function MobileDeviceStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const { deviceId } = params;
  const status = searchParams.get('status');

  const mobileDevice = useSelector((state) => state.organization.selectedMobileDevice);

  const onErrorFetchingDevice = () => {
    navigate('/org-mobile-devices/devices');
    dispatch(showErrorToast('organization.organization-mobile-devices-edit.error-fetch-device'));
  };

  useEffect(() => {
    if (!validateDeviceStatus(status)) {
      dispatch(showErrorToast('messages.load-failure'));
      navigate('/org-mobile-devices/devices');
      return;
    }

    if (mobileDevice.org_device_id !== deviceId) {
      dispatch(clearOrganizationSelectedMobileDevice());
    }

    fetchMobileDevices(new URLSearchParams(), { org_device_id: deviceId })
      .then((response) => {
        const { data } = response;

        if (!data.results) {
          onErrorFetchingDevice();
        } else if (data.results.length === 1) {
          const device = data.results[0];
          dispatch(clearOrganizationSelectedMobileDevice());
          dispatch(setOrganizationSelectedMobileDevice(device));
        } else {
          onErrorFetchingDevice();
        }
      })
      .catch(() => onErrorFetchingDevice());
  }, []);

  if (isTrustedDevice(status)) {
    return (
      <MobileDeviceRegistration
        title={t('organization.organization-mobile-devices-edit.title-edit-device', { name: mobileDevice.name })}
        currentNav={t('organization.breadcrumbs.edit-organization-mobile-device')}
        device={mobileDevice}
      />
    );
  }

  return <MobileDeviceStatusUpdate device={mobileDevice} />;
}

export default MobileDeviceStatus;
