import { useState } from 'react';
import { showErrorToast } from 'features/toastSlice';
import LinkItem from 'components/LinkItem';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCorporateProfileData, setOrganizations } from 'features/userSlice';
import { directLogin } from 'api/portal';
import { getToken, setToken } from 'utils/tokens';
import { Typography } from '@mui/material';
import UpdatePasswordForm from 'components/forms/UpdatePasswordForm';
import { useTranslation } from 'react-i18next';
import AccountList from 'components/AccountList';
import LoginForm from 'components/forms/LoginForm';
import LoginDialog from 'components/dialogs/LoginDialog';
import Timeout from '../../../components/routes/timeout';

function DirectLogin() {
  const [profile, setProfile] = useState(undefined);
  const [credentials, setCredentials] = useState({});

  const [accounts, setAccounts] = useState(undefined);
  const [account, setAccount] = useState(undefined);
  const [errors, setErrors] = useState(new Map());

  const [passwordUpdateType, setPasswordUpdateType] = useState(undefined);
  const [passwordPolicy, setPasswordPolicy] = useState({});
  const [skipSettings, setSkipSettings] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const INVALID_CREDENTIAL = t('auth.login.auth-error');

  const handleLoginErrors = (data, requestBody) => {
    if (data.hasOwnProperty('update_password')) {
      setPasswordUpdateType(data.update_password);
      setCredentials({ ...requestBody, orgId: data.org_id });
      setPasswordPolicy(data.password_policy);
      setSkipSettings(data.skip_settings);
      return null;
    }

    if (data.hasOwnProperty('blocked')) {
      return 'auth.login.user-blocked';
    }

    if (data.hasOwnProperty('ip_blocked')) {
      return 'auth.login.ip-blocked';
    }

    if (data !== false) {
      return 'auth.login.auth-error';
    }

    return null;
  };

  const onLoginSuccess = (data) => {
    const { user, roles } = data;

    const accessToken = data.access_token;
    const refreshToken = data.refresh_token;

    setToken('access_token', accessToken);
    setToken('refresh_token', refreshToken);

    setProfile(user);
    dispatch(setCorporateProfileData(data));

    const hasAcceptedTerms = user.accept_terms;

    if (hasAcceptedTerms === false) {
      navigate('/auth/accept-terms');
      return;
    }

    if (roles.length === 1) {
      const role = roles[0];

      if (role.id === 1) {
        navigate('/organization/members');
        return;
      }

      if (role.id === 3) {
        navigate('/organization/physio');
        return;
      }
    }

    navigate('/');
  };

  const onSubmitLoginForm = (formData) => {
    setIsSubmitting(true);

    const requestBody = {
      document: formData.document,
      password: formData.password,
    };

    directLogin(requestBody)
      .then((response) => {
        const { data } = response;
        const { accounts } = data;

        setIsSubmitting(false);

        dispatch(setOrganizations(accounts));

        if (data.access_token && data.refresh_token) {
          onLoginSuccess(data);
        } else {
          const { user } = data;

          setProfile(user);
          setCredentials({
            document: formData.document,
            password: formData.password,
          });
          setAccounts(accounts);
        }
      })
      .catch((error) => {
        const { response } = error;

        const { data } = response;

        setIsSubmitting(false);

        const errorMessage = handleLoginErrors(data, requestBody);

        if (errorMessage !== null) {
          dispatch(showErrorToast(errorMessage));
          return;
        }

        dispatch(showErrorToast('auth.login.auth-error'));
      });
  };

  const directLoginWithAccount = (document, password, account) => {
    const requestBody = {
      document,
      password,
      account: account.id,
    };

    directLogin(requestBody)
      .then((response) => {
        const { data } = response;

        setIsSubmitting(false);
        setIsLoading(false);

        if (data.access_token && data.refresh_token) {
          onLoginSuccess(data);
        } else {
          const { user } = data;
          setProfile(user);
        }
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;

        setIsSubmitting(false);
        setIsLoading(false);

        const errorMessage = handleLoginErrors(data, requestBody);

        if (errorMessage !== null) {
          dispatch(showErrorToast(errorMessage));
          setErrors(new Map([['password', t(errorMessage)]]));
          return;
        }

        setErrors(new Map([['password', INVALID_CREDENTIAL]]));
      });
  };

  const onSubmitLoginDialog = (formData) => {
    setIsSubmitting(true);
    directLoginWithAccount(credentials.document, formData.password, account);
  };

  const onAccountSelected = (account) => {
    const accessToken = getToken('access_token');
    const refreshToken = getToken('refresh_token');

    if (account.login_status) {
      if (accessToken === undefined || refreshToken === undefined) {
        setIsLoading(true);
        directLoginWithAccount(credentials.document, credentials.password, account);
      } else {
        navigate('/');
      }
    } else {
      setAccount(account);
    }
  };

  const getLoggoutText = () => (
    <Typography variant="body2" color="secondary" marginBottom="32px">
      {t('auth.accounts-start.text-account-not-user', { firstname: profile.first_name })}
      <LinkItem href="/auth/logout">{t('auth.accounts-start.button-logout')}</LinkItem>
    </Typography>
  );

  const onSuccessPasswordUpdate = (data) => {
    const { accounts } = data;

    dispatch(setOrganizations(accounts));

    if (data.access_token && data.refresh_token) {
      onLoginSuccess(data);
    } else {
      const { user } = data;
      setProfile(user);
    }
  };

  if (passwordUpdateType !== undefined) {
    return (
      <Timeout>
        <UpdatePasswordForm
          credentials={credentials}
          passwordPolicy={passwordPolicy}
          onSuccess={onSuccessPasswordUpdate}
          skipSettings={skipSettings}
          type={passwordUpdateType}
        />
      </Timeout>
    );
  }

  if (accounts) {
    return (
      <Timeout>
        <AccountList
          profile={profile}
          accounts={accounts}
          isLoading={isLoading}
          onAccountSelected={(account) => onAccountSelected(account)}
          loggoutText={getLoggoutText()}
        />
        <LoginDialog
          organization={account}
          isOpen={account !== undefined}
          onClose={() => {
            setAccount(undefined);
            setErrors(new Map());
          }}
          isSubmitting={isSubmitting}
          onSubmit={(data) => onSubmitLoginDialog(data)}
          errors={errors}
        />
      </Timeout>
    );
  }

  return <LoginForm isSubmitting={isSubmitting} onSubmit={onSubmitLoginForm} />;
}

export default DirectLogin;
