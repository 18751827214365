import { useSelector } from 'react-redux';
import AppMenu from 'components/templates/app-menu';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const App = ({ children }) => {
  const params = useParams();

  const { t } = useTranslation();

  const member = useSelector((state) => state.organization.selectedMember);

  const { pathname, search } = window.location;

  const { orgId, memberId } = params;

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_ORGANIZATION = t('organization.breadcrumbs.manage-organization');
  const ORGANIZATION_MEMBERS = t('braincare.breadcrumbs.organization-members');

  const EDIT_MEMBER = t('organization.breadcrumbs.edit-member');
  const REDEFINE_PASSWORD = t('organization.breadcrumbs.redefine-password');

  const INFO = t('organization.organization-members-edit.title-sub-member-edit');
  const PERMISSIONS = t('organization.organization-members-edit-permissions.title-sub-member-roles');
  const SUSPEND = t('organization.organization-members-edit-suspend.title-main-member-suspend');

  const INFO_URL = `/braincare/organizations/${orgId}/members/${memberId}/edit/info`;
  const PERMISSIONS_URL = `/braincare/organizations/${orgId}/members/${memberId}/edit/permissions`;
  const SUSPEND_URL = `/braincare/organizations/${orgId}/members/${memberId}/edit/suspend`;
  const REDEFINE_PASSWORD_URL = `/braincare/organizations/${orgId}/members/${memberId}/edit/password`;

  const SIDE_MENU = [
    {
      href: INFO_URL,
      text: INFO,
      isActive: pathname === INFO_URL,
    },
    {
      href: PERMISSIONS_URL,
      text: PERMISSIONS,
      isActive: pathname === PERMISSIONS_URL,
    },
    {
      href: SUSPEND_URL,
      text: SUSPEND,
      isActive: pathname === SUSPEND_URL,
    },
    {
      href: REDEFINE_PASSWORD_URL,
      text: REDEFINE_PASSWORD,
      isActive: pathname === REDEFINE_PASSWORD_URL,
    },
  ];

  const getBreadcrumb = (path) => {
    switch (path) {
      case INFO_URL:
        return INFO;
      case PERMISSIONS_URL:
        return PERMISSIONS;
      case SUSPEND_URL:
        return SUSPEND;
      case REDEFINE_PASSWORD_URL:
        return REDEFINE_PASSWORD;
      default:
        return '';
    }
  };

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/braincare/organizations',
      title: MANAGE_ORGANIZATION,
    },
    {
      path: `/braincare/organizations/${orgId}/members`,
      title: ORGANIZATION_MEMBERS,
    },
    {
      path: `${pathname}${search}`,
      title: getBreadcrumb(pathname),
      isActive: true,
    },
  ];

  const { user, organization } = member;

  const title = user && organization ? `${organization.name} - ${user.first_name} ${user.last_name}` : EDIT_MEMBER;

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={title} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
};

export default App;
