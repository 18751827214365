import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from 'components/Button';
import DialogHeader from 'components/dialogs/DialogHeader';
import { useTranslation } from 'react-i18next';

function DeleteUserDialog({ open, onClose, onDelete }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} data-testid="delete-dialog">
      <DialogHeader>{t('braincare.users-edit-suspend-exclude.title-box-member-exclude')}</DialogHeader>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('braincare.users-edit-suspend-exclude.text-box-organization-members-delete')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} data-testid="dismiss-button">
          {t('braincare.users-edit-suspend-exclude.box-button-cancel')}
        </Button>
        <Button fullWidth color="gradient" onClick={onDelete} data-testid="confirm-deletion-button">
          {t('braincare.users-edit-suspend-exclude.box-button-suspend')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteUserDialog;
