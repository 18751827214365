import { Autocomplete as MuiAutoComplete } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const CustomAutocomplete = styled(MuiAutoComplete)(({ theme }) => ({
  '& .MuiInputBase-formControl': {
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.error.light}`,
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.colorGreyLight.light}`,
    },
    '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.colorGreyLight.light}`,
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
    '& .MuiAutocomplete-input': {
      padding: '3px',
    },
  },
}));

export default function Autocomplete(props) {
  return <CustomAutocomplete clearIcon={null} popupIcon={<ExpandMoreIcon />} {...props} />;
}
