export default class PasswordPolicyBuilder {
  constructor() {
    this.passwordPolicy = {};
  }

  addLengthPolicy(min, max) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      size_range: { max, min },
    };
  }

  addNonAlphanumericPolicy(min, max) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      non_alphanumeric_range: { max, min },
    };
  }

  addDigitsPolicy(min, max) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      digits_range: { max, min },
    };
  }

  addUppercasePolicy(min, max) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      uppercase_range: { max, min },
    };
  }

  addLowercasePolicy(min, max) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      lowercase_range: { max, min },
    };
  }

  addForbiddenWordsPolicy(words, isCaseSensitive) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      forbidden_words: {
        words,
        is_case_sensitive: isCaseSensitive,
      },
    };
  }

  addSequentialStringPolicy(max, looping, reverseOrder, repeatedLetters, isCaseSensitive) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      sequential_strings: {
        max,
        looping,
        reverse_order: reverseOrder,
        repeated_letters: repeatedLetters,
        is_case_sensitive: isCaseSensitive,
      },
    };
  }

  addCommonPasswordsPolicy(excludeCommonPasswords) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      exclude_common_passwords: excludeCommonPasswords,
    };
  }

  addExcludePersonalInformation(fields, similarityRatio) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      exclude_personal_information: {
        fields,
        similarity_ratio: similarityRatio,
      },
    };
  }

  addRepeatedCharSequencesPolicy(max, isCaseSensitive) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      repeated_char_sequences: {
        max,
        is_case_sensitive: isCaseSensitive,
      },
    };
  }

  addPasswordReusePolicy(passwordReuse) {
    this.passwordPolicy = {
      ...this.passwordPolicy,
      password_reuse: passwordReuse,
    };
  }
}
