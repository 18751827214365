import { Button as MuiButton } from '@mui/material';
import Loader from 'components/Loader';
import styles from './styles.module.css';

const LOADER_THEMES = {
  default: styles.loaderDefault,
  primary: styles.loaderDefault,
  danger: styles.loaderDanger,
};

const styleGradientButton = {
  background: 'linear-gradient(to right, #0080e5, #00d7a4)',
  color: '#ffffff',
  '&.Mui-disabled': {
    background: 'transparent',
  },
};

export default function Button({ loading = false, sx, color = 'primary', children, ...props }) {
  if (color === 'gradient') {
    return (
      <MuiButton sx={{ ...sx, ...styleGradientButton }} {...props}>
        {loading ? <Loader className={LOADER_THEMES[color]} /> : children}
      </MuiButton>
    );
  }

  return (
    <MuiButton color={color} sx={sx} {...props}>
      {loading ? <Loader className={LOADER_THEMES[color]} /> : children}
    </MuiButton>
  );
}
