import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { updateMobileDevice } from 'api/portal';
import Button from 'components/Button';
import { showErrorToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';

function ConfirmationStatusUpdate({ device, status, onClickNextStep }) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const initialStatus = device.status;
  const targetStatus = status;

  const updateDeviceStatus = () => {
    setIsLoading(true);

    const deviceData = {
      status,
      org_device_id: device.org_device_id,
    };

    updateMobileDevice(deviceData)
      .then((response) => {
        const { data } = response;
        const { status } = data;

        setIsLoading(false);
        onClickNextStep({ ...deviceData, ...device, status });
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(showErrorToast('organization.organization-mobile-devices-edit.error-device-update'));
      });
  };

  const renderStatusDescription = () => {
    if (status === 'NORMAL') {
      return (
        <Typography variant="body2" color="secondary" data-testid="normal-description">
          {t('organization.organization-mobile-devices-info.text-normal-part-2')}
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="secondary" data-testid="blocked-description">
        {t('organization.organization-mobile-devices-info.text-blocked-part-2')}
      </Typography>
    );
  };

  return (
    <>
      <Section maxWidth="428px">
        <Subtitle>{t('organization.organization-mobile-devices-edit.title-sub-status-update')}</Subtitle>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-mobile-devices-edit.text-status-update-1', {
            initial_status: t(
              `organization.organization-mobile-devices-info.${initialStatus ? initialStatus.toLowerCase() : ''}`,
            ),
            target_status: t(
              `organization.organization-mobile-devices-info.${targetStatus ? targetStatus.toLowerCase() : ''}`,
            ),
          })}
        </Typography>
        {renderStatusDescription()}
      </Section>
      <Button color="gradient" loading={isLoading} onClick={updateDeviceStatus} data-testid="confirm-button">
        {t('organization.organization-mobile-devices-edit.button-confirm')}
      </Button>
      <Button
        sx={{ margin: '0 0 0 16px' }}
        variant="outlined"
        onClick={() => navigate('/org-mobile-devices/devices')}
        data-testid="cancel-button"
      >
        {t('organization.organization-mobile-devices-edit.button-cancel')}
      </Button>
    </>
  );
}

export default ConfirmationStatusUpdate;
