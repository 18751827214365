import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

function BulkUploadInstructionsTable() {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {t('organization.organization-members-bulk-upload.table.head-name.field')}
            </TableCell>
            <TableCell align="left">
              {t('organization.organization-members-bulk-upload.table.head-name.description')}
            </TableCell>
            <TableCell align="left">
              {t('organization.organization-members-bulk-upload.table.head-name.formats')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow data-testid="first-name-row">
            <TableCell>{t('organization.organization-members-bulk-upload.table.first-column.first-name')}</TableCell>
            <TableCell>
              {t('organization.organization-members-bulk-upload.table.second-column.first-name-description')}
              <div className={styles.cautionDescription}>
                {t('organization.organization-members-bulk-upload.table.second-column.caution-description')}
              </div>
            </TableCell>
            <TableCell>
              {t('organization.organization-members-bulk-upload.table.third-column.first-name-format')}
            </TableCell>
          </TableRow>
          <TableRow data-testid="last-name-row">
            <TableCell>{t('organization.organization-members-bulk-upload.table.first-column.last-name')}</TableCell>
            <TableCell>
              {t('organization.organization-members-bulk-upload.table.second-column.last-name-description')}
              <div className={styles.cautionDescription}>
                {t('organization.organization-members-bulk-upload.table.second-column.caution-description')}
              </div>
            </TableCell>
            <TableCell>
              {t('organization.organization-members-bulk-upload.table.third-column.last-name-format')}
            </TableCell>
          </TableRow>
          <TableRow data-testid="birth-year-row">
            <TableCell>{t('organization.organization-members-bulk-upload.table.first-column.birthday')}</TableCell>
            <TableCell>
              {t('organization.organization-members-bulk-upload.table.second-column.birthday-description')}
              <div className={styles.cautionDescription}>
                {t('organization.organization-members-bulk-upload.table.second-column.caution-description')}
              </div>
            </TableCell>
            <TableCell>
              {t('organization.organization-members-bulk-upload.table.third-column.birthday-format')}
            </TableCell>
          </TableRow>
          <TableRow data-testid="id-number-row">
            <TableCell>{t('organization.organization-members-bulk-upload.table.first-column.id-number')}</TableCell>
            <TableCell>
              {t('organization.organization-members-bulk-upload.table.second-column.id-number-description')}
              <div className={styles.cautionDescription}>
                {t('organization.organization-members-bulk-upload.table.second-column.caution-description')}
              </div>
            </TableCell>
            <TableCell>
              {t('organization.organization-members-bulk-upload.table.third-column.id-number-id-format')}
            </TableCell>
          </TableRow>
          <TableRow data-testid="password-row">
            <TableCell>{t('organization.organization-members-bulk-upload.table.first-column.password')}</TableCell>
            <TableCell>
              {t('organization.organization-members-bulk-upload.table.second-column.password-description')}
            </TableCell>
            <TableCell>
              {t('organization.organization-members-bulk-upload.table.third-column.password-format')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BulkUploadInstructionsTable;
