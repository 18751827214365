import Button from 'components/Button';
import Section from 'components/Section';
import SubSubtitle from 'components/SubSubtitle';
import Subtitle from 'components/Subtitle';
import App from 'pages/organization/settings/App';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveOrganizationData, setOrganizations } from 'features/userSlice';
import { updateMemberOrganization } from 'api/portal';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import DialogHeader from 'components/dialogs/DialogHeader';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';
import Fade from 'components/Fade';

function OrganizationReportsPolicies() {
  const organization = useSelector((state) => state.user.active_organization);
  const organizations = useSelector((state) => state.user.organizations);

  const [globalAutoShare, setGlobalAutoShare] = useState(organization.global_auto_share);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [autoPurge, setAutoPurge] = useState(organization.auto_purge);
  const [autoPurgePeriod, setAutoPurgePeriod] = useState(organization.expiration_days || '');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(new Map());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const AUTO_PURGE_PERIOD_EMPTY = t('organization.organization-settings.reports.auto-purge-period-empty');

  const validateForm = () => {
    const errors = new Map();

    if (autoPurge) {
      if (autoPurgePeriod === '') {
        errors.set('autoPurgePeriod', AUTO_PURGE_PERIOD_EMPTY);
      }
    }

    return errors;
  };

  const onSubmit = () => {
    const errors = validateForm();

    setErrors(errors);

    if (errors.size === 0) {
      setIsSubmitting(true);

      updateMemberOrganization({
        global_auto_share: globalAutoShare,
        auto_purge: autoPurge,
        expiration_days: autoPurgePeriod === '' ? null : autoPurgePeriod,
      })
        .then((response) => {
          const { data } = response;
          setIsSubmitting(false);

          dispatch(showSuccessToast('organization.organization-settings.save-success-message'));
          dispatch(setActiveOrganizationData(data));
          dispatch(
            setOrganizations(
              organizations.map((item) => {
                if (item.id === data.id) {
                  return data;
                }

                return item;
              }),
            ),
          );
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(showErrorToast('organization.organization-settings.save-failure-message'));
        });
    } else {
      dispatch(showErrorToast('messages.fix-fields'));
    }
  };

  return (
    <App>
      <Fade>
        <Box maxWidth="428px" marginBottom="48px">
          <Subtitle>{t('organization.organization-settings.reports.subtitle')}</Subtitle>

          <Dialog open={isConfirmDialogOpen} data-testid="confirm-auto-purge-dialog">
            <DialogHeader type="warning">
              {t('organization.organization-settings.reports.auto-purge-confirmation-title')}
            </DialogHeader>
            <DialogContent>
              <Typography variant="body2" color="secondary" marginBottom="24px">
                {t('organization.organization-settings.reports.auto-purge-confirmation-body')}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button fullWidth onClick={() => setIsConfirmDialogOpen(false)}>
                {t('organization.organization-settings.reports.auto-purge-confirmation-cancel-button')}
              </Button>
              <Button
                fullWidth
                color="gradient"
                onClick={() => {
                  setIsConfirmDialogOpen(false);
                  setAutoPurge(true);
                }}
                loading={false}
                data-testid="confirm-auto-purge-button"
              >
                {t('organization.organization-settings.reports.auto-purge-confirmation-confirm-button')}
              </Button>
            </DialogActions>
          </Dialog>

          <Section>
            <br />
            <SubSubtitle>{t('organization.organization-settings.reports.global-auto-share-subsubtitle')}</SubSubtitle>

            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('organization.organization-settings.reports.global-auto-share-text')}
            </Typography>

            <FormControlLabel
              label={t('organization.organization-settings.reports.global-auto-share-checkbox')}
              control={
                <Checkbox
                  checked={globalAutoShare}
                  inputProps={{ 'data-testid': 'auto-share-checkbox-input' }}
                  onClick={() => setGlobalAutoShare(!globalAutoShare)}
                  data-testid="auto-share-checkbox"
                />
              }
            />
          </Section>

          <Section>
            <SubSubtitle>{t('organization.organization-settings.reports.auto-purge-subsubtitle')}</SubSubtitle>

            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('organization.organization-settings.reports.auto-purge-text')}
            </Typography>

            <FormControlLabel
              label={t('organization.organization-settings.reports.auto-purge-checkbox')}
              control={
                <Checkbox
                  checked={autoPurge}
                  inputProps={{ 'data-testid': 'auto-purge-checkbox-input' }}
                  onClick={() => {
                    if (autoPurge) {
                      setAutoPurge(false);
                    } else {
                      setIsConfirmDialogOpen(true);
                    }
                  }}
                  data-testid="auto-purge-checkbox"
                />
              }
            />

            <TextField
              id="autoPurgePeriod"
              sx={{ marginTop: 2 }}
              value={autoPurgePeriod}
              onChange={(event) => setAutoPurgePeriod(filterNumbers(event.target.value))}
              label={t('organization.organization-settings.reports.auto-purge-input')}
              variant="outlined"
              error={errors.has('autoPurgePeriod')}
              helperText={errors.get('autoPurgePeriod')}
              data-testid="auto-purge-text-field"
              inputProps={{ 'data-testid': 'auto-purge-input' }}
              FormHelperTextProps={{ 'data-testid': 'auto-purge-error' }}
              fullWidth
            />
          </Section>

          <Button variant="outlined" loading={isSubmitting} onClick={onSubmit} data-testid="save-button">
            {t('organization.organization-settings.reports.submit-button-text')}
          </Button>
          <Button sx={{ marginLeft: 2 }} variant="outlined" onClick={() => navigate('/')} data-testid="cancel-button">
            {t('organization.organization-settings.reports.cancel-button-text')}
          </Button>
        </Box>
      </Fade>
    </App>
  );
}

export default OrganizationReportsPolicies;
