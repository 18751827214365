import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const Section = ({ maxWidth = '100%', children, className, ...props }) => (
  <Box sx={{ marginBottom: '48px', maxWidth }} className={className} {...props}>
    {children}
  </Box>
);

Section.propTypes = {
  maxWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Section;
