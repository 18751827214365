import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import SubSubtitle from 'components/SubSubtitle';
import { useTranslation } from 'react-i18next';

function CommonPasswordsForm({ enabled, onChange }) {
  const { t } = useTranslation();

  const COMMON_PASSWORDS_LABEL = t(
    'organization.organization-settings.password-policy.common-passwords.common-passwords-label',
  );

  return (
    <Box marginBottom="48px">
      <SubSubtitle>{t('organization.organization-settings.password-policy.common-passwords.subsubtitle')}</SubSubtitle>

      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-settings.password-policy.common-passwords.text')}
      </Typography>

      <FormControlLabel
        label={COMMON_PASSWORDS_LABEL}
        control={
          <Checkbox
            checked={enabled}
            onClick={() => onChange(!enabled)}
            inputProps={{ 'data-testid': 'common-passwords-checkbox' }}
          />
        }
      />
    </Box>
  );
}

export default CommonPasswordsForm;
