import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import SubSubtitle from 'components/SubSubtitle';
import { useTranslation } from 'react-i18next';
import { filterNumbers } from 'utils/inputs';

function RepeatedCharsPolicyForm({ maxLength, maxLengthError, isCaseSensitive, onChange }) {
  const { t } = useTranslation();

  const REPEATED_CHARS_CASES_SENSITIVE_LABEL = t(
    'organization.organization-settings.password-policy.repeated-chars.case-sensitive-label',
  );

  return (
    <Box marginBottom="48px">
      <SubSubtitle>{t('organization.organization-settings.password-policy.repeated-chars.subsubtitle')}</SubSubtitle>

      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('organization.organization-settings.password-policy.repeated-chars.text')}
      </Typography>

      <TextField
        id="max-length-repeated-chars"
        sx={{ margin: '0px 0px 8px 0px' }}
        value={maxLength}
        onChange={(event) => onChange(filterNumbers(event.target.value), isCaseSensitive)}
        label={t('organization.organization-settings.password-policy.repeated-chars.max-length-label')}
        variant="outlined"
        error={Boolean(maxLengthError)}
        helperText={maxLengthError}
        data-testid="max-length-repeated-chars-text-field"
        inputProps={{ 'data-testid': 'max-length-repeated-chars-input' }}
        FormHelperTextProps={{ 'data-testid': 'max-length-repeated-chars-error' }}
        fullWidth
      />

      <FormControlLabel
        label={REPEATED_CHARS_CASES_SENSITIVE_LABEL}
        control={
          <Checkbox
            checked={isCaseSensitive}
            onClick={() => onChange(maxLength, !isCaseSensitive)}
            inputProps={{ 'data-testid': 'case-sensitive-repeated-chars-checkbox' }}
          />
        }
      />
    </Box>
  );
}

export default RepeatedCharsPolicyForm;
