import PropTypes from 'prop-types';
import styles from './styles.module.css';

const IconMore = ({ number = 0 }) => <div className={styles.iconMore}>{number < 100 ? `+${number}` : '99+'}</div>;

IconMore.propTypes = {
  number: PropTypes.number,
};

export default IconMore;
