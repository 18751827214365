import AccountCard from 'components/cards/AccountCard';
import Loader from 'components/SectionLoader';
import IconLockOpened from 'assets/images/ic_lock_opened.svg';
import IconLockClosed from 'assets/images/ic_lock_closed.svg';
import Subtitle from 'components/Subtitle';
import { useTranslation } from 'react-i18next';
import AuthTitle from 'components/AuthTitle';
import Translator from 'components/Translator';
import styles from './styles.module.css';
import Footer from '../Footer';

function AccountList({ accounts, isLoading, loggoutText, profile, onAccountSelected }) {
  const { t } = useTranslation();

  return (
    <div className={styles.accountsContainer}>
      {profile.first_name && (
        <Subtitle>{t('auth.accounts-start.title-main-account', { firstname: profile.first_name })}</Subtitle>
      )}
      <AuthTitle>{t('auth.accounts-start.title-sub-account-center')}</AuthTitle>
      <Loader loading={isLoading}>
        <div className={styles.accountList}>
          {accounts.map((account, index) => {
            let icon;

            if (account.login_status === false) {
              icon = IconLockOpened;
            }

            if (account.login_status === true) {
              icon = IconLockClosed;
            }

            return (
              <AccountCard
                key={account.id}
                picture={account.photo}
                pictureDescription="Account"
                title={account.name}
                subtitle={account.address}
                delay={(index + 1) * 200}
                onClick={() => onAccountSelected(account)}
                icon={icon}
                data-testid={`account-${account.id}`}
              />
            );
          })}
        </div>
      </Loader>
      {loggoutText}
      <Translator />
      <Footer />
    </div>
  );
}

export default AccountList;
