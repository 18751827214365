import App from 'components/templates/app';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  List,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Loader from 'components/SectionLoader';
import ShareAcquisitionDialog from 'components/dialogs/ShareAcquisitionDialog';
import ShareHistoryDialog from 'components/dialogs/ShareHistoryDialog';
import Button from 'components/Button';
import { showErrorToast } from 'features/toastSlice';
import TagsSelectDialog from 'components/dialogs/TagFilterDialog';
import Autocomplete from 'components/Autocomplete';
import ProfileTag from 'components/ProfileChip';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import QueryStatsSharpIcon from '@mui/icons-material/QueryStatsSharp';
import ShareSharpIcon from '@mui/icons-material/ShareSharp';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getDoctorNames } from 'api/portal';
import { getAcquisitions, getDoctors, getPatientHashes, getTags, reportAuthorization } from 'api/analytics';
import { decrypt, getReport } from 'api/client-phi';
import AnalyticsDialog from 'components/AnalyticsDialog';
import Fade from 'components/Fade';
import AcquisitionRow from 'components/tables/AcquisitionRow';
import { deserializeTabularData } from 'utils/serialization';
import AcquisitionMenuItem from 'components/AcquisitionMenuItem';
import styles from './styles.module.css';

const LIMIT_PER_PAGE = 10;

function OrganizationReports() {
  const { t } = useTranslation();

  const DEFAULT_DOCTOR = {
    id: -1,
    label: t('physio.physio-personal.dropdown-filter-doctor-all'),
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const [protocolToShare, setProtocolToShare] = useState(undefined);
  const [orgToShare, setOrgToShare] = useState(undefined);
  const [sharedWith, setSharedWith] = useState([]);

  const [decryptData, setDecryptData] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [tags, setTags] = useState([]);

  const [protocol, setProtocol] = useState('');
  const [doctor, setDoctor] = useState(DEFAULT_DOCTOR);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);

  const [acquisitions, setAcquisitions] = useState([]);

  const [selectedAcquisition, setSelectedAcquisition] = useState({});
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showAnalyticsDialog, setShowAnalyticsDialog] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showSharedWithDialog, setShowSharedWithDialog] = useState(false);
  const [showFilterByTags, setShowFilterByTags] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingDoctors, setLoadingDoctors] = useState(false);
  const [loadingPatients, setLoadingPatients] = useState(false);

  const activeOrganization = useSelector((state) => state.user.active_organization);
  const analyticsTool = activeOrganization.analytics_tool;

  const dispatch = useDispatch();

  const HOME = t('physio.breadcrumbs.home');
  const TITLE = t('physio.breadcrumbs.title-organization');

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/organization/physio',
      title: TITLE,
      isActive: true,
    },
  ];

  const onFetchDataFailure = () => dispatch(showErrorToast('messages.fetch-data-failure'));

  const handleFetchDoctors = (values) => {
    getDoctorNames(values)
      .then((response) => {
        const { data } = response;
        setLoadingDoctors(false);
        setDoctors([DEFAULT_DOCTOR, ...data.map((doctor) => ({ ...doctor, label: doctor.full_name }))]);
      })
      .catch(() => {
        setLoadingDoctors(false);
        onFetchDataFailure();
      });
  };

  const fetchDoctorHandler = () => {
    setLoadingDoctors(true);

    getDoctors()
      .then((response) => {
        const { data, error } = response.data;

        if (!error && data && !data.error) {
          handleFetchDoctors(data.values);
          return;
        }

        setLoadingDoctors(false);
        onFetchDataFailure();
      })
      .catch(() => {
        setLoadingDoctors(false);
        onFetchDataFailure();
      });
  };

  const fetchDecryptData = (keys, dataObj) => {
    Object.entries(dataObj).forEach(([key, value]) => {
      const anonymous = value.filter((item) => item[0] === 'Anonymous');

      decrypt(key, {
        hashes: {
          keys: ['patientHash', 'birthdayHash', 'dbId', 'name', 'birthday'],
          values: value.filter((item) => item[0] !== 'Anonymous'),
        },
      })
        .then((response) => {
          const { data, error } = response.data;

          setLoadingPatients(false);

          if (!error) {
            const patientObj = {};

            const hashKeys = data.hashes.keys;

            data.hashes.values.forEach((patientData) => {
              patientObj[patientData[0]] = deserializeTabularData(hashKeys, patientData);
            });

            anonymous.forEach((patientData) => {
              patientObj[patientData[0]] = deserializeTabularData(hashKeys, patientData);
            });

            setDecryptData({ ...decryptData, ...patientObj });
            return;
          }

          onFetchDataFailure();
        })
        .catch(() => {
          setLoadingPatients(false);
          onFetchDataFailure();
        });
    });
  };

  const groupPatientsByOrgId = (data) => {
    const keys = [...data.keys];
    const values = [...data.values];
    const orgIndex = keys.indexOf('org_id');

    return values.reduce((acc, obj) => {
      const key = obj[orgIndex];

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(obj);
      return acc;
    }, {});
  };

  const fetchPatientsHashes = () => {
    setLoadingPatients(true);

    getPatientHashes()
      .then((response) => {
        const { data, error } = response.data;

        if (!error) {
          const keys = [...data.keys];
          const dataGroupedByOrgId = groupPatientsByOrgId(data);

          fetchDecryptData(keys, dataGroupedByOrgId);
          return;
        }

        setLoadingPatients(false);
        onFetchDataFailure();
      })
      .catch(() => {
        setLoadingPatients(false);
        onFetchDataFailure();
      });
  };

  const fetchAcquisitions = ({ selectedPatients, selectedTags, protocol, doctor, currentPage }) => {
    const doctorId = doctor && doctor.id !== -1 ? doctor.id : '';

    setLoading(true);
    setCurrentPage(currentPage);

    getAcquisitions({
      filters: {
        patient_names: selectedPatients.map((patient) => patient.patientHash),
        tags: selectedTags,
        acquisition_id: [protocol],
        doctor_id: String(doctorId),
      },
      paging: {
        page: currentPage,
        limit: LIMIT_PER_PAGE,
      },
    })
      .then((response) => {
        const { data, error } = response.data;

        setLoading(false);

        if (!error) {
          const acquisitionKeys = data.acquisitions.keys;

          setAcquisitions(
            data.acquisitions.values.map((acquisition) => deserializeTabularData(acquisitionKeys, acquisition)),
          );
          setCount(Math.ceil(data.acquisitions.paging.count / LIMIT_PER_PAGE));

          fetchPatientsHashes();
          fetchDoctorHandler();
          return;
        }

        onFetchDataFailure();
      })
      .catch(() => {
        setLoading(false);
        onFetchDataFailure();
      });
  };

  useEffect(() => {
    getTags()
      .then((response) => {
        const { data, error } = response.data;

        if (error) {
          onFetchDataFailure();
          return;
        }

        setTags(data.values);
      })
      .catch(() => {
        onFetchDataFailure();
      });

    fetchAcquisitions({
      selectedPatients,
      selectedTags,
      protocol,
      doctor,
      currentPage,
    });
  }, []);

  const filterAcquisitions = () => {
    fetchAcquisitions({
      selectedPatients,
      selectedTags,
      protocol,
      doctor,
      currentPage: 1,
    });
  };

  const clearFilters = () => {
    setSelectedPatients([]);
    setProtocol('');
    setDoctor(DEFAULT_DOCTOR);
    setSelectedTags([]);

    fetchAcquisitions({
      selectedPatients: [],
      selectedTags: [],
      protocol: '',
      doctor: DEFAULT_DOCTOR,
      currentPage: 1,
    });
  };

  const onPaginationChange = (event, value) => {
    fetchAcquisitions({
      selectedPatients,
      selectedTags,
      protocol,
      doctor,
      currentPage: value,
    });
  };

  const setAcquisitionAsDownloading = (targetProtocolId, isDownloading) => {
    setAcquisitions(
      acquisitions.map((acquisition) => {
        const protocolId = acquisition.acquisition_id;
        return {
          ...acquisition,
          isDownloading: protocolId === targetProtocolId ? isDownloading : acquisition.isDownloading,
        };
      }),
    );
  };

  const openReportWhenAuthorized = (protocolId, url) => {
    let timeOutCount = 0;

    const interval = setInterval(() => {
      fetch(url).then((res) => {
        timeOutCount += 1;
        // open the report in a new tab
        if (res.status === 200) {
          setAcquisitionAsDownloading(protocolId, false);
          window.open(url, '_blank');
          clearInterval(interval);
        }
        // timeout after 3 minutes (180 attempts)
        if (timeOutCount >= 180) {
          // eslint-disable-next-line no-alert
          alert('Report error, please try again');
          setAcquisitionAsDownloading(protocolId, false);
          clearInterval(interval);
        }
      });
    }, 1000);
  };

  const downloadReport = (acquisition) => {
    const protocolId = acquisition.acquisition_id;
    const orgId = acquisition.org_id;

    setAcquisitionAsDownloading(protocolId, true);

    reportAuthorization({
      acquisition_id: protocolId,
      report_language: i18next.language,
      report_format: 'PDF',
    })
      .then((response) => {
        const { data, error } = response.data;

        if (error) {
          setAcquisitionAsDownloading(protocolId, false);
          onFetchDataFailure();
          return;
        }

        getReport(orgId, data).then((response) => {
          const { data } = response;
          openReportWhenAuthorized(protocolId, data.data);
        });
      })
      .catch(() => {
        setAcquisitionAsDownloading(protocolId, false);
        onFetchDataFailure();
      });
  };

  const openShareReportDialog = (protocolId, orgId) => {
    setProtocolToShare(protocolId);
    setOrgToShare(orgId);
    setShowShareDialog(true);
  };

  const openShareHistoryDialog = (sharedWith) => {
    setSharedWith(sharedWith);
    setShowSharedWithDialog(true);
  };

  const closeShareDialog = (shared) => {
    setShowShareDialog(false);
    if (shared) {
      fetchAcquisitions({
        selectedPatients,
        selectedTags,
        protocol,
        doctor,
        currentPage,
      });
    }
  };

  const closeShareHistoryDialog = () => {
    setShowSharedWithDialog(false);
    setSharedWith([]);
  };

  const renderActionPopover = () => {
    const protocolId = selectedAcquisition.acquisition_id;
    const orgId = selectedAcquisition.org_id;
    const isShared = selectedAcquisition.shared;
    const sharedWith = selectedAcquisition.shared_with;

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          <AcquisitionMenuItem
            icon={<VisibilitySharpIcon />}
            onClick={() => {
              downloadReport(selectedAcquisition);
              setAnchorEl(null);
            }}
            data-testid="view-report-item"
            text={t('physio.physio-personal.button-report-view')}
          />
          {analyticsTool && (
            <AcquisitionMenuItem
              icon={<QueryStatsSharpIcon />}
              onClick={() => {
                setShowAnalyticsDialog(true);
                setAnchorEl(null);
              }}
              data-testid="analytics-item"
              text="Analytics"
            />
          )}

          {!isShared && (
            <AcquisitionMenuItem
              icon={<ShareSharpIcon />}
              onClick={() => {
                openShareReportDialog(protocolId, orgId);
                setAnchorEl(null);
              }}
              data-testid="share-report-item"
              text={t('physio.physio-personal.button-report-share')}
            />
          )}
          {sharedWith && sharedWith.length > 0 && (
            <AcquisitionMenuItem
              icon={<GroupSharpIcon />}
              onClick={() => {
                openShareHistoryDialog(sharedWith);
                setAnchorEl(null);
              }}
              data-testid="shared-with-item"
              text={t('physio.physio-personal.button-report-shared-with')}
            />
          )}
        </List>
      </Popover>
    );
  };

  const renderMonitorizationTable = () =>
    acquisitions && acquisitions.length > 0 ? (
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('physio.physio-personal.table-physio-protocol')}</TableCell>
                <TableCell align="left">{t('physio.physio-personal.table-physio-patient')}</TableCell>
                <TableCell align="left">{t('physio.physio-personal.table-physio-date')}</TableCell>
                <TableCell align="left">{t('physio.physio-personal.table-physio-duration')}</TableCell>
                <TableCell align="left">{t('physio.physio-personal.table-physio-doctor')}</TableCell>
                <TableCell align="left">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acquisitions.map((acquisition) => (
                <AcquisitionRow
                  key={acquisition.acquisition_id}
                  loadingDoctor={loadingDoctors}
                  loadingPatient={loadingPatients}
                  acquisition={acquisition}
                  decryptData={decryptData}
                  doctors={doctors}
                  onClickMenu={(event, acquisition) => {
                    setSelectedAcquisition(acquisition);
                    setAnchorEl(event.currentTarget);
                  }}
                  onClickProtocol={(acquisition) => downloadReport(acquisition)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {renderActionPopover()}
      </Box>
    ) : (
      <Typography variant="body2" color="secondary" data-testid="no-monitorization-text">
        {t('physio.physio-personal.no-monitorizations-found')}
      </Typography>
    );

  const patientOptions = Object.values(decryptData).map((item, index) => ({
    id: index,
    patientHash: item.patientHash,
    label: item.name,
  }));

  return (
    <App title={TITLE} breadcrumbs={breadcrumbs}>
      <Section className={styles.sectionFilters}>
        <Subtitle>{t('physio.physio-personal.title-filter-physio')}</Subtitle>
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <Grid marginTop="8px" container spacing="12px">
            <Grid item xs={12}>
              <Autocomplete
                multiple
                onChange={(event, newValue) => setSelectedPatients([...newValue])}
                options={patientOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <ProfileTag key={index} name={option.label} {...getTagProps({ index })} />
                  ))
                }
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.id} data-testid={`doctor-option-${option.id}`}>
                    {option.label}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    data-testid="doctor-text-field"
                    label={t('physio.physio-personal.input-filter-name')}
                  />
                )}
                data-testid="patient-select"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2.4}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel>{t('physio.physio-personal.input-filter-protocol')}</InputLabel>
                <OutlinedInput
                  name="protocol"
                  value={protocol}
                  onChange={(event) => setProtocol(event.target.value)}
                  inputProps={{ 'data-testid': 'protocol-input' }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={2.4}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <Autocomplete
                  disablePortal
                  value={doctor}
                  onChange={(event, newValue) => setDoctor(newValue)}
                  options={doctors}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => (
                    <Typography
                      {...props}
                      variant="body2"
                      color="secondary"
                      key={option.id}
                      data-testid={`doctor-option-${option.id}`}
                    >
                      {option.label}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t('physio.physio-personal.input-filter-doctor')} />
                  )}
                  data-testid="doctor-select"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={2.4}>
              <Button variant="outlined" onClick={() => setShowFilterByTags(true)} fullWidth data-testid="tag-button">
                {selectedTags.length === 0
                  ? t('physio.physio-personal.button-filter-by-tag')
                  : t('physio.physio-personal.button-filter-by-tag-selected', { number_tags: selectedTags.length })}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2.4}>
              <Button variant="outlined" onClick={clearFilters} fullWidth data-testid="reset-button">
                {t('physio.physio-personal.button-filter-reset')}
              </Button>{' '}
            </Grid>
            <Grid item xs={12} sm={4} md={2.4}>
              <Button type="submit" color="gradient" onClick={filterAcquisitions} fullWidth data-testid="apply-button">
                {t('physio.physio-personal.button-filter-apply')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Section>

      <Section>
        <Loader loading={loading}>
          <Fade>{renderMonitorizationTable()}</Fade>
        </Loader>
      </Section>

      <AnalyticsDialog
        open={showAnalyticsDialog}
        onClose={() => setShowAnalyticsDialog(false)}
        selectedAcquisition={selectedAcquisition}
      />

      <ShareAcquisitionDialog
        open={showShareDialog}
        protocolToShare={protocolToShare}
        orgToShare={orgToShare}
        onClose={closeShareDialog}
      />

      <ShareHistoryDialog open={showSharedWithDialog} onCloseDialog={closeShareHistoryDialog} sharedWith={sharedWith} />

      <TagsSelectDialog
        tags={tags}
        selectedTags={selectedTags}
        show={showFilterByTags}
        onCheckboxClick={(tag) => {
          if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
            return;
          }

          setSelectedTags([...selectedTags, tag]);
        }}
        onDialogToggle={() => setShowFilterByTags(false)}
      />

      <Pagination count={count} onChange={onPaginationChange} page={currentPage} />
    </App>
  );
}

export default OrganizationReports;
