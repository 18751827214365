import PropTypes from 'prop-types';
import AppMenu from 'components/templates/app-menu';
import { useTranslation } from 'react-i18next';

const App = ({ children }) => {
  const { t } = useTranslation();

  const { pathname } = window.location;

  const HOME = t('account.breadcrumbs.home');
  const TITLE = t('account.breadcrumbs.title');

  const PERSONAL_INFO = t('account.member-edit-personal-info.menu-title');
  const CORPORATE_INFO = t('account.member-edit-info.menu-title');
  const PERMISSIONS = t('account.member-edit-permissions.menu-title');
  const PASSWORD = t('account.edit-password.menu-personal-password');

  const PERSONAL_INFO_LINK = '/account/member/edit/personal-info';
  const CORPORATE_INFO_LINK = '/account/member/edit/corporate-info';
  const PERMISSIONS_LINK = '/account/member/edit/permissions';
  const PASSWORD_LINK = '/account/member/edit/password';

  const getBreadcrumb = (path) => {
    switch (path) {
      case PERSONAL_INFO_LINK:
        return PERSONAL_INFO;
      case CORPORATE_INFO_LINK:
        return CORPORATE_INFO;
      case PERMISSIONS_LINK:
        return PERMISSIONS;
      case PASSWORD_LINK:
        return PASSWORD;
      default:
        return '';
    }
  };

  const SIDE_MENU = [
    {
      href: PERSONAL_INFO_LINK,
      text: PERSONAL_INFO,
      isActive: pathname === PERSONAL_INFO_LINK,
    },
    {
      href: CORPORATE_INFO_LINK,
      text: CORPORATE_INFO,
      isActive: pathname === CORPORATE_INFO_LINK,
    },
    {
      href: PERMISSIONS_LINK,
      text: PERMISSIONS,
      isActive: pathname === PERMISSIONS_LINK,
    },
    {
      href: PASSWORD_LINK,
      text: PASSWORD,
      isActive: pathname === PASSWORD_LINK,
    },
  ];
  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: CORPORATE_INFO_LINK,
      title: TITLE,
    },
    {
      path: pathname,
      title: getBreadcrumb(pathname),
      isActive: true,
    },
  ];

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={TITLE} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
