import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AppMenu from 'components/templates/app-menu';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const App = ({ children }) => {
  const unit = useSelector((state) => state.organization.selectedUnit);

  const params = useParams();

  const { t } = useTranslation();

  const { unitId } = params;

  const { pathname, search } = window.location;

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_ORGANIZATION = t('organization.breadcrumbs.manage-organization');
  const EDIT_UNIT = t('organization.breadcrumbs.edit-unit');

  const INFO = t('organization.organization-units-edit.menu-unit-info');
  const MEMBERS = t('organization.organization-units-members.menu-member-info');

  const EDIT_UNIT_INFO = `/organization/units/${unitId}/edit`;
  const MEMBERS_UNIT = `/organization/units/${unitId}/members`;

  const SIDE_MENU = [
    {
      href: EDIT_UNIT_INFO,
      text: INFO,
      isActive: pathname === EDIT_UNIT_INFO,
    },
    {
      href: MEMBERS_UNIT,
      text: MEMBERS,
      isActive: pathname === MEMBERS_UNIT,
    },
  ];

  const getBreadcrumb = (path) => {
    switch (path) {
      case EDIT_UNIT_INFO:
        return INFO;
      case MEMBERS_UNIT:
        return MEMBERS;
      default:
        return '';
    }
  };

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/organization/units',
      title: MANAGE_ORGANIZATION,
    },
    {
      path: `${pathname}${search}`,
      title: getBreadcrumb(pathname),
      isActive: true,
    },
  ];
  const TITLE = unit.id !== -1 ? unit.name : EDIT_UNIT;

  return (
    <AppMenu breadcrumbs={breadcrumbs} title={TITLE} menuItems={SIDE_MENU}>
      {children}
    </AppMenu>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
