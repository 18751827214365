import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/dialogs/DialogHeader';
import {
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getUsersById, getOrganizationsById } from 'api/portal';
import { formatToHumanizedDate } from 'utils/format';

function ShareHistoryDialog({ open, onCloseDialog, sharedWith }) {
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrg, setIsLoadingOrg] = useState(false);

  const { t } = useTranslation();

  const WITHOUT_ORGANIZATION = t('messages.without-organization');
  const NOT_FOUND = t('messages.not-found');
  const LOADING = t('messages.loading');

  const shareTranslation = {
    FULL: t('physio.physio-shared-with-dialog.share-type-full'),
    HIDE: t('physio.physio-shared-with-dialog.share-type-anonymous'),
  };

  const handleFetchUser = () => {
    const ids = [];

    sharedWith.forEach((share) => ids.push(['id', share.user_id]));

    if (ids.length > 0) {
      const searchParams = new URLSearchParams(ids);

      setIsLoading(true);

      getUsersById(searchParams)
        .then((response) => {
          const { data } = response;
          setIsLoading(false);
          setUsers(data);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const handleFetchOrganization = () => {
    const ids = [];

    sharedWith.forEach((share) => ids.push(['physio_org_id', share.org_id]));

    if (ids.length > 0) {
      const searchParams = new URLSearchParams(ids);

      setIsLoadingOrg(true);

      getOrganizationsById(searchParams)
        .then((response) => {
          const { data } = response;
          setIsLoadingOrg(false);
          setOrganizations(data);
        })
        .catch(() => {
          setIsLoadingOrg(false);
        });
    }
  };

  useEffect(() => {
    handleFetchUser();
    handleFetchOrganization();
  }, [sharedWith, open]);

  const getUserName = (id) => {
    const user = users.find((index) => index.id === Number(id));
    if (user) return `${user.first_name} ${user.last_name}`;
    return NOT_FOUND;
  };

  const getOrganization = (id) => {
    const organization = organizations.find((index) => index.physio_org_id === id);

    if (id === 'null') {
      return WITHOUT_ORGANIZATION;
    }

    if (organization) return organization.name;

    return NOT_FOUND;
  };

  const renderTable = () => (
    <TableContainer sx={{ marginTop: '8px' }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('physio.physio-shared-with-dialog.table-header-user')}</TableCell>
            <TableCell align="left">{t('physio.physio-shared-with-dialog.table-header-organization')}</TableCell>
            <TableCell align="left">{t('physio.physio-shared-with-dialog.table-header-share-type')}</TableCell>
            <TableCell align="left">{t('physio.physio-shared-with-dialog.table-header-share-exp')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sharedWith.map((share, index) => (
            <TableRow key={index} data-testid={`sharing-row-${index}`}>
              <TableCell align="left">{!isLoading ? getUserName(share.user_id) : LOADING}</TableCell>
              <TableCell align="left">{!isLoadingOrg ? getOrganization(share.org_id) : LOADING}</TableCell>
              <TableCell align="left">{shareTranslation[share.share_type]}</TableCell>
              <TableCell align="left">{formatToHumanizedDate(share.exp)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Dialog open={open} size="medium">
      <Header onClose={onCloseDialog}>{t('physio.physio-shared-with-dialog.title')}</Header>
      <DialogContent>
        <Typography variant="body2" color="secondary">
          {t('physio.physio-shared-with-dialog.text')}
        </Typography>
        {renderTable()}
      </DialogContent>
    </Dialog>
  );
}

ShareHistoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  sharedWith: PropTypes.instanceOf(Array).isRequired,
};

export default ShareHistoryDialog;
