import { useEffect, useState } from 'react';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import Button from 'components/Button';
import Loader from 'components/SectionLoader';
import MembersSelectDialog from 'components/dialogs/MembersSelectDialog';
import MemberCard from 'components/cards/MemberCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedUnit, setOrganizationSelectedUnit } from 'features/organizationSlice';
import { fetchMembers, fetchUnit, updateUnit } from 'api/portal';
import { Grid, Pagination, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Fade from 'components/Fade';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { showErrorToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';
import MemberTable from 'components/tables/MemberTable';
import App from '../template';
import styles from './styles.module.css';

const PAGE_SIZE = 8;

function UnitMembers() {
  const [isLoading, setIsLoading] = useState(false);
  const [unitMembers, setUnitMembers] = useState([]);
  const [isSavingMembers, setIsSavingMembers] = useState(false);
  const [isToShowMembersDialog, setIsToShowMembersDialog] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [view, setView] = useState('cards');
  const [loadingError, setLoadingError] = useState(false);

  const organization = useSelector((state) => state.organization);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();

  const { unitId } = params;

  const fetchAllMembersHandler = () => {
    const searchParams = new URLSearchParams();

    searchParams.set('page_size', 9999);

    fetchMembers(searchParams)
      .then((response) => {
        const { data } = response;

        if (data && data.results.length > 0) {
          setAllMembers(data.results);
        }
      })
      .catch(() => {
        dispatch(showErrorToast('organization.organization-units-members.load-members-failure'));
      });
  };

  const fetchUnitMembersHandler = (page) => {
    setIsLoading(true);

    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('unit', unitId);
    searchParams.set('page_size', PAGE_SIZE);

    setLoadingError(false);
    setCurrentPage(page);

    fetchMembers(searchParams)
      .then((response) => {
        const { data } = response;

        setIsLoading(false);
        setLoadingError(false);

        const unitMembers = data.results;

        if (data && unitMembers.length > 0) {
          const selectedIds = unitMembers.map((member) => member.id);

          setUnitMembers(unitMembers);
          setSelectedIds(selectedIds);
          setCount(Math.ceil(data.count / PAGE_SIZE));
        }
      })
      .catch(() => {
        setIsLoading(false);
        setLoadingError(true);
        dispatch(showErrorToast('organization.organization-units-members.load-unit-members-failure'));
      });
  };

  useEffect(() => {
    const unit = organization ? organization.selectedUnit : organization;

    if (unit.id !== Number(unitId)) {
      dispatch(clearOrganizationSelectedUnit());

      setIsLoading(true);

      fetchUnit(unitId)
        .then((response) => {
          const { data } = response;

          dispatch(setOrganizationSelectedUnit(data));
          fetchAllMembersHandler();
          fetchUnitMembersHandler(1);
        })
        .catch(() => {
          navigate('/organization/units');
        });
    } else {
      fetchAllMembersHandler();
      fetchUnitMembersHandler(1);
    }
  }, []);

  const handleCheckboxToggle = (id) => {
    let selectedMembersId = [];

    if (selectedIds.includes(id)) {
      selectedMembersId = selectedIds.filter((item) => item !== id);
    } else {
      selectedMembersId = [...selectedIds, id];
    }

    setSelectedIds(selectedMembersId);
  };

  const handleAddingMembers = () => {
    setIsSavingMembers(true);

    updateUnit(unitId, { members: selectedIds })
      .then(() => {
        setIsSavingMembers(false);
        setIsToShowMembersDialog(false);
        fetchUnitMembersHandler(1);
      })
      .catch(() => {
        setIsSavingMembers(false);
        setIsToShowMembersDialog(false);
        dispatch(showErrorToast('messages.save-failure'));
      });
  };

  const renderSelectMembersDialog = () => (
    <MembersSelectDialog
      title={t('organization.organization-units-members.title-box-unit-member')}
      allMembers={allMembers}
      selectedIds={selectedIds}
      isSavingMembers={isSavingMembers}
      show={isToShowMembersDialog}
      onCheckboxClick={handleCheckboxToggle}
      onDialogToggle={() => setIsToShowMembersDialog(!isToShowMembersDialog)}
      onAddClick={handleAddingMembers}
    />
  );

  const renderMemberList = () => {
    if (loadingError) {
      return (
        <div className={styles.emptyList} data-testid="error-member-list">
          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('organization.organization-units-members.load-unit-members-failure')}
          </Typography>
          <Button variant="outlined" onClick={() => fetchUnitMembersHandler(currentPage)}>
            {t('organization.organization-units-members.text-try-again')}
          </Button>
        </div>
      );
    }

    if (unitMembers.length > 0) {
      if (view === 'cards') {
        return (
          <Fade>
            <Grid container spacing="24px">
              {unitMembers.map((member) => (
                <Grid key={`member-card-${member.id}`} item xs={6} lg={3}>
                  <MemberCard
                    sx={{ height: '100%' }}
                    member={member}
                    onClick={() => navigate(`/organization/members/${member.id}/edit/info`)}
                    onClickButton={() => navigate(`/organization/members/${member.id}/edit/password`)}
                    data-testid={`member-card-${member.id}`}
                  />
                </Grid>
              ))}
            </Grid>
          </Fade>
        );
      }

      return (
        <Fade>
          <MemberTable
            members={unitMembers}
            onClick={(member) => navigate(`/organization/members/${member.id}/edit/info`)}
            onClickButton={(member) => navigate(`/organization/members/${member.id}/edit/password`)}
          />
        </Fade>
      );
    }

    return (
      <div className={styles.emptyList}>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-units-members.text-unit-member-empty')}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => setIsToShowMembersDialog(true)}
          data-testid="button-add-remove-member"
        >
          {t('organization.organization-units-members.button-add-remove-member')}
        </Button>
      </div>
    );
  };

  return (
    <App>
      <Section>
        <div className={styles.memberHeader}>
          <div>
            <Subtitle>{t('organization.organization-units-members.menu-member-info')}</Subtitle>
            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('organization.organization-units-members.text-unit-member-list')}
            </Typography>
          </div>
          {unitMembers.length > 0 || loadingError ? (
            <Button
              variant="outlined"
              onClick={() => setIsToShowMembersDialog(true)}
              data-testid="button-add-remove-member"
            >
              {t('organization.organization-units-members.button-add-remove-member')}
            </Button>
          ) : null}
        </div>
        {renderSelectMembersDialog()}
        <ToggleButtonGroup
          sx={{ margin: '16px 0' }}
          value={view}
          exclusive
          onChange={(event, nextView) => {
            if (nextView) {
              setView(nextView);
            }
          }}
        >
          <ToggleButton value="cards" aria-label="cards">
            <ViewModuleIcon />
          </ToggleButton>
          <ToggleButton value="list" aria-label="list">
            <ViewListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <Loader loading={isLoading}>{renderMemberList()}</Loader>
        <div className={styles.footer}>
          <Button variant="outlined" onClick={() => navigate('/organization/units')} data-testid="button-cancel">
            {t('organization.organization-units-members.button-cancel')}
          </Button>
          <Pagination count={count} page={currentPage} onChange={(event, value) => fetchUnitMembersHandler(value)} />
        </div>
      </Section>
    </App>
  );
}

export default UnitMembers;
