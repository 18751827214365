import PropTypes from 'prop-types';
import { Avatar, Chip } from '@mui/material';
import { getNameInitials } from 'utils/format';

const ProfileTag = ({ avatar, name, iconClick, ...props }) => (
  <Chip
    sx={{ '& .MuiChip-avatar': { color: 'white' } }}
    avatar={
      avatar ? (
        <Avatar src={avatar} alt={name} />
      ) : (
        <Avatar
          sx={{
            backgroundColor: 'var(--color-primary)',
            color: 'white',
          }}
        >
          {getNameInitials(name)}
        </Avatar>
      )
    }
    label={name}
    onDelete={iconClick}
    {...props}
  />
);

ProfileTag.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  iconClick: PropTypes.func,
  className: PropTypes.string,
};

export default ProfileTag;
