import { useEffect, useState } from 'react';
import Section from 'components/Section';
import Subtitle from 'components/Subtitle';
import Loader from 'components/SectionLoader';
import Header from 'components/dialogs/DialogHeader';
import Button from 'components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrganizationSelectedMobileDevice, setOrganizationSelectedMobileDevice } from 'features/organizationSlice';
import { fetchActiveMembers, fetchMobileDevices } from 'api/portal';
import { showErrorToast } from 'features/toastSlice';
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Fade from 'components/Fade';
import { isTrustedDevice } from 'utils/mobile-devices';
import DialogMemberPinUpdate from 'components/dialogs/PinRedefinitionDialog';
import MemberItem from 'components/MemberItem';
import App from '../template';

function MobileDevicePinRedefintion() {
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isToShowErrorAsync, setIsToShowErrorAsync] = useState(false);
  const [isToShowErrorPending, setIsToShowErrorPending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMembers, setErrorMembers] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { deviceId } = params;

  const mobileDevice = useSelector((state) => state.organization.selectedMobileDevice);

  const { t } = useTranslation();

  const ERROR_FETCHING_MEMBERS = t('organization.organization-mobile-devices-edit.error-fetching-device-members');
  const TEXT_REDEFINE_PIN = t('organization.organization-mobile-devices-edit.text-member-card-edit-member-pin');
  const TEXT_NON_ANSWERED_INVITATION = t('organization.organization-mobile-devices-edit.text-non-answered-invitation');
  const TEXT_NOT_SYNCED_MEMBER = t('organization.organization-mobile-devices-edit.text-member-card-not-synced-member');

  const onErrorFetchingDevice = () => {
    navigate('/org-mobile-devices/devices');
    dispatch(showErrorToast('organization.organization-mobile-devices-edit.error-fetch-device'));
  };

  const getActiveMembers = () => {
    setIsLoading(true);
    setErrorMembers(false);

    fetchActiveMembers(deviceId)
      .then((response) => {
        const { data } = response;
        setMembers(data.active_members);
        setIsLoading(false);
        setErrorMembers(false);
      })
      .catch(() => {
        dispatch(showErrorToast('organization.organization-mobile-devices-edit.error-fetching-device-members'));
        setIsLoading(false);
        setErrorMembers(true);
      });
  };

  useEffect(() => {
    if (mobileDevice.org_device_id !== deviceId) {
      dispatch(clearOrganizationSelectedMobileDevice());
    }

    setIsLoading(true);

    fetchMobileDevices(new URLSearchParams(), { org_device_id: deviceId })
      .then((response) => {
        const { data } = response;

        if (!data.results) {
          onErrorFetchingDevice();
        } else if (data.results.length === 1) {
          const device = data.results[0];

          if (isTrustedDevice(device.status)) {
            dispatch(clearOrganizationSelectedMobileDevice());
            dispatch(setOrganizationSelectedMobileDevice(device));
            getActiveMembers();
          } else {
            onErrorFetchingDevice();
          }
        } else {
          onErrorFetchingDevice();
        }
      })
      .catch(() => {
        onErrorFetchingDevice();
      });
  }, []);

  const renderErrorAsyncUser = () => (
    <Dialog open={isToShowErrorAsync} data-testid="non-synced-dialog">
      <Header data-testid="non-synced-dialog-header">
        {t('organization.organization-mobile-devices-edit.log-dialog.header-async')}
      </Header>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-mobile-devices-edit.log-dialog.text-async')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={() => setIsToShowErrorAsync(false)}>
          {t('organization.organization-mobile-devices-edit.log-dialog.button-back')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderErrorPendingUser = () => (
    <Dialog open={isToShowErrorPending} data-testid="pending-dialog">
      <Header>{t('organization.organization-mobile-devices-edit.log-dialog.header-pending')}</Header>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="24px">
          {t('organization.organization-mobile-devices-edit.log-dialog.text-pending')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={() => setIsToShowErrorPending(false)}>
          {t('organization.organization-mobile-devices-edit.log-dialog.button-back')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const getSecondaryText = (member) => {
    if (member.synced) {
      return TEXT_REDEFINE_PIN;
    }

    if (member.status === 'PENDING') {
      return TEXT_NON_ANSWERED_INVITATION;
    }

    return TEXT_NOT_SYNCED_MEMBER;
  };

  const renderResults = () => {
    if (errorMembers) {
      return (
        <Typography variant="body2" color="error">
          {ERROR_FETCHING_MEMBERS}
        </Typography>
      );
    }

    return members.map((member) => (
      <MemberItem
        key={`member-${member.id}`}
        image={member.picture}
        primaryText={member.name}
        secondaryText={getSecondaryText(member)}
        disabled={!member.synced}
        hasIcon={!member.synced || member.status === 'PENDING'}
        onClick={() => {
          if (member.synced) {
            setSelectedMember(member);
          } else if (member.status === 'PENDING') {
            setIsToShowErrorPending(true);
          } else {
            setIsToShowErrorAsync(true);
          }
        }}
        data-testid={`member-item-${member.id}`}
      />
    ));
  };

  return (
    <App device={mobileDevice}>
      <Section maxWidth="428px">
        <Subtitle>{t('organization.organization-mobile-devices-edit.title-sub-member-pin-edit')}</Subtitle>
        {renderErrorPendingUser()}
        {renderErrorAsyncUser()}

        <Loader loading={isLoading}>
          <Fade>
            <Box maxWidth="428px" marginBottom="48px">
              <Typography variant="body2" color="secondary" marginBottom="24px">
                {t('organization.organization-mobile-devices-edit.text-member-pin-edit')}
              </Typography>
              {renderResults()}
            </Box>
          </Fade>
        </Loader>
      </Section>
      <DialogMemberPinUpdate deviceId={deviceId} member={selectedMember} setSelectedMember={setSelectedMember} />
      <Button variant="outlined" onClick={() => navigate('/org-mobile-devices/devices')} data-testid="back-button">
        {t('organization.organization-mobile-devices-create.button-back')}
      </Button>
    </App>
  );
}

export default MobileDevicePinRedefintion;
