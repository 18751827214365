import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import styles from './styles.module.css';

const Brand = ({ image, title, href, ...props }) => {
  const renderImage = () => <img src={image} alt={title} className={styles.brand} />;

  return (
    <Box {...props}>
      {href ? (
        <Link to={href} className={styles.link}>
          {renderImage()}
        </Link>
      ) : (
        renderImage()
      )}
    </Box>
  );
};

Brand.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
};

export default Brand;
