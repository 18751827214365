import { useState } from 'react';
import braincare from 'components/routes/braincare';
import { Alert, Box, Typography } from '@mui/material';
import Subtitle from 'components/Subtitle';
import SubSubtitle from 'components/SubSubtitle';
import Loader from 'components/SectionLoader';
import App from 'pages/braincare/users/template';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteBuUser, updateBuUser } from 'api/portal';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { showErrorToast, showSuccessToast } from 'features/toastSlice';
import { useTranslation } from 'react-i18next';
import SuspendUserDialog from 'components/dialogs/SuspendUserDialog';
import DeleteUserDialog from 'components/dialogs/DeleteUserDialog';
import { useFetchUserAdmin } from 'hooks/useFetchUserAdmin';
import Fade from 'components/Fade';

function AdminUserSuspension() {
  const [user, setUser] = useState(undefined);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isActive, setIsActive] = useState(true);

  const [isSuspending, setIsSuspending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showSuspendDialog, setShowSuspendDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { userId } = params;

  const { t } = useTranslation();

  useFetchUserAdmin({
    userId,
    onSuccess: (data) => {
      setUser(data);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setIsActive(data.is_active);
    },
    onFailure: () => {
      dispatch(showErrorToast('messages.load-failure'));
      navigate('/braincare/users');
    },
  });

  const changeAccountStatus = (status) => {
    setShowSuspendDialog(false);
    setIsSuspending(true);

    updateBuUser(userId, {
      is_active: status,
    })
      .then(() => {
        setIsSuspending(false);
        dispatch(showSuccessToast('messages.save-success'));
        setIsActive(status);
      })
      .catch(() => {
        setIsSuspending(false);
        dispatch(showErrorToast('messages.save-failure'));
      });
  };

  const deleteUserAccount = () => {
    setShowDeleteDialog(false);
    setIsDeleting(true);

    deleteBuUser(userId)
      .then(() => {
        setIsDeleting(false);
        dispatch(showSuccessToast('messages.delete-success'));
        navigate('/braincare/users');
      })
      .catch(() => {
        setIsDeleting(false);
        dispatch(showErrorToast('messages.delete-failure'));
      });
  };

  const renderSuspendAccount = () => (
    <div>
      <SubSubtitle data-testid="subsubtitle-suspend">
        {t('braincare.users-edit-suspend.title-sub-member-suspend')}
      </SubSubtitle>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('braincare.users-edit-suspend.text-organization-members-suspend')}
      </Typography>
      <Button
        variant="outlined"
        loading={isSuspending}
        onClick={() => setShowSuspendDialog(true)}
        data-testid="suspend-button"
      >
        {t('braincare.users-edit-suspend.button-member-suspend')}
      </Button>
    </div>
  );

  const renderUnsuspendAccount = () => (
    <Alert variant="outlined" icon={false} severity="warning">
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('braincare.users-edit-suspend-revert.title-box-member-suspended')}
      </Typography>
      <Typography variant="body2" color="secondary" marginBottom="24px">
        {t('braincare.users-edit-suspend-revert.text-box-organization-member-suspend')}
      </Typography>
      <Button
        variant="outlined"
        color="warning"
        loading={isSuspending}
        onClick={() => changeAccountStatus(true)}
        data-testid="unsuspend-button"
      >
        {t('braincare.users-edit-suspend-revert.button-suspend-revert')}
      </Button>
    </Alert>
  );

  return (
    <App title={user ? `${firstName} ${lastName}` : undefined}>
      <Loader loading={!user}>
        <Fade>
          <Subtitle>{t('braincare.users-edit-suspend.title-main-member-suspend')}</Subtitle>
          <Typography variant="body2" color="secondary" marginBottom="24px">
            {t('braincare.users-edit-suspend.text-organization-members-suspend-exclude')}
          </Typography>
          <Box marginBottom="48px">{!isActive ? renderUnsuspendAccount() : renderSuspendAccount()}</Box>

          <Box marginBottom="48px">
            <SubSubtitle data-testid="subsubtitle-delete">
              {t('braincare.users-edit-suspend.title-sub-member-exclude')}
            </SubSubtitle>
            <Typography variant="body2" color="secondary" marginBottom="24px">
              {t('braincare.users-edit-suspend.text-organization-members-exclude')}
            </Typography>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setShowDeleteDialog(true)}
              loading={isDeleting}
              disabled={!user}
              data-testid="delete-button"
            >
              {t('braincare.users-edit-suspend.button-member-exclude')}
            </Button>
          </Box>
          <SuspendUserDialog
            open={showSuspendDialog}
            onClose={() => setShowSuspendDialog(true)}
            onSuspend={() => changeAccountStatus(false)}
          />
          <DeleteUserDialog
            open={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={deleteUserAccount}
          />
          <Button variant="outlined" onClick={() => navigate('/braincare/users')} data-testid="cancel-button">
            {t('braincare.users-edit-suspend.button-cancel')}
          </Button>
        </Fade>
      </Loader>
    </App>
  );
}

export default braincare(AdminUserSuspension);
