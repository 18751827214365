import { useState } from 'react';
import Header from 'components/dialogs/DialogHeader';
import Button from 'components/Button';
import LinkItem from 'components/LinkItem';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function LoginDialog({ errors, isOpen, isSubmitting, onClose, onSubmit, organization }) {
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
    setPassword('');
    setIsPasswordVisible(false);
  };

  return (
    <Dialog open={isOpen} data-testid="login-dialog">
      <Header>
        {organization
          ? t('auth.accounts-corp-login.button-account-config')
          : t('auth.accounts-personal-login.button-account-config')}
      </Header>
      <DialogContent>
        <Typography variant="body2" color="secondary" marginBottom="32px">
          {organization
            ? t('auth.accounts-corp-login.title-box-corp-login', { organization: organization.name })
            : t('auth.accounts-personal-login.title-box-personal-login')}
        </Typography>
        <FormControl error={errors.has('password')} fullWidth>
          <InputLabel data-testid="password-label">{t('auth.accounts-corp-login.input-password')}</InputLabel>
          <OutlinedInput
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            type={isPasswordVisible ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  edge="end"
                >
                  {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{ 'data-testid': 'password-input' }}
          />
          <FormHelperText data-testid="password-error">{errors.get('password')}</FormHelperText>
        </FormControl>
        <LinkItem
          href={`${organization ? `/auth/forgot-password/${organization.id}` : '/auth/forgot-password'}`}
          data-testid="forgot-password-link"
        >
          {t('auth.accounts-corp-login.link-password-reset')}
        </LinkItem>
      </DialogContent>
      <DialogActions>
        <Button
          color="gradient"
          onClick={() => onSubmit({ password })}
          loading={isSubmitting}
          data-testid="submit-button"
        >
          {organization
            ? t('auth.accounts-corp-login.button-login-corp')
            : t('auth.accounts-personal-login.button-login-personal')}
        </Button>
        <Button sx={{ margin: '0 0 0 16px' }} variant="outlined" onClick={handleClose}>
          {t('auth.accounts-corp-login.button-cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LoginDialog;
