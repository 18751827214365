import Loader from 'components/Loader';
import { Box } from '@mui/material';
import styles from './styles.module.css';

function SectionLoader({ loading, children, ...props }) {
  return loading ? (
    <Box display="flex" justifyContent="center" alignItems="center" padding="100px" width="100%" {...props}>
      <Loader className={styles.loader} />
    </Box>
  ) : (
    children
  );
}

export default SectionLoader;
