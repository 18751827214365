import { analyticsApi } from './http';

export const getDoctors = () => analyticsApi().get('doctors');
export const getDoctorsByOrg = (orgId) => analyticsApi().get(`/${orgId}/doctors`);
export const getPatientHashes = () => analyticsApi().get('patients-hashes');
export const getAcquisitions = (data) => analyticsApi().post('acquisitions', data);
export const getAcquisitionsByOrg = (data, orgId) => analyticsApi().post(`/${orgId}/acquisitions`, data);
export const getTags = () => analyticsApi({ statusException: false }).get('tags');
export const getTagsByOrg = (orgId) => analyticsApi({ statusException: false }).get(`/${orgId}/tags`);
export const reportAuthorization = (data) => analyticsApi().post('report-authorization', data);
export const reportAuthorizationByOrg = (data, orgId) => analyticsApi().post(`/${orgId}/report-authorization`, data);
export const getShareLink = (data) => analyticsApi().post('/share-link', data);
export const share = (data) => analyticsApi().post('/share', data);
